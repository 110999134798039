import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-resellerapproveplan',
  templateUrl: './resellerapproveplan.component.html',
  styleUrls: ['./resellerapproveplan.component.css']
})
export class ResellerapproveplanComponent implements OnInit {
records:any;
baseurl:any;
  constructor(private http:HttpClient,private fb:FormBuilder, private route:Router) {  }

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
this.getrecords();
  }

  getrecords(){
 $(".fullpageloader").fadeIn('fast');
this.http.get(this.baseurl+"approve_plan_listview").subscribe((data:any)=>{
 this.records = data;
 $(".fullpageloader").fadeOut('fast');

})
  }
navogateUrl(ress_id,plan_id){
   this.route.navigate(['/resseller/resellerviewplan',ress_id,plan_id]);

}
}
