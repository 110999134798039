import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';
@Component({
  selector: 'app-smsreport',
  templateUrl: './smsreport.component.html',
  styleUrls: ['./smsreport.component.css']
})
export class SmsreportComponent implements OnInit {

    servicelog;
    itemsPerPage:any=15;
   currentPage:number =1;
     filterform:FormGroup;
   submitted=false;
   today= new Date();
   jstoday = '';
   constructor(private http:HttpClient,private fb:FormBuilder) { 
 
  this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
     this.filterform = this.fb.group({
      fromdate: [''],
       todate: [''],
       companyname:[''],
       username:['']
     })
 
   }
 
   ngOnInit() {
  
 this.getdata(this.currentPage,'','','','')
 $("#reptype").on('change',function(){
   //alert($(this).val());
   switch($(this).val()){
     case 'bycompanyname':
   $("#bycompanyname").show('fast');
   $("#byusername").hide('fast');
   break;
   case 'byusername':
   $("#byusername").show('fast');
   $("#bycompanyname").hide('fast');
   break;
   default:
  $("#byusername,#bycompanyname").hide('fast');
 
   }
 })
   }
 
 onSubmit()
 {
    this.submitted=true;
   //console.log(this.filterform.value);
  
    let  companyname=this.filterform.value.companyname;
     let  userid=this.filterform.value.username;
    let fromdate=$('#fromdate').val();
    let todate=$('#todate').val()
   
    $('.fullpageloader').show('fast');
     this.http.get(' https://api.cerebra.quadrafort.in/client_sms_report?companyname='+companyname+'&fromdate='+fromdate+'&todate='+todate+'&userid='+userid).subscribe(
     (data)=>{
       this.servicelog=data;
         $('.cfillBox').slideUp('fast');
        $('.fullpageloader').hide('fast');
 
     })
 
 }
 
   getdata(crpage:number,companyname,fromdate,todate,userid)
   {
     $('.fullpageloader').show('fast');
      this.http.get(' https://api.cerebra.quadrafort.in/client_sms_report?page='+crpage+'&companyname='+companyname+'&fromdate='+fromdate+'&todate='+todate+'&userid='+userid).subscribe(
      (data)=>{
         this.servicelog=data;
         $('.fullpageloader').hide('fast');
      });
   }
 
    pageChange(newpage:number){
      let  companyname=this.filterform.value.companyname;
      let  userid=this.filterform.value.username;
     let fromdate=$('#fromdate').val();
     let todate=$('#todate').val()
     this.getdata(newpage,companyname,fromdate,todate,userid);
     this.currentPage = newpage;
       }
}
