import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-execmanagement',
  templateUrl: './execmanagement.component.html',
  styleUrls: ['./execmanagement.component.css']
})
export class ExecmanagementComponent implements OnInit {
submitted:boolean = false;
execForm:FormGroup;
execrecords:any;
currentUserloclstrage:any;
usertype=['Sales','Telesales','Technical','SuperAdmin','Admin'];

constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService){ 
  
}

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
   this.createForm(); 
this.getRecord();
$(".addlink").on('click',function(){
  $('#addexecutive form')[0].reset();
});
 
  }

  createForm(){
    this.execForm = this.fb.group({
      name:['',[Validators.required]],
      email:['',[Validators.required,Validators.email]],
      mobile:['',[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
      usertype:['',[Validators.required]],
      password:['',[Validators.required]],
      execid:[''],
    })
  }

  getRecord(){
    $(".fullpageloader").fadeIn('fast');
   this.http.get(" https://api.cerebra.quadrafort.in/execmanagement").subscribe((data:any)=>{
     this.execrecords = data;
     $(".fullpageloader").fadeOut('fast');
   })
  }
  editRecord(id:number){
    $(".fullpageloader").fadeIn('fast');
this.http.post(" https://api.cerebra.quadrafort.in/editexec?uid="+id,{},{}).subscribe((data:any)=>{
//console.log(data);
this.execForm.setValue({
  name:data[0].name,
  email:data[0].email,
  mobile:data[0].mobile,
  usertype:data[0].user_type,
  password:data[0].password,
  execid:data[0].executive_id
})
 });

 $("#addexecutive").modal('show');
 $(".fullpageloader").fadeOut('fast');
  }

  changestatus(execid:number,event:any){
    let status = event.target.checked;
this.http.post(" https://api.cerebra.quadrafort.in/exechangestatus?execid="+execid+"&status="+status,{},{}).subscribe((data:any)=>{
console.log(data);
if(data.status=='success'){
  this.toastr.success('Status Change  succesfully!', 'Success!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
  this.getRecord();
}
})
  }
  get f(){return this.execForm.controls};
  onSubmit(){
    this.submitted = true;
    let execformdata = this.execForm.value;
if(this.execForm.invalid){
return false;
}
else{
  $(".fullpageloader").fadeIn('fast');
  this.http.post(" https://api.cerebra.quadrafort.in/addexec",{data:execformdata}).subscribe((data:any)=>{
      console.log(data);
  $(".fullpageloader").hide('fast');
    if(data.status=="success"){
      $("#addexecutive").modal('hide');
      this.toastr.success('Data Submitted  succesfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      this.getRecord();
      this.execForm.reset();
    }
    else if(data.status=="fail"){
      
     this.toastr.error(data.message, 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
     
    }
    
  },
  error => {
    this.toastr.error(error, 'error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
})
}
  }

}
