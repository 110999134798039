import { Component, OnInit,ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-networkview',
  templateUrl: './networkview.component.html',
  styleUrls: ['./networkview.component.css']
})
export class NetworkviewComponent implements OnInit {

  roleid:any;
  records:any;
  constructor(private http:HttpClient, private router:Router , private route:ActivatedRoute) { }

  ngOnInit() {
   this.roleid = this.route.snapshot.paramMap.get("roleid");
    this.getrecord();
}

getrecord(){
  $(".fullpageloader").fadeIn('fast');
this.http.get(" https://api.cerebra.quadrafort.in/migration_list_detail?role_id="+this.roleid).subscribe((data:any)=>{
  $(".fullpageloader").fadeOut('fast');
this.records = data;
})
}

}
