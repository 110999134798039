import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';
@Component({
  selector: 'app-overallsms',
  templateUrl: './overallsms.component.html',
  styleUrls: ['./overallsms.component.css']
})
export class OverallsmsComponent implements OnInit {

    servicelog;
    itemsPerPage:any=15;
   currentPage:number =1;
     filterform:FormGroup;
   submitted=false;
   today= new Date();
   jstoday = '';
   constructor(private http:HttpClient,private fb:FormBuilder) { 
 
  this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
     this.filterform = this.fb.group({
       user_id: ['', ],
       fromdate: [''],
       todate: [''],
       mobile:[''],
       email:['']
     })
 
   }
 
   ngOnInit() {
  
 this.getdata(this.currentPage)
 $("#reptype").on('change',function(){
   //alert($(this).val());
   switch($(this).val()){
     case 'bymobile':
   $("#bymobile").show('fast');
   $("#byemail,#byuserid").hide('fast');
   break;
   case 'byemail':
   $("#byemail").show('fast');
   $("#bymobile,#byuserid").hide('fast');
   break;
   case 'byuserid':
   $("#byuserid").show('fast');
   $("#byemail,#bymobile").hide('fast');
   break;
   default:
  $("#byemail,#byuserid,#bymobile").hide('fast');
 
   }
 })
   }
 
 onSubmit()
 {
    this.submitted=true;
   //console.log(this.filterform.value);
    let user_id=this.filterform.value.user_id;
    let  mobile=this.filterform.value.mobile;
     let  email=this.filterform.value.email;
    let fromdate=$('#fromdate').val();
    let todate=$('#todate').val()
   
    $('.fullpageloader').show('fast');
     this.http.get(' https://api.cerebra.quadrafort.in/service_alert_logs?user_id='+user_id+'&fromdate='+fromdate+'&todate='+todate+'&email='+email+'&mobile='+mobile).subscribe(
     (data)=>{
       this.servicelog=data;
         $('.cfillBox').slideUp('fast');
        $('.fullpageloader').hide('fast');
 
     })
 
 }
 
   getdata(crpage:number)
   {
     $('.fullpageloader').show('fast');
      this.http.get(' https://api.cerebra.quadrafort.in/service_alert_logs?page='+crpage).subscribe(
      (data)=>{
         this.servicelog=data;
         $('.fullpageloader').hide('fast');
      });
   }
 
    pageChange(newpage:number){
     this.getdata(newpage);
     this.currentPage = newpage;
       }
}
