import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-topuprequest',
  templateUrl: './topuprequest.component.html',
  styleUrls: ['./topuprequest.component.css']
})
export class TopuprequestComponent implements OnInit {
  
  topup;
  currentUserloclstrage:any;
  baseurl:any;
  sheet_no:any;
  servicetimer:any;
  constructor(private http:HttpClient, private route:Router,private toastr:ToastrService) { }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.baseurl=localStorage.getItem('baseurl');
  this.getdata();
 this.servicetimer= setInterval(() => {
    this.getdata(); 
    }, 10000);
  }

  getdata()
  {
     //$('.fullpageloader').show();
      this.http.get(' https://api.cerebra.quadrafort.in/topup_request').subscribe(
      data=>{
      this.topup=data;

      $('.fullpageloader').hide();

      })
  }

  navigateRoute(id:number,userid:string,service_type:string,topup,topup_type,amount,topup_remarks,sms_rate,dlt_rate){
    if(service_type=='VSMS' || service_type=='vsms' ){
      this.route.navigate(['/crequest/topupsms',id,userid,service_type,topup,topup_type,amount,topup_remarks,sms_rate,dlt_rate]);
    }
    else{
      this.route.navigate(['/crequest/topup',id,userid,service_type,topup,topup_type,amount,topup_remarks]);
    }
    
 }

disapprove(sheet_id)
{
this.sheet_no=sheet_id;
$('#disapprovedremark').val('');
$("#disapprovedmodal").modal('show');

} 

disapproveBtn(){
  if($('#disapprovedremark').val()!=''){
    $('.fullpageloader').show();
    this.http.post(this.baseurl+'disapprove_service',{sheet_id:this.sheet_no,remarks:$('#disapprovedremark').val(),type:'topup'}).subscribe(
   (data:any)=>{
    
   if(data.status=='success')
   {
      this.toastr.success(data.message, 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      $("#disapprovedmodal").modal('hide');
   }
   else
   {
      this.toastr.error(data.message, 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  
   }
   $('.fullpageloader').hide();

   })
  }
  else{
 $("#disapprovedremark").addClass("is-invalid");
 setTimeout(() => {
  $("#disapprovedremark").removeClass("is-invalid");
 }, 4000);
  }
}

ngOnDestroy(){
  clearInterval(this.servicetimer);
}

}
