import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';


@Component({
  selector: 'app-apierrorlog',
  templateUrl: './apierrorlog.component.html',
  styleUrls: ['./apierrorlog.component.css']
})
export class ApierrorlogComponent implements OnInit {
 errorlog;

   itemsPerPage:any=15;
  currentPage:number =1;
  filterform:FormGroup;
  submitted=false;
  today= new Date();
  jstoday = '';
  baseurl:any;

  constructor(private http:HttpClient,private fb:FormBuilder) {
  
   this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    this.filterform = this.fb.group({
      user_id: ['', ],
      fromdate: [this.jstoday],
      todate: [this.jstoday],
     
    })

   }

 

  ngOnInit() {
    var self = this;
    this.baseurl = localStorage.getItem('baseurl');
  this.getdata(this.currentPage,'','','');
    $("#logtype").on('change',function(){
      switch($(this).val()){
      case '2':
        $("#user_id").val('');
        self.filterform.value.user_id=''
      $("#byuserid").show('fast');
       break;
      default:
         $("#user_id").val('');
         self.filterform.value.user_id=''
          $("#byuserid").hide('fast');
    
      }
    })
  }

  onSubmit()
{  
  
   this.submitted=true;
  //console.log(this.filterform.value);

   let user_id=this.filterform.value.user_id;
   let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
  


   $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'api_error_logs?user_id='+user_id+'&fromdate='+fromdate+'&todate='+todate).subscribe(
    (data)=>{
      this.errorlog=data;
        $('.cfillBox').slideUp('fast');
       $('.fullpageloader').hide('fast');

    })


 
}

  getdata(crpage:number,user_id,fromdate,todate)
  {
   $('.fullpageloader').show();
     this.http.get(this.baseurl+'api_error_logs?page='+crpage+'&user_id='+user_id+'&fromdate='+fromdate+'&todate='+todate).subscribe(
     data=>{
      this.errorlog=data;
       $('.fullpageloader').hide();
    

     });
  }


   pageChange(newpage:number){
    let user_id=this.filterform.value.user_id;
    let fromdate=$('#fromdate').val();
    let todate=$('#todate').val();
    this.getdata(newpage,user_id,fromdate,todate);
    this.currentPage = newpage;
      }

}
