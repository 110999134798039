import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-topup',
  templateUrl: './upgradeplansms.component.html',
  styleUrls: ['./upgradeplansms.component.css']
})
export class UpgradeplansmsComponent implements OnInit {
  records:any;
  submitted:boolean = false;
  upgradeForm:FormGroup;
  user_id:any;
  service_type:any;
  ren_upgrade_id:any;
baseurl:any;
  topup_remarks:any;
  dlt_rate:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute){
    this.ren_upgrade_id = this.route.snapshot.paramMap.get("id");
    this.user_id = this.route.snapshot.paramMap.get("user_id");
    this.service_type = this.route.snapshot.paramMap.get("service_type");
    this.dlt_rate = this.route.snapshot.paramMap.get("dlt_rate");

  } 

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
this.createForm();
this.getdata(this.ren_upgrade_id,this.user_id,this.service_type);
  }
createForm(){
this.upgradeForm = this.fb.group({
  sms_rate:'',
  total_coins:'',
  validity:'',
  service_amount:'',
  remark:['',[Validators.required]],
  ren_upgrade_id:'',
  user_id:'',
  service_type:'',
  session_id:''
})
  }

get f(){return this.upgradeForm.controls}
onSubmit(){
this.submitted= true;
let upgradeFormdata = JSON.stringify(this.upgradeForm.value);
if(this.upgradeForm.invalid){
return false;
}
else{
  //alert(upgradeFormdata);
  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+"vsms_ren_upgrade",{data:upgradeFormdata}).subscribe((data:any)=>{
   
    if(data.status=='success'){
      this.toastr.success('Data Submitted  succesfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      $(".fullpageloader").fadeOut('fast');
      this.router.navigateByUrl('crequest/upgraderequest');
    }
    else if(data.status=="fail"){
      console.log("fail");
      this.toastr.error(data.message, 'Error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
       $(".fullpageloader").fadeOut('fast');
     }
  }, error => {
    this.toastr.error(error, 'error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
    $(".fullpageloader").fadeOut('fast');
  }
  )
  
  }
}

getdata(ren_upgrade_id,user_id,service_type)
{
 $('.fullpageloader').show('fast');
  this.http.get(this.baseurl+"getupgrade_details?id="+ren_upgrade_id+"&user_id="+user_id+"&service_type="+service_type).subscribe(
  (data:any)=>{
   this.records=data;
   this.getcoins(data.new[0].sms_rate,data.new[0].service_amount,data.new[0].dlt_rate_in_paisa)
   //console.log(data.existing[0].billing);
 // console.log(JSON.stringify(this.records));
 
  this.upgradeForm.setValue({
   sms_rate:function(sms_rate,dlt_rate){return parseFloat(sms_rate)+parseFloat(dlt_rate);}(data.new[0].sms_rate,data.new[0].dlt_rate_in_paisa),
   total_coins:'',
   validity:data.new[0].service_validity,
   service_amount:data.new[0].service_amount,
    remark:'',
    ren_upgrade_id:this.ren_upgrade_id,
    user_id:this.user_id,
    service_type:this.service_type,
    session_id:JSON.parse(localStorage.getItem('currentUser')).user_id
  })
 
  $('.fullpageloader').hide('fast');

  });
}
 getcoins(smsrate,amount,dlt_rate){
    var totalcoin = Math.round(amount/((parseFloat(smsrate)+ parseFloat(dlt_rate))/100));
   // totalcoin= Math.round(totalcoin);
   setTimeout(() => {
  this.upgradeForm.get('total_coins').setValue(totalcoin);
  this.upgradeForm.get('total_coins').updateValueAndValidity();
   }, 300);
 }

}
