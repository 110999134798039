﻿import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination'; 
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
// used to create fake backend
import { fakeBackendProvider } from './_helpers';
import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './login';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { ContentrequestComponent } from './contentrequest/contentrequest.component';
import { ServicerequestComponent } from './servicerequest/servicerequest.component';
import { TopuprequestComponent } from './topuprequest/topuprequest.component';
import { RenewalrequestComponent } from './renewalrequest/renewalrequest.component';
import { UpgraderequestComponent } from './upgraderequest/upgraderequest.component';
import { VirtualinventryComponent } from './virtualinventry/virtualinventry.component';
import { DidinventryComponent } from './didinventry/didinventry.component';
import { AddvnumsingleComponent } from './addvnumsingle/addvnumsingle.component';
import { AddvnumbulkComponent } from './addvnumbulk/addvnumbulk.component';
import { AdddidsingleComponent } from './adddidsingle/adddidsingle.component';
import { AdddidbulkComponent } from './adddidbulk/adddidbulk.component';
import { ServicelistComponent } from './servicelist/servicelist.component';
import { AgentComponent } from './agent/agent.component';
import { AddagentComponent } from './addagent/addagent.component';
import { SettleamtlistComponent } from './settleamtlist/settleamtlist.component';
import { AddsettamountComponent } from './addsettamount/addsettamount.component';
import { SettleamountComponent } from './settleamount/settleamount.component';
import { CampaignlistComponent } from './campaignlist/campaignlist.component';
import { CampaginviewComponent } from './campaginview/campaginview.component';
import { VnumserviceComponent } from './vnumservice/vnumservice.component';
import { TollfreeserviceComponent } from './tollfreeservice/tollfreeservice.component';
import { ObdserviceComponent } from './obdservice/obdservice.component';
import { ClicktocallserviceComponent } from './clicktocallservice/clicktocallservice.component';
import { VleadserviceComponent } from './vleadservice/vleadservice.component';
import { VmanageserviceComponent } from './vmanageservice/vmanageservice.component';
import { SmsserviceComponent } from './smsservice/smsservice.component';
import { EmailaccsettComponent } from './emailaccsett/emailaccsett.component';
import { SmsaccsettComponent } from './smsaccsett/smsaccsett.component';
import { MinavomanagementComponent } from './minavomanagement/minavomanagement.component';
import { CrmComponent } from './crm/crm.component';
import { AddminavomgntComponent } from './addminavomgnt/addminavomgnt.component';
import { ServicealertlogComponent } from './servicealertlog/servicealertlog.component';
import { ApierrorlogComponent } from './apierrorlog/apierrorlog.component';
import { CrmapilogComponent } from './crmapilog/crmapilog.component';
import { AfterdisconnectapilogComponent } from './afterdisconnectapilog/afterdisconnectapilog.component';
import { ApilogComponent } from './apilog/apilog.component';
import { MrequestnavComponent } from './mrequestnav/mrequestnav.component';
import { NumberOnlyDirective } from './numberdigit/number.directive';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';  
import {ConfirmDialogService} from './confirm-dialog/confirm-dialog.service';  
import { ExecmanagementComponent } from './execmanagement/execmanagement.component' ;
import { MsvnumserviceComponent } from './msvnumservice/msvnumservice.component';
import { MstollfreeserviceComponent } from './mstollfreeservice/mstollfreeservice.component';
import { MsobdserviceComponent } from './msobdservice/msobdservice.component';
import { MssmsserviceComponent } from './mssmsservice/mssmsservice.component';
import { MsclicktocallserviceComponent } from './msclicktocallservice/msclicktocallservice.component';
import { MsvleadserviceComponent } from './msvleadservice/msvleadservice.component';
import { MsvmanageserviceComponent } from './msvmanageservice/msvmanageservice.component';
//report sec start
import { CallreportComponent } from './reports/callreport/callreport.component';
import { CalloverallComponent } from './reports/calloverall/calloverall.component';
import { CallviewreportComponent } from './reports/callviewreport/callviewreport.component';
import { CalloverallviewComponent } from './reports/calloverallview/calloverallview.component';
import { SmsreportComponent } from './reports/smsreport/smsreport.component';
import { OverallsmsComponent } from './reports/overallsms/overallsms.component';
import { PendingrenewalComponent } from './reports/pendingrenewal/pendingrenewal.component';
import { TopupreportComponent } from './reports/topupreport/topupreport.component';
import { RenewalextendComponent } from './reports/renewalextend/renewalextend.component';
import { DemoaccountComponent } from './reports/demoaccount/demoaccount.component';
import { DemodetailsComponent } from './reports/demodetails/demodetails.component';
import { CappedcallComponent } from './reports/cappedcall/cappedcall.component';
import { CappedcallviewComponent } from './reports/cappedcallview/cappedcallview.component';
import { EmailreportComponent } from './reports/emailreport/emailreport.component';
import { EmailoverallreportComponent } from './reports/emailoverallreport/emailoverallreport.component';
import { ClientdetailreportComponent } from './reports/clientdetailreport/clientdetailreport.component';
import { ServerpulsereportComponent } from './reports/serverpulsereport/serverpulsereport.component';
import { VirtualnumreportComponent } from './reports/virtualnumreport/virtualnumreport.component';
import { ClientwiseComponent } from './reports/clientwise/clientwise.component';
import { TopupComponent } from './topup/topup.component';
import { TopupsmsComponent } from './topupsms/topupsms.component';
import { RenewalComponent } from './renewal/renewal.component';
import { UpgradeplanComponent } from './upgradeplan/upgradeplan.component';
import { UpgradeplansmsComponent } from './upgradeplansms/upgradeplansms.component';
import { NetworkdidComponent } from './networkdid/networkdid.component';
import { NetworkviewComponent } from './networkview/networkview.component';
import { VconvoserviceComponent } from './vconvoservice/vconvoservice.component';
import { MsvconvoserviceComponent } from './msvconvoservice/msvconvoservice.component';
import { NewticketComponent } from './support/newticket/newticket.component';
import { SanitizeHtmlPipe } from './sanitize-html-pipe/sanitize-html.pipe';
import { CreatedemoComponent } from './createdemo/createdemo.component';
import { EditrequestComponent } from './editrequest/editrequest.component';
import { EditdetailComponent } from './editdetail/editdetail.component';
import { Obdc2ccliComponent } from './obdc2ccli/obdc2ccli.component';
import { CdrComponent } from './cdr/cdr.component';
import { SupportlistComponent } from './support/supportlist/supportlist.component';


import { RessellerresistrationComponent } from './ressellerresistration/ressellerresistration.component';
import { ManageresselerComponent } from './manageresseler/manageresseler.component';
import { ResellercreditbackComponent } from './resellercreditback/resellercreditback.component';
import { ResellerrenewalextendComponent } from './resellerrenewalextend/resellerrenewalextend.component';
import { ResellerapproveplanComponent } from './resellerapproveplan/resellerapproveplan.component';
import { ResellercreateplanComponent } from './resellercreateplan/resellercreateplan.component';
import { ResellerviewplanComponent } from './resellerviewplan/resellerviewplan.component';
import { ResellerprofileeditComponent } from './resellerprofileedit/resellerprofileedit.component';
import { ResellersettingComponent } from './resellersetting/resellersetting.component';
import { ResellerplanlistComponent } from './resellerplanlist/resellerplanlist.component';
import { ResellerplanlistviewComponent } from './resellerplanlistview/resellerplanlistview.component';
import { ResellervirtualinventryComponent } from './resellervirtualinventry/resellervirtualinventry.component';
import { AddressvnumdidsingleComponent } from './addressvnumdidsingle/addressvnumdidsingle.component';
import { AddressvnumdidbulkComponent } from './addressvnumdidbulk/addressvnumdidbulk.component';
import { ResellerrequestComponent } from './resellerrequest/resellerrequest.component';
import { TestcallComponent } from './testcall/testcall.component';
import { LivechannelComponent } from './livechannel/livechannel.component';;
import { HindbotComponent } from './hindbot/hindbot.component';
import { MshindbotComponent } from './mshindbot/mshindbot.component';
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NoopAnimationsModule,
        FormsModule,
        NgxPaginationModule,
        ToastrModule.forRoot({
             preventDuplicates: true,
            
            }),
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        Ng2SearchPipeModule,
       
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        AdminComponent,
        LoginComponent,
        HeaderComponent ,
        FooterComponent ,
        ContentrequestComponent ,
        ServicerequestComponent ,
        TopuprequestComponent,
        RenewalrequestComponent,
        UpgraderequestComponent,
        VirtualinventryComponent,
        DidinventryComponent,
        AddvnumsingleComponent,
        AddvnumbulkComponent ,
        AdddidsingleComponent ,
        AdddidbulkComponent ,
        ServicelistComponent,
        AgentComponent,
        AddagentComponent,
        AddsettamountComponent,
        SettleamtlistComponent,
        SettleamountComponent,
        CampaignlistComponent,
        CampaginviewComponent,
        VnumserviceComponent,
        TollfreeserviceComponent,
        ObdserviceComponent ,
        SmsserviceComponent ,
        ClicktocallserviceComponent,
        VleadserviceComponent,
        VmanageserviceComponent,
        EmailaccsettComponent,
        SmsaccsettComponent,
        MinavomanagementComponent,
        CrmComponent ,
        AddminavomgntComponent ,
        ServicealertlogComponent ,
        ApierrorlogComponent ,
        CrmapilogComponent,
        AfterdisconnectapilogComponent,
        ApilogComponent,
        MrequestnavComponent,
        NumberOnlyDirective,
        ConfirmDialogComponent,
        ExecmanagementComponent,
        MsvnumserviceComponent,
        MstollfreeserviceComponent,
        MsobdserviceComponent,
        MssmsserviceComponent,
        MsclicktocallserviceComponent,
        MsvleadserviceComponent,
        MsvmanageserviceComponent,
        CallreportComponent,
        CalloverallComponent,
        CallviewreportComponent,
        CalloverallviewComponent,
        SmsreportComponent,
        OverallsmsComponent,
        PendingrenewalComponent,
        TopupreportComponent,
        RenewalextendComponent,
        DemoaccountComponent,
        DemodetailsComponent,
        CappedcallComponent,
        CappedcallviewComponent,
        EmailreportComponent,
        EmailoverallreportComponent,
        ClientdetailreportComponent,
        ServerpulsereportComponent,
        VirtualnumreportComponent,
        ClientwiseComponent,
        TopupComponent,
        TopupsmsComponent,
        RenewalComponent,
        UpgradeplanComponent,
        UpgradeplansmsComponent,
        NetworkdidComponent,
        VconvoserviceComponent,
        MsvconvoserviceComponent,
        NetworkviewComponent,
        SupportlistComponent,
        NewticketComponent,
        CreatedemoComponent,
        SanitizeHtmlPipe,
        EditrequestComponent,
        EditdetailComponent,
        Obdc2ccliComponent,
        CdrComponent,
        RessellerresistrationComponent,
        ManageresselerComponent,
        ResellercreditbackComponent,
        ResellerrenewalextendComponent,
        ResellerapproveplanComponent,
        ResellercreateplanComponent,
        ResellerviewplanComponent,
        ResellerprofileeditComponent,
        ResellersettingComponent,
        ResellerplanlistComponent,
        ResellerplanlistviewComponent,
        ResellervirtualinventryComponent,
        AddressvnumdidsingleComponent,
        AddressvnumdidbulkComponent,
        ResellerrequestComponent,
        TestcallComponent,
        LivechannelComponent,
        HindbotComponent,
        MshindbotComponent],
        
        
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider,
        ConfirmDialogService
        
    ],
    exports: [SanitizeHtmlPipe ],
    bootstrap: [AppComponent]
})

export class AppModule { }