import { Component, OnInit,ElementRef } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-msclicktocallservice',
  templateUrl: './msclicktocallservice.component.html',
  styleUrls: ['./msclicktocallservice.component.css']
})
export class MsclicktocallserviceComponent implements OnInit {
    buttunhide:boolean=false;
    clck2callForm:FormGroup;
    topupdemoform:FormGroup;
    demorenewform:FormGroup;
    submitted2:boolean = false;
    submitted1:boolean = false;
    submitted:boolean = false;
    user_id:any;
    subtype:any;
    stype:any;
    settings:FormArray;
    costomizedplan:boolean=false;
    smsacount:boolean=false;
    minavopersonnel:boolean=false;
    crmsettsec:boolean=false;
    records:any;
    clidata:any;
    arr=[];
    didlist:boolean = false;
    bckdidlist:boolean = false;
    didnumberbox:boolean = true;
    bkpdidnumberbox:boolean = true;
    virtualnumberbox:boolean = true;
    virtualnumberradioBtn:boolean = false;
    virtualnumbersec:boolean=false;
    resselerList:boolean=false;
    capturecall:boolean=false;
    click2call:boolean=false;
    agentpopup:boolean=false;
    callrouting:boolean=false;
    singlepopup:boolean=false;
    calldisposition:boolean=false;
    personalsmscoin:boolean=false;
    currentUserloclstrage:any;
    baseurl:any;
    remarksrecords:any;
    session_id:any;
    networkList=[];
    outcliList=[];
    nubmerfreezed=[];
    serverList:any;
    OBDnetworkList=[];
    selectedCli=[];
    duplicateNum:boolean = false;
    alreadyassign:boolean = false;

    agentserver_typecheck:boolean = true;
    agentListnetwork=[];
    agentListsamecase=[];
    agentoutcliList=[];
    agentnubmerfreezed=[];
    server_typecheck:boolean=false;
    industryList=['Education','Health Care','Banking','Beauty Care','Grocery','Government','Politics','Hotel/Guest House','NGO','Other'];

    constructor(private elment: ElementRef, private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
    this.user_id = this.route.snapshot.paramMap.get("userid");
    this.subtype = this.route.snapshot.paramMap.get("subtype");
    this.stype = this.route.snapshot.paramMap.get("stype");
    console.log(`${this.user_id} ${this.subtype} ${this.stype}`);
  }

  ngOnInit() {
    let that2 = this;
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));

    this.session_id =  this.currentUserloclstrage.user_id;
    this.baseurl = localStorage.getItem('baseurl');
  this.getc2cserver();
  this.createForm();
  this.creattopupdemoform();
  this.creatrenwaldemoform();
  this.getdata();
  this.getremarkslist(this.user_id);
  $(function() {
    $(".pool_network").select2();
    //   $( document ).on( 'focus', ':input', function(){
    //     $( this ).attr( 'autocomplete', 'off' );
    // });

  // $(".acchead").click(function() {
  //         $(this).next('.accbox').slideToggle('fast');
  //         $(this).toggleClass('active');
  //     });

  });

  setTimeout(() => {
    var options = $('#network0 option');

    var optionvalues = $.map(options ,function(option) {
        var pks = {};
        pks["available_network"]=option.value;
        return pks
    });
    optionvalues.shift();
    this.agentListnetwork=optionvalues;
    $(".agentselect,.cliselect,.gsm_call_prefix").prop('disabled',true);
    this.clck2callForm.get('server_type').valueChanges
   .subscribe(server_type => {

  if(server_type=='0'){
    var options = $('#network0 option');

    var optionvalues = $.map(options ,function(option) {
        var pks = {};
        pks["available_network"]=option.value;
        return pks
    });
    optionvalues.shift();
    console.log(optionvalues);
    this.agentListnetwork=optionvalues;
  }
  else{
    this.agentListnetwork=[];
    $(".cliselect").val('');

     for(var trlength=0;$("#agentListtable>tr").length>trlength;trlength++){
     this.agentnubmerfreezed[trlength]='';
     this.agentserver_typecheck=true;
    }
    $(".agentselect,.cliselect,.gsm_call_prefix").prop('disabled',true);
  }
   })
  }, 2000);

  this.clck2callForm.get('dedplan').get('allcommon').valueChanges
   .subscribe(allcommon => {
  // this.clck2callForm.get('dedplan').get('allcommon').value()==allcommon;
  if(allcommon=="customized"){
  //this.costomizedplan = true;

  this.clck2callForm.get('dedplan').get('obd_ded').setValue(this.records.ded_plan[0].legA_ded);
  this.clck2callForm.get('dedplan').get('ibd_ded').setValue(this.records.ded_plan[0].legB_ded);
 this.clck2callForm.get('dedplan').get('sms_ded').setValue(this.records.ded_plan[0].sms_ded);

  }
  else if(allcommon=="allcommon"){

  this.clck2callForm.get('dedplan').get('obd_ded').setValue('Common');
  this.clck2callForm.get('dedplan').get('ibd_ded').setValue('Common');

  this.clck2callForm.get('dedplan').get('sms_ded').setValue('Common');

  }
  else{

  this.clck2callForm.get('dedplan').get('obd_ded').setValue('Self');
  this.clck2callForm.get('dedplan').get('ibd_ded').setValue('Self');

  this.clck2callForm.get('dedplan').get('sms_ded').setValue('Self');

  }

  })

  this.clck2callForm.get('smssetting').get('account_type').valueChanges
  .subscribe(account_type => {
 if(account_type=="minavo"){
   // alert("Hi if"+account_type);
  this.smsacount = false;
  this.minavopersonnel  = false;
  this.personalsmscoin=false;

this.clck2callForm.get("smssetting").get("base_uri").setValidators(null);
this.clck2callForm.get("smssetting").get("param_user_name").setValidators(null);
this.clck2callForm.get("smssetting").get("param_user_value").setValidators(null);
this.clck2callForm.get("smssetting").get("param_pass_name").setValidators(null);
this.clck2callForm.get("smssetting").get("param_pass_value").setValidators(null);
this.clck2callForm.get("smssetting").get("param_sid_name").setValidators(null);
this.clck2callForm.get("smssetting").get("param_sid_value").setValidators(null);
this.clck2callForm.get("smssetting").get("param_lang_name").setValidators(null);
this.clck2callForm.get("smssetting").get("param_lang_value").setValidators(null);
this.clck2callForm.get("smssetting").get("param_mobile_name").setValidators(null);
this.clck2callForm.get("smssetting").get("param_message_name").setValidators(null);

this.clck2callForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_message_name').updateValueAndValidity();

  }
  else if(account_type=="personal"){
  this.smsacount = true;
  this.minavopersonnel  = false;
  this.personalsmscoin=false;
this.clck2callForm.get("smssetting").get("base_uri").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_user_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_user_value").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_pass_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_pass_value").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_sid_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_sid_value").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_lang_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_lang_value").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_mobile_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_message_name").setValidators([Validators.required]);



this.clck2callForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_message_name').updateValueAndValidity();

  }
  else if(account_type=="minavo-personal"){
  this.smsacount = true;
  this.minavopersonnel  = true
  this.personalsmscoin=true;

this.clck2callForm.get("smssetting").get("base_uri").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_user_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_user_value").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_pass_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_pass_value").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_sid_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_sid_value").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_lang_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_lang_value").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_mobile_name").setValidators([Validators.required]);
this.clck2callForm.get("smssetting").get("param_message_name").setValidators([Validators.required]);

this.clck2callForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.clck2callForm.get('smssetting').get('param_message_name').updateValueAndValidity();
  }
  })

  this.clck2callForm.get('smssetting').get('minpertype').valueChanges
  .subscribe(minpertype => {
   if(minpertype=='default'){

      this.clck2callForm.get("smssetting").get("base_uri").setValue(this.records.deafult_sms_details[0].base_uri);
      this.clck2callForm.get("smssetting").get("param_user_name").setValue(this.records.deafult_sms_details[0].param_user_name);
      this.clck2callForm.get("smssetting").get("param_user_value").setValue(this.records.deafult_sms_details[0].param_user_value);
      this.clck2callForm.get("smssetting").get("param_pass_name").setValue(this.records.deafult_sms_details[0].param_pass_name);
      this.clck2callForm.get("smssetting").get("param_pass_value").setValue(this.records.deafult_sms_details[0].param_pass_value);
      this.clck2callForm.get("smssetting").get("param_sid_name").setValue(this.records.deafult_sms_details[0].param_sid_name);
      this.clck2callForm.get("smssetting").get("param_sid_value").setValue(this.records.deafult_sms_details[0].param_sid_value);
      this.clck2callForm.get("smssetting").get("param_lang_name").setValue(this.records.deafult_sms_details[0].param_lang_name);
      this.clck2callForm.get("smssetting").get("param_lang_value").setValue(this.records.deafult_sms_details[0].param_lang_value);
      this.clck2callForm.get("smssetting").get("param_mobile_name").setValue(this.records.deafult_sms_details[0].param_mobile_name);
      this.clck2callForm.get("smssetting").get("param_message_name").setValue(this.records.deafult_sms_details[0].param_message_name);

      this.clck2callForm.get('smssetting').get('base_uri').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_user_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_user_value').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_message_name').updateValueAndValidity();
     }
     else{

    setTimeout(()=>{
      this.clck2callForm.get("smssetting").get("base_uri").setValue(this.records.sms_detail && this.records.sms_detail[0].base_uri);
      this.clck2callForm.get("smssetting").get("param_user_name").setValue(this.records.sms_detail && this.records.sms_detail[0].param_user_name);
      this.clck2callForm.get("smssetting").get("param_user_value").setValue(this.records.sms_detail && this.records.sms_detail[0].param_user_value);
      this.clck2callForm.get("smssetting").get("param_pass_name").setValue(this.records.sms_detail && this.records.sms_detail[0].param_pass_name);
      this.clck2callForm.get("smssetting").get("param_pass_value").setValue(this.records.sms_detail && this.records.sms_detail[0].param_pass_value);
      this.clck2callForm.get("smssetting").get("param_sid_name").setValue(this.records.sms_detail && this.records.sms_detail[0].param_sid_name);
      this.clck2callForm.get("smssetting").get("param_sid_value").setValue(this.records.sms_detail && this.records.sms_detail[0].param_sid_value);
      this.clck2callForm.get("smssetting").get("param_lang_name").setValue(this.records.sms_detail && this.records.sms_detail[0].param_lang_name);
      this.clck2callForm.get("smssetting").get("param_lang_value").setValue(this.records.sms_detail && this.records.sms_detail[0].param_lang_value);
      this.clck2callForm.get("smssetting").get("param_mobile_name").setValue(this.records.sms_detail && this.records.sms_detail[0].param_mobile_name);
      this.clck2callForm.get("smssetting").get("param_message_name").setValue(this.records.sms_detail && this.records.sms_detail[0].param_message_name);
  }, 200);

      this.clck2callForm.get('smssetting').get('param_user_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_user_value').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
      this.clck2callForm.get('smssetting').get('param_message_name').updateValueAndValidity();
     }
  })

  this.clck2callForm.get('crmsetting').get('crm_name').valueChanges
  .subscribe(crm_name => {

   if(crm_name=='minavo'){
       this.crmsettsec=false;
       this.clck2callForm.get('crmsetting').get('capture_call_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('click_tocall_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('agentpopup_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('callrouting_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('singlpopup_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('calldisposition_status').setValue(0);

  }
  else{
    this.crmsettsec=true;
     if(this.records.crm_details[0]===undefined){

       this.clck2callForm.get('crmsetting').get('capture_call_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('click_tocall_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('agentpopup_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('callrouting_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('singlpopup_status').setValue(0);
       this.clck2callForm.get('crmsetting').get('calldisposition_status').setValue(0);


       this.clck2callForm.get('crmsetting').get('capture_call_input').setValue('');
       this.clck2callForm.get('crmsetting').get('click_tocall_input').setValue('');
       this.clck2callForm.get('crmsetting').get('agentpopup_input').setValue('');
       this.clck2callForm.get('crmsetting').get('callrouting_input').setValue('');
       this.clck2callForm.get('crmsetting').get('singlpopup_input').setValue('');
       this.clck2callForm.get('crmsetting').get('calldisposition_input').setValue('');
     }
     else{
       this.clck2callForm.get('crmsetting').get('capture_call_status').setValue(this.records.crm_details[0].capture_call_logs_status);
       this.clck2callForm.get('crmsetting').get('click_tocall_status').setValue(this.records.crm_details[0].click_2_call_status);
       this.clck2callForm.get('crmsetting').get('agentpopup_status').setValue(this.records.crm_details[0].agent_popup_status);
       this.clck2callForm.get('crmsetting').get('callrouting_status').setValue(this.records.crm_details[0].call_routing_status);
       this.clck2callForm.get('crmsetting').get('singlpopup_status').setValue(this.records.crm_details[0].single_sign_status);
       this.clck2callForm.get('crmsetting').get('calldisposition_status').setValue(this.records.crm_details[0].call_disposition_status);

     }


      }
  })

  this.clck2callForm.get('plandetail').get('acc_owner').valueChanges
  .subscribe(acc_owner => {

   if(acc_owner=='Minavo'){
      this.resselerList= false;
      this.clck2callForm.get('plandetail').get('resselerlist').setValidators(null);
      this.clck2callForm.get('plandetail').get('resselerlist').updateValueAndValidity();
   }
  else{
  this.resselerList= true;
  this.clck2callForm.get('plandetail').get('resselerlist').setValidators([Validators.required]);
  this.clck2callForm.get('plandetail').get('resselerlist').updateValueAndValidity();

   }
  })

  this.clck2callForm.get('crmsetting').get('capture_call_status').valueChanges
  .subscribe(capture_call_status => {
  if(capture_call_status==1){
  this.capturecall = true;
  this.clck2callForm.get('crmsetting').get('capture_call_input').setValidators([Validators.required]);
  this.clck2callForm.get('crmsetting').get('capture_call_input').updateValueAndValidity();
  }
  else{
  this.capturecall = false;
  this.clck2callForm.get('crmsetting').get('capture_call_input').setValidators(null);
  this.clck2callForm.get('crmsetting').get('capture_call_input').updateValueAndValidity();
  }
  })
  this.clck2callForm.get('crmsetting').get('click_tocall_status').valueChanges
  .subscribe(click_tocall_status => {

  if(click_tocall_status==1){
  this.click2call = true;
  this.clck2callForm.get('crmsetting').get('click_tocall_input').setValidators([Validators.required]);
  this.clck2callForm.get('crmsetting').get('click_tocall_input').updateValueAndValidity();
  }
  else{
  this.click2call = false;
  this.clck2callForm.get('crmsetting').get('click_tocall_input').setValidators(null);
  this.clck2callForm.get('crmsetting').get('click_tocall_input').updateValueAndValidity();
  }
  })
  this.clck2callForm.get('crmsetting').get('agentpopup_status').valueChanges
  .subscribe(agentpopup_status => {
  if(agentpopup_status==1){
  this.agentpopup = true;
  this.clck2callForm.get('crmsetting').get('agentpopup_input').setValidators([Validators.required]);
  this.clck2callForm.get('crmsetting').get('agentpopup_input').updateValueAndValidity();
  }
  else{
  this.agentpopup = false;
  this.clck2callForm.get('crmsetting').get('agentpopup_input').setValidators(null);
  this.clck2callForm.get('crmsetting').get('agentpopup_input').updateValueAndValidity();
  }
  })
  this.clck2callForm.get('crmsetting').get('callrouting_status').valueChanges
  .subscribe(callrouting_status => {
  if(callrouting_status==1){
  this.callrouting = true;
  this.clck2callForm.get('crmsetting').get('callrouting_input').setValidators([Validators.required]);
  this.clck2callForm.get('crmsetting').get('callrouting_input').updateValueAndValidity();
  }
  else{
  this.callrouting = false;
  this.clck2callForm.get('crmsetting').get('callrouting_input').setValidators(null);
  this.clck2callForm.get('crmsetting').get('callrouting_input').updateValueAndValidity();
  }
  })
  this.clck2callForm.get('crmsetting').get('singlpopup_status').valueChanges
  .subscribe(singlpopup_status => {
  if(singlpopup_status==1){
  this.singlepopup = true;
  this.clck2callForm.get('crmsetting').get('singlpopup_input').setValidators([Validators.required]);
  this.clck2callForm.get('crmsetting').get('singlpopup_input').updateValueAndValidity();
  }
  else{
  this.singlepopup = false;
  this.clck2callForm.get('crmsetting').get('singlpopup_input').setValidators(null);
  this.clck2callForm.get('crmsetting').get('singlpopup_input').updateValueAndValidity();
  }
  })
  this.clck2callForm.get('crmsetting').get('calldisposition_status').valueChanges
  .subscribe(calldisposition_status => {
  if(calldisposition_status==1){
  this.calldisposition = true;
  this.clck2callForm.get('crmsetting').get('calldisposition_input').setValidators([Validators.required]);
  this.clck2callForm.get('crmsetting').get('calldisposition_input').updateValueAndValidity();
  }
  else{
  this.calldisposition = false;
  this.clck2callForm.get('crmsetting').get('calldisposition_input').setValidators(null);
  this.clck2callForm.get('crmsetting').get('calldisposition_input').updateValueAndValidity();
  }
  })

  }

  createForm(){

    // this.arr.push({settname:'Coin Allocation Type',coinalltype:['monthly']})
  this.clck2callForm = this.fb.group({
    clientdetail:this.fb.group({
        company:[{value:'', disabled:true},[Validators.required]],
        first_name:[{value:'', disabled:true},[Validators.required]],
        last_name:[{value:'', disabled:true}],
        designation:[{value:'', disabled:true},[Validators.required]],
        industry:[{value:'', disabled:true},[Validators.required]],
        comp_size:[{value:'', disabled:true}],
        mobile:[{value:'', disabled:true},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
        email:[{value:'', disabled:true},[Validators.required,Validators.email]],
        password:[{value:'', disabled:true},[Validators.required]],
        address_fl:[{value:'', disabled:true}],
        address_ll:[{value:'', disabled:true}],
        pin:[{value:'', disabled:true}],
        country:[{value:'', disabled:true}],
        state:[{value:'', disabled:true}],
        city:[{value:'', disabled:true}],
        sale_exc_name:[{value:'', disabled:true}],
        telesale_exc_name:[{value:'', disabled:true}]
    }),
    adminsetting:this.fb.group({
      name:[{value:'', disabled:true},[Validators.required]],
      email:[{value:'', disabled:true},[Validators.required,Validators.email]],
      mobile:[{value:'', disabled:true},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]]
    }),
    dedplan:this.fb.group({
        allcommon:[{value:'', disabled:true},[Validators.required]],
        obd_ded:[{value:'', disabled:true},[Validators.required]],
        ibd_ded:[{value:'', disabled:true},[Validators.required]],
        sms_ded:[{value:'', disabled:true},[Validators.required]],

     }),
    plandetail:this.fb.group({
         contract_period:[{value:'', disabled:true},[Validators.required]],
         service_billing:[{value:'', disabled:true}],
         free_chanel:[{value:'', disabled:true}],
         plan_type:[{value:'', disabled:true},[Validators.required]],
         sub_type:'',
         channel_pool:'',
         plan_price:[{value:'', disabled:true}],
         plan_term:[{value:'', disabled:true},[Validators.required]],
         total_coins:[{value:'', disabled:true},[Validators.required]],
         allowedclient:[{value:'', disabled:true},[Validators.required]],
         ibd_coins:[{value:'', disabled:true},[Validators.required]],
         obd_coins:[{value:'', disabled:true},[Validators.required]],
         sms_coins:[{value:'', disabled:true},[Validators.required]],
         ibd_ded_sec:[{value:'', disabled:true},[Validators.required]],
         ibd_ded_rate:[{value:'', disabled:true},[Validators.required]],
         obd_ded_sec:[{value:'', disabled:true},[Validators.required]],
         obd_ded_rate:[{value:'', disabled:true},[Validators.required]],
         sms_ded__cnt:[{value:'', disabled:true},[Validators.required]],
         smsded_rate:[{value:'', disabled:true},[Validators.required]],
         virtual_number:[{value:'', disabled:true},[Validators.required]],

         accounttype:[{value:'', disabled:true},[Validators.required]],
         acc_owner:[{value:'', disabled:true},[Validators.required]],
         resselerlist:[{value:'', disabled:true}],

  }),
  smssetting:this.fb.group({
  account_type:[{value:'', disabled:true},[Validators.required]],
  minpertype:[{value:'', disabled:true},[Validators.required]],
  base_uri:[{value:'', disabled:true},[Validators.required]],
  param_user_name:[{value:'', disabled:true},[Validators.required]],
  param_user_value:[{value:'', disabled:true},[Validators.required]],
  param_pass_name:[{value:'', disabled:true},[Validators.required]],
  param_pass_value:[{value:'', disabled:true},[Validators.required]],
  param_sid_name:[{value:'', disabled:true},[Validators.required]],
  param_sid_value:[{value:'', disabled:true},[Validators.required]],
  param_lang_name:[{value:'', disabled:true},[Validators.required]],
  param_lang_value:[{value:'', disabled:true},[Validators.required]],
  param_mobile_name:[{value:'', disabled:true},[Validators.required]],
  param_message_name:[{value:'', disabled:true},[Validators.required]],
  personal_sms_coins:[{value:'', disabled:true},[Validators.required]],
  accaccount:[{value:'', disabled:true},[Validators.required]],
  param_value:[{value:'', disabled:true},[Validators.required]],

  }),
  crmsetting:this.fb.group({
    crm_name:[{value:'', disabled:true},[Validators.required]],
    //crmsett:[{value:'', disabled:false},[Validators.required]],
    capture_call_status:[{value:'', disabled:true}],
    capture_call_input:[{value:'', disabled:true},[Validators.required]],
    click_tocall_status:[{value:'', disabled:true}],
    click_tocall_input:[{value:'', disabled:true},[Validators.required]],
    agentpopup_status:[{value:'', disabled:true}],
    agentpopup_input:[{value:'', disabled:true},[Validators.required]],
    callrouting_status:[{value:'', disabled:true}],
    callrouting_input:[{value:'', disabled:true},[Validators.required]],
    singlpopup_status:[{value:'', disabled:true}],
    singlpopup_input:[{value:'', disabled:true},[Validators.required]],
    calldisposition_status:[{value:'', disabled:true}],
    calldisposition_input:[{value:'', disabled:true},[Validators.required]],
  }),
  networksdata:this.fb.array([this.addnetworks()]),
  remarks:[{value:'', disabled:true}],
  server_type:[{value:'', disabled:true}],
  userid:'',
  stype:'',
  subtype:'',
  account_id:''
  })
  }

  BuildFormDynamic(sett:any):FormGroup{
    return this.fb.group({
     settname:[sett.settname],
     labelname:[sett.labelname],
     settvalue:{value:0, disabled:true},
     })
   }

 addnetworks(): FormGroup {
  return this.fb.group({
    server:['',Validators.required],
    network:['',Validators.required],
    pool_network:[''],
    outcli:[''],
    channelno:['0'],
    retrysel:['0',Validators.required],
    call_retry:['0',Validators.required],
  });
}
addnetwork(): void {
 (<FormArray>this.clck2callForm.get('networksdata')).push(this.addnetworks());
  setTimeout(() => {
    $(".pool_network,.outcli_select").select2();
    }, 10);
    this.clck2callForm.enable();
}

removenetwork(i: number){
this.clck2callForm.controls['networksdata']['controls'].splice(i, 1);
this.clck2callForm.controls['networksdata'].updateValueAndValidity();
}

  get f() { return this.clck2callForm.controls }
  onSubmit(){
    var that = this;
  if(this.clck2callForm.get("server_type").value=='0'){
   $('.serveselect').each(function(){
   if($("#serveselect0").val() == $(this).val()){
    that.server_typecheck=true;
      }
       else{
        that.server_typecheck=false;
        return false;
       }

    })
    $('.agentselect').each(function(){
      if($(this).val()!=''){
       that.agentserver_typecheck=true;
      }
          else{
            // $(this).css('border-color','#ff0000');
            // setTimeout(() => {
            //   $(this).css('border-color','#b8b8b8');
            // }, 8000);
           that.agentserver_typecheck=true;
          // return false;
          }

       })

  }
  else{
    that.server_typecheck=true;
    this.agentserver_typecheck=true;

  }
    var cliitemarra=[];
    const cliitemarralength = (<FormArray>this.clck2callForm.get('networksdata')).length;
  for(var kt= 0;cliitemarralength>kt;kt++){
    var outcliarray = $("#outcli_select"+kt).val();
    cliitemarra.push(outcliarray);
   }

  var result = [];

  for (var idk=0, iLen=cliitemarra.length; idk<iLen; idk++) {
    result = result.concat(cliitemarra[idk]);
  }

  var checkcli = result.some(function(item, idx){
    return result.indexOf(item) != idx
  });
  this.duplicateNum=checkcli;
 if(that.agentserver_typecheck==false){
    this.toastr.error("Please Choose all agent network from agent list in case of same server.", 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
  return false;
  }
 else if(that.server_typecheck==false){
    this.toastr.error("Please Choose same server from server detail in case of same server.", 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
return false;
  }
  else if(this.alreadyassign==true){
    this.toastr.error("Out CLI already assigend. Please choose Another.", 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
return false;
  }
 else if(this.duplicateNum==true){
  this.toastr.error("You can not choose same Out CLI. Please choose Another.", 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  return false;
 }
else{

  let totallength = (<FormArray>this.clck2callForm.get('networksdata')).length;
  for(var tl= 0;totallength>tl;tl++){
    var clisec = $("#outcli_select"+tl).val();
    var pool_networksec =  $("#pool_network"+tl).val();
   if(clisec!=undefined || clisec!='' || clisec!=null){

     var clisecfinal = clisec.map(function(item){
     return item
     }).join(",");

     }
     else{
       clisecfinal='';
     }
     if(pool_networksec!=undefined || pool_networksec!='' || pool_networksec!=null){

       var pool_networksecfinal = pool_networksec.map(function(item){
       return item
       }).join(",");

       }
       else{
         pool_networksecfinal='';
       }
     this.clck2callForm.get('networksdata')['controls'][tl]['controls']['outcli'].setValue(clisecfinal);
     this.clck2callForm.get('networksdata')['controls'][tl]['controls']['outcli'].updateValueAndValidity();
     this.clck2callForm.get('networksdata')['controls'][tl]['controls']['pool_network'].setValue(pool_networksecfinal);
     this.clck2callForm.get('networksdata')['controls'][tl]['controls']['pool_network'].updateValueAndValidity();

   }
   var agentlisttable=[];

   $("#agentListtable >tr").each(function() {

     var agentid = $(this).find('input[type="text"].agentid').val();
     var agentidnetwork = $(this).find('select.agentselect').val();
     var cliselect = $(this).find('input[type="text"].cliselect').val();
     var gsm_call_prefix = $(this).find('input[type="text"].gsm_call_prefix').val();

    var item = {}
     item ["agentid"] = agentid;
     item ["network"] = agentidnetwork;
     item ["agentcli"] = cliselect;
     item ["gsm_call_prefix"] = gsm_call_prefix;
     agentlisttable.push(item);
 });

  this.submitted = true;
  let vagentdata =  JSON.stringify(this.clck2callForm.value);
  let agentlistdata = JSON.stringify(agentlisttable);
console.log(agentlistdata);
if(this.clck2callForm.invalid){
  if (!this.clck2callForm.valid) {
    let target;

    target = this.elment.nativeElement.querySelector('.is-invalid')

    if (target) {
        $('html,body').animate({ scrollTop: $(target).offset().top }, 'slow');
        target.focus();
    }
}
  //alert("not valid");
return false;
}

else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"update_service",{data:vagentdata,agentlist:agentlistdata}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         this.router.navigateByUrl('/servicelist');
         }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });

       }

     },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
   })
     }
}

  }

  getdata(){
    $(".fullpageloader").fadeIn('fast');
    this.http.get(this.baseurl+"get_service_details?user_id="+this.user_id+"&type="+this.stype).subscribe((data:any)=>{

    this.records=data;
    this.serverList=data.server_ip;
    this.OBDnetworkList=data.obd_network;


  for(var scli=0;this.records.obd_network.length>scli;scli++){
    if(this.records.obd_network[scli].cli!=''){
     var splititem=this.records.obd_network[scli].cli.split(',');
     this.selectedCli[scli]=splititem;
    }
    else{
     this.selectedCli[scli]=[];
    }
   }


  this.clck2callForm.patchValue({
    clientdetail:{
         company:data.profile[0].company,
        first_name:data.profile[0].first_name+" "+data.profile[0].last_name,
        last_name:"",
        designation:data.profile[0].designation,
        industry:data.profile[0].industry,
        comp_size:data.profile[0].comp_size,
        mobile:data.profile[0].mobile,
        email:data.profile[0].email,
        password:data.profile[0].password,
        address_fl:data.profile[0].address_fl,
        address_ll:data.profile[0].address_ll,
        pin:data.profile[0].pin,
        country:data.profile[0].country,
        state:data.profile[0].state,
        city:data.profile[0].city,
        sale_exc_name:data.profile[0].sale_exc_name,
        telesale_exc_name:data.profile[0].telesale_exc_name
    },
    adminsetting:{
      name:data.admin_details[0] && data.admin_details[0].name,
      email:data.admin_details[0] && data.admin_details[0].email,
      mobile:data.admin_details[0] && data.admin_details[0].mobile
     },
    dedplan:{
        allcommon:this.getallcommon(data.ded_plan[0].obd_ded,data.ded_plan[0].ibd_ded,data.ded_plan[0].sms_ded),
        obd_ded:data.ded_plan[0].legA_ded,
        ibd_ded:data.ded_plan[0].legB_ded,
        sms_ded:data.ded_plan[0].sms_ded,
     },
    plandetail:{
        contract_period:data.profile[0].contract_period,
        service_billing:data.plan[0].service_billing,
        free_chanel:data.plan[0].channel,
        channel_pool:data.profile[0].channel_type,
        plan_type:data.plan[0]&&data.plan[0].plan_type,
        sub_type:data.profile[0].sub_type,
        plan_price:data.plan[0].plan_cost,
        plan_term:data.plan[0].plan_term,
        total_coins:data.plan[0].total_coins,
        allowedclient:data.profile[0].allowed_client,
        ibd_coins:data.plan[0].legA_coins,
        obd_coins:data.plan[0].legB_coins,
        sms_coins:data.plan[0].sms_ded_cnt,
        ibd_ded_sec:data.plan[0].legA_ded_sec,
        ibd_ded_rate:data.plan[0].legA_ded_rate,
        obd_ded_sec:data.plan[0].legB_ded_sec,
        obd_ded_rate:data.plan[0].legB_ded_rate,
        sms_ded__cnt:data.plan[0].sms_ded_cnt,
        smsded_rate:data.plan[0].sms_ded_rate,
        virtual_number:data.profile[0].virtual_number,
        accounttype:data.profile[0].account_type,
        acc_owner:data.profile[0].acc_owner,
        resselerlist:data.profile[0].owner_id,


       },
    smssetting:{
        account_type:function(smstype){
if(smstype=="minavo" || smstype=="Minavo" || smstype=='minavo_common'){
return 'minavo';
}
else{
return smstype;
}

        }(data.setting[0].param_value),
        minpertype:function(sett_param){
          if(sett_param=='personal'){
            return 'default';
          }
          else{
            return 'manual';
          }
        }(data.setting[0].param_value),
        base_uri:data.deafult_sms_details[0].base_uri,
        param_user_name:data.deafult_sms_details[0].param_user_name,
        param_user_value:data.deafult_sms_details[0].param_user_value,
        param_pass_name:data.deafult_sms_details[0].param_pass_name,
        param_pass_value:data.deafult_sms_details[0].param_pass_value,
        param_sid_name:data.deafult_sms_details[0].param_sid_name,
        param_sid_value:data.deafult_sms_details[0].param_sid_value,
        param_lang_name:data.deafult_sms_details[0].param_lang_name,
        param_lang_value:data.deafult_sms_details[0].param_lang_value,
        param_mobile_name:data.deafult_sms_details[0].param_mobile_name,
        param_message_name:data.deafult_sms_details[0].param_message_name,
        personal_sms_coins:data.plan[0].personal_sms_coins,
        accaccount:data.setting1[5].param_value,
        param_value:function(allotype){
          if(allotype.toLowerCase()=='monthly'){
      return "monthly";
          }
          else if(allotype.toLowerCase()=='one time'){
              return "one time";
          }
          else if(allotype.toLowerCase()=='monthly carryfwd'){
            return "monthly carryfwd";
          }
          else{
            return allotype;
        }
          }(data.setting1[4].param_value),
    },
  crmsetting:{
      crm_name:data.profile[0].crm_name,
     // crmsett:data.profile[0].crm_name,
    capture_call_status:data.crm_details[0] && data.crm_details[0].capture_call_logs_status,
    capture_call_input:data.crm_details[0] && data.crm_details[0].capture_call_logs,
    click_tocall_status:data.crm_details[0] && data.crm_details[0].capture_call_logs_status,
    click_tocall_input:data.crm_details[0] && data.crm_details[0].click_2_call,
    agentpopup_status:data.crm_details[0] && data.crm_details[0].agent_popup_status,
    agentpopup_input:data.crm_details[0] && data.crm_details[0].agent_popup,
    callrouting_status:data.crm_details[0] && data.crm_details[0].call_routing_status,
    callrouting_input:data.crm_details[0] && data.crm_details[0].call_routing,
    singlpopup_status:data.crm_details[0] && data.crm_details[0].single_sign_status,
    singlpopup_input:data.crm_details[0] && data.crm_details[0].single_sign,
    calldisposition_status:data.crm_details[0] && data.crm_details[0].call_disposition_status,
    calldisposition_input:data.crm_details[0] && data.crm_details[0].call_disposition,
    },
    server_type:function(stp){
      if(stp==0){
       return "0"
      }
      else{
       return "1"
      }
    }(data.profile[0].network_setting),
     remarks:'',
     userid:data.profile[0].user_id,
     stype:this.stype,
     subtype:this.subtype,
     account_id:data.profile[0].account_id
  })
  if(this.clck2callForm.get('smssetting').get('account_type').value=='personal'){
 setTimeout(() => {
      this.clck2callForm.get("smssetting").get("base_uri").setValue(this.records.sms_detail[0].base_uri);
      this.clck2callForm.get("smssetting").get("param_user_name").setValue(this.records.sms_detail[0].param_user_name);
      this.clck2callForm.get("smssetting").get("param_user_value").setValue(this.records.sms_detail[0].param_user_value);
      this.clck2callForm.get("smssetting").get("param_pass_name").setValue(this.records.sms_detail[0].param_pass_name);
      this.clck2callForm.get("smssetting").get("param_pass_value").setValue(this.records.sms_detail[0].param_pass_value);
      this.clck2callForm.get("smssetting").get("param_sid_name").setValue(this.records.sms_detail[0].param_sid_name);
      this.clck2callForm.get("smssetting").get("param_sid_value").setValue(this.records.sms_detail[0].param_sid_value);
      this.clck2callForm.get("smssetting").get("param_lang_name").setValue(this.records.sms_detail[0].param_lang_name);
      this.clck2callForm.get("smssetting").get("param_lang_value").setValue(this.records.sms_detail[0].param_lang_value);
      this.clck2callForm.get("smssetting").get("param_mobile_name").setValue(this.records.sms_detail[0].param_mobile_name);
      this.clck2callForm.get("smssetting").get("param_message_name").setValue(this.records.sms_detail[0].param_message_name);
    }, 500);
  }

  this.allnetwork();
  $(".fullpageloader").fadeOut('fast');
  })

  }
  getallcommon(obd,ibd,sms){
  if(obd=='Common' && ibd=='Common' &&  sms=='Common'){
  return 'allcommon'
  }
  else if(obd=='Self' && ibd=='Self' && sms=='Self'){
  return 'allself'
  }
  else{
    return 'customized'
  }

  }

  releasedid(){
  this.didlist = true;
  this.didnumberbox= false;
  }
  freezedid(){

  }
  releasebkpdid(){
  this.bckdidlist = true;
  this.bkpdidnumberbox= false;
  }
  freezebkpdid(){
  this.didlist = true;
  this.didnumberbox= false;
  }

choosevirnum(){
    this.virtualnumbersec=true;
  }

  changeCRM(crmvalue:any){
    if(crmvalue=='minavo'){
      this.crmsettsec=false;
    }
  else{
    this.crmsettsec=true;
  }

  }

  editform(){
  this.clck2callForm.enable();
  this.buttunhide = true;
  $(".agentselect,.cliselect,.gsm_call_prefix").prop('disabled',false);
  if(this.clck2callForm.get('smssetting').get('account_type').value=='personal'){
    setTimeout(() => {
         this.clck2callForm.get("smssetting").get("base_uri").setValue(this.records.sms_detail[0].base_uri);
         this.clck2callForm.get("smssetting").get("param_user_name").setValue(this.records.sms_detail[0].param_user_name);
         this.clck2callForm.get("smssetting").get("param_user_value").setValue(this.records.sms_detail[0].param_user_value);
         this.clck2callForm.get("smssetting").get("param_pass_name").setValue(this.records.sms_detail[0].param_pass_name);
         this.clck2callForm.get("smssetting").get("param_pass_value").setValue(this.records.sms_detail[0].param_pass_value);
         this.clck2callForm.get("smssetting").get("param_sid_name").setValue(this.records.sms_detail[0].param_sid_name);
         this.clck2callForm.get("smssetting").get("param_sid_value").setValue(this.records.sms_detail[0].param_sid_value);
         this.clck2callForm.get("smssetting").get("param_lang_name").setValue(this.records.sms_detail[0].param_lang_name);
         this.clck2callForm.get("smssetting").get("param_lang_value").setValue(this.records.sms_detail[0].param_lang_value);
         this.clck2callForm.get("smssetting").get("param_mobile_name").setValue(this.records.sms_detail[0].param_mobile_name);
         this.clck2callForm.get("smssetting").get("param_message_name").setValue(this.records.sms_detail[0].param_message_name);
       }, 500);
     }

  }

creattopupdemoform(){
  this.topupdemoform = this.fb.group({
    amount:[{value:''},[Validators.required]],
    coins:[{value:''},[Validators.required]],
    remarks:[{value:''},[Validators.required]],
    topuptype:[{value:''},[Validators.required]],
    user_id:this.user_id,
    service_type:this.subtype,
    login_id:this.currentUserloclstrage.user_id

  })
  }

  get g(){
    return this.topupdemoform.controls
  }
  topupdemo(){
    this.submitted1 = true;
    let topupdata= JSON.stringify(this.topupdemoform.value);
    if(this.topupdemoform.invalid){
  return false
    }
    else{
      $(".fullpageloader").fadeIn('fast');
      this.http.post(this.baseurl+"demo_topup",{data:topupdata}).subscribe((data:any)=>{
       // console.log(data);
        if(data.status=="success"){
          $("#topupdemo").modal('hide');
          $(".fullpageloader").fadeOut('fast');
            this.toastr.success(data.message, 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });

          }
        else if(data.status=="fail"){
          $(".fullpageloader").fadeOut('fast');
         this.toastr.error(data.message, 'Error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });

        }
      },
      error => {
       $(".fullpageloader").fadeOut('fast');
        this.toastr.error(error, 'error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
    });
    }
  }

  creatrenwaldemoform(){
    this.demorenewform = this.fb.group({
      renew_type:[{value:''},[Validators.required]],
      remarks:[{value:''},[Validators.required]],
      user_id:this.user_id,
      service_type:this.subtype,
      loginid:this.currentUserloclstrage.user_id
    })
    }
    get h(){
      return this.demorenewform.controls
    }
    demorenew(){
      this.submitted2 = true;
      let renewdata= JSON.stringify(this.demorenewform.value);
      if(this.demorenewform.invalid){
    return false
      }
      else{
        $(".fullpageloader").fadeIn('fast');
        this.http.post(this.baseurl+"demo_renewal",{data:renewdata}).subscribe((data:any)=>{
          console.log(data);
          if(data.status=="success"){
            $("#renewdemo").modal('hide');
            $(".fullpageloader").fadeOut('fast');
              this.toastr.success(data.message, 'Success!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });

            }
          else if(data.status=="fail"){
            $(".fullpageloader").fadeOut('fast');
           this.toastr.error(data.message, 'Error!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });

          }
        },
        error => {
         $(".fullpageloader").fadeOut('fast');
          this.toastr.error(error, 'error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
      });
      }
    }

    getremarkslist(user_id){
      this.http.get(this.baseurl+"get_status_history?user_id="+user_id).subscribe(
        (result:any)=>{
          console.log(result);
        this.remarksrecords= result;
       });
    }

    persmsmodal(){
      if($("#persmscoin").val()!=''){
        $(".fullpageloader").fadeIn('fast');
        this.http.get(this.baseurl+'update_persnal_sms_coins?user_id='+this.user_id+'&service_type='+this.subtype+'&coins='+$("#persmscoin").val()).subscribe((data:any)=>{
          if(data.status=='success'){
            $(".fullpageloader").fadeOut('fast');
            $("#persmsmodal").modal('hide');
            $("#persmscoin").val('');
            this.toastr.success(data.message, 'Success!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
          }
          else{
              $(".fullpageloader").fadeOut('fast');
              this.toastr.error(data.message, 'Error!',{
                positionClass:'toast-top-center',
                timeOut: 3000
              });

        }
      })

    }
    else{
      $("#persmscoin").css('border-color','#ff0000');
      setTimeout(() => {
        $("#persmscoin").css('border-color','#b8b8b8');
      }, 3000);
    }
  }


  senderid(){
    if($("#senderid").val()!='' && $("#peid").val()!=''){
      $(".fullpageloader").fadeIn('fast');
      this.http.get(this.baseurl+'approve_sender_id?user_id='+this.user_id+'&sender_id='+$("#senderid").val()+'&peid='+$("#peid").val()).subscribe((data:any)=>{
        if(data.status=='success'){
          $(".fullpageloader").fadeOut('fast');
          $("#senderidmodal").modal('hide');
          $("#senderid,#peid").val('');
          this.toastr.success(data.message, 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
        }
        else{
            $(".fullpageloader").fadeOut('fast');
            this.toastr.error(data.message, 'Error!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });

      }
    })

  }
  else{
    if($("#senderid").val()!=''){
      $("#peid").css('border-color','#ff0000');
      setTimeout(() => {
        $("#peid").css('border-color','#b8b8b8');
      }, 3000);
    }
    else if($("#peid").val()!=''){
      $("#senderid").css('border-color','#ff0000');
      setTimeout(() => {
        $("#senderid").css('border-color','#b8b8b8');
      }, 3000);
    }
    else{
      $("#senderid,#peid").css('border-color','#ff0000');
      setTimeout(() => {
        $("#senderid,#peid").css('border-color','#b8b8b8');
      }, 3000);
    }

  }
  }


  extenddate(){
    if($("#extendday").val()!='' && $("#extendremark").val()!=''){
      $(".fullpageloader").fadeIn('fast');
      this.http.post(this.baseurl+'extend_demo',{user_id:this.user_id,service_type:this.subtype,days:$("#extendday").val(),remarks:$("#extendremark").val(),session_id:this.session_id}).subscribe((data:any)=>{
        if(data.status=='success'){
          $(".fullpageloader").fadeOut('fast');
          $("#extendmodal").modal('hide');
          $("#extendday,#extendremark").val('');
          this.toastr.success(data.message, 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
        }
        else{
          $(".fullpageloader").fadeOut('fast');
          this.toastr.error(data.message, 'Error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
        }

    })

  }
  else{
    if($("#extendday").val()==''&& $("#extendremark").val()==''){
      $("#extendday,#extendremark").css('border-color','#ff0000');
      setTimeout(() => {
        $("#extendday,#extendremark").css('border-color','#b8b8b8');
      }, 3000);
    }
   else if($("#extendday").val()==''){
    $("#extendday").css('border-color','#ff0000');
    setTimeout(() => {
      $("#extendday").css('border-color','#b8b8b8');
    }, 3000);
   }
   else{
    $("#extendremark").css('border-color','#ff0000');
    setTimeout(() => {
      $("#extendremark").css('border-color','#b8b8b8');
    }, 3000);
   }

  }
  }

getc2cclic(network,ip,i,gcstatus){
  var that = this;
  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+'get_c2c_cli',{ip:ip,network:network,session_id:this.session_id}).subscribe((data:any)=>{

    if(gcstatus==0){
   this.clck2callForm.get('networksdata')['controls'][i]['controls']['outcli'].setValue("");
    this.clck2callForm.get('networksdata')['controls'][i]['controls']['outcli'].updateValueAndValidity();
    }

    this.outcliList[i]= data.cli_list;
    this.nubmerfreezed[i]='';

    var $outcli =$("#outcli_select"+i).select2();
    $outcli.on("change", function(){
    that.checkdid($(this).val(),$(this).data('id'));
    });
    $(".fullpageloader").fadeOut('fast');
})
}
getc2cnetwork(ip,scnt,gtstatus){
$(".fullpageloader").fadeIn('fast');
this.http.post(this.baseurl+'get_c2c_network',{ip:ip,session_id:this.session_id}).subscribe((data:any)=>{
this.clck2callForm.get('networksdata')['controls'][scnt]['controls']['network'].setValue("");
this.clck2callForm.get('networksdata')['controls'][scnt]['controls']['network'].updateValueAndValidity();
this.clck2callForm.get('networksdata')['controls'][scnt]['controls']['pool_network'].setValue("");
this.clck2callForm.get('networksdata')['controls'][scnt]['controls']['pool_network'].updateValueAndValidity();
this.clck2callForm.get('networksdata')['controls'][scnt]['controls']['outcli'].setValue("");
this.clck2callForm.get('networksdata')['controls'][scnt]['controls']['outcli'].updateValueAndValidity();
if(gtstatus==0){
  this.selectedCli[scnt]=[];
}
$("#outcli_select"+scnt).val(null).trigger('change');
$("#pool_network"+scnt).val(null).trigger('change');
this.nubmerfreezed[scnt]='';
this.networkList[scnt]= data.network_list;
this.outcliList[scnt]=[];
if(scnt==0){
if(this.clck2callForm.get("server_type").value=='0'){
this.agentListnetwork=data.network_list;
this.agentListsamecase=data.network_list;
}
else{
this.agentListnetwork=[];
}
}

$(".fullpageloader").fadeOut('fast');
})
}
changeserver(i,serrverval){
 let scnt=i;
 this.getc2cnetwork(serrverval,scnt,1);
}
changeserver1(i,serrverval){
  let scnt=i;
  this.getc2cnetwork(serrverval,scnt,0);
 }
  changenetwork(netwrkval,ips,i){

  this.getc2cclic(netwrkval,ips,i,1);
}
changenetwork1(netwrkval,ips,i){

    this.getc2cclic(netwrkval,ips,i,0);
}

 checkdid(clicval,i) {

 var cliitemarra=[];
  const cliitemarralength = (<FormArray>this.clck2callForm.get('networksdata')).length;
for(var kt= 0;cliitemarralength>kt;kt++){
  var outcliarray = $("#outcli_select"+kt).val();
  cliitemarra.push(outcliarray);
 }

var result = [];

for (var idk=0, iLen=cliitemarra.length; idk<iLen; idk++) {
  result = result.concat(cliitemarra[idk]);
}

var checkcli = result.some(function(item, idx){
  return result.indexOf(item) != idx
});
this.duplicateNum=checkcli;
if(clicval!=''){
  if(clicval!=undefined || clicval!='' || clicval!=null){
   var clinum = clicval.map(function(item){
    return item
    }).join(",");

    }

  $(".fullpageloader").fadeIn('fast');
  this.nubmerfreezed[i]='';
 this.http.post(this.baseurl+'check_vnum_c2c',{vnum:clinum,user_id:this.user_id}).subscribe((data:any)=>{
      $(".fullpageloader").fadeOut('fast');
 this.nubmerfreezed[i]= data;

if(this.nubmerfreezed[i].status == 'fail'){
  this.alreadyassign=true;
 //  this.nubmerfreezed[i]='';
  this.toastr.error("Out CLI already assigend. Please choose Another.", 'Error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
}
this.alreadyassign=false;
  })
 }
 else{
  this.nubmerfreezed[i]='';
 }

}
getc2cserver(){
  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+'get_c2c_server',{session_id:this.session_id}).subscribe((data:any)=>{
   this.serverList= data.server_ip;
   //this.nubmerfreezed='';
 $(".fullpageloader").fadeOut('fast');
})
}
changeagentnetwork(count){
  $("#cliselect"+count).val('');
  }
agentcheckdid(clicval,i) {
if(clicval.length==10){
  if(clicval!=''){
    $(".fullpageloader").fadeIn('fast');
       this.agentnubmerfreezed[i]='';
      this.http.post(this.baseurl+'check_vnum_c2c',{vnum:clicval,user_id:this.user_id}).subscribe((data:any)=>{
           $(".fullpageloader").fadeOut('fast');
      this.agentnubmerfreezed[i]= data;
     console.log(data);
     if(this.agentnubmerfreezed[i].status == 'fail'){

      //  this.nubmerfreezed[i]='';
       this.toastr.error("Out CLI already assigend. Please choose Another.", 'Error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
     }else{

     }

       })
      }
      else{
       this.agentnubmerfreezed[i]='';
      }

}
 }

allnetwork(){
  let that = this;

for(var scli=0;this.records.obd_network.length>scli;scli++){
  if(this.records.obd_network[scli].cli!=''){
   var splititem=this.records.obd_network[scli].cli.split(',');
   this.selectedCli[scli]=splititem;
  }
  else{
   this.selectedCli[scli]=[];
  }
 }
this.records.obd_network.forEach((element,index) => {
 this.changeserver(index,element.server);
 this.changenetwork(element.network,element.server,index);
});


if(this.OBDnetworkList.length>0){
  this.clck2callForm.setControl('networksdata',
  this.fb.array(this.OBDnetworkList.map(
  id => this.fb.group({server: [{value:id.server,disabled:true},Validators.required],network: [{value:id.network,disabled:true},Validators.required],pool_network: {value:'',disabled:true},outcli: [{value:id.cli,disabled:true}],channelno: [{value:id.channel,disabled:true}], retrysel:[{value:id.agent_retry,disabled:true},Validators.required],call_retry:[{value:id.caller_retry,disabled:true},Validators.required]})
  ) || [])
  );
}else{
  let dummyobddata = [{server:'',network:'',pool_network:'',outcli:'',channelno:'0',retrysel:'0',caller_retry:'0'}];
  this.clck2callForm.setControl('networksdata',
  this.fb.array(dummyobddata.map(
  id => this.fb.group({server: [id.server,Validators.required],network: [id.network,Validators.required],pool_network: '',outcli: [id.outcli],channelno: [id.channelno], retrysel:[id.retrysel,Validators.required],call_retry:[id.caller_retry,Validators.required]})
  ) || [])
  );
  setTimeout(() => {
    $(".pool_network").select2();
  }, 10);
}


var function1 = (callback1) => {

  setTimeout(() => {
  this.OBDnetworkList.forEach((element,index) => {
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['server'].setValue(element.server);
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['server'].updateValueAndValidity();
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['network'].setValue(element.network);
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['network'].updateValueAndValidity();
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['pool_network'].setValue(element.pooling_network);
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['pool_network'].updateValueAndValidity();
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['outcli'].setValue(element.cli);
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['outcli'].updateValueAndValidity();
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['retrysel'].setValue(element.agent_retry);
  this.clck2callForm.get('networksdata')['controls'][index]['controls']['retrysel'].updateValueAndValidity();
  var $vagentid = $("#pool_network"+index).select2();
  var $outcli_select =$("#outcli_select"+index).select2();
  var siddata = this.OBDnetworkList[index].pooling_network.split(",");
  var outcli_selectdata = this.OBDnetworkList[index].cli.split(",");
  $vagentid.val(siddata).trigger('change');
  $outcli_select.val(outcli_selectdata).trigger('change');
  });
  callback1()
}, this.OBDnetworkList.length*900);
}

var function2 = () => {
 setTimeout(() => {
 var $outcli =$(".outcli_select").select2();
  $outcli.on("change", function(){
    that.checkdid($(this).val(),$(this).data('id'));
  });
 }, this.OBDnetworkList.length*1000);
}

function1(function2);
}
}
