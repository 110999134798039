import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mstollfreeservice',
  templateUrl: './mstollfreeservice.component.html',
  styleUrls: ['./mstollfreeservice.component.css']
})
export class MstollfreeserviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
