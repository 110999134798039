import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service'; 
import {formatDate } from '@angular/common'; 

@Component({
  selector: 'app-resellervirtualinventry',
  templateUrl: './resellervirtualinventry.component.html',
  styleUrls: ['./resellervirtualinventry.component.css']
})
export class ResellervirtualinventryComponent implements OnInit {

  vinvrecords:any;
  itemsPerPage:any=15;
  currentPage:number =1;
  filterForm:FormGroup;
  oprater:any;
  circle:any;
  resel_id:any;
  status:any;
  fromdate:any;
  todate:any;
  currentUserloclstrage:any;
  baseurl:any;
  resellerList:any;
  operaters=['Airtel','Reliance','Vodafone','BSNL','IDEA','Tata Indicom','Aircel','MTNL','Spice','MTNL'];
  circles=['Andaman and Nicobar Islands','Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chandigarh','Chhattisgarh','Dadra and Nagar Haveli','Delhi','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu and Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Puducherry','Punjab','Rajasthan','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal'];
  today= new Date();
  jstoday = '';
  
    constructor(private fb:FormBuilder, private http:HttpClient, private toastr:ToastrService ,private confirmDialogService:ConfirmDialogService) { }
  
    ngOnInit() {
      this.baseurl = localStorage.getItem('baseurl');
      this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
      this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    
     this.getressellerlist();
     this.createform();
      this.getRecord(this.currentPage);
    }
  createform(){
   
    this.filterForm = this.fb.group({
      oprater:[''],
      circle:[''],
      resel_id:[''],
      status:[''],
      fromdate:'',
      todate:''
    })
  }
  getRecord(crpage:number){
  $(".fullpageloader").fadeIn('fast');
  //this.http.get(this.baseurl+"inventry_list").subscribe((data:any)=>{
   this.http.post(this.baseurl+"inventry_list",{page:crpage,oprater:this.oprater,circle:this.circle,status:this.status,fromdate:this.fromdate,todate:this.todate}).subscribe((data:any)=>{
  $(".fullpageloader").fadeOut('fast');
  //console.log(data);
  this.vinvrecords = data;
  })
    }

  pageChange(newpage:number){
      this.getRecord(newpage);
      this.currentPage = newpage;
        }
  
  serachRecords(sinpuval:any){
    
          if(sinpuval==''){
            $("#searchinput").css('border-color','#ff0000');
            setTimeout(() => {
              $("#searchinput").css('border-color','#b8b8b8');
            }, 6000);
          }
          else{
           
            $(".fullpageloader").show();
            this.http.post(this.baseurl+"inventry_list",{search:sinpuval,page:this.currentPage}).subscribe(
              (data:any)=>{
          this.vinvrecords = data;
  
            // console.log(data.length);
             $(".fullpageloader").hide();
            })
          }
            }
   onSubmit(){
      this.oprater=this.filterForm.value.oprater;
      this.circle=this.filterForm.value.circle;
      this.resel_id=this.filterForm.value.resel_id;
      
      this.status=this.filterForm.value.status;
      this.fromdate= $('#fromdate').val();
      this.todate= $('#todate').val();
     
      $('.fullpageloader').show('fast');
    
     this.http.post(this.baseurl+"inventry_list",{oprater:this.oprater,circle:this.circle,resel_id:this.resel_id,status:this.status,fromdate:this.fromdate,todate:this.todate,page:this.currentPage}).subscribe(
     (data:any)=>{
       this.vinvrecords=data;
        $('.cfillBox').slideUp('fast');
        $('.fullpageloader').hide('fast');
  })
   }

   deleteRecord(id:number,curpage=this.currentPage){
    let that = this;
     this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
       
          that.http.post(that.baseurl+"delete_number_details",{id:id}).subscribe((data:any)=>{
          //  alert(that.baseurl+"delete_number_details?inventory_id=");
          // console.log(data);
         // alert(id);
            if(data.status=='success'){
             that.toastr.success('Record Deleted  succesfully!', 'Success!',{
              positionClass:'toast-top-center',
              timeOut: 3000
             });
             that.getRecord(curpage); 
            }
            
       })
      
        }, function () {  
          return false;
        }) 
       
   }
   getressellerlist(){
    this.http.get(this.baseurl+"get_reseller_list").subscribe((data:any)=>{
      this.resellerList = data.data;
    })
      }
}
