import { Component, OnInit,ElementRef } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-clicktocallservice',
  templateUrl: './clicktocallservice.component.html',
  styleUrls: ['./clicktocallservice.component.css']
})
export class ClicktocallserviceComponent implements OnInit {
    buttunhide:boolean=false;
    vagentForm:FormGroup;
    submitted:boolean = false;
    service_id:any;
    settings:FormArray;
    costomizedplan:boolean=false;
    smsacount:boolean=false;
    minavopersonnel:boolean=false;
    crmsettsec:boolean=false;
    records:any;
    clidata:any;
    accList:boolean = false;
    arr=[];
    didlist:boolean = true;
    bckdidlist:boolean = true;
   // didnumberbox:boolean = false;
  //  bkpdidnumberbox:boolean = false;
   // virtualnumberbox:boolean = false;
    virtualnumberradioBtn:boolean = true;
    virtualnumbersec:boolean=false;
    resselerList:boolean=false;
    capturecall:boolean=false;
    click2call:boolean=false;
    agentpopup:boolean=false;
    callrouting:boolean=false;
    singlepopup:boolean=false;
    calldisposition:boolean=false;
    personalsmscoin:boolean=false;
    currentUserloclstrage:any;
    session_id:any;
    baseurl:any;
  networkList=[];
  outcliList=[];
  nubmerfreezed=[];
  serverList:any;
  selectedCli=[];
  duplicateNum:boolean = false;
  alreadyassign:boolean = false;
  user_id:any;
    industryList=['Education','Health Care','Banking','Beauty Care','Grocery','Government','Politics','Hotel/Guest House','NGO','Other'];

    constructor(private elment: ElementRef, private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
    this.service_id = this.route.snapshot.paramMap.get("service_id");
  }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.session_id =  this.currentUserloclstrage.user_id;
    this.user_id =  this.currentUserloclstrage.user_id;
    this.baseurl = localStorage.getItem('baseurl');
    this.getc2cserver();
this.createForm();
this.getdata();
$(function() {
  setTimeout(() => {
    $(".pool_network,.outcli_select").select2();

  }, 200);
    //   $( document ).on( 'focus', ':input', function(){
    //     $( this ).attr( 'autocomplete', 'off' );
    // });

    $("#deflang").change(function() {
      // $("#otherlang").select2().val(null).trigger("change");
      $("#otherlang option").prop('disabled',false);
     // $("#otherlang").select2('destroy').val("").select2();
      $("#otherlang").not(this).find("option[value="+ $(this).val() + "]").attr('disabled', true);
       });

$("#otherlang").not("#deflang").find("option[value="+ $("#deflang").val() + "]").attr('disabled', true);

 $(".acchead").click(function() {
          $(this).next('.accbox').slideToggle('fast');
          $(this).toggleClass('active');
      });

  });
  this.vagentForm.get('clientdetail').get('acc_type').valueChanges
  .subscribe(acc_type => {
     if(acc_type=='new'){
        this.accList= false;
        this.vagentForm.get('clientdetail').get('acc_list').setValidators(null);
        this.vagentForm.get('clientdetail').get('acc_list').updateValueAndValidity()
     }
  else{
    this.accList= true;
    this.vagentForm.get('clientdetail').get('acc_list').setValidators([Validators.required]);
    this.vagentForm.get('clientdetail').get('acc_list').updateValueAndValidity()
     }
  })
  this.vagentForm.get('admincheckbox').valueChanges
  .subscribe(admincheckbox => {
if(admincheckbox==true){
  this.vagentForm.get('adminsetting').get('name').setValue(this.records.data[0].contact_person);
  this.vagentForm.get('adminsetting').get('email').setValue(this.records.data[0].contact_email);
  this.vagentForm.get('adminsetting').get('mobile').setValue(this.records.data[0].mobile);

this.vagentForm.get('adminsetting').get('name').updateValueAndValidity();
this.vagentForm.get('adminsetting').get('email').updateValueAndValidity();
this.vagentForm.get('adminsetting').get('mobile').updateValueAndValidity();
}
else{
  this.vagentForm.get('adminsetting').get('name').setValue('');
  this.vagentForm.get('adminsetting').get('email').setValue('');
  this.vagentForm.get('adminsetting').get('mobile').setValue('');

this.vagentForm.get('adminsetting').get('name').updateValueAndValidity();
this.vagentForm.get('adminsetting').get('email').updateValueAndValidity();
this.vagentForm.get('adminsetting').get('mobile').updateValueAndValidity();
}
  });

  this.vagentForm.get('dedplan').get('allcommon').valueChanges
   .subscribe(allcommon => {
  // this.vagentForm.get('dedplan').get('allcommon').value()==allcommon;

if(allcommon=="allcommon"){
    setTimeout(() => {
        this.vagentForm.get('dedplan').get('obd_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('ibd_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('email_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('sms_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('tf_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('fax_ded').setValue('Common');
    }, 200);

this.vagentForm.get('dedplan').get('obd_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('ibd_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('email_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('sms_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('tf_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('fax_ded').updateValueAndValidity();

}
else if(allcommon=='allself'){
setTimeout(() => {
this.vagentForm.get('dedplan').get('obd_ded').setValue('Self');
this.vagentForm.get('dedplan').get('ibd_ded').setValue('Self');
this.vagentForm.get('dedplan').get('email_ded').setValue('Self');
this.vagentForm.get('dedplan').get('sms_ded').setValue('Self');
this.vagentForm.get('dedplan').get('tf_ded').setValue('Self');
this.vagentForm.get('dedplan').get('fax_ded').setValue('Self');
}, 200);

this.vagentForm.get('dedplan').get('obd_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('ibd_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('email_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('sms_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('tf_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('fax_ded').updateValueAndValidity();
 }

})

this.vagentForm.get('smssetting').get('account_type').valueChanges
.subscribe(account_type => {

if(account_type=="Minavo"){
this.smsacount = false;
this.minavopersonnel  = false;
this.personalsmscoin = false;
//this.vagentForm.get('dedplan').get('fax_ded').setValue('Self');

//this.vagentForm.get('othersetting').get('A15').get("param_value").setValue(account_type);

this.vagentForm.get("smssetting").get("base_uri").setValidators(null);
this.vagentForm.get("smssetting").get("param_user_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_user_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_message_name").setValidators(null);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();

}
else if(account_type=="personal"){
this.smsacount = true;
this.minavopersonnel  = false;
this.personalsmscoin = false;

this.vagentForm.get("smssetting").get("base_uri").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_message_name").setValidators([Validators.required]);

this.vagentForm.get("smssetting").get("base_uri").setValue(this.records.deafult_sms_details[0].base_uri);
this.vagentForm.get("smssetting").get("param_user_name").setValue(this.records.deafult_sms_details[0].param_user_name);
this.vagentForm.get("smssetting").get("param_user_value").setValue(this.records.deafult_sms_details[0].param_user_value);
this.vagentForm.get("smssetting").get("param_pass_name").setValue(this.records.deafult_sms_details[0].param_pass_name);
this.vagentForm.get("smssetting").get("param_pass_value").setValue(this.records.deafult_sms_details[0].param_pass_name);
this.vagentForm.get("smssetting").get("param_sid_name").setValue(this.records.deafult_sms_details[0].param_sid_name);
this.vagentForm.get("smssetting").get("param_sid_value").setValue(this.records.deafult_sms_details[0].param_sid_value);
this.vagentForm.get("smssetting").get("param_lang_name").setValue(this.records.deafult_sms_details[0].param_lang_name);
this.vagentForm.get("smssetting").get("param_lang_value").setValue(this.records.deafult_sms_details[0].param_lang_value);
this.vagentForm.get("smssetting").get("param_mobile_name").setValue(this.records.deafult_sms_details[0].param_mobile_name);
this.vagentForm.get("smssetting").get("param_message_name").setValue(this.records.deafult_sms_details[0].param_message_name);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
}
else if(account_type=="minavo-personal"){
this.personalsmscoin = true;
this.smsacount = true;
this.minavopersonnel  = true

this.vagentForm.get("smssetting").get("base_uri").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_message_name").setValidators([Validators.required]);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
 }
})

this.vagentForm.get('smssetting').get('minpertype').valueChanges
.subscribe(minpertype => {

   if(minpertype=='default'){
    this.vagentForm.get("smssetting").get("base_uri").setValue(this.records.deafult_sms_details[0].base_uri);
    this.vagentForm.get("smssetting").get("param_user_name").setValue(this.records.deafult_sms_details[0].param_user_name);
    this.vagentForm.get("smssetting").get("param_user_value").setValue(this.records.deafult_sms_details[0].param_user_value);
    this.vagentForm.get("smssetting").get("param_pass_name").setValue(this.records.deafult_sms_details[0].param_pass_name);
    this.vagentForm.get("smssetting").get("param_pass_value").setValue(this.records.deafult_sms_details[0].param_pass_name);
    this.vagentForm.get("smssetting").get("param_sid_name").setValue(this.records.deafult_sms_details[0].param_sid_name);
    this.vagentForm.get("smssetting").get("param_sid_value").setValue(this.records.deafult_sms_details[0].param_sid_value);
    this.vagentForm.get("smssetting").get("param_lang_name").setValue(this.records.deafult_sms_details[0].param_lang_name);
    this.vagentForm.get("smssetting").get("param_lang_value").setValue(this.records.deafult_sms_details[0].param_lang_value);
    this.vagentForm.get("smssetting").get("param_mobile_name").setValue(this.records.deafult_sms_details[0].param_mobile_name);
    this.vagentForm.get("smssetting").get("param_message_name").setValue(this.records.deafult_sms_details[0].param_message_name);

    this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
   }
   else{

    this.vagentForm.get("smssetting").get("base_uri").setValue('');
    this.vagentForm.get("smssetting").get("param_user_name").setValue('');
    this.vagentForm.get("smssetting").get("param_user_value").setValue('');
    this.vagentForm.get("smssetting").get("param_pass_name").setValue('');
    this.vagentForm.get("smssetting").get("param_pass_value").setValue('');
    this.vagentForm.get("smssetting").get("param_sid_name").setValue('');
    this.vagentForm.get("smssetting").get("param_sid_value").setValue('');
    this.vagentForm.get("smssetting").get("param_lang_name").setValue('');
    this.vagentForm.get("smssetting").get("param_lang_value").setValue('');
    this.vagentForm.get("smssetting").get("param_mobile_name").setValue('');
    this.vagentForm.get("smssetting").get("param_message_name").setValue('');

    this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
   }
})

this.vagentForm.get('crmsetting').get('crm_name').valueChanges
.subscribe(crm_name => {
if(crm_name=='minavo'){
         this.crmsettsec=false;
        this.vagentForm.get('crmsetting').get('capture_call_status').setValue(0);
        this.vagentForm.get('crmsetting').get('click_tocall_status').setValue(0);
        this.vagentForm.get('crmsetting').get('agentpopup_status').setValue(0);
        this.vagentForm.get('crmsetting').get('callrouting_status').setValue(0);
        this.vagentForm.get('crmsetting').get('singlpopup_status').setValue(0);
        this.vagentForm.get('crmsetting').get('calldisposition_status').setValue(0);

  }
   else{
    this.vagentForm.get('crmsetting').get('capture_call_status').setValue(1);
    this.vagentForm.get('crmsetting').get('click_tocall_status').setValue(1);
    this.vagentForm.get('crmsetting').get('agentpopup_status').setValue(1);
    this.vagentForm.get('crmsetting').get('callrouting_status').setValue(1);
    this.vagentForm.get('crmsetting').get('singlpopup_status').setValue(1);
    this.vagentForm.get('crmsetting').get('calldisposition_status').setValue(1);
    this.crmsettsec=true;
   }
})

this.vagentForm.get('plandetail').get('acc_owner').valueChanges
.subscribe(acc_owner => {

   if(acc_owner=='Minavo'){
      this.resselerList= false;
      this.vagentForm.get("plandetail").get("resselerlist").setValidators(null);
      this.vagentForm.get('plandetail').get('resselerlist').updateValueAndValidity();
   }
else{
  this.resselerList= true;
  this.vagentForm.get("plandetail").get("resselerlist").setValidators([Validators.required]);
  this.vagentForm.get('plandetail').get('resselerlist').updateValueAndValidity();
   }
})

this.vagentForm.get('crmsetting').get('capture_call_status').valueChanges
.subscribe(capture_call_status => {
  if(capture_call_status==1){
  this.capturecall = true;
  this.vagentForm.get('crmsetting').get('capture_call_input').setValidators([Validators.required]);

}
else{
this.capturecall = false;
this.vagentForm.get('crmsetting').get('capture_call_input').setValidators(null);
this.vagentForm.get('crmsetting').get('capture_call_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('click_tocall_status').valueChanges
.subscribe(click_tocall_status => {

if(click_tocall_status==1){
  this.click2call = true;
  this.vagentForm.get('crmsetting').get('click_tocall_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('click_tocall_input').updateValueAndValidity();
}
else{
  this.click2call = false;
  this.vagentForm.get('crmsetting').get('click_tocall_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('click_tocall_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('agentpopup_status').valueChanges
.subscribe(agentpopup_status => {
if(agentpopup_status==1){
  this.agentpopup = true;
  this.vagentForm.get('crmsetting').get('agentpopup_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('agentpopup_input').updateValueAndValidity();
}
else{
  this.agentpopup = false;
  this.vagentForm.get('crmsetting').get('agentpopup_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('agentpopup_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('callrouting_status').valueChanges
.subscribe(callrouting_status => {
 if(callrouting_status==1){
  this.callrouting = true;
  this.vagentForm.get('crmsetting').get('callrouting_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('callrouting_input').updateValueAndValidity();
}
else{
  this.callrouting = false;
  this.vagentForm.get('crmsetting').get('callrouting_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('callrouting_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('singlpopup_status').valueChanges
.subscribe(singlpopup_status => {
  if(singlpopup_status==1){
  this.singlepopup = true;
  this.vagentForm.get('crmsetting').get('singlpopup_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('singlpopup_input').updateValueAndValidity();
}
else{
  this.singlepopup = false;
  this.vagentForm.get('crmsetting').get('singlpopup_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('singlpopup_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('calldisposition_status').valueChanges
.subscribe(calldisposition_status => {
 if(calldisposition_status==1){
  this.calldisposition = true;
  this.vagentForm.get('crmsetting').get('calldisposition_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('calldisposition_input').updateValueAndValidity();
}
else{
  this.calldisposition = false;
  this.vagentForm.get('crmsetting').get('calldisposition_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('calldisposition_input').updateValueAndValidity();
}
})

  }

createForm(){

    // this.arr.push({settname:'Coin Allocation Type',coinalltype:['monthly']})
this.vagentForm = this.fb.group({
    clientdetail:this.fb.group({
         acc_type:[{value:''},[Validators.required]],
         acc_list:[{value:''}],
        company:[{value:''},[Validators.required]],
        first_name:[{value:''},[Validators.required]],
        last_name:[{value:''}],
        designation:[{value:''},[Validators.required]],
        industry:[{value:''},[Validators.required]],
        comp_size:[{value:''}],
        mobile:[{value:''},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
        email:[{value:''},[Validators.required,Validators.email]],
        password:[{value:''},[Validators.required]],
        address_fl:[{value:''}],
        address_ll:[{value:''}],
        pin:[{value:''}],
        country:[{value:'India'},[Validators.required]],
        state:[{value:''}],
        city:[{value:''}],
        sale_exc_name:[{value:''}],
        telesale_exc_name:[{value:''}]
    }),
    adminsetting:this.fb.group({
      name:[{value:''},[Validators.required]],
      email:[{value:''},[Validators.required,Validators.email]],
      mobile:[{value:''},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]]
    }),
   dedplan:this.fb.group({
        allcommon:[{value:''},[Validators.required]],
        obd_ded:[{value:''},[Validators.required]],
        ibd_ded:[{value:''},[Validators.required]],
        email_ded:[{value:''},[Validators.required]],
        sms_ded:[{value:''},[Validators.required]],
        tf_ded:[{value:''},[Validators.required]],
        fax_ded:[{value:''},[Validators.required]],
     }),
    plandetail:this.fb.group({
      contract_period:[{value:''},[Validators.required]],
      billing_type:[{value:''},[Validators.required]],
      postpaid_limit:[{value:''}],
      sub_type:[{value:''},[Validators.required]],
      call_rate:[{value:''},[Validators.required]],
      service_billing:[{value:''},[Validators.required]],
      channel_pool:[{value:'self'}],
      plan_name:[{value:''},[Validators.required]],
      plan_price:[{value:''},[Validators.required]],
      plan_term:[{value:''},[Validators.required]],
      total_coins:[{value:''},[Validators.required]],
      allowed_client:[{value:''},[Validators.required]],
      free_chanel:[{value:''},[Validators.required]],
      ibd_coins:[{value:''},[Validators.required]],
      obd_coins:[{value:''},[Validators.required]],
      sms_coins:[{value:''},[Validators.required]],
      ibd_ded_sec:[{value:''},[Validators.required]],
      ibd_ded_rate:[{value:''},[Validators.required]],
      obd_ded_sec:[{value:''},[Validators.required]],
      obd_ded_rate:[{value:''},[Validators.required]],
      sms_ded__cnt:[{value:''},[Validators.required]],
      smsded_rate:[{value:''},[Validators.required]],
      accounttype:[{value:''},[Validators.required]],
      acc_owner:[{value:''}],
      resselerlist:[{value:''}],
      server:[{value:''}],
      network:[{value:''}],
      outcli:[{value:''}]

 }),
 smssetting:this.fb.group({
account_type:[{value:''},[Validators.required]],
minpertype:[{value:''},[Validators.required]],
base_uri:[{value:''},[Validators.required]],
param_user_name:[{value:''},[Validators.required]],
param_user_value:[{value:''},[Validators.required]],
param_pass_name:[{value:''},[Validators.required]],
param_pass_value:[{value:''},[Validators.required]],
param_sid_name:[{value:''},[Validators.required]],
param_sid_value:[{value:''},[Validators.required]],
param_lang_name:[{value:''},[Validators.required]],
param_lang_value:[{value:''},[Validators.required]],
param_mobile_name:[{value:''},[Validators.required]],
param_message_name:[{value:''},[Validators.required]],
personal_sms_coins:[{value:''}],
accaccount:[{value:''},[Validators.required]],
  param_value:[{value:''},[Validators.required]],
}),
crmsetting:this.fb.group({
    crm_name:[{value:''},[Validators.required]],
    //crmsett:[{value:'', disabled:false},[Validators.required]],
    capture_call_status:[{value:''}],
    capture_call_input:[{value:''},[Validators.required]],
    click_tocall_status:[{value:''}],
    click_tocall_input:[{value:''},[Validators.required]],
    agentpopup_status:[{value:''}],
    agentpopup_input:[{value:''},[Validators.required]],
    callrouting_status:[{value:''}],
    callrouting_input:[{value:''},[Validators.required]],
    singlpopup_status:[{value:''}],
    singlpopup_input:[{value:''},[Validators.required]],
    calldisposition_status:[{value:''}],
    calldisposition_input:[{value:''},[Validators.required]],

}),
finance_remarks:[{value:''},[Validators.required]],
networksdata:this.fb.array([this.addnetworks()]),
remarks:[{value:''},[Validators.required]],
finance_code:[],
caf_id:[],
service_id:'',
service_type:'',
sub_service_type:'',
admincheckbox:'',
})
}

addnetworks(): FormGroup {
  return this.fb.group({
    server:['',Validators.required],
    network:['',Validators.required],
    pool_network:[''],
    outcli:[''],
    channelno:[''],
    retrysel:"0",
    call_retry:"0",
  });

}
addnetwork(): void {
 (<FormArray>this.vagentForm.get('networksdata')).push(this.addnetworks());
  setTimeout(() => {
    $(".pool_network,.outcli_select").select2();
    }, 50);
}

removenetwork(i: number){
this.vagentForm.controls['networksdata']['controls'].splice(i, 1);
this.vagentForm.controls['networksdata'].updateValueAndValidity();
}


get f() { return this.vagentForm.controls }
onSubmit(){

  var cliitemarra=[];
  const cliitemarralength = (<FormArray>this.vagentForm.get('networksdata')).length;
for(var kt= 0;cliitemarralength>kt;kt++){
  var outcliarray = $("#outcli_select"+kt).val();
  cliitemarra.push(outcliarray);
 }

var result = [];

for (var idk=0, iLen=cliitemarra.length; idk<iLen; idk++) {
  result = result.concat(cliitemarra[idk]);
}

var checkcli = result.some(function(item, idx){
  return result.indexOf(item) != idx
});
this.duplicateNum=checkcli;
if(this.alreadyassign==true){
  this.toastr.error("Out CLI already assigend. Please choose Another.", 'Error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
return false;
}
else if(this.duplicateNum==true){
this.toastr.error("You can not choose same Out CLI. Please choose Another.", 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
return false;
}
else{

let totallength = (<FormArray>this.vagentForm.get('networksdata')).length;
for(var tl= 0;totallength>tl;tl++){
  var clisec = $("#outcli_select"+tl).val();
  var pool_networksec =  $("#pool_network"+tl).val();
 if(clisec!=undefined || clisec!='' || clisec!=null){

   var clisecfinal = clisec.map(function(item){
   return item
   }).join(",");

   }
   else{
     clisecfinal='';
   }
   if(pool_networksec!=undefined || pool_networksec!='' || pool_networksec!=null){

     var pool_networksecfinal = pool_networksec.map(function(item){
     return item
     }).join(",");

     }
     else{
       pool_networksecfinal='';
     }
   this.vagentForm.get('networksdata')['controls'][tl]['controls']['outcli'].setValue(clisecfinal);
   this.vagentForm.get('networksdata')['controls'][tl]['controls']['outcli'].updateValueAndValidity();
   this.vagentForm.get('networksdata')['controls'][tl]['controls']['pool_network'].setValue(pool_networksecfinal);
   this.vagentForm.get('networksdata')['controls'][tl]['controls']['pool_network'].updateValueAndValidity();

 }

  this.submitted = true;
  let vagentdata =  JSON.stringify(this.vagentForm.value);
if(this.vagentForm.invalid){
  //alert("invalid");
  if (!this.vagentForm.valid) {
    let target;

    target = this.elment.nativeElement.querySelector('.is-invalid')

    if (target) {
        $('html,body').animate({ scrollTop: $(target).offset().top }, 'slow');
        target.focus();
    }
}
return false;
}

else{

  $(".fullpageloader").fadeIn("fast");
      this.http.post(" https://api.cerebra.quadrafort.in/service_request_deatils_insert",{data:vagentdata}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         this.router.navigateByUrl('/crequest/servicerequest');
         }
       else if(data.status=="fail"){
        $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });

       }

     },
     error => {
      $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
   })
     }
}

}

getdata(){
    $(".fullpageloader").fadeIn('fast');

    this.http.get(this.baseurl+"service_request_deatils?service_id="+this.service_id).subscribe((data:any)=>{
    this.records=data;
    console.log(data.data[0].service_id);
    if(data.data[0].service_subtype.toLowerCase()=='both unlimited' || data.data[0].service_subtype.toLowerCase()=='missed call unlimited'){
      setTimeout(() => {
        this.vagentForm.get('plandetail').get('total_coins').setValue('500000');
        this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
      }, 1200);
    }
  else{
    if(data.data[0].amount_type=='Rental'){
      setTimeout(() => {
        this.vagentForm.get('plandetail').get('total_coins').setValue('0');
        this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
      }, 1200);
      }
      else{
        this.calculatecoin(this.records.data[0].ibd_call_rate,this.records.data[0].obd_call_rate,this.records.data[0].allocation_type);
      }
  }
  //  console.log(data.setting);

    $(".fullpageloader").fadeOut('fast');
  this.vagentForm.patchValue({
    clientdetail:{
      acc_type:'new',
      acc_list:'',
        company:data.data[0].company_name,
        first_name:data.data[0].contact_person,
        last_name:'',
        designation:data.data[0].designation,
        industry:data.data[0].industry,
        comp_size:'',
        mobile:data.data[0].mobile,
        email:data.data[0].email,
        password:'',
        address_fl:data.data[0].address1,
        address_ll:data.data[0].address2,
        pin:data.data[0].pincode,
        country:"India",
        state:data.data[0].state,
        city:data.data[0].city,
        sale_exc_name:data.data[0].sale_person,
        telesale_exc_name:data.data[0].telesale_person
    },
    adminsetting:{
      name:'',
      email:'',
      mobile:'',
     },
    dedplan:{
        allcommon:this.getallcommon(data.data[0].coins),
        obd_ded:'' ,
        ibd_ded:'',
        email_ded:'',
        sms_ded:'',
        tf_ded:'',
        fax_ded:'',
    },
    plandetail:{
        contract_period:data.data[0].contract,
        billing_type:data.data[0].billing,
        postpaid_limit:data.data[0].postpaid_limit,
        service_billing:data.data[0].service_subtype,
        channel_pool:'self',
        call_rate:function(tibd,tobd,ibdcallrate,obdcallrate){
          if(tibd >0 || tobd >0){
           if(tibd>0){
             return tibd;
             }
           else{
            return tobd;
             }
          }
          else{
           if(ibdcallrate>0){
             return ibdcallrate;
             }
           else{
            return obdcallrate;
             }
          }
           }(this.records.data[0.].topup_ibd_call_rate,this.records.data[0.].topup_obd_call_rate,this.records.data[0.].ibd_call_rate,this.records.data[0.].obd_call_rate),
        sub_type:function(smstype){

          if(smstype=='' || smstype==null){

            return '';

            }
          else{

            if(smstype=="Admin Client" || smstype=="admin-client"){
              return "admin-client";
              }
              else if(smstype=="c1n" || smstype=="C1n"){
                return 'c1n'
              }
              else if(smstype=="c2n" || smstype=="C2n"){
                return 'c2n'
              }

             }
        }(this.records.data[0].sms_type),
        plan_name:data.data[0].plan_name,
        plan_price:data.data[0].service_amount,
        plan_term:data.data[0].service_validity,
        total_coins:'',
        allowed_client:data.data[0].allow_agent,
        free_chanel:data.data[0].channel,
        ibd_coins:'0',
        obd_coins:'0',
        sms_coins:function(rate,cost){
          let rrate:number =parseInt(rate);
          let ccost:number =parseInt(cost);
          let total:number= (ccost*100)/rrate;
        if(ccost==0){
         return '0';
        }
        else{
         return Math.floor(total);
        }

         }(data.data[0].sms_rate,data.data[0].call_end_sms_cost),
        ibd_ded_sec:data.data[0].ibd_call_pulse,
        ibd_ded_rate:function(ibdcount){
if(ibdcount<=30){
return "1"
}
else{
  return "1"
}
        }(data.data[0].ibd_call_pulse),
        obd_ded_sec:data.data[0].obd_call_pulse,
        obd_ded_rate:function(obdcount){
           return "1"
           }(data.data[0].obd_call_pulse),
        sms_ded__cnt:function(smscont){
          if(smscont>0){
          return "1"
          }
          else{
            return "0"
          }
          }(data.data[0].sms_rate),
        smsded_rate:function(smscont){
          if(smscont>0){
          return "1"
          }
          else{
            return "0"
          }
                  }(data.data[0].sms_rate),
        accounttype:'Premium',
        acc_owner:this.getOwner(data.data[0].type_of_client),
        resselerlist:'',//data.profile[0].account_type,
        server:'',
        network:'',
        outcli:'',
    },
    smssetting:{
        account_type:'Minavo',
        minpertype:'default',
        base_uri:data.deafult_sms_details[0].base_uri,
        param_user_name:data.deafult_sms_details[0].param_user_name,
        param_user_value:data.deafult_sms_details[0].param_user_value,
        param_pass_name:data.deafult_sms_details[0].param_pass_name,
        param_pass_value:data.deafult_sms_details[0].param_pass_value,
        param_sid_name:data.deafult_sms_details[0].param_sid_name,
        param_sid_value:data.deafult_sms_details[0].param_sid_value,
        param_lang_name:data.deafult_sms_details[0].param_lang_name,
        param_lang_value:data.deafult_sms_details[0].param_lang_value,
        param_mobile_name:data.deafult_sms_details[0].param_mobile_name,
        param_message_name:data.deafult_sms_details[0].param_message_name,
        personal_sms_coins:'',
        accaccount:'Promotional',
        param_value:function(allotype){
          if(allotype.toLowerCase()=='monthly'){
      return "monthly";
          }
          else if(allotype.toLowerCase()=='one time'){
              return "one time";
          }
          else if(allotype.toLowerCase()=='monthly carryfwd'){
            return "monthly carryfwd";
          }
          else{
            return allotype;
        }  }(data.data[0].allocation_type),
    },
    crmsetting:{
    crm_name:'minavo',
    //crmsett:data.profile[0].crm_name,
    capture_call_status:'',
    capture_call_input:'',
    click_tocall_status:'',
    click_tocall_input:'',
    agentpopup_status:'',
    agentpopup_input:'',
    callrouting_status:'',
    callrouting_input:'',
    singlpopup_status:'',
    singlpopup_input:'',
    calldisposition_status:'',
    calldisposition_input:'',
   },
    finance_remarks:data.data[0].remarks,
    remarks:'',
     finance_code:data.data[0].finance_code,
     caf_id:data.data[0].caf_no,
     service_id:data.data[0].service_id,
     service_type:'Click2Call',
     sub_service_type:'vco',

  })

  })

  }
  getallcommon(coins){
if(coins=='Common'){
return 'allcommon'
}
else if(coins=='Individual'){
return 'allself'
}
}
getOwner(owner){
    if(owner == 'Minavo Individual'){
return 'Minavo';
    }
    else{
return 'Reseller';
    }
}

  releasedid(){
this.didlist = false;
//this.didnumberbox= false;
  }
  freezedid(){

  }
  releasebkpdid(){
this.bckdidlist = true;
//this.bkpdidnumberbox= false;
  }
  freezebkpdid(){
  this.didlist = true;
//this.didnumberbox= true;
  }
  releasevnumber(){
//this.virtualnumberbox = false;
this.virtualnumberradioBtn = true
  }
  freezevnumber(){

  }

  sameasdid(){
    this.virtualnumbersec=false;

  }
  choosevirnum(){
    this.virtualnumbersec=true;
  }
  changeCRM(crmvalue:any){
    if(crmvalue=='minavo'){
      this.crmsettsec=false;
    }
  else{
    this.crmsettsec=true;
  }

  }
  editform(){
 this.vagentForm.enable();
this.buttunhide = true;
  }

  calculatecoin(ibd_call_rate:number,obd_call_rate:number,allocationtype){
    let ibdobd:number;
    let totalcoin:number;
    if(ibd_call_rate>0){
      ibdobd=ibd_call_rate;
    }
    else if(obd_call_rate>0){
      ibdobd=obd_call_rate;
    }
    else if(ibd_call_rate == 0 && obd_call_rate==0){
      ibdobd=0;
    }

     totalcoin =((this.records.data[0].service_amount)/(ibdobd/100))/this.records.data[0].service_validity;
     setTimeout(() => {
      if(ibdobd==0){
        totalcoin =0;
        this.vagentForm.get('plandetail').get('total_coins').setValue(totalcoin);
        this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
      }
      else{
        if(allocationtype=='monthly' || allocationtype=='Monthly' ){
          totalcoin =((this.records.data[0].service_amount)/(ibdobd/100))/this.records.data[0].service_validity;
           this.vagentForm.get('plandetail').get('total_coins').setValue(Math.round(totalcoin));
          this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
        }
        else{
          totalcoin =((this.records.data[0].service_amount)/(ibdobd/100))
          this.vagentForm.get('plandetail').get('total_coins').setValue(Math.round(totalcoin));
          this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
        }
      }
    }, 1000);
    }

    getc2cclic(network,ip,i){
      var that = this;
      $(".fullpageloader").fadeIn('fast');
      this.http.post(this.baseurl+'get_c2c_cli',{ip:ip,network:network,session_id:this.session_id}).subscribe((data:any)=>{
         this.outcliList[i]= data.cli_list;
         this.nubmerfreezed[i]='';
        this.vagentForm.get('networksdata')['controls'][i]['controls']['outcli'].setValue("");
        this.vagentForm.get('networksdata')['controls'][i]['controls']['outcli'].updateValueAndValidity();

        var $outcli =$("#outcli_select"+i).select2();
        $outcli.on("change", function(){
        that.checkdid($(this).val(),$(this).data('id'));
        });
        $(".fullpageloader").fadeOut('fast');
    })
    }
    getc2cnetwork(ip,scnt){
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+'get_c2c_network',{ip:ip,session_id:this.session_id}).subscribe((data:any)=>{
    //alert(this.vagentForm.get('networksdata')['controls'][scnt]['controls']['network'].value+"----"+scnt);
    console.log('');
    this.vagentForm.get('networksdata')['controls'][scnt]['controls']['network'].setValue("");
    this.vagentForm.get('networksdata')['controls'][scnt]['controls']['network'].updateValueAndValidity();
    this.vagentForm.get('networksdata')['controls'][scnt]['controls']['pool_network'].setValue("");
    this.vagentForm.get('networksdata')['controls'][scnt]['controls']['pool_network'].updateValueAndValidity();
    this.vagentForm.get('networksdata')['controls'][scnt]['controls']['outcli'].setValue("");
    this.vagentForm.get('networksdata')['controls'][scnt]['controls']['outcli'].updateValueAndValidity();
    $("#outcli_select"+scnt).val(null).trigger('change');
    $("#pool_network"+scnt).val(null).trigger('change');

    this.nubmerfreezed[scnt]='';
    this.networkList[scnt]= data.network_list;
    this.outcliList[scnt]=[];
    $(".fullpageloader").fadeOut('fast');
    })
    }
    changeserver(i,serrverval){
     let scnt=i;
     this.getc2cnetwork(serrverval,scnt);
    }
      changenetwork(netwrkval,ips,i){

      this.getc2cclic(netwrkval,ips,i);
    }

     checkdid(clicval,i) {

     var cliitemarra=[];
      const cliitemarralength = (<FormArray>this.vagentForm.get('networksdata')).length;
    for(var kt= 0;cliitemarralength>kt;kt++){
      var outcliarray = $("#outcli_select"+kt).val();
      cliitemarra.push(outcliarray);
     }
     //console.log("toatal array"+JSON.stringify(cliitemarra)+"type of"+typeof cliitemarra+"and current array"+outclicurarray+"and type of:"+typeof outclicurarray);
    var result = [];

    for (var idk=0, iLen=cliitemarra.length; idk<iLen; idk++) {
      result = result.concat(cliitemarra[idk]);
    }
    //console.log(result);


    //let checkcli =  outclicurarray.some(i => result.includes(i));
    var checkcli = result.some(function(item, idx){
      return result.indexOf(item) != idx
    });
    this.duplicateNum=checkcli;
    if(clicval!=''){
      if(clicval!=undefined || clicval!='' || clicval!=null){
       var clinum = clicval.map(function(item){
        return item
        }).join(",");

        }

      $(".fullpageloader").fadeIn('fast');
      this.nubmerfreezed[i]='';
     this.http.post(this.baseurl+'check_vnum_c2c',{vnum:clinum,user_id:this.user_id}).subscribe((data:any)=>{
          $(".fullpageloader").fadeOut('fast');
     this.nubmerfreezed[i]= data;

    if(this.nubmerfreezed[i].status == 'fail'){
      this.alreadyassign=true;
     //  this.nubmerfreezed[i]='';
      this.toastr.error("Out CLI already assigend. Please choose Another.", 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
    }
    this.alreadyassign=false;
      })
     }
     else{
      this.nubmerfreezed[i]='';
     }

    }
    getc2cserver(){
      $(".fullpageloader").fadeIn('fast');
      this.http.post(this.baseurl+'get_c2c_server',{session_id:this.session_id}).subscribe((data:any)=>{
       this.serverList= data.server_ip;
       //this.nubmerfreezed='';
     $(".fullpageloader").fadeOut('fast');
    })
    }
}
