import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editrequest',
  templateUrl: './editrequest.component.html',
  styleUrls: ['./editrequest.component.css']
})
export class EditrequestComponent implements OnInit {
  
  renewal:any;
  itemsPerPage:any=15;
  currentPage:number =1;
  currentUserloclstrage:any;
  baseurl:any;
 
  constructor(private http:HttpClient, private route:Router) { }

  ngOnInit() {
   var self =this;
  this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
  this.baseurl = localStorage.getItem('baseurl');
  this.getdata(this.currentPage,$(".serachpanel input[type='text']").val());
  $(".serachpanel input[type='text']").on('keyup',function(){
    self.getdata(self.currentPage,$(this).val());
  });
  // setInterval(() => {
  //   this.getdata(); 
  //   }, 10000);
  }

  getdata(crpage:number,stext)
  {
   
 // $('.fullpageloader').show();
     this.http.get(this.baseurl+'edit_request?page='+crpage+'&search='+stext).subscribe(
     data=>{
     this.renewal=data;
      $('.fullpageloader').hide();

     });
  }

navigateRoute(id:number){
 this.route.navigate(['/crequest/editdetail',id]);
}
pageChange(newpage:number){
  this.getdata(newpage,$(".serachpanel input[type='text']").val());
  this.currentPage = newpage;
    }


}
