import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-newticket',
  templateUrl: './newticket.component.html',
  styleUrls: ['./newticket.component.css']
})
export class NewticketComponent implements OnInit {
ticketForm:FormGroup;
submitted:boolean = false;
records:any;
comapanydata:any;
compdetail:any;
currentUserloclstrage:any;
  constructor(private http:HttpClient,private toastr:ToastrService, private fb:FormBuilder, private router:Router) { }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    var self = this; 
    $('#compList').on("select2:select", function(e) { 
  self.changeservice($(this).val());
  });
 
    this.createform();
    this.getrecords();
  }

  getrecords(){
    $(".fullpageloader").fadeIn('fast');
   this.http.get(" https://api.cerebra.quadrafort.in/get_company").subscribe((data:any)=>{
this.comapanydata = data;
$('#compList').select2();
$(".fullpageloader").fadeOut('fast');
   })
  }

  createform(){
this.ticketForm = this.fb.group({
  company:[''],
  email:['',[Validators.required,Validators.email]],
  mobile:['',[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
  contact_person:['',[Validators.required]],
  sales_exe:['',[Validators.required]],
  telesales_person:[''],
  tollfree:['',[Validators.required]],
  service_type:['',[Validators.required]],
  subject:['',[Validators.required]],
  issue_type:['',[Validators.required]],
  ticket_status:['',[Validators.required]],
  issue:['',[Validators.required]],
  session_id:this.currentUserloclstrage.user_id
  })
  }

  get f(){return this.ticketForm.controls;}
  submit(){
    let compndata= $("#compList").val();
  this.ticketForm.get('company').setValue(compndata);
let ticketdata = JSON.stringify(this.ticketForm.value);
console.log(ticketdata);
    if(this.ticketForm.invalid){
      this.submitted = true;
      return false;
    }
    else{
$(".fullpageloader").fadeIn('fast');
this.http.post(" https://api.cerebra.quadrafort.in/create_ticket",{data:ticketdata}).subscribe((data:any)=>{
if(data.status=='success'){
  this.records=data;
  $(".fullpageloader").fadeOut('fast');
  this.toastr.success(data.message,'Success!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
  this.router.navigate(['/support/supportlist'])
}
else if(data.status=='fail'){
  $(".fullpageloader").fadeOut('fast');
  this.toastr.error(data.message,'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
}

}, (err) => {
  $(".fullpageloader").fadeOut('fast');
  this.toastr.error(err,'Error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
})
    }
  }

  changeservice(event){
    //console.log(event);
    //alert(event);
  let cdata= event.split('-');
  let user_id= cdata[0];
  let service_type= cdata[1];
this.http.get(" https://api.cerebra.quadrafort.in/get_company_details?user_id="+user_id+"&service_type="+service_type).subscribe((data:any)=>{
  this.compdetail = data;
  
  this.ticketForm.patchValue({
   
    email:data.data[0].email,
    mobile:data.data[0].mobile,
    contact_person:data.data[0].name,
    sales_exe:data.data[0].sale_exc_name,
    telesales_person:function(tdata){
if(tdata!=null){
  return tdata;
}
else{
  return 'NA';
}
    }(data.data[0].telesale_exc_name),
    tollfree:data.data[0].virtual_number,
    service_type:data.data[0].service_type,
    
  })
})
  }

}
