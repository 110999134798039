import { Component, OnInit,ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-settleamtlist',
  templateUrl: './settleamtlist.component.html',
  styleUrls: ['./settleamtlist.component.css']
})
export class SettleamtlistComponent implements OnInit {
  itemsPerPage:any=15;
  currentPage:number =1;
  result:any;
  baseurl:any;
  table1:boolean = true;
  table2:boolean = false;
  searchForm:FormGroup;
  submitted1:boolean=false;
  constructor(private http:HttpClient, private fb:FormBuilder, private toastr:ToastrService, private route:Router,private _host: ElementRef) { 
    
  }

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
   this.coinadjust();
   this.createsearchform();

   $(".searchlist a").click(function(e) {
    $(".slistdrwn").slideToggle('fast');
   });

 
   $(".slistdrwn li").on('click',function(e) {
 
      var sval = "Enter" + " " + $(this).text();
      $(".searchinput").attr('placeholder', sval);
       $(".searchinput1").val($(this).text());
       $(".slistdrwn").slideUp('fast');
       
 });
  }

  createsearchform(){
    this.searchForm = this.fb.group({
        searchtext:['',[Validators.required]],
        searchinput:[''],
       
     })
    }
    get g(){return this.searchForm.controls;}
searchFilter(searchdata:any){
 this.submitted1= true;
 if(this.searchForm.invalid){
  $("#forminvalid").fadeIn(200).fadeOut(6000);
  $(".searchinput").css('border-color','#ff0000');
setTimeout(() => {
  $(".searchinput").css('border-color','#b8b8b8');
}, 6000);
   return false;
}
  else{
    var searchtext = searchdata.searchtext;
   var searchtype = $(".searchinput1").val();
   var stype = 'vco';
    $('.fullpageloader').show('fast');
   if($("#coinlink1.active").text()=='Coin Adjustment'){
     
   // this.http.post(this.baseurl+"coin_adjustment_report?type="+stype+"&searchtext="+searchtext+"&searchtype="+searchtype).subscribe(
    this.http.post(this.baseurl+"coin_adjustment_report",{type:stype,searchtext:searchtext,searchtype:searchtype}).subscribe(
      (result:any)=>{
      this.result=result;
      $('.fullpageloader').hide('fast');
     });
   }
   else{
   // this.http.get(this.baseurl+"other_adjustment_report?type="+stype+"&searchtext="+searchtext+"&searchtype="+searchtype).subscribe(
    this.http.post(this.baseurl+"other_adjustment_report",{type:stype,searchtext:searchtext,searchtype:searchtype}).subscribe(
      (result:any)=>{
      this.result=result;
      $('.fullpageloader').hide('fast');
     });
   }
  }
 
}

coinadjust(){
this.http.post(this.baseurl+"coin_adjustment_report",{type:'',searchtext:'',searchtype:''}).subscribe((data:any)=>{
this.result = data;
});
this.table1 = true;
this.table2 = false;
$("#coinlink1").addClass('active');
$("#coinlink2").removeClass('active');
  }
atheradjust(){
    this.http.post(this.baseurl+"other_adjustment_report",{type:'',searchtext:'',searchtype:''}).subscribe((data:any)=>{
      this.result = data;
      });
    this.table1 = false;
    this.table2 = true;
    $("#coinlink2").addClass('active');
$("#coinlink1").removeClass('active');
  }

}
