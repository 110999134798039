import { Component, OnInit,ElementRef } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-callviewreport',
  templateUrl: './callviewreport.component.html',
  styleUrls: ['./callviewreport.component.css']
})
export class CallviewreportComponent implements OnInit {
userid:any;
cdate:any;
id:any;
records:any;
    constructor(private elment: ElementRef, private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
        this.userid = this.route.snapshot.paramMap.get("uid");
        this.cdate = this.route.snapshot.paramMap.get("cdate");
        this.id = this.route.snapshot.paramMap.get("id");
      }

  ngOnInit() {
    this.getdata(this.userid,this.cdate,this.id)
  }
  getdata(userid,cdate,id){
    $(".fullpageloader").fadeIn('fast');
    this.http.get(" https://api.cerebra.quadrafort.in/get_client_call_detail_summary?uid="+userid+"&cdate="+cdate+"&id="+id).subscribe((data:any)=>{
    this.records= data;

    $(".fullpageloader").fadeOut('fast');
  })
  }

  onchange(evt){
     
    this.getdata(this.userid,this.cdate,evt.target.value);
    this.router.navigate(['/reports/callview',this.userid,this.cdate,evt.target.value]);
   // this.router.navigate(['orders'], { queryParams: { id: '1234' } });
  }
}
