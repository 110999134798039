import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-editdetail',
  templateUrl: './editdetail.component.html',
  styleUrls: ['./editdetail.component.css']
})
export class EditdetailComponent implements OnInit {
  self=this;
  editrecords:any;
  itemsPerPage:any=15;
  currentPage:number =1;
  currentUserloclstrage:any;
  edit_id:any;
  baseurl:any;
  session_id:any;
  constructor(private http:HttpClient,private toaster:ToastrService, private route:ActivatedRoute,private _router:Router) {
    this.edit_id = this.route.snapshot.paramMap.get("edit_id");
   }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.session_id = this.currentUserloclstrage.user_id;
    console.log(this.session_id);
    this.baseurl = localStorage.getItem('baseurl');
    this.getdata(this.edit_id);
}

  getdata(edit_id:any)
  {
   $('.fullpageloader').show();
     this.http.get(this.baseurl+'get_edit_detail?edit_id='+edit_id).subscribe(
     (data:any)=>{
        this.editrecords=data;
      $('.fullpageloader').hide();

     });
  }
postData(){
if($("#cremarks").val()!=''){
this.self.http.post(this.baseurl+'update_edit_service',{virtual_number:$("#virtualnum").val(),service_id:this.editrecords.data[0].service_id,session_id:this.session_id,edit_id:this.edit_id,remarks:$("#cremarks").val()}).subscribe((data:any)=>{
  if(data.status=='success'){
this.toaster.success(data.message,'Success',{
  positionClass:'toast-top-center',
  timeOut: 3000
});
this._router.navigate(['/crequest/editrequest']);
  }
  else{
  
    this.toaster.error(data.message,'Error',{
      positionClass:'toast-top-center',
      timeOut: 3000
    })

  }
})
    }else{
$("#cremarks").css('border-color','#ff0000');
setTimeout(() => {
  $("#cremarks").css('border-color','#b8b8b8');
}, 3000);
    }
  }





}
