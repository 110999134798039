import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-addsettamount',
  templateUrl: './addsettamount.component.html',
  styleUrls: ['./addsettamount.component.css']
})
export class AddsettamountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
