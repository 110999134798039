import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addagent',
  templateUrl: './addagent.component.html',
  styleUrls: ['./addagent.component.css']
})
export class AddagentComponent {
  error = '';
  agentform:FormGroup;
  agentsettings:FormArray; 
  submitted = false;
  user_id:any;
  allsettings=[    
    {    
    'settname':'Dashboard' 
    }, 
   {    
    'settname':'Content Requests'    
   },    
   {    
    'settname':'Service Requests'    
    },    
   {    
    'settname':'TopUp Requests'    
   },
   {    
    'settname':'Renewal Requests'    
   }, 
   {    
    'settname':'Upgrade Requests'    
   }, 
   {    
    'settname':'Number Inventory'    
   }, 
   {    
    'settname':'Manage Services'    
   }, 
   {    
    'settname':'Settle Amount'    
   }, 
   {    
    'settname':'Campaigns'    
   }, 
   {    
    'settname':'Settings'    
   },     
] ;

  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
   
 this.user_id = this.route.snapshot.paramMap.get("id");
 //console.log(this.user_id);
 if (this.user_id !== null){
  this.editAgent( this.user_id)
 }

 }

  ngOnInit(){
this.createform();
  }
 
createform()    
{    
  let arr=[];    
  for(let i=0;i< this.allsettings.length;i++)    
  {       
    
   // console.log(this.allsettings[i]);
    arr.push(this.BuildFormDynamic(this.allsettings[i]))    
       
  } 
   this.agentform =  this.fb.group({    
      agentdetails: this.fb.group({
        agentusername: ['', [Validators.required, Validators.pattern(/^\S*$/),Validators.minLength(4)]],
        mobile: ['',[Validators.required,Validators.maxLength(10),Validators.minLength(10)]],
        password: ['',[Validators.required, Validators.pattern(/^\S*$/),Validators.minLength(6),]],
        userid:[''],
         }),  
      agentsettings:this.fb.array(arr)
    })    
}  
    
BuildFormDynamic(sett:any):FormGroup{    
 return this.fb.group({    
  settname:[sett.settname],    
  visible:false,    
  editable:false,    
  
  })    
}  
editAgent(agentid){
 // alert(agentid);
this.http.get(" https://api.cerebra.quadrafort.in/edit_agent?user_id="+agentid).subscribe((data:any)=>{
 $(".fullpageloader").show('fast');
 // console.log(data);
this.agentform.setValue({
agentdetails:{
agentusername: data.agentdetails.username,
mobile:data.agentdetails.mobile,
password: data.agentdetails.password,
userid: data.agentdetails.user_id,
},
agentsettings:[
 {settname: data.agentsettings[0].settname, visible: data.agentsettings[0].visible, editable: data.agentsettings[0].editable},
 {settname: data.agentsettings[1].settname, visible: data.agentsettings[1].visible, editable: data.agentsettings[1].editable},
 {settname: data.agentsettings[2].settname, visible: data.agentsettings[2].visible, editable: data.agentsettings[2].editable},
 {settname: data.agentsettings[3].settname, visible: data.agentsettings[3].visible, editable: data.agentsettings[3].editable},
 {settname: data.agentsettings[4].settname, visible: data.agentsettings[4].visible, editable: data.agentsettings[4].editable},
 {settname: data.agentsettings[5].settname, visible: data.agentsettings[5].visible, editable: data.agentsettings[5].editable},
 {settname: data.agentsettings[6].settname, visible: data.agentsettings[6].visible, editable: data.agentsettings[6].editable},
 {settname: data.agentsettings[7].settname, visible: data.agentsettings[7].visible, editable: data.agentsettings[7].editable},
 {settname: data.agentsettings[8].settname, visible: data.agentsettings[8].visible, editable: data.agentsettings[8].editable},
 {settname: data.agentsettings[9].settname, visible: data.agentsettings[9].visible, editable: data.agentsettings[9].editable},
 {settname: data.agentsettings[10].settname, visible: data.agentsettings[10].visible, editable: data.agentsettings[10].editable}
]
});
$(".fullpageloader").hide('fast');
}
 )

}
  get f() { return this.agentform.controls }
 
  onSubmit() {
   // console.log(this.f);
    this.submitted = true;
   // alert(this.submitted);
     let  agentdata =JSON.stringify(this.agentform.value);
      if (this.agentform.invalid) {
      //  alert("form invalid");
          return false;
      }
      else{
        $(".fullpageloader").show('fast');
        this.http.post(' https://api.cerebra.quadrafort.in/add_agent',{data:agentdata}).subscribe((data:any)=>{
          
        $(".fullpageloader").hide('fast');
          if(data.status=="success"){
            
            this.toastr.success('Data Submitted  succesfully!', 'Success!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
            this.router.navigateByUrl('/agent');
            
          }
          else if(data.status=="fail"){
           console.log("fail");
           this.toastr.error(data.message, 'Error!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
           
          }
          
        },
        error => {
          this.toastr.error(error, 'error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
      }
        )
    
      }
    
  }
 
}
