import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-topup',
  templateUrl: './upgradeplan.component.html',
  styleUrls: ['./upgradeplan.component.css']
})
export class UpgradeplanComponent implements OnInit {
  records:any;
  submitted:boolean = false;
  upgradeForm:FormGroup;
  user_id:any;
  service_type:any;
  ren_upgrade_id:any;
baseurl:any;
  topup_remarks:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute){
    this.ren_upgrade_id = this.route.snapshot.paramMap.get("id");
    this.user_id = this.route.snapshot.paramMap.get("user_id");
    this.service_type = this.route.snapshot.paramMap.get("service_type");

  } 

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
this.createForm();
this.getdata(this.ren_upgrade_id,this.user_id,this.service_type);
  }
createForm(){
this.upgradeForm = this.fb.group({
  servicetype:['',[Validators.required]],
  plan_name:['',[Validators.required]],
  plan_price:['',[Validators.required]],
  plan_term:['',[Validators.required]],
  owner_number:['',[Validators.required]],
  channel:['',[Validators.required]],
  type_vnum:[''],
  contract_period:['',[Validators.required]],
  total_coins:['',[Validators.required]],
  billing_type:['',[Validators.required]],
  service_billing:[''],
  postpaid_limit:[''],
  ibd_coins:['',[Validators.required]],
  obd_coins:['',[Validators.required]],
  sms_coins:['',[Validators.required]],
  email_coins:['',[Validators.required]],
  fac_coins:['',[Validators.required]],
  tf_coins:['',[Validators.required]],
  ibd_ded_sec:['',[Validators.required]],
  ibd_ded_rate:['',[Validators.required]],
  obd_ded_sec:['',[Validators.required]],
  obd_ded_rate:['',[Validators.required]],
  sms_ded__cnt:['',[Validators.required]],
  smsded_rate:['',[Validators.required]],
  tf_ded_cnt:['',[Validators.required]],
  tf_ded_rate:['',[Validators.required]],
  email_ded_cnt:['',[Validators.required]],
  email_ded_rate:['',[Validators.required]],
  fax_ded_cnt:['',[Validators.required]],
  fax_ded_rate:['',[Validators.required]],
  c2c_ded_sec:['',[Validators.required]],
  c2c_rate:['',[Validators.required]],
  call_rate:['',[Validators.required]],
  operator:[''],
  virtual_number:[''],
  allocation_type:['',[Validators.required]],
  remark:['',[Validators.required]],
  ren_upgrade_id:'',
  user_id:'',
  service_type:'',
  session_id:''
})
  }

get f(){return this.upgradeForm.controls}
onSubmit(){
this.submitted= true;
let upgradeFormdata = JSON.stringify(this.upgradeForm.value);
if(this.upgradeForm.invalid){
return false;
}
else{
  //alert(upgradeFormdata);
  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+"upgrade_request",{data:upgradeFormdata}).subscribe((data:any)=>{
   
    if(data.status=='success'){
      this.toastr.success('Data Submitted  succesfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      $(".fullpageloader").fadeOut('fast');
      this.router.navigateByUrl('crequest/upgraderequest');
    }
    else if(data.status=="fail"){
      console.log("fail");
      this.toastr.error(data.message, 'Error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
       $(".fullpageloader").fadeOut('fast');
     }
  }, error => {
    this.toastr.error(error, 'error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
    $(".fullpageloader").fadeOut('fast');
  }
  )
  
  }
}

getdata(ren_upgrade_id,user_id,service_type)
{
 $('.fullpageloader').show('fast');
  this.http.get(this.baseurl+"getupgrade_details?id="+ren_upgrade_id+"&user_id="+user_id+"&service_type="+service_type).subscribe(
  (data:any)=>{
   this.records=data;
   console.log(data.existing[0].billing);
 // console.log(JSON.stringify(this.records));
  if(data.new[0].amount_type=='Rental'){
    setTimeout(() => {
      this.upgradeForm.get('total_coins').setValue('0');
      this.upgradeForm.get('total_coins').updateValueAndValidity();
    }, 1200);
    }
    else{
      this.calculatecoin(this.records.new[0].ibd_call_rate,this.records.new[0].obd_call_rate,this.records.new[0].allocation_type);
    }
  this.upgradeForm.setValue({
    servicetype:data.new[0].service_type,
    plan_name:data.new[0].plan_name,
    plan_price:data.new[0].service_amount,
    plan_term:data.new[0].service_validity,
    owner_number:data.new[0].owner_of_number,
    channel:data.new[0].channel,
    type_vnum:data.new[0].typeofnumber,
    contract_period:data.new[0].contract,
     total_coins:'',
    billing_type:data.new[0].billing,
    service_billing:data.new[0].service_subtype,
    postpaid_limit:data.new[0].postpaid_limit,
    ibd_coins:'0',
    obd_coins:'0',
    sms_coins:'0',
    email_coins:'0',
    fac_coins:'0',
    tf_coins:'0',
    ibd_ded_sec:data.new[0].ibd_call_pulse,
    ibd_ded_rate:function(ibdpluse){
      if(ibdpluse==0){
        return "0";
      }
     else{
        return "1";
      }
    }(data.new[0].ibd_call_pulse),
    obd_ded_sec:data.new[0].obd_call_pulse,
    obd_ded_rate:function(obdpluse){
      if(obdpluse==0){
        return "0";
                  }
    else{
        return "1";
      }
    }(data.new[0].obd_call_pulse),
    sms_ded__cnt:'1',
    smsded_rate:'1',
    tf_ded_cnt:'0',
    tf_ded_rate:'0',
    email_ded_cnt:'0',
    email_ded_rate:'0',
    fax_ded_cnt:'0',
    fax_ded_rate:'0',
    c2c_ded_sec:'60',
    c2c_rate:'1',
    call_rate:function(ibd,obd){
       if(!ibd){
      return ibd;
      }
     else{
     return obd;
      }
      
    }(data.new[0].topup_ibd_call_rate,data.new[0].topup_obd_call_rate),
    operator:data.new[0].operator_vir_no,
    virtual_number:data.new[0].virtual_number,
    allocation_type:data.new[0].allocation_type,
    remark:'',
    ren_upgrade_id:this.ren_upgrade_id,
    user_id:this.user_id,
    service_type:this.service_type,
    session_id:JSON.parse(localStorage.getItem('currentUser')).user_id
  })
 
  $('.fullpageloader').hide('fast');

  });
}

calculatecoin(ibd_call_rate:number,obd_call_rate:number,allocationtype){
  let ibdobd:number;
  let totalcoin:number;
  if(ibd_call_rate>0){
    ibdobd=ibd_call_rate;
  }
  else if(obd_call_rate>0){
    ibdobd=obd_call_rate;
  }
  else if(ibd_call_rate == 0 && obd_call_rate==0){
    ibdobd=0;
  }
  
   totalcoin =((this.records.new[0].service_amount)/(ibdobd/100))/this.records.new[0].service_validity;
    setTimeout(() => {
    if(ibdobd==0){
      totalcoin =0;
      this.upgradeForm.get('total_coins').setValue(totalcoin);
      this.upgradeForm.get('total_coins').updateValueAndValidity();
    }
    else{
    if(allocationtype=='monthly' || allocationtype=='Monthly' ){
        totalcoin =((this.records.new[0].service_amount)/(ibdobd/100))/this.records.new[0].service_validity;
        this.upgradeForm.get('total_coins').setValue(Math.round(totalcoin));
        this.upgradeForm.get('total_coins').updateValueAndValidity();
      }
      else{
        totalcoin =((this.records.new[0].service_amount)/(ibdobd/100))
        this.upgradeForm.get('total_coins').setValue(Math.round(totalcoin));
        this.upgradeForm.get('total_coins').updateValueAndValidity();
      }
    }
  }, 1000);
  }
}
