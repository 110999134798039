﻿import { Component, OnInit,ElementRef ,OnDestroy} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';

import { User } from '../_models';
import { UserService, AuthenticationService } from '../_services';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    currentUser: User;
    userFromApi: User;
    totalcalls:any;
   // io:any;
    am4core:any;
    graphdata:any;
    mnth:any;
    year:any;
    date:any;
    finaldate:any;
    todaydate:any;
    totalchannel:any;
    prodwsum:any;
    prodwsum1:any;
    channeltimer:any;
  //  AmCharts:any;
   currentdate = new Date();
  // console.log(currentdate);
   temdate = this.currentdate.toLocaleDateString().split("/");


    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private http:HttpClient,
    ) {
        this.currentUser = this.authenticationService.currentUserValue;
    }

    ngOnInit() {
       let that = this;
        this.mnth=this.currentdate.getMonth();
        if(this.mnth=='0'|| this.mnth=='1' || this.mnth=='2' || this.mnth=='3' || this.mnth=='4' || this.mnth=='5' || this.mnth=='6' || this.mnth=='7' || this.mnth=='8' || this.mnth=='9')
        {

         if(this.mnth=='0'){
            this.mnth = "0".concat(this.mnth+1);
         }
         else{
            this.mnth = "0".concat(this.mnth);
         }
        }
        this.date=this.currentdate.getDate();
         if(this.date=='0'|| this.date=='1' || this.date=='2' || this.date=='3' || this.date=='4' || this.date=='5' || this.date=='6' || this.date=='7' || this.date=='8' || this.date=='9')
        {
           this.date = "0".concat(this.date);
        }
        this.year=this.currentdate.getFullYear();
        this.year=this.year.toString().substr(-2);



          this.finaldate = this.mnth+"-"+this.date+"-"+this.year;
          this.todaydate = this.temdate[2]+"-"+this.temdate[1]+"-"+this.temdate[0];
    //     $("#fromdate").datepicker({
    //          dateFormat: "yy-mm-dd",
    //          buttonImage: "assets/img/cal_icon.png",
    //          buttonImageOnly: true

    // }).datepicker("setDate", new Date())
    $("#fromdate").val(this.todaydate);
        this.http.get(" https://api.cerebra.quadrafort.in/hours_summary").subscribe((data)=>{
            this.graphdata= JSON.stringify(data);
            console.log(this.graphdata);
            this.drawgraph(this.graphdata)
            //this.totalgraph(this.graphdata);

        })

        setInterval(() => {
    this.http.get(" https://api.cerebra.quadrafort.in/hours_summary").subscribe((data)=>{
        this.graphdata= JSON.stringify(data);
        console.log(this.graphdata);
        this.drawgraph(this.graphdata)
        //this.totalgraph(this.graphdata);

    })
}, 600000);
$("#frame2div,#frame1div,#frame4div").hide();
$("#fram1").on('click',function(){
$(".mreqnav ul li a").removeClass('active');
$("#frame2div,#frame3div,#frame4div").hide('fast');
$("#frame3div").fadeIn('fast');
$(this).addClass('active');
 })
 $("#fram2").on('click',function(){
    $(".mreqnav ul li a").removeClass('active');
    $("#frame1div,#frame3div,#frame4div").hide('fast');
    $("#frame2div").show('fast');
    $(this).addClass('active');
})
$("#fram3").on('click',function(){
        $(".mreqnav ul li a").removeClass('active');
        $("#frame1div,#frame2div,#frame4div").hide('fast');
        $("#frame3div").show('fast');
        $(this).addClass('active');
})
$("#fram4").on('click',function(){
        $(".mreqnav ul li a").removeClass('active');
        $("#frame1div,#frame2div,#frame3div").hide('fast');
        $("#frame4div").show('fast');
        $(this).addClass('active');
})

$(document).on('click',"#chatlog a,#chatlog1 a", function() {
  that.removlivecall($(this).data('ruserid'),$(this).data('ruleg'),$(this).data('rtype'));
});

console.log(this.finaldate);
//document.getElementsByTagName('iframe')[0].setAttribute( 'src', 'http://43.231.116.111:85/'+this.finaldate+'/pristat.txt');
// document.getElementsByTagName('iframe')[1].setAttribute( 'src', 'http://43.231.116.112:85/'+this.finaldate+'/pristat.txt');
// document.getElementsByTagName('iframe')[2].setAttribute( 'src', 'http://103.241.147.148:85/'+this.finaldate+'/pristat.txt');
// document.getElementsByTagName('iframe')[3].setAttribute( 'src', 'http://182.18.144.97:85/'+this.finaldate+'/pristat.txt');

//    setInterval(() => {
//       this.reloadIFrame(this.finaldate);
//    }, 600000);
    //this.loadTextdata();
        this.userService.getById(this.currentUser.id).pipe(first()).subscribe(user => {
            this.userFromApi = user;
        });







let socketio = io.connect("https://qkonnect.io:9999");

socketio.on('myevent', function (data){
    console.log(data);

   let j=0;
   $('#chatlog').empty();
   var totalcalls = 0;
   var ctcalls = 0;
   var rtcalls = 0;
   var itcalls = 0;
   var schcall = 0;

  let u_id=  $("#u_id").val();

   for(let i =0; i<data['Uid'].length;i++ )
	{
      if(u_id)
      {
          if(u_id==data['Uid'][i])
          {



            $('#chatlog').append('<tr class="'+data['Status'][i]+'"><td>'+data['Uid'][i]+'</td><td>'+data['Caller'][i]+'</td><td>'+data['department'][i]+'</td><td>'+data['Status'][i]+'</td><td>'+data['Agent'][i]+'</td><td>'+data['Uleg'][i]+'</td><td><a href="javascript:void(0)" data-ruserid="'+data['Uid'][i]+'" data-ruleg="'+data['Uleg'][i]+'" data-rtype="vagent">remove</a></td></tr>');
            j++;
          }
      }
      else
      {


        $('#chatlog').append('<tr class="'+data['Status'][i]+'"><td>'+data['Uid'][i]+'</td><td>'+data['Caller'][i]+'</td><td>'+data['department'][i]+'</td><td>'+data['Status'][i]+'</td><td>'+data['Agent'][i]+'</td><td>'+data['Uleg'][i]+'</td><td><a href="javascript:void(0)" data-ruserid="'+data['Uid'][i]+'" data-ruleg="'+data['Uleg'][i]+'" data-rtype="vagent">remove</a></td></tr>');
        j++;
      }


    }



	if(j==0)
	{
       $('#chatlog').empty();
	}


   ctcalls= $("table tr.Connected").length;
   rtcalls= $("table tr.Ringing").length;
   itcalls= $("table tr.Initiating").length;
   schcall=$("table tr.Searching").length;

   totalcalls=ctcalls+rtcalls+itcalls+schcall;

  $("#ctcalls").text(ctcalls);
  $("#rtcalls").text(rtcalls);
  $("#itcalls").text(itcalls);
  $('#schcalls').text(schcall);
  $("#tcalls").text(totalcalls);
});




socketio.on('c2cevent', function (data){

      var j=0;
      $('#chatlog1').empty();
var totalcalls = 0;
   var ctcalls = 0;
   var rtcalls = 0;
   var itcalls = 0;
   var schcall = 0;

  let u_id=  $("#u_id").val();

    for(var i =0; i<data['Uid'].length;i++ )
    {


        if(u_id)
        {
            if(u_id==data['Uid'][i])
            {



              $('#chatlog1').append('<tr class="'+data['Status'][i]+'"><td>'+data['Uid'][i]+'</td><td>'+data['Caller'][i]+'</td><td>NA</td><td>'+data['Status'][i]+'</td><td>'+data['Agent'][i]+'</td><td>'+data['Uleg'][i]+'</td><td><a href="javascript:void(0)" data-ruserid="'+data['Uid'][i]+'" data-ruleg="'+data['Uleg'][i]+'" data-rtype="c2c">remove</a></td></tr>');
              j++;
            }
        }
        else
        {


          $('#chatlog1').append('<tr class="'+data['Status'][i]+'"><td>'+data['Uid'][i]+'</td><td>'+data['Caller'][i]+'</td><td>NA</td><td>'+data['Status'][i]+'</td><td>'+data['Agent'][i]+'</td><td>'+data['Uleg'][i]+'</td><td><a href="javascript:void(0)" data-ruserid="'+data['Uid'][i]+'" data-ruleg="'+data['Uleg'][i]+'" data-rtype="c2c">remove</a></td></tr>');
          j++;
        }



    }

    if(j==0)
    {
         $('#chatlog1').empty();
    }


    ctcalls= $("table tr.Connected").length;
    rtcalls= $("table tr.Ringing").length;
    itcalls= $("table tr.Initiating").length;
    schcall=$("table tr.Searching").length;

    totalcalls=ctcalls+rtcalls+itcalls+schcall;

   $("#ctcalls").text(ctcalls);
   $("#rtcalls").text(rtcalls);
   $("#itcalls").text(itcalls);
   $('#schcalls').text(schcall);
   $("#tcalls").text(totalcalls);

  })
  this.getchannelutilization();
  this.channeltimer = setInterval(function () {
    that.getchannelutilization()
   }, 6000);
}

reloadIFrame(finaldate) {
    console.log(finaldate);
    document.getElementsByTagName('iframe')[0].setAttribute( 'src', 'http://43.231.116.111:85/'+finaldate+'/pristat.txt');
    document.getElementsByTagName('iframe')[1].setAttribute( 'src', 'http://43.231.116.112:85/'+finaldate+'/pristat.txt');
    document.getElementsByTagName('iframe')[2].setAttribute( 'src', 'http://103.241.147.148:85/'+finaldate+'/pristat.txt');

}
graphserach(){
    $(".fullpageloader").fadeIn('fast');
   let userid =  $("#userID").val();
   let date =  $("#fromdate").val();

this.http.get(" https://api.cerebra.quadrafort.in/hours_summary?date="+date+"&user_id="+userid+"").subscribe((data:any)=>{
    this.graphdata= JSON.stringify(data);
    //console.log("my data"+this.graphdata);
    this.drawgraph(this.graphdata);
    $(".fullpageloader").fadeOut('fast');
})

}

drawgraph(graphdata){

    var arr = JSON.parse(graphdata);
    let total = 0;
for (var i=0; i<arr.length; i++) {
  //  alert(arr[i].calls);
    total += arr[i].calls;
}
$("#totalcall").text(total);

       // alert(this.graphdata);
        let chartData = graphdata;
        let  chart = AmCharts.makeChart("chartdiv", {
            "type": "serial",
            "categoryField": "hours",
            //"dataDateFormat": "YYYY-MM-DD",
            // "theme": "light",
            "processCount": 994,
            //"rotate": true,
            "startDuration": 1,
            "startOnAxis": 0,
            "categoryAxis": {
                "gridPosition": "start",
                "position": "left"

            },
            "chartCursor": {
                "enabled": true
            },
            "colors": ["#00c8b7"],
            "trendLines": [],
            "graphs": [{
                "balloonText": "Total Calls:[[calls]]",
                "fillAlphas": 0.8,
                "id": "AmGraph-1",
                "lineAlpha": 0.2,
                "title": "Calls",
                "type": "column",
                "valueField": "calls"
            }],
            "guides": [],
            "valueAxes": [{
                "id": "ValueAxis-1",
                "position": "left",
                "axisAlpha": 0
            }],
            "allLabels": [],
            "balloon": {},
            "titles": [],

            "export": {
                "enabled": true
            },
            "dataProvider":'',

    })

// generate some random data, quite different range
function generateChartData(gdata) {

var chartData = JSON.parse(gdata);

//END WORKAROUND

return chartData;
}

setTimeout(function () {
chart.dataProvider = generateChartData(chartData);
chart.validateData();
}, 200);
setInterval(function () {
chart.dataProvider = generateChartData(chartData);
chart.validateData();
}, 600000);

}
removlivecall(ruserid,ruleg,rtype){
this.http.post(" https://api.cerebra.quadrafort.in/remove_live_call",{user_id:ruserid,uuid:ruleg,type:rtype}).subscribe((data:any)=>{

})
}


getchannelutilization(){
   this.http.get(" https://api.cerebra.quadrafort.in/channel_utlilization").subscribe((data)=>{
      this.totalchannel = data
        const initialValue = 0;
        let tusedchannel = data['total'].map((item)=>item.used_channel);
        let tusedchannelprod = data['productwise'].map((item)=>item.used_channel);
        this.prodwsum = tusedchannel.reduce((a, b) => a + b, 0);
        this.prodwsum1 = tusedchannelprod.reduce((a, b) => a + b, 0);

    })
}

ngOnDestroy()
{
     clearInterval(this.channeltimer)
}
}