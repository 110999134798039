import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import {formatDate } from '@angular/common';
@Component({
  selector: 'app-manageresseler',
  templateUrl: './manageresseler.component.html',
  styleUrls: ['./manageresseler.component.css']
})
export class ManageresselerComponent implements OnInit {
  submitted1:boolean = false;
  submitted:boolean = false;
  itemsPerPage:any=15;
  currentPage:number =1;
  baseurl:any;
  searchForm:FormGroup;
  filterform:FormGroup;
 resellerrecords:any;
 today= new Date();
 jstoday = '';
  constructor(private fb:FormBuilder, private http:HttpClient, private toastr:ToastrService,private route:Router,) {

    this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    this.filterform = this.fb.group({
      user_id: [''],
      fromdate: [this.jstoday],
      todate: [this.jstoday],
      status:''
    })


   }

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
    this.createsearchform();
this.getrecords(this.currentPage);
$(".searchlist a").click(function(e) {
  $(".slistdrwn").slideToggle('fast');
 });


 $(".slistdrwn li").on('click',function(e) {

    var sval = "Enter" + " " + $(this).text();
     //alert(sval);
     $(".searchinput").attr('placeholder', sval);
     $(".searchinput1").val($(this).text());
     $(".slistdrwn").slideUp('fast');
     
});
  }

  getrecords(crpage:number){
$(".fullpageloader").fadeIn('fast');
this.http.get(this.baseurl+"reseller_list_view?page="+crpage).subscribe((data:any)=>{
  if(data.status=='success'){
    $(".fullpageloader").fadeOut('fast');
    this.resellerrecords = data;
  }

})
  }

  
  changestatus(ress_id:any,status:any,event:any){
console.log(`${ress_id} status ${status} event${event.target.checked}`);
    $(".fullpageloader").fadeIn('fast');
  this.http.get(this.baseurl+"enable_disable?resel_id="+ress_id+"&status="+event.target.checked).subscribe((data:any)=>{
if(data.status=='success'){
  this.toastr.success(data.message, 'Success!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
  $(".fullpageloader").fadeOut('fast');

}
else{
  $("#ress"+ress_id).prop('checked',false);
  this.toastr.error(data.message, 'Error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
 $(".fullpageloader").fadeOut('fast');
}
  })

  
 }

 createsearchform(){
  this.searchForm = this.fb.group({
      searchtext:['',[Validators.required]],
      searchinput:[''],
     
   })
  }
  get g(){return this.searchForm.controls;}
  searchFilter(searchdata:any){
   this.submitted1= true;
   if(this.searchForm.invalid){
    $("#forminvalid").fadeIn(200).fadeOut(6000);
    $(".searchinput").css('border-color','#ff0000');
  setTimeout(() => {
    $(".searchinput").css('border-color','#b8b8b8');
  }, 6000);
     return false;
  }
    else{
      var searchtext = searchdata.searchtext;
     var searchtype = $(".searchinput1").val();
      $('.fullpageloader').show('fast');
      this.http.get(this.baseurl+"reseller_list_view?searchtext="+searchtext+"&searchtype="+searchtype).subscribe(
      (result:any)=>{
      this.resellerrecords=result;
      $('.fullpageloader').hide('fast');
  
      });
    }
   
  }

  filtersubmit()
{
   this.submitted=true;
 
   let user_id=this.filterform.value.user_id;
  let  status=this.filterform.value.status;

    
   let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
  
   $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'reseller_list_view?status='+status+'&fromdate='+fromdate+'&todate='+todate).subscribe(
    (data)=>{
      this.resellerrecords=data;
        $('.cfillBox').slideUp('fast');
       $('.fullpageloader').hide('fast');

    })

}

  pageChange(newpage:number){
    this.getrecords(newpage);
    this.currentPage = newpage;
      }
      navigate(id:any){
        this.route.navigate(['/servicelist',id]);
      }
}
