
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';




@Component({
  selector: 'app-apilog',
  templateUrl: './apilog.component.html',
  styleUrls: ['./apilog.component.css']
})
export class ApilogComponent implements OnInit {


  apilog;
    itemsPerPage:any=15;
  currentPage:number =1;
  filterform:FormGroup;
  submitted=false;
  today= new Date();
  jstoday = '';
  baseurl:any;
  constructor(private http:HttpClient,private fb:FormBuilder) {
   this.baseurl = localStorage.getItem('baseurl');
    this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    this.filterform = this.fb.group({
      user_id: ['', ],
      fromdate: [this.jstoday],
      todate: [this.jstoday],
      Aleg_uuid:['']
    })


   }


  ngOnInit() {
    var self = this;
   this.getdata(this.currentPage);
    $("#logtype").on('change',function(){
      //alert($(this).val());
      
      switch($(this).val()){
      case '2':
        self.filterform.value.user_id='';
        self.filterform.value.Aleg_uuid='';
      $("#byuserid").show('fast');
      $("#user_id,#Aleg_uuid").val('');
      $("#legAid").hide('fast');
       break;
       case '3':
        self.filterform.value.user_id='';
        self.filterform.value.Aleg_uuid='';
      $("#user_id,#Aleg_uuid").val('');
      $("#legAid").show('fast');
      $("#byuserid").hide('fast');
           break;
       
      default:
        self.filterform.value.user_id='';
        self.filterform.value.Aleg_uuid='';
        $("#user_id,#Aleg_uuid").val('');
          $("#byuserid,#legAid").hide('fast');
    
      }
    })
  }



onSubmit()
{
   this.submitted=true;
  //console.log(this.filterform.value);
   let user_id=this.filterform.value.user_id;
   let  Aleg_uuid=this.filterform.value.Aleg_uuid;
   let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
  


   $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'api_logs?user_id='+user_id+'&fromdate='+fromdate+'&todate='+todate+'&aleg_uuid='+Aleg_uuid).subscribe(
    (data)=>{
      this.apilog=data;
        $('.cfillBox').slideUp('fast');
       $('.fullpageloader').hide('fast');

    })


 
}



  getdata(crpage:number)
  {
    $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'api_logs?page='+crpage).subscribe(
    (data)=>{
     this.apilog=data;
     //console.log(data);
      $('.fullpageloader').hide('fast');
    })
  }

   pageChange(newpage:number){
    this.getdata(newpage);
    this.currentPage = newpage;
      }

}
