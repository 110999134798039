import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';  


@Component({
  selector: 'app-obdc2ccli',
  templateUrl: './obdc2ccli.component.html',
  styleUrls: ['./obdc2ccli.component.css']
})
export class Obdc2ccliComponent implements OnInit {
  obdc2cclirecords:any;
   addobdc2cForm:FormGroup;
   submitted=false;
   today= new Date();
   jstoday = '';
   getserverntwk:any;
   outcallcli:any;
   baseurl:any;
   currentUserloclstrage:any;
   session_id:any;
   serverList:any;
   networkList:any;
   networksec:boolean=false;
   hidedeletebtn:boolean= true;
   constructor(private http:HttpClient,private fb:FormBuilder,private toastr:ToastrService, private route:Router,private confirmDialogService: ConfirmDialogService) {
   
    this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
   
    }
 
   ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.session_id =  this.currentUserloclstrage.user_id;
    this.baseurl = localStorage.getItem('baseurl');
    
   this.getdata('OBD');
   this.createform();
   this.getc2cserver();
   $(".pool_netwok").select2();
   
    }
 
    createform(){
   this.addobdc2cForm = this.fb.group({
     service_type:['',Validators.required],
     circle:['',Validators.required],
     ip:['',Validators.required],
     network:[''],
     pool_network:''
   })
 }
 
 get f(){return this.addobdc2cForm.controls;}
 onSubmit(){
  var pool_networksec =  $(".pool_netwok").val();
  if(pool_networksec!=undefined || pool_networksec!='' || pool_networksec!=null){
 
    var clisecfinal = pool_networksec.map(function(item){ 
    return item
    }).join(",");
    
    }
    else{
      clisecfinal='';
    }
    this.addobdc2cForm.get('pool_network').setValue(clisecfinal);
    this.addobdc2cForm.get('pool_network').updateValueAndValidity();
   this.submitted = true;
   let  addobdc2cFormdata = JSON.stringify(this.addobdc2cForm.value);
   if(this.addobdc2cForm.invalid){
    
    return false;
   }
   else{
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+"add_server",{data:addobdc2cFormdata}).subscribe((data:any)=>{
    
      if(data.status=='success'){
        this.getdata(this.addobdc2cForm.get('service_type').value);
        this.toastr.success(data.message, 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
      //  this.route.navigate('/servicelist');
        $("#addobdcli").modal('hide');
        $(".fullpageloader").fadeOut('fast');
       
      }
      else if(data.status=="fail"){
      
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         $(".fullpageloader").fadeOut('fast');
 
       }
    }, error => {
      this.toastr.error(error, 'error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      $(".fullpageloader").fadeOut('fast');
    }
    )
    
    }
 }

 
   getdata(service:any)
   {
    $('.fullpageloader').show();
      this.http.get(this.baseurl+'get_obd_details?service='+service).subscribe(
      data=>{
      
        if(service=='OBD'){
$("#getobd").addClass('active');
$("#getc2c").removeClass('active');

        }
   else{
  $("#getc2c").addClass('active');
  $("#getobd").removeClass('active');
  
        }
    
       this.obdc2cclirecords=data;
if(this.obdc2cclirecords.data.length==1){
  this.hidedeletebtn= false;
}
else{
  this.hidedeletebtn= true; 
}
        $('.fullpageloader').hide();
          });
   }


  getobd(service:any){
   this.getdata(service);
  }
  getc2c(service:any){
  this.getdata(service);
  }

  changestatus(ip_id:any,status:any,servicetype:any,event:any){
    $(".fullpageloader").fadeIn('fast');
  this.http.get(this.baseurl+"enble_disabled_server?ip_id="+ip_id+"&status="+event.target.checked+"&service="+servicetype).subscribe((data:any)=>{
if(data.status=='success'){
  this.toastr.success(data.message, 'Success!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
  $(".fullpageloader").fadeOut('fast');

}
else{
  $("#dis"+ip_id).prop('checked',false);
  this.toastr.error(data.message, 'Error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
 $(".fullpageloader").fadeOut('fast');
}
  })

  
 }
 alldisbleenable(event:any){
  $(".fullpageloader").fadeIn('fast');
  this.http.get(this.baseurl+"all_enable_disable?status="+event.target.checked).subscribe((data:any)=>{
    if(data.status=='success'){
    this.toastr.success(data.message, 'Success!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
    $(".fullpageloader").fadeOut('fast');
    }
    else{
    this.toastr.error(data.message, 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
    $(".fullpageloader").fadeOut('fast');
    }
    
    })
 
}
getc2cnetwork(ip){
  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+'get_c2c_network',{ip:ip,session_id:this.session_id}).subscribe((data:any)=>{
   this.networkList= data.network_list;
 $(".pool_netwok").val(null).trigger('change');
 this.addobdc2cForm.get('pool_network').setValue('');
 this.addobdc2cForm.get('pool_network').updateValueAndValidity();
 this.addobdc2cForm.get('network').setValue('');
 this.addobdc2cForm.get('network').updateValueAndValidity();
   $(".fullpageloader").fadeOut('fast');
})
}
getc2cserver(){
  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+'get_c2c_server',{session_id:this.session_id}).subscribe((data:any)=>{
   this.serverList= data.server_ip;
  
 
   $(".fullpageloader").fadeOut('fast');
})
}
changeserver(event){
 this.getc2cnetwork(event.target.value);
}
deleterecord(ip_id,service_type) {
  
  var that = this;
  var baseurl= this.baseurl;
  
  this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
    $(".fullpageloader").fadeIn('fast');  
    that.http.post(baseurl+"delete_server",{ip_id:ip_id,service_type:service_type}).subscribe((data:any)=>{
$(".fullpageloader").fadeOut('fast'); 

if(data.status=='success'){
that.getdata($(".mreqnav ul li>a.active").text());
that.toastr.success(data.message,'Success!',{
  positionClass:'toast-top-center',
  timeOut: 3000
});

}
else if(data.status=='fail'){
$(".fullpageloader").fadeIn('fast');  
that.toastr.error(data.message,'Error!',{
  positionClass:'toast-top-center',
  timeOut: 3000
});
}

})
  }, function () {
   
  })  
} 
sertype(event){
 
if(event.target.value=="Click2Call"){
this.addobdc2cForm.get('network').setValidators([Validators.required]);
this.addobdc2cForm.get('network').updateValueAndValidity();
}
else{
  this.addobdc2cForm.get('network').setValidators(null);
  this.addobdc2cForm.get('network').updateValueAndValidity();
}
}
}
