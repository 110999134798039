import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-msvleadservice',
  templateUrl: './msvleadservice.component.html',
  styleUrls: ['./msvleadservice.component.css']
})
export class MsvleadserviceComponent implements OnInit {
  vleadForm:FormGroup;
  demorenewform:FormGroup;
  submitted:boolean = false;
  submitted2:boolean = false;
  user_id:any;
  stype:any;
  records:any;
  buttunhide:boolean=false;
  serviceList:any;
  currentUserloclstrage:any;
  baseurl:any;
  remarksrecords:any;
  subtype:any;
  vagentcurdata=[];
  c2ccurdata=[];
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
  this.user_id = this.route.snapshot.paramMap.get("userid");
  this.stype = this.route.snapshot.paramMap.get("stype");
  this.subtype = this.route.snapshot.paramMap.get("subtype");
  //console.log(`${this.user_id} ${this.stype}`)
}

  ngOnInit() {
  // $(".select2").select2({
  //  minimumResultsForSearch: Infinity,  
  // });
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.baseurl = localStorage.getItem('baseurl');
    this.getserviclist();
    this.creatForm();
    this.creatrenwaldemoform();
    this.getdata();
   
  // $(".select2").select2({
  //   minimumResultsForSearch: Infinity,  
  // });
   

  }

  creatForm(){
    this.vleadForm= this.fb.group({
      companyname:[{ value: ''},Validators.required],
      mobile:[{ value: ''},[Validators.required,Validators.maxLength(10),Validators.minLength(10)]],
      email:[{ value: ''},[Validators.required,Validators.email]],
      password:[{ value: ''},[Validators.required]],
      cperson:[{ value: ''},[Validators.required]],
      salesexec:[{ value: ''}],
      tsaleexec:[{ value: ''}],
      vagentlist:[{ value: ''}],
      c2clist:[{ value: ''}],
      agentallowed:[{ value: ''},[Validators.required]],
      validity:[{ value: ''},[Validators.required]],
      account_type:[{ value: ''},[Validators.required]],
      auto_followup:[{ value: '1'}],
      auto_assigned:[{ value: '1'}],
      autodialer:[{ value: '1', }],
      custom_field:[{ value: ''},[Validators.required]],
      remarks:[{ value: ''}],
      userid:[],
      stype:[],
    })

  }
getdata(){
  $(".fullpageloader").fadeIn('fast');
  this.http.get(this.baseurl+"get_service_details?user_id="+this.user_id+"&type="+this.stype).subscribe((data:any)=>{
  this.records=data;
this.vagentlistdata(data.profile[0].vagent_user_id);
this.c2clistdata(data.profile[0].c2c_user_id);
  console.log(this.records);
  $(".fullpageloader").fadeOut('fast');
this.vleadForm.setValue({
  companyname:data.profile[0].company_name,
  mobile:data.profile[0].mobile,
  email:data.profile[0].email,
  password:data.profile[0].password,
  cperson:data.profile[0].user_name,
  salesexec:data.profile[0].sale_exc_name,
  tsaleexec:data.profile[0].telesale_exc_name,
  vagentlist:function(sid){
    var $vagentid = $("#vagentid").select2();
    if(sid==null || sid==undefined || sid==''){
      $vagentid.val(sid).trigger('change');
      return '';
  }
    else{
    var siddata=sid.split(",");
    $vagentid.val(sid);
   setTimeout(() => {
    $vagentid.val(siddata).trigger('change');
   }, 200);
    //$('#vagentid').select2('val',sid,true).trigger('change');
    return sid;
    }

  }(data.profile[0].vagent_user_id),
  c2clist:function(sid){
    var $c2cid = $("#c2cid").select2();
    if(sid==null || sid==undefined || sid==''){
      $c2cid.val(sid).trigger('change');
     return '';
    }
    else{
      var siddata=sid.split(",");
      $c2cid.val(["1","2"]).trigger('change');
      setTimeout(() => {
        $c2cid.val(siddata).trigger('change');
       }, 200);
      //$c2cid.val(siddata).trigger('change');
      return sid;
    }
  }(data.profile[0].c2c_user_id),
agentallowed:data.profile[0].agent_allowed,
  validity:data.profile[0].validity_till,
  account_type:data.profile[0].account_type,
  auto_followup:data.profile[0].auto_followup,
  auto_assigned:data.profile[0].auto_assigned,
  autodialer:data.profile[0].autodialer,
  custom_field:data.profile[0].custom_field,
  remarks:'',
  userid:data.profile[0].user_id,
  stype:this.stype,
})
})

}
  get f(){ return this.vleadForm.controls;}
  onSubmit(){
  this.submitted = true;

  var vglvals = $("#vagentid").val();
  //console.log("vagent values"+vglvals);
  var c2clists = $("#c2cid").val();

if(vglvals!=undefined || vglvals!='' || vglvals!=null){

var vglvals1final = vglvals.map(function(item){ 
return item
}).join(",");

}
else{
  vglvals1final='';
}


if(vglvals!=undefined || vglvals!='' || vglvals!=null){
var c2clists1final = c2clists.map(function(item){ 
      return item
      }).join(",");
}
else{
  c2clists1final=''; 
}


this.vleadForm.get('validity').setValue($("#validitydate").val());
this.vleadForm.get('vagentlist').setValue(vglvals1final);
this.vleadForm.get('c2clist').setValue(c2clists1final);
    var vleaddata= JSON.stringify(this.vleadForm.value);
alert(vleaddata);
    if(this.vleadForm.invalid){
return false;
    }
    else{
 $(".fullpageloader").fadeIn("fast");
     this.http.post(this.baseurl+"update_service",{data:vleaddata}).subscribe((data:any)=>{
      if(data.status=="success"){
        $(".fullpageloader").fadeOut('fast');
          this.toastr.success('Data Submitted  succesfully!', 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        this.router.navigateByUrl('/servicelist');
        
      }
      else if(data.status=="fail"){
        $(".fullpageloader").fadeOut('fast');
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
       
      }
      
    },
    error => {
      $(".fullpageloader").fadeOut('fast');
      this.toastr.error(error, 'error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  })
    }
  }

  getserviclist(){
    this.http.get(this.baseurl+"get_serice_list").subscribe((data:any)=>{
      this.serviceList=data.service;

  })
  }
  getremarkslist(user_id){
    this.http.get(this.baseurl+"get_remarks_list?user_id="+user_id).subscribe(
      (result:any)=>{
        console.log(result);
      this.remarksrecords= result;
     });
  }
  creatrenwaldemoform(){
    this.demorenewform = this.fb.group({
      renew_type:[{value:''},[Validators.required]],
      remarks:[{value:''},[Validators.required]],
      user_id:this.user_id,
      service_type:"vlead",
      loginid:this.currentUserloclstrage.user_id
    })
    }
    get h(){
      return this.demorenewform.controls
    }
    demorenew(){
      this.submitted2 = true;
      let renewdata= JSON.stringify(this.demorenewform.value);
      if(this.demorenewform.invalid){
    return false
      }
      else{
        $(".fullpageloader").fadeIn('fast');
        this.http.post(this.baseurl+"demo_renewal",{data:renewdata}).subscribe((data:any)=>{
          console.log(data);
          if(data.status=="success"){
            $("#renewdemo").modal('hide');
            $(".fullpageloader").fadeOut('fast');
              this.toastr.success(data.message, 'Success!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
            this.demorenewform.patchValue({
              renew_type:"",
              remarks:"",
              user_id:this.user_id,
              service_type:"vlead",
              loginid:this.currentUserloclstrage.user_id
            })
            }
          else if(data.status=="fail"){
            $(".fullpageloader").fadeOut('fast');
           this.toastr.error(data.message, 'Error!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
           
          }
        },
        error => {
         $(".fullpageloader").fadeOut('fast');
          this.toastr.error(error, 'error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
      });
      }
    }
    vagentlistdata(sid){
      if(sid==null || sid==undefined || sid==''){
        this.vagentcurdata =[];
    }
      else{
        var siddata=sid.split(",");
       
        this.vagentcurdata=siddata;
      }
      
    }
    c2clistdata(sid){
      if(sid==null || sid==undefined || sid==''){
        this.c2ccurdata =[];
    }
      else{
        var siddata=sid.split(",");
       
        this.c2ccurdata=siddata;
      }
      
    }
    clickEventHandler(){
    
      // this.vleadForm.get('companyname').enable();
      // this.vleadForm.get('mobile').enable();
      // this.vleadForm.get('email').enable();
      // this.vleadForm.get('password').enable();
      // this.vleadForm.get('cperson').enable();
      // this.vleadForm.get('serviceid').enable();
      // this.vleadForm.get('agentallowed').enable();
      // this.vleadForm.get('validity').enable();
      // this.vleadForm.get('remarks').enable();
      // this.vleadForm.get('auto_assigned').enable();
      // this.vleadForm.get('auto_followup').enable();
      // this.vleadForm.get('autodialer').enable();
      // this.vleadForm.get('custom_field').enable();
      this.buttunhide = true;
  
    }
    
}
