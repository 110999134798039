import { Component, OnInit } from '@angular/core';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contentrequest',
  templateUrl: './contentrequest.component.html',
  styleUrls: ['./contentrequest.component.css']
})
export class ContentrequestComponent implements OnInit {

  content;
  submitted:boolean = false;
  submitted1:boolean = false;
  templateForm:FormGroup;
  senderidForm:FormGroup;
  senderidsec:boolean= true;
  senderIDList:any;
  currentUserloclstrage:any;
  constructor(private http:HttpClient,private toastr: ToastrService,private router:Router,private fb: FormBuilder,) { }

  ngOnInit() 
  {
    
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.senidformcreate();
    this.createTempleteform();
     this.getdata();
  //    setInterval(() => {
  //   this.getdata();
  //  }, 5000);
//      this.senderidForm.get('status').valueChanges
//      .subscribe(status => {
     
// if(status=='1'){
//   this.senderidsec= true;
//   this.senderidForm.get('senderid').setValidators([Validators.required]);
//   this.senderidForm.get('senderid').updateValueAndValidity();
// }
// else{
//   this.senderidsec= false;
//   this.senderidForm.get('senderid').setValidators(null);
//   this.senderidForm.get('senderid').updateValueAndValidity();
// }
//      })

  }

  getdata()
  {
    // $('.fullpageloader').show();
     this.http.get(' https://api.cerebra.quadrafort.in/content_request').subscribe(
     data=>{
      this.content=data;
     //  $('.fullpageloader').hide();
    
     });

  }

  approvedtickets(event,user_id,status,type,itemid){
   
   let remarks = <Element>event.target.closest('.remarksBox').childNodes[0].value;
if(!remarks){
  <Element>event.target.closest('.remarksBox').childNodes[0].classList.add('is-invalid');
  setTimeout(() => {
    <Element>event.target.closest('.remarksBox').childNodes[0].classList.remove('is-invalid');
  }, 5000);  
  $('.fullpageloader').hide();
  return false;
}
else{
//alert(user_id+""+status+""+type+""+itemid+''+remarks);

this.http.post(" https://api.cerebra.quadrafort.in/approve_ticket",{data:JSON.stringify({user_id:user_id,remarks:remarks,status:status,type:type,id:itemid})}).subscribe((data:any)=>{
    console.log(data);
    $('.fullpageloader').fadeIn('fast');
    if(data.status=="success"){
      this.getdata();
      this.router.navigateByUrl('/crequest/contentrequest');
      $('.fullpageloader').fadeOut('fast');
      this.toastr.success('Status change successfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
    }

  })
}
  }

  //createForm(){
senidformcreate(){
  this.senderidForm = this.fb.group({
    status:['1',[Validators.required]],
    senderid:['',[Validators.required,Validators.minLength(6),Validators.maxLength(6),]],
    peid:['',[Validators.required]],
    remarks:['',[Validators.required]],
    user_id:'',
    ticked_id:'',
    type:'',
    file_type:'',
    attach_id:'',
    session_id:JSON.parse(localStorage.getItem('currentUser')).user_id
    })
}

get f(){return this.senderidForm.controls}
submitsenderfrom(){
  this.submitted= true;
  let senderidFormdata = JSON.stringify(this.senderidForm.value);
  if(this.senderidForm.invalid){
  return false;
  }
  else{
    this.http.post(" https://api.cerebra.quadrafort.in/approve_ticket",{data:senderidFormdata}).subscribe((data:any)=>{
      console.log(data);
      $('.fullpageloader').fadeIn('fast');
      if(data.status=="success"){
        $("#senderidmodal").modal('hide');
        this.getdata();
        this.router.navigateByUrl('/crequest/contentrequest');
        $('.fullpageloader').fadeOut('fast');
        this.toastr.success('Status change successfully!', 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
      }
      else{
        $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
    
  }
  
    })
  }
}
  
approvedsenderid(event,user_id,ticked_id,type,file_type,attach_id){
this.senderidForm.get('user_id').setValue(user_id);
this.senderidForm.get('ticked_id').setValue(ticked_id);
this.senderidForm.get('type').setValue(type);
this.senderidForm.get('file_type').setValue(file_type);
this.senderidForm.get('attach_id').setValue(attach_id);
}

  // approvedsenderid(event,user_id,ticked_id,type,file_type,attach_id){
  //   this.senderidForm.get('user_id').setValue(user_id);
  //   this.senderidForm.get('ticked_id').setValue(ticked_id);
  //   this.senderidForm.get('type').setValue(type);
  //   this.senderidForm.get('file_type').setValue(file_type);
  //   this.senderidForm.get('attach_id').setValue(attach_id);
  //     }

  createTempleteform(){
    this.templateForm= this.fb.group({
     
      tempdata: this.fb.array([ this.addtepmplateFormgroup()]),
      remarks:['', [Validators.required]],
      user_id:'',
      ticked_id:'',
      type:'',
      file_type:'',
      attach_id:'',
      session_id:JSON.parse(localStorage.getItem('currentUser')).user_id
    })
  }

  addtepmplateFormgroup(): FormGroup {  
    return this.fb.group({ 
      tempsenderid:['', Validators.required],
      temptype: ['', Validators.required],  
      templang : ['', Validators.required],  
      temptext: ['', Validators.required],
      tempstatus: ['1', Validators.required] 
    });  
  } 
  adddynamictepmlate(): void {  
    (<FormArray>this.templateForm.get('tempdata')).push(this.addtepmplateFormgroup());  
  }   

 getsenderList(user_id){
  this.http.get(' https://api.cerebra.quadrafort.in/get_serder_id?user_id='+user_id).subscribe(
    data=>{
     this.senderIDList=data;
     
    });
 }
 removetemplate(i: number){

 // this.templateForm.get('tempdata').controls.splice(i, 1);
 this.templateForm.controls['tempdata']['controls'].splice(i, 1);
 
 }

  approvedtemplate(event,user_id,ticked_id,type,file_type,attach_id){
    this.getsenderList(user_id);
    this.templateForm.get('user_id').setValue(user_id);
    this.templateForm.get('ticked_id').setValue(ticked_id);
    this.templateForm.get('type').setValue(type);
    this.templateForm.get('file_type').setValue(file_type);
    this.templateForm.get('attach_id').setValue(attach_id);
      }
  get k(){return this.templateForm.controls}
  submittemplate(){
   
    this.submitted1= true;
    let templateFormdata = JSON.stringify(this.templateForm.value);
    if(this.templateForm.invalid){
    return false;
    }
    else{
     
      this.http.post(" https://api.cerebra.quadrafort.in/approve_ticket",{data:templateFormdata}).subscribe((data:any)=>{
      //  console.log(data);
        $('.fullpageloader').fadeIn('fast');
        if(data.status=="success"){
          $("#templatemodal").modal('hide');
          this.getdata();
          this.router.navigateByUrl('/crequest/contentrequest');
          $('.fullpageloader').fadeOut('fast');
          this.toastr.success('Status change successfully!', 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
        }
    
      })
    }
  }

}
