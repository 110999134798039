import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-resellerplanlist',
  templateUrl: './resellerplanlist.component.html',
  styleUrls: ['./resellerplanlist.component.css']
})
export class ResellerplanlistComponent implements OnInit {
  submitted1:boolean = false;
  submitted:boolean = false;
  itemsPerPage:any=15;
  currentPage:number =1;
  baseurl:any;
  searchForm:FormGroup;
  filterform:FormGroup;
 plansrecords:any;
 today= new Date();
 jstoday = '';
  constructor(private fb:FormBuilder, private http:HttpClient, private toastr:ToastrService, private router:Router , private route:ActivatedRoute) {

    this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    this.filterform = this.fb.group({
      user_id: [''],
      fromdate: [this.jstoday],
      todate: [this.jstoday],
      status:''
    })


   }

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
    this.createsearchform();
this.getrecords(this.currentPage);
$(".searchlist a").click(function(e) {
  $(".slistdrwn").slideToggle('fast');
 });


 $(".slistdrwn li").on('click',function(e) {

    var sval = "Enter" + " " + $(this).text();
     //alert(sval);
     $(".searchinput").attr('placeholder', sval);
     $(".searchinput1").val($(this).text());
     $(".slistdrwn").slideUp('fast');
     
});
  }

  getrecords(crpage:number){
$(".fullpageloader").fadeIn('fast');
this.http.get(this.baseurl+"reseller_plan_list?page="+crpage).subscribe((data:any)=>{
  if(data.status=='success'){
    $(".fullpageloader").fadeOut('fast');
    this.plansrecords = data;
  }

})
  }

  

 createsearchform(){
  this.searchForm = this.fb.group({
      searchtext:['',[Validators.required]],
      searchinput:[''],
     
   })
  }
  get g(){return this.searchForm.controls;}
  searchFilter(searchdata:any){
   this.submitted1= true;
   if(this.searchForm.invalid){
    $("#forminvalid").fadeIn(200).fadeOut(6000);
    $(".searchinput").css('border-color','#ff0000');
  setTimeout(() => {
    $(".searchinput").css('border-color','#b8b8b8');
  }, 6000);
     return false;
  }
    else{
      var searchtext = searchdata.searchtext;
     var searchtype = $(".searchinput1").val();
      $('.fullpageloader').show('fast');
      this.http.get(this.baseurl+"reseller_plan_list?searchtext="+searchtext+"&searchtype="+searchtype).subscribe(
      (result:any)=>{
      this.plansrecords=result;
      $('.fullpageloader').hide('fast');
  
      });
    }
   
  }

  filtersubmit()
{
   this.submitted=true;
 
   let user_id=this.filterform.value.user_id;
  let  status=this.filterform.value.status;

    
   let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
  
   $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'reseller_plan_list?status='+status+'&fromdate='+fromdate+'&todate='+todate).subscribe(
    (data)=>{
      this.plansrecords=data;
        $('.cfillBox').slideUp('fast');
       $('.fullpageloader').hide('fast');

    })

}

  pageChange(newpage:number){
    this.getrecords(newpage);
    this.currentPage = newpage;
      }
      navogateUrl(ress_id,plan_id){
      
        this.router.navigate(['/resseller/resellerplanlistview',ress_id,plan_id]);
     
     }

}
