import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resellercreateplan',
  templateUrl: './resellercreateplan.component.html',
  styleUrls: ['./resellercreateplan.component.css']
})
export class ResellercreateplanComponent implements OnInit {

  resellerplanform:FormGroup;
  submitted:boolean = false;
  retailpanel:boolean = false;
  baseurl:any;
  resellerList:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {

  
  }

  ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');
this.getressellerlist();
this.createform();

this.resellerplanform.get('account_type').valueChanges
  .subscribe(account_type => {
 if(account_type=='ind'){
    this.retailpanel = true;

    this.resellerplanform.get('resseller_id').setValidators([Validators.required]);
    this.resellerplanform.get('resseller_id').updateValueAndValidity();
  }
  else{
    this.retailpanel = false;

    this.resellerplanform.get('resseller_id').setValidators(null);
    this.resellerplanform.get('resseller_id').updateValueAndValidity();

  }

});


  }

  createform(){
    this.resellerplanform= this.fb.group({
      account_type:'all',
      resseller_id:[''],
      plan_name:['',[Validators.required]],
      plan_term:['',[Validators.required]],
      amount:['',[Validators.required]],
      channel:['',[Validators.required]],
      service_type:['',[Validators.required]],
      call_rate:['',[Validators.required]],
      monthly_coin:['',[Validators.required]],
      ibd_coin:['',[Validators.required]],
      ibd_duration:['',[Validators.required]],
      ibd_ded:['',[Validators.required]],
      obd_coin:['',[Validators.required]],
      obd_duration:['',[Validators.required]],
      obd_ded:['',[Validators.required]],
      sms_type:'common',
      sms_coin:['',[Validators.required]],
      sms_count:['',[Validators.required]],
      sms_ded:['',[Validators.required]],
      email_coin:['',[Validators.required]],
      email_count:['',[Validators.required]],
      email_ded:['',[Validators.required]],
     })
  }


  get f(){ return this.resellerplanform.controls;}
  onSubmit(){
  this.submitted = true;
  //this.vleadForm.get('validity').setValue($("#validitydate").val());
    var resellerplanformdata= JSON.stringify(this.resellerplanform.value);

    if(this.resellerplanform.invalid){
   return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"create_plan",{data:resellerplanformdata}).subscribe((data:any)=>{
        console.log(data);
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       this.router.navigateByUrl('/resseller/resellerapproveplan');
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }

  getressellerlist(){
this.http.get(this.baseurl+"get_reseller_list").subscribe((data:any)=>{
  this.resellerList = data.data;
})
  }


}
