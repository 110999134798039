import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upgraderequest',
  templateUrl: './upgraderequest.component.html',
  styleUrls: ['./upgraderequest.component.css']
})
export class UpgraderequestComponent implements OnInit {
  
  renewal;
  currentUserloclstrage:any;
  baseurl:any;
  sheet_no:any;
  servicetimer:any;
  constructor(private http:HttpClient, private route:Router, private toastr:ToastrService) { }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.baseurl=localStorage.getItem('baseurl');
   this.getdata();
   this.servicetimer=setInterval(() => {
    this.getdata(); 
    }, 10000);
  }

  getdata()
  {
    //$('.fullpageloader').show();
     this.http.get(' https://api.cerebra.quadrafort.in/upgrade_request').subscribe(
     data=>{
     this.renewal=data;
     $('.fullpageloader').hide();

     });
  }

  navigateRoute(id:number,userid:string,service_type:string,contract:string,service_validity:string,allow_agent:string){
if(service_type=='VSMS'){
  this.route.navigate(['/crequest/upgradeplansms',id,userid,service_type]);
}
else if(service_type=='VManage'|| service_type=='VLead'){
  this.route.navigate(['/crequest/renewal',id,userid,service_type,contract,service_validity,allow_agent]);
}
else{
  this.route.navigate(['/crequest/upgradeplan',id,userid,service_type]);
}
}

disapprove(sheet_id)
{
this.sheet_no=sheet_id;
$('#disapprovedremark').val('');
$("#disapprovedmodal").modal('show');

} 

disapproveBtn(){
  if($('#disapprovedremark').val()!=''){
    $('.fullpageloader').show();
    this.http.post(this.baseurl+'disapprove_service',{sheet_id:this.sheet_no,remarks:$('#disapprovedremark').val(),type:'upgrade'}).subscribe(
   (data:any)=>{
    
   if(data.status=='success')
   {
      this.toastr.success(data.message, 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      $("#disapprovedmodal").modal('hide');
   }
   else
   {
      this.toastr.error(data.message, 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  
   }
   $('.fullpageloader').hide();

   })
  }
  else{
 $("#disapprovedremark").addClass("is-invalid");
 setTimeout(() => {
  $("#disapprovedremark").removeClass("is-invalid");
 }, 4000);
  }
}
ngOnDestroy(){
  clearInterval(this.servicetimer);
}

}
