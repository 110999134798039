import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-mrequestnav',
  templateUrl: './mrequestnav.component.html',
  styleUrls: ['./mrequestnav.component.css']
})
export class MrequestnavComponent implements OnInit {
   currentUser;
   usersetting;
   crequestpage;
   noti;
   baseurl:any;
 intervalset:any;
  constructor(private http:HttpClient) {  }

  ngOnInit() {

this.baseurl = localStorage.getItem('baseurl');
 this.getdata();
 this. intervalset= setInterval(() => {
    this.getdata(); 
    }, 10000);

  this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }

  getdata()
  {
    
     this.http.get(this.baseurl+'topup_request').subscribe(
     data=>{
       this.noti=data;
       $('.fullpageloader').hide();
    

     });

  }

  ngOnDestroy() {
    if (this.intervalset) { clearInterval(this.intervalset); }
  
   }
  

}
