import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-ressellerresistration',
  templateUrl: './ressellerresistration.component.html',
  styleUrls: ['./ressellerresistration.component.css']
})
export class RessellerresistrationComponent implements OnInit {
  resslrenrollform:FormGroup;
  submitted:boolean = false;
  baseurl:any;
  partner_id:any;
  user_id:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
    this.partner_id =  this.route.snapshot.paramMap.get('id');
  
  }

  ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');
this.user_id = JSON.parse(localStorage.getItem('currentUser')).user_id;
this.createform()

if(this.partner_id!=''){
  this.getrecords();
}
  }

  createform(){
    this.resslrenrollform= this.fb.group({
      name:['',[Validators.required]],
      companyname:['',[Validators.required]],
      mobile:['',[Validators.required]],
      email:['',[Validators.required,Validators.email]],
      address:['',[Validators.required]],
      country:['',[Validators.required]],
      state:['',[Validators.required]],
      city:['',[Validators.required]],
      pincode:['',[Validators.required]],
      password:['',[Validators.required]],
      amount:['',[Validators.required]],
      total_coins:['',[Validators.required]],
      amount_type:['',[Validators.required]],
      ibd_duration:['',[Validators.required]],
      ibd_coins:['',[Validators.required]],
      obd_duration:['',[Validators.required]],
      obd_coins:['',[Validators.required]],
      sms_count:['',[Validators.required]],
      sms_coins:['',[Validators.required]],
      activation_date:['',[Validators.required]],
      expire_date:['',[Validators.required]],
      virtual_cost:['',[Validators.required]],
      did_cost:['',[Validators.required]],
      channel_cost:['',[Validators.required]],
      onetime_allc_ocost:['',[Validators.required]],
      merge_cost:['',[Validators.required]],
      crm_cost:['',[Validators.required]],
      custmize_cost:['',[Validators.required]],
      channel_alloc_cost:['',[Validators.required]],
      whitelabel:'',
      partner_id:'',
      user_id:this.user_id

    })
  }


  get f(){ return this.resslrenrollform.controls;}
  onSubmit(){
  this.submitted = true;
  //this.vleadForm.get('validity').setValue($("#validitydate").val());
    var enrollresellerdata= JSON.stringify(this.resslrenrollform.value);

    if(this.resslrenrollform.invalid){
   return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"create_reseller_first",{data:enrollresellerdata}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       this.resslrenrollform.reset();
       this.router.navigate(['/resseller/resellersetting',data.resel_id]);
      //this.router.navigateByUrl();
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }

  getrecords(){
   
    $(".fullpageloader").fadeIn("fast");
this.http.get(this.baseurl+"reseller_request_detail?partner_id="+this.partner_id).subscribe((data:any)=>{
  //alert(JSON.stringify(data));
if(data.status=='success'){
  $(".fullpageloader").fadeOut("fast");
  this.resslrenrollform.patchValue({
     name:data.data[0].name,
     companyname:data.data[0].company_name,
      mobile:data.data[0].mobile,
      email:data.data[0].email,
      address:data.data[0].address,
      country:data.data[0].country,
      state:data.data[0].state,
      city:data.data[0].city,
      pincode:data.data[0].pincode,
      password:'',
      amount:data.data[0].amount,
      total_coins:(data.data[0].amount*100/data.data[0].call_rate),
      amount_type:data.data[0].partner_type,
      ibd_duration:'0',
      ibd_coins:'0',
      obd_duration:'0',
      obd_coins:'0',
      sms_count:'0',
      sms_coins:'0',
      activation_date:'',
      expire_date:'',
      virtual_cost:'0',
      did_cost:'0',
      channel_cost:'0',
      onetime_allc_ocost:data.data[0].one_time_cost,
      merge_cost:'0',
      crm_cost:'0',
      custmize_cost:'0',
      channel_alloc_cost:'0',
      whitelabel:data.data[0].whitelabel,
      partner_id:data.data[0].partner_id,
      user_id:this.user_id
  });
}
})
  }


}
