import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-testcall',
  templateUrl: './testcall.component.html',
  styleUrls: ['./testcall.component.css']
})
export class TestcallComponent implements OnInit {
  Testcall:FormGroup;
  submitted:boolean = false;
  subtypesec:boolean = false;
  baseurl:any;
  networkList:any;
  outcliList:any;
  currentUserloclstrage:any;
  session_id:any;
  serverList:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {

  
  }

  ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');
this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
this.session_id =  this.currentUserloclstrage.user_id;
this.createform();
this.getc2cserver();
  }

  createform(){
    this.Testcall= this.fb.group({
      server:['',[Validators.required]],
      network:['',[Validators.required]],
      support_mobile:['',[Validators.required]],
      customer_mobile:['',[Validators.required]],
      outcli:[''],
  })
  }


  get f(){ return this.Testcall.controls;}
  onSubmit(){
  this.submitted = true;
   var testcall= JSON.stringify(this.Testcall.value);
    if(this.Testcall.invalid){
   return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"make_test_call",{data:testcall}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
      
         this.Testcall.patchValue({
          server:'',
          network:'',
          support_mobile:'',
          customer_mobile:'',
          outcli:'',
         })
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }


  getc2cnetwork(ip){
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+'get_c2c_network',{ip:ip,session_id:this.session_id}).subscribe((data:any)=>{
     this.networkList= data.network_list;
     
   $(".fullpageloader").fadeOut('fast');
  })
  }
  getc2cserver(){
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+'get_c2c_server',{session_id:this.session_id}).subscribe((data:any)=>{
     this.serverList= data.server_ip;

     $(".fullpageloader").fadeOut('fast');
  })
  }
  changeserver(event){
   this.getc2cnetwork(event.target.value);
  }

}
