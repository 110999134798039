import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.css']
})
export class RenewalComponent implements OnInit {

  submitted:boolean = false;
  topupForm:FormGroup;
  renewal_id:any;
  user_id:any;
  service_type:any;
  renewsec:boolean=true;
  extendsec:boolean=false;
  validity:any;
  contract:any;
  agent:any;
  
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute){
    this.renewal_id = this.route.snapshot.paramMap.get("id");
    this.user_id = this.route.snapshot.paramMap.get("user_id");
    this.service_type = this.route.snapshot.paramMap.get("service_type");
    this.validity = this.route.snapshot.paramMap.get("validity");
    this.contract = this.route.snapshot.paramMap.get("contract");
    this.agent = this.route.snapshot.paramMap.get("agent");
    
  } 

  ngOnInit() {
   this.createForm();

   this.topupForm.get('plantype').valueChanges
   .subscribe(plantype => {
    if(plantype=='renew'){
   
     this.renewsec = true;
     this.extendsec = false;
     this.topupForm.get('renewtype').setValidators([Validators.required]);
     this.topupForm.get('extentype').setValidators(null);
     this.topupForm.get('extentype').setValue('');
     this.topupForm.get('extentype').updateValueAndValidity();
     this.topupForm.get('renewtype').updateValueAndValidity();
     
     }
   else{
     this.renewsec = false;
     this.extendsec = true;
     this.topupForm.get('renewtype').setValidators(null);
     this.topupForm.get('extentype').setValidators([Validators.required]);
     this.topupForm.get('extentype').updateValueAndValidity();
     this.topupForm.get('renewtype').updateValueAndValidity();
   }
   })
  }
createForm(){
this.topupForm = this.fb.group({
  plantype:'renew',
  renewtype:['',[Validators.required]],
  extentype:[''],
  validity:this.validity,
  contract:this.contract,
  agent:this.agent,
  remark:['',[Validators.required]],
  renewal_id:this.renewal_id,
  user_id:this.user_id,
  service_type:this.service_type,
  session_id:JSON.parse(localStorage.getItem('currentUser')).user_id
})
  }

get f(){return this.topupForm.controls}
onSubmit(){
this.submitted= true;
let topupFormdata = JSON.stringify(this.topupForm.value);
if(this.topupForm.invalid){
return false;
}
else{
 
  $(".fullpageloader").fadeIn('fast');
  this.http.post(" https://api.cerebra.quadrafort.in/make_renewal",{data:topupFormdata}).subscribe((data:any)=>{
   
    if(data.status=='success'){
      this.toastr.success('Data Submitted  succesfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      this.router.navigateByUrl('/crequest/renewalrequest');
    }
    else if(data.status=="fail"){
      console.log("fail");
      this.toastr.error(data.message, 'Error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
      
     }
  }, error => {
    this.toastr.error(error, 'error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
  }
  )
  
  }
}

}
