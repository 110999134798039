import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';  

@Component({
  selector: 'app-smsaccsett',
  templateUrl: './smsaccsett.component.html',
  styleUrls: ['./smsaccsett.component.css']
})
export class SmsaccsettComponent implements OnInit {
  smsaccForm:FormGroup;
  submitted = false;
smsrecords:any;
totalservice:string[]=['VAgent','Vmanage','Click2Call','OBD','Vmanage','Vlead'];
eventtype:string[]=['demo alerts','voicemail','voicemail1','IVR','IVR1','service alert','payment alerts'];
currentUserloclstrage:any;

  constructor(private fb:FormBuilder,private http:HttpClient,private toastr:ToastrService,private confirmDialogService:ConfirmDialogService) { 
    
  }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.createform();
    this.getrecord();
  }
createform(){
  this.smsaccForm= this.fb.group({
    eventtype:['',Validators.required],
    servicetype:['',Validators.required],
    baseurl:['http://servicesms.minavo.in/WebServiceSMS.aspx',Validators.required],
    username:['User',Validators.required],
    uservalue:['Vagent',Validators.required],
    passwordname:['password',Validators.required],
    passwordvalue:['vag@123',Validators.required],
    sidname:['sid',Validators.required],
    sidvalue:['Vagent',Validators.required],
    langname:['mtype',Validators.required],
    langvalue:['N',Validators.required],
    mobilename:['mobilenumber',Validators.required],
    messagename:['message',Validators.required],
    ad_sms_id:['']

  })
}

get f() { return this.smsaccForm.controls; }
onSubmit(){
  var smsaccFormdata = JSON.stringify(this.smsaccForm.value);
  this.submitted =true;
  if(this.smsaccForm.invalid){
    return false;
  }
  else{
 $(".fullpageloader").fadeIn('fast');
this.http.post(" https://api.cerebra.quadrafort.in/addsms",{data:smsaccFormdata}).subscribe((data:any)=>{
  console.log(data.status);
    if(data.status=="success"){
      this.toastr.success('Data Submitted  successfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      this.getrecord();
     }
    else if(data.status=="fail"){
     //console.log("fail");
     this.toastr.error(data.message, 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
     
    }
  },error=>{
    this.toastr.error('Error is coming!', 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
  })
  }
  $("#addsmssett").modal('hide');
}

 editRecord(id:number) {
        this.http.post(" https://api.cerebra.quadrafort.in/editsms",{ad_sms_id:id}).subscribe((data:any)=>{
          console.log(data);
      
       this.smsaccForm.setValue(
         {
    eventtype:data[0].sms_event,
    servicetype:data[0].service_type,
    baseurl:data[0].base_uri,
    username:data[0].param_user_name,
    uservalue:data[0].param_user_value,
    passwordname:data[0].param_pass_name,
    passwordvalue:data[0].param_pass_value,
    sidname:data[0].param_sid_name,
    sidvalue:data[0].param_sid_value,
    langname:data[0].param_lang_name,
    langvalue:data[0].param_lang_value,
    mobilename:data[0].param_mobile_name,
    messagename:data[0].param_message_name,
    ad_sms_id:data[0].ad_sms_id
       });
});
$("#addsmssett").modal({
backdrop: 'static',
keyboard: true, 
show: true
})

}
deleteRecord(id:number){
  
  var that = this;
 this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
     
      that.http.post(' https://api.cerebra.quadrafort.in/deletesms',{ad_sms_id:id}).subscribe((data:any)=>{
        
        that.toastr.success('Record Deleted  successfully!', 'Success!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
       
      that.getrecord(); 
   })

    }, function () {  
      return false;
    }) 

}

getrecord(){
  $(".fullpageloader").fadeIn('fast');
this.http.get(" https://api.cerebra.quadrafort.in/smsaccsett").subscribe((data:any)=>{
this.smsrecords= data;
$(".fullpageloader").fadeOut('fast');
})
}

}
