import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Routes,Router,ActivatedRoute} from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service'; 
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-resellersetting',
  templateUrl: './resellersetting.component.html',
  styleUrls: ['./resellersetting.component.css']
})
export class ResellersettingComponent implements OnInit {

  
  resslresettform:FormGroup;
  emailaccForm:FormGroup;
  smsaccForm:FormGroup;
  submitted:boolean = false;
  submitted1:boolean = false;
  submitted2:boolean = false;
  ressdomainpanel:boolean = false;
  logopanel:boolean = false;
  prodtypepanel:boolean = false;
  footertextpanel:boolean = false;
  emailsettsec:boolean = false;
  smssettsec:boolean = false;
   baseurl:any;
   self;
   emailrecords:any;
   smsrecords:any;
   reseller_id:any;
   fileName:any;
   fileData:any;
   filedata:any;
   othersettings:any;
   alldata:any;
   logourl:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute,private confirmDialogService:ConfirmDialogService) {

    this.reseller_id =  this.route.snapshot.paramMap.get('ress_id');
  }

  ngOnInit() {
    let that = this;
    let ressidnew = this.reseller_id;
    this.self = this;
this.baseurl = localStorage.getItem('baseurl');
this.createform();
this.createemailaccform();
this.createsmsaccform();
this.getemaillist();
this.getsmslist();
this.getrecord();

$(".featuresetting .form-group.row .cradiobtn input[type='radio']").on('change',function(){
 // alert("hi"+$(this).val());
  if($(this).val()=='0'){
    $(this).closest('.col-sm-4').next('.costsec').fadeOut('fast');
      $(this).closest('.col-sm-4').next('.costsec').removeAttr('hidden');
  }
  else{
    $(this).closest('.col-sm-4').next('.costsec').fadeIn('fast');
   $(this).closest('.col-sm-4').next('.costsec').removeAttr('hidden');
  }
})



$(".featuresetting .form-group.row  input[type='button']").on('click',function(){

 let settvalue= $(this).closest('.form-group').find('input[type="radio"]:checked').val();
 let settname= $(this).closest('.form-group').find('input[type="text"]').data('settname');
 let costvalue= $(this).closest('.form-group').find('input[type="text"]').val();
 let ressId= ressidnew;
 
 if(settvalue==1){
if(costvalue!=''){
  
$(".fullpageloader").fadeIn("fast");

        that.http.post(that.baseurl+"udate_single_setting",{resel_id:ressId,setting_value:settvalue,setting_cost:costvalue,setting_name:settname}).subscribe((data:any)=>{
      
         if(data.status=="success"){
          $(".fullpageloader").fadeOut('fast');
             that.toastr.success('Data Submitted  succesfully!', 'Success!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
 
         }
         else if(data.status=="fail"){
           $(".fullpageloader").fadeOut('fast');
          that.toastr.error(data.message, 'Error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
         
        }
        
      },
       error => {
       
         $(".fullpageloader").fadeOut('fast');
         that.toastr.error(error, 'error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
        });
    })
}
else{
  $(this).closest('.form-group').find('input[type="text"]').css('border-color','#ff0000');
  setTimeout(() => {
    $(this).closest('.form-group').find('input[type="text"]').css('border-color','#b8b8b8');
    
  }, 2000);
return false;
}
 }
 else{
$(".fullpageloader").fadeIn("fast");
        that.http.post(that.baseurl+"udate_single_setting",{resel_id:ressId,setting_value:settvalue,setting_cost:costvalue,setting_name:settname}).subscribe((data:any)=>{
         if(data.status=="success"){
          $(".fullpageloader").fadeOut('fast');
             that.toastr.success('Data Submitted  succesfully!', 'Success!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
 
         }
         else if(data.status=="fail"){
           $(".fullpageloader").fadeOut('fast');
          that.toastr.error(data.message, 'Error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
         
        }
        
      },
       error => {
         $(".fullpageloader").fadeOut('fast');
         that.toastr.error(error, 'error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
        });
    })
 }

 

})


this.resslresettform.get('ressdomain').valueChanges
  .subscribe(ressdomain => {
  if(ressdomain==true){
    this.ressdomainpanel = true;

    this.resslresettform.get('ressdomain_name').setValidators([Validators.required]);
    this.resslresettform.get('ressdomain_name').updateValueAndValidity();
  }
  else{
    this.ressdomainpanel = false;

    this.resslresettform.get('ressdomain_name').setValidators(null);
    this.resslresettform.get('ressdomain_name').updateValueAndValidity();

  }

});

this.resslresettform.get('logo_type').valueChanges
  .subscribe(logo_type => {
  if(logo_type=='1'){
    this.logopanel = true;

    this.resslresettform.get('uploadlogo').setValidators([Validators.required]);
    this.resslresettform.get('uploadlogo').updateValueAndValidity();
  }
  else{
    this.logopanel = false;

    this.resslresettform.get('uploadlogo').setValidators(null);
    this.resslresettform.get('uploadlogo').updateValueAndValidity();

  }

});
this.resslresettform.get('prod_tpye').valueChanges
  .subscribe(prod_tpye => {
  if(prod_tpye=='1'){
    this.prodtypepanel = true;

    this.resslresettform.get('prod_name').setValidators([Validators.required]);
    this.resslresettform.get('prod_name').updateValueAndValidity();
  }
  else{
    this.prodtypepanel = false;

    this.resslresettform.get('prod_name').setValidators(null);
    this.resslresettform.get('prod_name').updateValueAndValidity();

  }

});
this.resslresettform.get('footer_text').valueChanges
  .subscribe(footer_text => {
  if(footer_text=='1'){
    this.footertextpanel = true;

    this.resslresettform.get('footer_text_val').setValidators([Validators.required]);
    this.resslresettform.get('footer_text_val').updateValueAndValidity();
  }
  else{
    this.footertextpanel = false;

    this.resslresettform.get('footer_text_val').setValidators(null);
    this.resslresettform.get('footer_text_val').updateValueAndValidity();

  }

});

this.resslresettform.get('emaccminavo').valueChanges
  .subscribe(emaccminavo => {
  if(emaccminavo=='1'){
    this.emailsettsec = true;
  }
  else{
    this.emailsettsec = false;
  }

});

this.resslresettform.get('smsaccount').valueChanges
  .subscribe(smsaccount => {
  if(smsaccount=='1'){
    this.smssettsec = true;
  }
  else{
    this.smssettsec = false;
  }

});

function readURL(input) {
  if (input.files && input.files[0]) {
      var reader = new FileReader();
      
      reader.onload = function (e) {
          $('#profile-img-tag').attr('src', reader.result);
      }
      reader.readAsDataURL(input.files[0]);
  }
}
$("#logoupload").change(function(){
  readURL(this);
 });


  }

  createform(){
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.resslresettform= this.fb.group({
      whitelabel:'0',
      logintype:'0',
      ressdomain:'',
      ressdomain_name:['',[Validators.pattern(urlRegex)]],
      demorequired:'0',
      logo_type:'minavologo',
      uploadlogo:'',
      prod_tpye:'prodvagent',
      prod_name:'',
      footer_text:'footerminavo',
      footer_text_val:'',
      didcoins:['',[Validators.required]],
      tollfreecoins:['',[Validators.required]],
      didsmscoins:['',[Validators.required]],
      tfsmscoins:['',[Validators.required]],
      didchannel:['',[Validators.required]],
      tollfreechannel:['',[Validators.required]],
      addcoin:'0',
      backcoin:'0',
      addchannel:'0',
      backchannel:'0',
      updatevnum:'0',
      addsms:'0',
      backsms:'0',
      emaccminavo:'0',
      smsaccount:'0',
      ress_id:this.reseller_id
     })
  }

createemailaccform(){
this.emailaccForm =  this.fb.group({
  eventtype:['',[Validators.required]],
  host:['',[Validators.required]],
  smtpauth:['',[Validators.required]],
  username:['',[Validators.required]],
  passwd:['',[Validators.required]],
  ad_email_id:'',
  ress_id:this.reseller_id
})
}

createsmsaccform(){
  this.smsaccForm =  this.fb.group({
    eventtype:['',[Validators.required]],
    baseurl:['',[Validators.required]],
    username:['',[Validators.required]],
    uservalue:['',[Validators.required]],
    passwordname:['',[Validators.required]],
    passwordvalue:['',[Validators.required]],
    sidname:['',[Validators.required]],
    sidvalue:['',[Validators.required]],
    langname:['',[Validators.required]],
    langvalue:['',[Validators.required]],
    mobilename:['',[Validators.required]],
    messagename:['',[Validators.required]],
    ad_sms_id:'',
    ress_id:this.reseller_id
    
  })
  }
  get f(){ return this.resslresettform.controls;}
  resslresettsubmit(){
  this.submitted = true;
  //this.vleadForm.get('validity').setValue($("#validitydate").val());
    var resslresettformdata= JSON.stringify(this.resslresettform.value);

    if(this.resslresettform.invalid){
   return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
  //const form = document.forms[0];
  let formData:FormData =  new FormData();
    //console.log(JSON.stringify(this.uploadForm.value));
    formData.append('logo', this.fileData);
    formData.append('formalldata',JSON.stringify(this.resslresettform.value));
   // alert(formData.getAll('formalldata'));
    //alert(formData.getAll('logo'));
      this.http.post(this.baseurl+"create_reseller_second",formData).subscribe((data:any)=>{
     
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       // this.router.navigateByUrl('/servicelist');
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }
  get g(){return this.emailaccForm.controls};
  emailaccformsubmit(){
    this.submitted1 = true;
    //this.vleadForm.get('validity').setValue($("#validitydate").val());
      var emailaccFormdata= JSON.stringify(this.emailaccForm.value);
  
      if(this.emailaccForm.invalid){
     return false;
      }
      else{
    $(".fullpageloader").fadeIn("fast");
        this.http.post(this.baseurl+"add_email",{data:emailaccFormdata}).subscribe((data:any)=>{
         if(data.status=="success"){
          this.getemaillist();
           $(".fullpageloader").fadeOut('fast');
             this.toastr.success('Data Submitted  succesfully!', 'Success!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
          $("#addemailsett").modal('hide');

          this.emailaccForm.patchValue({
            eventtype:['',[Validators.required]],
            host:['',[Validators.required]],
            smtpauth:['',[Validators.required]],
            username:['',[Validators.required]],
            passwd:['',[Validators.required]],
            ad_email_id:'',
            ress_id:this.reseller_id
          })
         
         // this.router.navigateByUrl('/servicelist');
          
         }
         else if(data.status=="fail"){
           $(".fullpageloader").fadeOut('fast');
          this.toastr.error(data.message, 'Error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
         
        }
        
      },
       error => {
         $(".fullpageloader").fadeOut('fast');
         this.toastr.error(error, 'error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
        });
    })
      }

  }


  get h(){return this.smsaccForm.controls};
  smsaccformsubmit(){
    this.submitted2 = true;
    //this.vleadForm.get('validity').setValue($("#validitydate").val());
      var smsaccFormdata= JSON.stringify(this.smsaccForm.value);
  
      if(this.smsaccForm.invalid){
     return false;
      }
      else{
    $(".fullpageloader").fadeIn("fast");
        this.http.post(this.baseurl+"add_sms",{data:smsaccFormdata}).subscribe((data:any)=>{
         if(data.status=="success"){
          this.getsmslist();
           $(".fullpageloader").fadeOut('fast');
             this.toastr.success('Data Submitted  succesfully!', 'Success!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
         
           $("#addsmssett").modal('hide');
           $("#addemailsett").modal('hide');

           this.smsaccForm.patchValue({
             eventtype:'',
             baseurl:'',
             username:'',
             uservalue:'',
             passwordname:'',
             passwordvalue:'',
             sidname:'',
             sidvalue:'',
             langname:'',
             langvalue:'',
             mobilename:'',
             messagename:'',
             ad_sms_id:'',
             ress_id:this.reseller_id
           })
          // this.smsaccForm.reset();
         // this.router.navigateByUrl('/servicelist');
          
         }
         else if(data.status=="fail"){
           $(".fullpageloader").fadeOut('fast');
          this.toastr.error(data.message, 'Error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
         
        }
        
      },
       error => {
         $(".fullpageloader").fadeOut('fast');
         this.toastr.error(error, 'error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
        });
    })
      }

  }

  fileProgress(fileInput: any) {
    // console.log(fileInput.target.files[0]);
    
       var validExts = new Array(".jpeg",".png",".jpg");
        var fileExt = fileInput.target.files[0].name;
       fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
        $("#filepath").empty('');
       
       if (validExts.indexOf(fileExt) < 0) {
         $("#exeterror").fadeIn(400).delay(3000).fadeOut(400);
         this.resslresettform.get('imagefile').setValue('');
         $("#customFile").val('');
         return false;
       }
       else{
           this.fileName = fileInput.target.files[0].name;
         $("#filepath").html(fileInput.target.files[0].name);
         this.fileData = <File>fileInput.target.files[0];
   
       const target: DataTransfer = <DataTransfer>(fileInput.target);
       if (target.files.length !== 1) throw new Error('Cannot use multiple files');
       const reader: FileReader = new FileReader();
       reader.readAsBinaryString(target.files[0]);
       //  console.log(target.files[0]);
         this.filedata = target.files[0]
         
         }
     }

  openemailpopup(){
   this.self.emailaccForm.get('ad_email_id').setValue('');
  }
  opensmspopup(){
    this.self.emailaccForm.get('ad_sms_id').setValue('');
   }

   editemail(ad_email_id,resel_id,host,smtpauth,username,password,email_event){
       this.self.emailaccForm.setValue({
      eventtype:email_event,
      host:host,
      smtpauth:smtpauth,
      username:username,
      passwd:password,
      ad_email_id:ad_email_id,
      ress_id:resel_id
    })
    

   }

   deletemail(ad_email_id){
var that = this;
  this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
        that.http.get(that.baseurl+"delete_email?id="+ad_email_id).subscribe((data:any)=>{
        console.log(data);
         if(data.status=='success'){
       that.toastr.success('Record Deleted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
          });
          that.getemaillist(); 
         }
         
    })
   
     }, function () {  
       return false;
     }) 
   
    
   }
   
   editsms(ad_sms_id,resel_id,base_uri,param_user_name,param_user_value,param_pass_name,param_pass_value,param_sid_name,param_sid_value,param_lang_name,param_lang_value,param_mobile_name,param_message_name,sms_event){

    this.self.smsaccForm.setValue({
      eventtype:sms_event,
      baseurl:base_uri,
      username:param_user_name,
      uservalue:param_user_value,
      passwordname:param_pass_name,
      passwordvalue:param_pass_value,
      sidname:param_sid_name,
      sidvalue:param_sid_value,
      langname:param_lang_name,
      langvalue:param_lang_value,
      mobilename:param_mobile_name,
      messagename:param_message_name,
      ad_sms_id:ad_sms_id,
      ress_id:this.reseller_id
   
 })
 

}

deletesms(ad_sms_id){
var that = this;
this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
   that.http.get(that.baseurl+"delete_sms?id="+ad_sms_id).subscribe((data:any)=>{
    //  alert(ad_sms_id);
     //console.log(data);
      if(data.status=='success'){
      that.toastr.success('Record Deleted  succesfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
       });
       that.getsmslist(); 
      }
      
 })

  }, function () {  
    return false;
  }) 

 
}
   getrecord(){
     $(".fullpageloader").fadeIn('fast');
this.http.get(this.baseurl+"edit_setting?resel_id="+this.reseller_id).subscribe((data:any)=>{
$(".fullpageloader").fadeOut('fast');
this.logourl =data.profiledata[0].logo_name;
//this.alldata= data;
this.othersettings = data.othersetting;
this.resslresettform.patchValue({
      whitelabel:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "whitelable");
        if(settvalue.setting_value==1){
return true;
        }
        else{
          return false;
        }
      })(data.data),
      logintype:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "minavologin");
         if(settvalue.setting_value==1){
          return true;
                  }
                  else{
                    return false;
                  }
      })(data.data),
      ressdomain:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "domain");
        if(settvalue.setting_value==1){
          return true;
                  }
                  else{
                    return false;
                  }
      })(data.data),
      ressdomain_name:data.profiledata[0].domain_name,
      demorequired:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "demo");
        if(settvalue.setting_value==1){
          return true;
                  }
                  else{
                    return false;
                  }
      })(data.data),
      logo_type:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "logo");
        return settvalue.setting_value;
      })(data.data),
      uploadlogo:data.profiledata[0].logo_name,
      prod_tpye:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "productname");
        return settvalue.setting_value;
      })(data.data),
      prod_name:data.profiledata[0].product_name,
      footer_text:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "productname");
        return settvalue.setting_value;
      })(data.data),
      footer_text_val:data.profiledata[0].footer,
      didcoins:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "did");
        return settvalue.setting_value;
      })(data.data),
      tollfreecoins:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "tollfree");
        return settvalue.setting_value;
      })(data.data),
      didsmscoins:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "sms");
        return settvalue.setting_value;
      })(data.data),
      tfsmscoins:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "tf_sms");
        return settvalue.setting_value;
      })(data.data),
      didchannel:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "did_channel");
        return settvalue.setting_value;
      })(data.data),
      tollfreechannel:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "tf_channel");
        return settvalue.setting_value;
      })(data.data),
      addcoin:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "credit_coin");
        return settvalue.setting_value;
      })(data.data),
      backcoin:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "debit_coin");
        return settvalue.setting_value;
      })(data.data),
      addchannel:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "credit_channel");
        return settvalue.setting_value;
      })(data.data),
      backchannel:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "debit_channel");
        return settvalue.setting_value;
      })(data.data),
      updatevnum:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "update_virutal_no");
        return settvalue.setting_value;
      })(data.data),
      addsms:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "credit_sms_coin");
        return settvalue.setting_value;
      })(data.data),
      backsms:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "debit_sms_coin");
        return settvalue.setting_value;
      })(data.data),
      emaccminavo:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "email");
        return settvalue.setting_value;
      })(data.data),
      smsaccount:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "sms");
        return settvalue.setting_value;
      })(data.data),
      ress_id:this.reseller_id
})
})
  }
  getemaillist(){
    this.http.get(this.baseurl+"get_email_list?resel_id="+this.reseller_id).subscribe((data:any)=>{
      this.emailrecords = data
    })
  }
  
  getsmslist(){
    this.http.get(this.baseurl+"get_sms_list?resel_id="+this.reseller_id).subscribe((data:any)=>{
      this.smsrecords = data
    })
  }
  
}
