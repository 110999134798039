import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tollfreeservice',
  templateUrl: './tollfreeservice.component.html',
  styleUrls: ['./tollfreeservice.component.css']
})
export class TollfreeserviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $(function() {

      $(".select2").select2({
          minimumResultsForSearch: -1
      });

      $(".acchead").click(function() {
          $(this).next('.accbox').slideToggle('fast');
          $(this).toggleClass('active');
      });
      $(".crmsettingbtn input[type='radio']").click(function() {
          if ($(this).val() == 'CRM') {
              $('#crmseetable').slideDown('fast');
          } else {

              $('#crmseetable').slideUp('fast');
          }
      });

      $(".smsatBtn input[type='radio']").click(function() {
          switch ($(this).val()) {
              case 'userpersonal':
                  $("#userpersec").slideDown('fast');
                  $("#minpersec").slideUp('fast');
                  break;
              case 'minavppersonel':
                  $("#minpersec").slideDown('fast');
                  $("#userpersec").slideUp('fast');
                  break;
              default:
                  $("#minpersec").slideUp('fast');
                  $("#userpersec").slideUp('fast');
                  break;
          }
      });

      $(".mptBtn input[type='radio']").click(function() {
          if ($(this).val() == 'default') {
              $('#mpdefaulSec').slideDown('fast');
              $('#mpmanualSec').slideUp('fast');

          } else {
              $('#mpdefaulSec').slideUp('fast');
              $('#mpmanualSec').slideDown('fast');
          }
      });

  });
  }

}
