import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-pendingrenewal',
  templateUrl: './pendingrenewal.component.html',
  styleUrls: ['./pendingrenewal.component.css']
})
export class PendingrenewalComponent implements OnInit {

    servicelog;
    itemsPerPage:any=15;
   currentPage:number =1;
     filterform:FormGroup;
   submitted=false;
   today= new Date();
   jstoday = '';
   constructor(private http:HttpClient,private fb:FormBuilder) { 
 
  this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
     this.filterform = this.fb.group({
     
       fromdate: '',
       todate: '',
       service_type:[''],
       
     })
 
   }
 
   ngOnInit() {
  
 this.getdata(this.currentPage,'','','')

   }
 
 onSubmit()
 {
    this.submitted=true;
   //console.log(this.filterform.value);
  
    let  service_type=this.filterform.value.service_type;
     let fromdate=$('#fromdate').val();
    let todate=$('#todate').val()
   
    $('.fullpageloader').show('fast');
     this.http.get(' https://api.cerebra.quadrafort.in/pending_renewal?servicetype='+service_type+'&fromdate='+fromdate+'&todate='+todate).subscribe(
     (data)=>{
       this.servicelog=data;
         $('.cfillBox').slideUp('fast');
        $('.fullpageloader').hide('fast');
 
     })
 
 }
 
   getdata(crpage:number,service_type,fromdate,todate)
   {
     $('.fullpageloader').show('fast');
      this.http.get(' https://api.cerebra.quadrafort.in/pending_renewal?page='+crpage+'&servicetype='+service_type+'&fromdate='+fromdate+'&todate='+todate).subscribe(
      (data)=>{
         this.servicelog=data;
         $('.fullpageloader').hide('fast');
      });
   }
 
    pageChange(newpage:number){
      let  service_type=this.filterform.value.service_type;
      let fromdate=$('#fromdate').val();
     let todate=$('#todate').val()
     this.getdata(newpage,service_type,fromdate,todate);
     this.currentPage = newpage;
       }
}
