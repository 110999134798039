import { Component, OnInit,OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-livechannel',
  templateUrl: './livechannel.component.html',
  styleUrls: ['./livechannel.component.css']
})
export class LivechannelComponent implements OnInit {
  Testcall:FormGroup;
  submitted:boolean = false;
  subtypesec:boolean = false;
  baseurl:any;
  networkList:any;
  outcliList:any;
  currentUserloclstrage:any;
  session_id:any;
  serverList:any;
  chaneldetail:any;
   refresher:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {

  
  }

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.session_id =  this.currentUserloclstrage.user_id;
    this.getc2cserver();
    this.createform();
      }
    
      createform(){
        this.Testcall= this.fb.group({
          server:['',[Validators.required]],
          network:['',[Validators.required]],
          refresh_time:['',[Validators.required]],
        
      })
      }
    
    
      get f(){ return this.Testcall.controls;}
      onSubmit(){
      this.submitted = true;
       var testcall= JSON.stringify(this.Testcall.value);
       var server = this.Testcall.value.server;
       var network = this.Testcall.value.network;
       var refresh_time = this.Testcall.value.refresh_time;
        if(this.Testcall.invalid){
       return false;
        }
        else{
    
      this.http.get(this.baseurl+"check_channel?host="+server+"&network="+network).subscribe((data:any)=>{
           this.chaneldetail=data;
           if(data.status=="success"){
          //    this.refresher= setInterval(() => {
          //   this.onSubmit();
          // }, refresh_time*1000);
            
           }
           else if(data.status=="fail"){
             $(".fullpageloader").fadeOut('fast');
            this.toastr.error(data.message, 'Error!',{
               positionClass:'toast-top-center',
               timeOut: 3000
             });
           
          }
          
        },
         error => {
           $(".fullpageloader").fadeOut('fast');
           this.toastr.error(error, 'error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
          });
      })
        }
      }
    
    
      getc2cnetwork(ip){
        $(".fullpageloader").fadeIn('fast');
        this.http.post(this.baseurl+'get_c2c_network',{ip:ip,session_id:this.session_id}).subscribe((data:any)=>{
         this.networkList= data.network_list;
         
       $(".fullpageloader").fadeOut('fast');
      })
      }
      getc2cserver(){
        $(".fullpageloader").fadeIn('fast');
        this.http.post(this.baseurl+'get_c2c_server',{session_id:this.session_id}).subscribe((data:any)=>{
         this.serverList= data.server_ip;
    
         $(".fullpageloader").fadeOut('fast');
      })
      }
      changeserver(event){
       this.getc2cnetwork(event.target.value);
      }
      // ngOnDestroy() {
      //   if (this.refresher) {
      //      clearInterval(this.refresher);
      //      }
      
      //  }
    

}
