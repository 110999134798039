import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-msvmanageservice',
  templateUrl: './msvmanageservice.component.html',
  styleUrls: ['./msvmanageservice.component.css']
})
export class MsvmanageserviceComponent implements OnInit {
  vmanageForm:FormGroup;
  demorenewform:FormGroup;
  submitted:boolean = false;
  submitted2:boolean = false;
  user_id:any;
  subtype:any;
  stype:any;
  buttunhide:boolean=false;
  acctype=['Demo','Premium'];
  currentUserloclstrage:any;
  baseurl:any;
  account_id:any;
  records:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
  this.user_id = this.route.snapshot.paramMap.get("userid");
  this.stype = this.route.snapshot.paramMap.get("stype");


}

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.baseurl = localStorage.getItem('baseurl');
    this.creatForm();
    this.creatrenwaldemoform();
    this.getdata();

  }

  creatForm(){
    this.vmanageForm= this.fb.group({
      companyname:[{ value: '', disabled: true },Validators.required],
      fname:[{ value: '', disabled: true },Validators.required],
      lname:[{ value: '', disabled: true }],
      mobile:[{ value: '', disabled: true },[Validators.required,Validators.maxLength(10),Validators.minLength(10)]],
      email:[{ value: '', disabled: true },[Validators.required,Validators.email]],
      password:[{ value: '', disabled: true },[Validators.required]],
      address:[{ value: '', disabled: true }],
      pincode:[{ value: '', disabled: true }],
      country:[{ value: '', disabled: true }],
      state:[{ value: '', disabled: true }],
      city:[{ value: '', disabled: true }],
      salesexec:[{ value: '', disabled: true }],
      tsalesexec:[{ value: '', disabled: true }],
      vnumber:[{ value: '', disabled: true }],
      acctype:[{ value: '', disabled: true }],
      validity:[{ value: '', disabled: true },[Validators.required]],
      remarks:[{ value: '', disabled: true }],
      userid:[],
      stype:[],
    })

  }
getdata(){
  $(".fullpageloader").fadeIn('fast');
  this.http.get(" https://api.cerebra.quadrafort.in/get_service_details?user_id="+this.user_id+"&type="+this.stype).subscribe((data:any)=>{
 this.account_id=data.profile[0].account_id;
 this.records=data;
  console.log(data);
  $(".fullpageloader").fadeOut('fast');
this.vmanageForm.setValue({
  companyname:data.profile[0].company,
  fname:data.profile[0].fname+" "+data.profile[0].lname,
  lname:'',
  mobile:data.profile[0].mobile,
  email:data.profile[0].email,
  password:data.profile[0].password,
  address:data.profile[0].address,
  pincode:data.profile[0].pin,
  country:data.profile[0].country,
  state:data.profile[0].state,
  city:data.profile[0].city,
  salesexec:data.profile[0].sale_exc_name,
  tsalesexec:data.profile[0].telesale_exc_name,
  vnumber:data.profile[0].virtual_number,
  acctype:data.profile[0].account_type,
  //validity:data.profile[0].expiry_date,
   validity:this.fromJsonDate(data.profile[0].expiry_date),
  remarks:data.profile[0].remarks,
  userid:data.profile[0].user_id,
  stype:this.stype,
})
})

}

fromJsonDate(jDate): string {
  const bDate: Date = new Date(jDate);
  return bDate.toISOString().substring(0, 10);  //Ignore time
}
  get f(){ return this.vmanageForm.controls;}
  onSubmit(){
  this.submitted = true;
  this.vmanageForm.get('validity').setValue($("#validitydate").val());
    var vmanagedata= JSON.stringify(this.vmanageForm.value);

    if(this.vmanageForm.invalid){
return false;
    }
    else{
 $(".fullpageloader").fadeIn("fast");
     this.http.post(" https://api.cerebra.quadrafort.in/update_service",{data:vmanagedata}).subscribe((data:any)=>{
      if(data.status=="success"){
        $(".fullpageloader").fadeOut('fast');
          this.toastr.success('Data Submitted  succesfully!', 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        this.router.navigateByUrl('/servicelist');
        
      }
      else if(data.status=="fail"){
        $(".fullpageloader").fadeOut('fast');
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
       
      }
      
    },
    error => {
      $(".fullpageloader").fadeOut('fast');
      this.toastr.error(error, 'error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  })
    }
  }
  clickEventHandler(){
    this.vmanageForm.get('companyname').enable();
    this.vmanageForm.get('fname').enable();
    this.vmanageForm.get('lname').enable();
    this.vmanageForm.get('mobile').enable();
    this.vmanageForm.get('email').enable();
    this.vmanageForm.get('password').enable();
    this.vmanageForm.get('address').enable();
    this.vmanageForm.get('pincode').enable();
    this.vmanageForm.get('country').enable();
    this.vmanageForm.get('state').enable();
    this.vmanageForm.get('city').enable();
    this.vmanageForm.get('vnumber').enable();
    this.vmanageForm.get('acctype').enable();
    this.vmanageForm.get('validity').enable();
    this.vmanageForm.get('remarks').enable();
    this.buttunhide = true;
  }
  creatrenwaldemoform(){
    this.demorenewform = this.fb.group({
      renew_type:[{value:''},[Validators.required]],
      remarks:[{value:''},[Validators.required]],
      user_id:this.user_id,
      service_type:'vmanage',
      loginid:this.currentUserloclstrage.user_id
    })
    }
    get h(){
      return this.demorenewform.controls
    }
    demorenew(){
     
      this.submitted2 = true;
      let renewdata= JSON.stringify(this.demorenewform.value);
      if(this.demorenewform.invalid){
    return false
      }
      else{
      
        $(".fullpageloader").fadeIn('fast');
        this.http.post(this.baseurl+"demo_renewal",{data:renewdata}).subscribe((data:any)=>{
          console.log(data);
          if(data.status=="success"){
            $("#renewdemo").modal('hide');
            $(".fullpageloader").fadeOut('fast');
              this.toastr.success(data.message, 'Success!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
        this.demorenewform.patchValue({
          renew_type:"",
          remarks:"",
          user_id:this.user_id,
          service_type:'vmanage',
          loginid:this.currentUserloclstrage.user_id
        })
            }
          else if(data.status=="fail"){
            $(".fullpageloader").fadeOut('fast');
           this.toastr.error(data.message, 'Error!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
           
          }
        },
        error => {
         $(".fullpageloader").fadeOut('fast');
          this.toastr.error(error, 'error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
      });
      }
    }
}
