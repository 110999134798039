import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';
@Component({
  selector: 'app-virtualnumreport',
  templateUrl: './virtualnumreport.component.html',
  styleUrls: ['./virtualnumreport.component.css']
})
export class VirtualnumreportComponent implements OnInit {
  

  servicelog;
  itemsPerPage:any=15;
 currentPage:number =1;
   filterform:FormGroup;
 submitted=false;
 today= new Date();
 jstoday = '';
 baseurl:any;
 searchForm:FormGroup;
  submitted1:boolean=false;
 constructor(private http:HttpClient,private fb:FormBuilder) { 

this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
   this.filterform = this.fb.group({
    fromdate: '',
     todate:'',
     companyname:[''],
     username:['']
   })

 }

 ngOnInit() {
  this.baseurl = localStorage.getItem('baseurl');
this.getdata(this.currentPage,'','','','')
$("#reptype").on('change',function(){
 //alert($(this).val());
 switch($(this).val()){
   case 'bycompanyname':
 $("#bycompanyname").show('fast');
 $("#byusername").hide('fast');
 break;
 case 'byusername':
 $("#byusername").show('fast');
 $("#bycompanyname").hide('fast');
 break;
 default:
$("#byusername,#bycompanyname").hide('fast');

 }
})
this.createsearchform();

   $(".searchlist a").click(function(e) {
    $(".slistdrwn").slideToggle('fast');
   });

 
   $(".slistdrwn li").on('click',function(e) {
 
      var sval = "Enter" + " " + $(this).text();
      $(".searchinput").attr('placeholder', sval);
       $(".searchinput1").val($(this).text());
       $(".slistdrwn").slideUp('fast');
       
 });
 }

onSubmit()
{
  this.submitted=true;
 //console.log(this.filterform.value);

  let  companyname=this.filterform.value.companyname;
   let  userid=this.filterform.value.username;
  let fromdate=$('#fromdate').val();
  let todate=$('#todate').val()
 
  $('.fullpageloader').show('fast');
   this.http.get(this.baseurl+'virtual_number_report?companyname='+companyname+'&fromdate='+fromdate+'&todate='+todate+'&userid='+userid).subscribe(
   (data)=>{
     this.servicelog=data;
       $('.cfillBox').slideUp('fast');
      $('.fullpageloader').hide('fast');

   })

}

createsearchform(){
  this.searchForm = this.fb.group({
      searchtext:['',[Validators.required]],
      searchinput:[''],
     
   })
  }
  get g(){return this.searchForm.controls;}
searchFilter(searchdata:any){
this.submitted1= true;
if(this.searchForm.invalid){
$("#forminvalid").fadeIn(200).fadeOut(6000);
$(".searchinput").css('border-color','#ff0000');
setTimeout(() => {
$(".searchinput").css('border-color','#b8b8b8');
}, 6000);
 return false;
}
else{
  var searchtext = searchdata.searchtext;
 var searchtype = $(".searchinput1").val();
 var stype = 'vco';
  $('.fullpageloader').show('fast');
 this.http.get(this.baseurl+"virtual_number_report?type="+stype+"&searchtext="+searchtext+"&searchtype="+searchtype).subscribe(
    (result:any)=>{
    this.servicelog=result;
    $('.fullpageloader').hide('fast');
   });
 
 
}

}


getdata(crpage:number,companyname,fromdate,todate,userid)
 {
  // alert("hi");
   $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'virtual_number_report?page='+crpage+'&companyname='+companyname+'&fromdate='+fromdate+'&todate='+todate+'&userid='+userid).subscribe(
    (data)=>{
       this.servicelog=data;
       $('.fullpageloader').hide('fast');
    });
 }

  pageChange(newpage:number){
    //alert(newpage);
    let  companyname=this.filterform.value.companyname;
    let  userid=this.filterform.value.username;
   let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
   this.getdata(newpage,companyname,fromdate,todate,userid);
   this.currentPage = newpage;
     }
}
