import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-adddidsingle',
  templateUrl: './adddidsingle.component.html',
  styleUrls: ['./adddidsingle.component.css']
})
export class AdddidsingleComponent implements OnInit {
  submitted:boolean = false;
  adddidForm:FormGroup;
  uid:any;
  operaters=['Airtel','Reliance','Vodafone','BSNL','IDEA','Tata Indicom','Aircel','MTNL','Spice','MTNL'];
  circles=['Andaman and Nicobar Islands','Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chandigarh','Chhattisgarh','Dadra and Nagar Haveli','Delhi','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu and Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Puducherry','Punjab','Rajasthan','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal'];
  didtype=['Demo','Premium'];
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute){
    this.uid = this.route.snapshot.paramMap.get("id");
    console.log(this.uid);
    if (this.uid !== null){
    this.editform(this.uid)
    }
  } 

  ngOnInit() {
   this.createForm();
  }
createForm(){
this.adddidForm = this.fb.group({
  didno:['',[Validators.required]],
  oprater:['',[Validators.required]],
  circle:['',[Validators.required]],
  didtype:['',[Validators.required]],
  uid:[''],
})
  }

  editform(id:number){
    $(".fullpageloder").show('fast');
  this.http.get(" https://api.cerebra.quadrafort.in/edit_did?uid="+id).subscribe((data:any)=>{
  //console.log(data);
  this.adddidForm.setValue({
    didno:data[0].did_no,
    oprater:data[0].operator,
    circle:data[0].circle,
    didtype:data[0].did_type,
    uid:data[0].uid,
  });
  $(".fullpageloder").hide('fast');
  })
  }
get f(){return this.adddidForm.controls}
onSubmit(){
this.submitted= true;
let addditformdata = JSON.stringify(this.adddidForm.value);
if(this.adddidForm.invalid){
return false;
}
else{
  $(".fullpageloader").fadeIn('fast');
  this.http.post(" https://api.cerebra.quadrafort.in/add_did_single",{data:addditformdata}).subscribe((data:any)=>{
   
    if(data.status=='success'){
      $(".fullpageloader").fadeOut('fast');
      this.toastr.success('Data Submitted  succesfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
     
      this.router.navigateByUrl('/numinventry/didinventry');
    }
    else if(data.status=="fail"){
      $(".fullpageloader").fadeOut('fast');
      console.log("fail");
      this.toastr.error(data.message, 'Error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
      
     }
  }, error => {
    $(".fullpageloader").fadeOut('fast');
    this.toastr.error(error, 'error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
  }
  )
  
  }
}
}
