import { Component, OnInit,ElementRef } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vconvoservice',
  templateUrl: './vconvoservice.component.html',
  styleUrls: ['./vconvoservice.component.css']
})
export class VconvoserviceComponent implements OnInit {

  buttunhide:boolean=false;
    vagentForm:FormGroup;
    submitted:boolean = false;
    service_id:any;
    settings:FormArray;
    costomizedplan:boolean=false;
    smsacount:boolean=false;
    minavopersonnel:boolean=false;
    crmsettsec:boolean=false;
    records:any;
    clidata:any;
    arr=[];
    didlist:boolean = true;
    bckdidlist:boolean = true;
    otherlangcheck:boolean= false;
    freesetext ='Freeze';

   // didnumberbox:boolean = false;
  //  bkpdidnumberbox:boolean = false;
   // virtualnumberbox:boolean = false;
    virtualnumberradioBtn:boolean = true;
    virtualnumbersec:boolean=true;
    resselerList:boolean=false;
    capturecall:boolean=false;
    click2call:boolean=false;
    agentpopup:boolean=false;
    callrouting:boolean=false;
    singlepopup:boolean=false;
    calldisposition:boolean=false;
    allcationtype:boolean=false;
    personalsmscoin:boolean=false;
    channelallocation:boolean=false;
    accList:boolean = false;
    nubmerfreezed;
    backupdid;
    virtualnumber;
    didstatus:boolean=false;
    didnetworkcli:any;
    didnetworkcli1:any;

    industryList=['Education','Health Care','Banking','Beauty Care','Grocery','Government','Politics','Hotel/Guest House','NGO','Other'];

    constructor(private elment: ElementRef, private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
    this.service_id = this.route.snapshot.paramMap.get("service_id");
  }

  ngOnInit() {
  //alert(this.records.data[0].ibd_call_rate);

this.createForm();
this.getdata();
this.sameasdid();
$(function() {

    //   $( document ).on( 'focus', ':input', function(){
    //     $( this ).attr( 'autocomplete', 'off' );
    // });

 $(".acchead").click(function() {
          $(this).next('.accbox').slideToggle('fast');
          $(this).toggleClass('active');
      });

  });

  this.vagentForm.get('admincheckbox').valueChanges
  .subscribe(admincheckbox => {
if(admincheckbox==true){
  this.vagentForm.get('adminsetting').get('name').setValue(this.records.data[0].contact_person);
  this.vagentForm.get('adminsetting').get('email').setValue(this.records.data[0].contact_email);
  this.vagentForm.get('adminsetting').get('mobile').setValue(this.records.data[0].mobile);

this.vagentForm.get('adminsetting').get('name').updateValueAndValidity();
this.vagentForm.get('adminsetting').get('email').updateValueAndValidity();
this.vagentForm.get('adminsetting').get('mobile').updateValueAndValidity();
}
else{
  this.vagentForm.get('adminsetting').get('name').setValue('');
  this.vagentForm.get('adminsetting').get('email').setValue('');
  this.vagentForm.get('adminsetting').get('mobile').setValue('');

this.vagentForm.get('adminsetting').get('name').updateValueAndValidity();
this.vagentForm.get('adminsetting').get('email').updateValueAndValidity();
this.vagentForm.get('adminsetting').get('mobile').updateValueAndValidity();
}
  });

  this.vagentForm.get('dedplan').get('allcommon').valueChanges
   .subscribe(allcommon => {
  // this.vagentForm.get('dedplan').get('allcommon').value()==allcommon;

if(allcommon=="allcommon"){
    setTimeout(() => {
        this.vagentForm.get('dedplan').get('obd_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('ibd_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('email_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('sms_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('tf_ded').setValue('Common');
        this.vagentForm.get('dedplan').get('fax_ded').setValue('Common');
    }, 200);

this.vagentForm.get('dedplan').get('obd_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('ibd_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('email_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('sms_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('tf_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('fax_ded').updateValueAndValidity();

}
else if(allcommon=='allself'){
setTimeout(() => {
this.vagentForm.get('dedplan').get('obd_ded').setValue('Self');
this.vagentForm.get('dedplan').get('ibd_ded').setValue('Self');
this.vagentForm.get('dedplan').get('email_ded').setValue('Self');
this.vagentForm.get('dedplan').get('sms_ded').setValue('Self');
this.vagentForm.get('dedplan').get('tf_ded').setValue('Self');
this.vagentForm.get('dedplan').get('fax_ded').setValue('Self');
}, 200);

this.vagentForm.get('dedplan').get('obd_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('ibd_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('email_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('sms_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('tf_ded').updateValueAndValidity();
this.vagentForm.get('dedplan').get('fax_ded').updateValueAndValidity();
 }

})

this.vagentForm.get('smssetting').get('account_type').valueChanges
.subscribe(account_type => {
if(account_type=="Minavo"){
this.smsacount = false;
this.minavopersonnel  = false;
this.personalsmscoin = false;
//this.vagentForm.get('dedplan').get('fax_ded').setValue('Self');

this.vagentForm.get("smssetting").get("base_uri").setValidators(null);
this.vagentForm.get("smssetting").get("param_user_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_user_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_message_name").setValidators(null);
this.vagentForm.get("smssetting").get("personal_sms_coins").setValidators(null);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('personal_sms_coins').updateValueAndValidity();

}
else if(account_type=="personal"){
this.smsacount = true;
this.minavopersonnel  = false;
this.personalsmscoin = false;

this.vagentForm.get("smssetting").get("base_uri").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_message_name").setValidators([Validators.required]);

this.vagentForm.get("smssetting").get("base_uri").setValue(this.records.deafult_sms_details[0].base_uri);
this.vagentForm.get("smssetting").get("param_user_name").setValue(this.records.deafult_sms_details[0].param_user_name);
this.vagentForm.get("smssetting").get("param_user_value").setValue(this.records.deafult_sms_details[0].param_user_value);
this.vagentForm.get("smssetting").get("param_pass_name").setValue(this.records.deafult_sms_details[0].param_pass_name);
this.vagentForm.get("smssetting").get("param_pass_value").setValue(this.records.deafult_sms_details[0].param_pass_name);
this.vagentForm.get("smssetting").get("param_sid_name").setValue(this.records.deafult_sms_details[0].param_sid_name);
this.vagentForm.get("smssetting").get("param_sid_value").setValue(this.records.deafult_sms_details[0].param_sid_value);
this.vagentForm.get("smssetting").get("param_lang_name").setValue(this.records.deafult_sms_details[0].param_lang_name);
this.vagentForm.get("smssetting").get("param_lang_value").setValue(this.records.deafult_sms_details[0].param_lang_value);
this.vagentForm.get("smssetting").get("param_mobile_name").setValue(this.records.deafult_sms_details[0].param_mobile_name);
this.vagentForm.get("smssetting").get("param_message_name").setValue(this.records.deafult_sms_details[0].param_message_name);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
}
else if(account_type=="minavo-personal"){
this.personalsmscoin = true;
this.smsacount = true;
this.minavopersonnel  = true

this.vagentForm.get("smssetting").get("base_uri").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_message_name").setValidators([Validators.required]);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
 }
})

this.vagentForm.get('smssetting').get('minpertype').valueChanges
.subscribe(minpertype => {

   if(minpertype=='default'){
    this.vagentForm.get("smssetting").get("base_uri").setValue(this.records.deafult_sms_details[0].base_uri);
    this.vagentForm.get("smssetting").get("param_user_name").setValue(this.records.deafult_sms_details[0].param_user_name);
    this.vagentForm.get("smssetting").get("param_user_value").setValue(this.records.deafult_sms_details[0].param_user_value);
    this.vagentForm.get("smssetting").get("param_pass_name").setValue(this.records.deafult_sms_details[0].param_pass_name);
    this.vagentForm.get("smssetting").get("param_pass_value").setValue(this.records.deafult_sms_details[0].param_pass_name);
    this.vagentForm.get("smssetting").get("param_sid_name").setValue(this.records.deafult_sms_details[0].param_sid_name);
    this.vagentForm.get("smssetting").get("param_sid_value").setValue(this.records.deafult_sms_details[0].param_sid_value);
    this.vagentForm.get("smssetting").get("param_lang_name").setValue(this.records.deafult_sms_details[0].param_lang_name);
    this.vagentForm.get("smssetting").get("param_lang_value").setValue(this.records.deafult_sms_details[0].param_lang_value);
    this.vagentForm.get("smssetting").get("param_mobile_name").setValue(this.records.deafult_sms_details[0].param_mobile_name);
    this.vagentForm.get("smssetting").get("param_message_name").setValue(this.records.deafult_sms_details[0].param_message_name);

    this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();

   }
   else{
    this.vagentForm.get("smssetting").get("base_uri").setValue('');
    this.vagentForm.get("smssetting").get("param_user_name").setValue('');
    this.vagentForm.get("smssetting").get("param_user_value").setValue('');
    this.vagentForm.get("smssetting").get("param_pass_name").setValue('');
    this.vagentForm.get("smssetting").get("param_pass_value").setValue('');
    this.vagentForm.get("smssetting").get("param_sid_name").setValue('');
    this.vagentForm.get("smssetting").get("param_sid_value").setValue('');
    this.vagentForm.get("smssetting").get("param_lang_name").setValue('');
    this.vagentForm.get("smssetting").get("param_lang_value").setValue('');
    this.vagentForm.get("smssetting").get("param_mobile_name").setValue('');
    this.vagentForm.get("smssetting").get("param_message_name").setValue('');

    this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
   }
})

this.vagentForm.get('crmsetting').get('crm_name').valueChanges
.subscribe(crm_name => {
if(crm_name=='minavo'){
         this.crmsettsec=false;
        this.vagentForm.get('crmsetting').get('capture_call_status').setValue(0);
        this.vagentForm.get('crmsetting').get('click_tocall_status').setValue(0);
        this.vagentForm.get('crmsetting').get('agentpopup_status').setValue(0);
        this.vagentForm.get('crmsetting').get('callrouting_status').setValue(0);
        this.vagentForm.get('crmsetting').get('singlpopup_status').setValue(0);
        this.vagentForm.get('crmsetting').get('calldisposition_status').setValue(0);
  }
   else{
    this.crmsettsec=true;
    this.vagentForm.get('crmsetting').get('capture_call_status').setValue(1);
    this.vagentForm.get('crmsetting').get('click_tocall_status').setValue(1);
    this.vagentForm.get('crmsetting').get('agentpopup_status').setValue(1);
    this.vagentForm.get('crmsetting').get('callrouting_status').setValue(1);
    this.vagentForm.get('crmsetting').get('singlpopup_status').setValue(1);
    this.vagentForm.get('crmsetting').get('calldisposition_status').setValue(1);

   }
})

// this.vagentForm.get('plandetail').get('select_server').valueChanges
// .subscribe(select_server => {

//    if(select_server=='NA'){
//       this.clidata=['NA'];
//      setTimeout(() => {
//       this.vagentForm.get('plandetail').get('outcall_cli').setValue("NA");
//       this.vagentForm.get('plandetail').get('outcall_cli').updateValueAndValidity();
//      }, 300);

//    }

//    else{
//     this.clidata=  this.records.cli.map(cli => cli.obd_cli);
//    }
// })

this.vagentForm.get('plandetail').get('acc_owner').valueChanges
.subscribe(acc_owner => {
if(acc_owner=='Minavo'){
      this.resselerList= false;
      this.vagentForm.get('plandetail').get('resselerlist').setValidators(null);
      this.vagentForm.get('plandetail').get('resselerlist').updateValueAndValidity();
   }
else{
  this.resselerList= true;
  this.vagentForm.get('plandetail').get('resselerlist').setValidators([Validators.required]);
  this.vagentForm.get('plandetail').get('resselerlist').updateValueAndValidity();
   }

})
this.vagentForm.get('clientdetail').get('acc_type').valueChanges
.subscribe(acc_type => {
   if(acc_type=='new'){
      this.accList= false;
      this.vagentForm.get('clientdetail').get('acc_list').setValidators(null);
      this.vagentForm.get('clientdetail').get('acc_list').updateValueAndValidity();
   }
else{
  this.accList= true;
  this.vagentForm.get('clientdetail').get('acc_list').setValidators([Validators.required]);
  this.vagentForm.get('clientdetail').get('acc_list').updateValueAndValidity();
   }
})

this.vagentForm.get('crmsetting').get('capture_call_status').valueChanges
.subscribe(capture_call_status => {
  if(capture_call_status==1){
  this.capturecall = true;
  this.vagentForm.get('crmsetting').get('capture_call_input').setValidators([Validators.required]);

}
else{
this.capturecall = false;
this.vagentForm.get('crmsetting').get('capture_call_input').setValidators(null);
this.vagentForm.get('crmsetting').get('capture_call_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('click_tocall_status').valueChanges
.subscribe(click_tocall_status => {

if(click_tocall_status==1){
  this.click2call = true;
  this.vagentForm.get('crmsetting').get('click_tocall_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('click_tocall_input').updateValueAndValidity();
}
else{
  this.click2call = false;
  this.vagentForm.get('crmsetting').get('click_tocall_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('click_tocall_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('agentpopup_status').valueChanges
.subscribe(agentpopup_status => {
if(agentpopup_status==1){
  this.agentpopup = true;
  this.vagentForm.get('crmsetting').get('agentpopup_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('agentpopup_input').updateValueAndValidity();
}
else{
  this.agentpopup = false;
  this.vagentForm.get('crmsetting').get('agentpopup_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('agentpopup_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('callrouting_status').valueChanges
.subscribe(callrouting_status => {
 if(callrouting_status==1){
  this.callrouting = true;
  this.vagentForm.get('crmsetting').get('callrouting_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('callrouting_input').updateValueAndValidity();
}
else{
  this.callrouting = false;
  this.vagentForm.get('crmsetting').get('callrouting_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('callrouting_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('singlpopup_status').valueChanges
.subscribe(singlpopup_status => {
  if(singlpopup_status==1){
  this.singlepopup = true;
  this.vagentForm.get('crmsetting').get('singlpopup_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('singlpopup_input').updateValueAndValidity();
}
else{
  this.singlepopup = false;
  this.vagentForm.get('crmsetting').get('singlpopup_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('singlpopup_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('calldisposition_status').valueChanges
.subscribe(calldisposition_status => {
 if(calldisposition_status==1){
  this.calldisposition = true;
  this.vagentForm.get('crmsetting').get('calldisposition_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('calldisposition_input').updateValueAndValidity();
}
else{
  this.calldisposition = false;
  this.vagentForm.get('crmsetting').get('calldisposition_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('calldisposition_input').updateValueAndValidity();
}
})

this.vagentForm.get('newsetting').get('allocation_type').valueChanges
.subscribe(allocation_type => {
 if(allocation_type=='self'){
  this.allcationtype = true;
}
else{
  this.allcationtype = false;
}
})

  }

createForm(){

this.vagentForm = this.fb.group({
    clientdetail:this.fb.group({

        acc_type:[{value:''},[Validators.required]],
        acc_list:[{value:''}],
        company:[{value:''},[Validators.required]],
        first_name:[{value:''},[Validators.required]],
        last_name:[{value:''}],
        designation:[{value:''},[Validators.required]],
        industry:[{value:''},[Validators.required]],
        comp_size:[{value:''}],
        mobile:[{value:''},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
        email:[{value:''},[Validators.required,Validators.email]],
        password:[{value:''},[Validators.required]],
        address_fl:[{value:''}],
        address_ll:[{value:''}],
        pin:[{value:''}],
        country:[{value:'India'},[Validators.required]],
        service_name:[{value:''},[Validators.required]],
        state:[{value:''}],
        city:[{value:''}],
        sale_exc_name:[{value:''},[Validators.required]],
        telesale_exc_name:[{value:''}]
    }),
    adminsetting:this.fb.group({
      name:[{value:''},[Validators.required]],
      email:[{value:''},[Validators.required,Validators.email]],
      mobile:[{value:''},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]]
    }),
   dedplan:this.fb.group({
        allcommon:[{value:''},[Validators.required]],
        obd_ded:[{value:''},[Validators.required]],
        ibd_ded:[{value:''},[Validators.required]],
        email_ded:[{value:''},[Validators.required]],
        sms_ded:[{value:''},[Validators.required]],
        tf_ded:[{value:''},[Validators.required]],
        fax_ded:[{value:''},[Validators.required]],
     }),
    plandetail:this.fb.group({
         contract_period:[{value:''},[Validators.required]],
         billing_type:[{value:''},[Validators.required]],
         postpaid_limit:[{value:''}],
         plan_name:[{value:''},[Validators.required]],
         plan_price:[{value:''},[Validators.required]],
         plan_term:[{value:''},[Validators.required]],
         total_coins:[{value:''},[Validators.required]],
         virtual_ownership:[{value:''},[Validators.required]],
         type_vnum:[{value:''},[Validators.required]],
         free_chanel:[{value:''},[Validators.required]],

         ibd_coins:[{value:''},[Validators.required]],
         obd_coins:[{value:''},[Validators.required]],
         sms_coins:[{value:''},[Validators.required]],
         email_coins:[{value:''},[Validators.required]],
         fac_coins:[{value:''},[Validators.required]],
         tf_coins:[{value:''},[Validators.required]],
         ibd_ded_sec:[{value:''},[Validators.required]],
         ibd_ded_rate:[{value:''},[Validators.required]],
         obd_ded_sec:[{value:''},[Validators.required]],
         obd_ded_rate:[{value:''},[Validators.required]],
         sms_ded__cnt:[{value:''},[Validators.required]],
         smsded_rate:[{value:''},[Validators.required]],
         tf_ded_cnt:[{value:''},[Validators.required]],
         tf_ded_rate:[{value:''},[Validators.required]],
         email_ded_cnt:[{value:''},[Validators.required]],
         email_ded_rate:[{value:''},[Validators.required]],
         fax_ded_cnt:[{value:''},[Validators.required]],
         fax_ded_rate:[{value:''},[Validators.required]],
         c2c_rate:[{value:''},[Validators.required]],
         sub_type:[{value:''},[Validators.required]],
         call_rate:[{value:''},[Validators.required]],
         c2c_ded_sec:[{value:''},[Validators.required]],
         virtual_number:[{value:''}],
         middle_mobile:[{value:''},[Validators.required]],
         operator:[{value:''},[Validators.required]],
         service_billing:[{value:''},[Validators.required]],
         ivr_script:[{value:''}],
         ivr_type:[{value:''},[Validators.required]],
         accounttype:[{value:''},[Validators.required]],
         acc_owner:[{value:''},[Validators.required]],
         resselerlist:[{value:''},[Validators.required]],
         did_no:[{value:''},[Validators.required]],
         //checkalldidbckdid:[{value:''},[Validators.required]],
         did_no_bk:[{value:''}],
         did_network:[{value:''},[Validators.required]],
         did_bkp_network:[{value:''}],
         did_outcall_cli:[{value:''}],
         bkpdid_outcall_cli:[{value:''}],
         did_network_input:[{value:''}],
         bkpdid_network_input:[{value:''}],
       //  select_server:[{value:''}],
        // outcall_cli:[{value:''}]
 }),
 smssetting:this.fb.group({
account_type:[{value:''},[Validators.required]],
minpertype:[{value:''},[Validators.required]],
base_uri:[{value:''},[Validators.required]],
param_user_name:[{value:''},[Validators.required]],
param_user_value:[{value:''},[Validators.required]],
param_pass_name:[{value:''},[Validators.required]],
param_pass_value:[{value:''},[Validators.required]],
param_sid_name:[{value:''},[Validators.required]],
param_sid_value:[{value:''},[Validators.required]],
param_lang_name:[{value:''},[Validators.required]],
param_lang_value:[{value:''},[Validators.required]],
param_mobile_name:[{value:''},[Validators.required]],
param_message_name:[{value:''},[Validators.required]],
personal_sms_coins:[{value:''},[Validators.required]],
}),

crmsetting:this.fb.group({
    crm_name:[{value:''},[Validators.required]],
    //crmsett:[{value:'', disabled:false},[Validators.required]],
    capture_call_status:[{value:''}],
    capture_call_input:[{value:''},[Validators.required]],
    click_tocall_status:[{value:''}],
    click_tocall_input:[{value:''},[Validators.required]],
    agentpopup_status:[{value:''}],
    agentpopup_input:[{value:''},[Validators.required]],
    callrouting_status:[{value:''}],
    callrouting_input:[{value:''},[Validators.required]],
    singlpopup_status:[{value:''}],
    singlpopup_input:[{value:''},[Validators.required]],
    calldisposition_status:[{value:''}],
    calldisposition_input:[{value:''},[Validators.required]],
}),
newsetting:this.fb.group({
  billing_check:'0',
  allocation_type:'self',
  total_channel:[{value:''},[Validators.required]],
  ibd_channel:[{value:''},[Validators.required]],
  obd_channel:[{value:''},[Validators.required]],
  coin_allocation:[{value:''},[Validators.required]],
  }),
finance_remarks:[{value:''},[Validators.required]],
remarks:[{value:''},[Validators.required]],
caf_id:'',
finance_code:'',
service_id:'',
service_type:'',
sub_service_type:'',
admincheckbox:''
})
}

BuildFormDynamic(sett:any):FormGroup{
    return this.fb.group({
     settname:[sett.settname],
     labelname:[sett.labelname],
     settvalue:0,
     })
   }

get f() { return this.vagentForm.controls }
onSubmit(){

  //  console.log(this.f.clientdetail.controls['company'].errors);
    this.submitted = true;
    let vagentdata =  JSON.stringify(this.vagentForm.value);
 if(this.vagentForm.invalid){
if (!this.vagentForm.valid) {
    let target;

    target = this.elment.nativeElement.querySelector('.is-invalid')

    if (target) {
        $('html,body').animate({ scrollTop: $(target).offset().top }, 'slow');
        target.focus();
    }
}
   //alert("form invalid");
return false;
 }

  else{
    //$(".fullpageloader").fadeIn("fast");
        this.http.post(" https://api.cerebra.quadrafort.in/service_request_deatils_insert",{data:vagentdata}).subscribe((data:any)=>{
         if(data.status=="success"){
           $(".fullpageloader").fadeOut('fast');
             this.toastr.success('Data Submitted  succesfully!', 'Success!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
           this.router.navigateByUrl('/crequest/servicerequest');
           }
         else if(data.status=="fail"){
         //  $(".fullpageloader").fadeOut('fast');
          this.toastr.error(data.message, 'Error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });

         }

       },
       error => {
       //  $(".fullpageloader").fadeOut('fast');
         this.toastr.error(error, 'error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
     })
       }

}

getdata(){
    $(".fullpageloader").fadeIn('fast');

    this.http.get(" https://api.cerebra.quadrafort.in/service_request_deatils?service_id="+this.service_id).subscribe((data:any)=>{
    this.records=data;
    console.log(data.data[0].service_id);
    //console.log(data);
    if(data.data[0].service_subtype.toLowerCase()=='both unlimited' || data.data[0].service_subtype.toLowerCase()=='missed call unlimited'){
      setTimeout(() => {
        this.vagentForm.get('plandetail').get('total_coins').setValue('500000');
        this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
      }, 1200);
    }
else{
  if(data.data[0].amount_type.toLowerCase()=='rental' || data.data[0].billing.toLowerCase()=='postpaid' ||  data.data[0].service_subtype.toLowerCase()=='ibd unlimited' || data.data[0].service_subtype.toLowerCase()=='obd unlimited'|| data.data[0].service_subtype.toLowerCase()=='both unlimited'){
    setTimeout(() => {
      this.vagentForm.get('plandetail').get('total_coins').setValue('0');
      this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
    }, 1200);
    }
    else{
      this.calculatecoin(this.records.data[0].ibd_call_rate,this.records.data[0].obd_call_rate,this.records.data[0].allocation_type,this.records.data[0].sms_type);
    }
}

    //  console.log(data.setting);

    $(".fullpageloader").fadeOut('fast');
  this.vagentForm.patchValue({
    clientdetail:{
        acc_type:'new',
        acc_list:'',
        company:data.data[0].company_name,
        first_name:data.data[0].contact_person,
        last_name:'',
        designation:data.data[0].designation,
        industry:data.data[0].industry,
        comp_size:'',
        mobile:data.data[0].mobile,
        email:data.data[0].email,
        password:'',
        address_fl:data.data[0].address1,
        address_ll:data.data[0].address2,
        pin:data.data[0].pincode,
        country:"India",
        service_name:'',
        state:data.data[0].state,
        city:data.data[0].city,
        sale_exc_name:data.data[0].sale_person,
        telesale_exc_name:data.data[0].telesale_person
    },
    adminsetting:{
      name:'',
      email:'',
      mobile:'',
     },
    dedplan:{
        allcommon:this.getallcommon(data.data[0].coins),
        obd_ded:'' ,
        ibd_ded:'',
        email_ded:'',
        sms_ded:'',
        tf_ded:'',
        fax_ded:'',
    },
    plandetail:{
        contract_period:data.data[0].contract,
        billing_type:data.data[0].billing,
        postpaid_limit:data.data[0].postpaid_limit,
        plan_name:data.data[0].plan_name,
        plan_price:data.data[0].service_amount,
        plan_term:data.data[0].service_validity,
        total_coins:'0',
        virtual_ownership:data.data[0].owner_of_number,
        type_vnum:data.data[0].typeofnumber,
        free_chanel:data.data[0].channel,
        ibd_coins:'0',
        obd_coins:'0',
        sms_coins:function(rate,cost){
          let rrate:number =parseInt(rate);
          let ccost:number =parseInt(cost);
          let total:number= (ccost*100)/rrate;
        if(ccost==0){
         return '0';
        }
        else{
         return Math.floor(total);
        }

         }(data.data[0].sms_rate,data.data[0].call_end_sms_cost),
        email_coins:'0',
        fac_coins:'0',
        tf_coins:'0',
        ibd_ded_sec:data.data[0].ibd_call_pulse,
        ibd_ded_rate:function(ibdpluse){
          if(ibdpluse==0){
            return "0";
          }
           else{
            return "1";
          }
        }(data.data[0].ibd_call_pulse),
        obd_ded_sec:data.data[0].obd_call_pulse,
        obd_ded_rate:function(obdpluse){
          if(obdpluse==0){
            return "0";
                      }
        else{
            return "1";
          }
        }(data.data[0].obd_call_pulse),
        sms_ded__cnt:function(smscount){
          if(smscount>0){
return '1'
          }
          else{
            return '0'
          }
        }(data.data[0].sms_rate),
        smsded_rate:function(smscount){
          if(smscount>0){
return '1'
          }
          else{
            return '0'
          }
        }(data.data[0].sms_rate),
        tf_ded_cnt:'0',
        tf_ded_rate:'0',
        email_ded_cnt:'0',
        email_ded_rate:'0',
        fax_ded_cnt:'0',
        fax_ded_rate:'0',
        c2c_rate:'1',
        sub_type:data.data[0].sms_type,
        call_rate:function(tibd,tobd,ibdcallrate,obdcallrate){
          if(tibd >0 || tobd >0){
           if(tibd>0){
             return tibd;
             }
           else{
            return tobd;
             }
          }
          else{
           if(ibdcallrate>0){
             return ibdcallrate;
             }
           else{
            return obdcallrate;
             }
          }
           }(this.records.data[0.].topup_ibd_call_rate,this.records.data[0.].topup_obd_call_rate,this.records.data[0.].ibd_call_rate,this.records.data[0.].obd_call_rate),
        c2c_ded_sec:'60',
        virtual_number:data.data[0].virtual_number,
        middle_mobile:'',
        operator:data.data[0].operator_vir_no,
        service_billing:data.data[0].service_subtype,
        ivr_script:'',
        ivr_type:'NA',
        accounttype:'Premium',
        acc_owner:this.getOwner(data.data[0].type_of_client),
        resselerlist:'',//data.profile[0].account_type,
        did_no:'',
       // checkalldidbckdid:'',
        did_no_bk:'',
        did_network:'',
        did_bkp_network:'',
        did_outcall_cli:'',
        bkpdid_outcall_cli:'',
        did_network_input:'',
        bkpdid_network_input:'',
       // select_server:'NA',
       // outcall_cli:''
    },
    smssetting:{
        account_type:'Minavo',
        minpertype:'default',
        base_uri:data.deafult_sms_details[0].base_uri,
        param_user_name:data.deafult_sms_details[0].param_user_name,
        param_user_value:data.deafult_sms_details[0].param_user_value,
        param_pass_name:data.deafult_sms_details[0].param_pass_name,
        param_pass_value:data.deafult_sms_details[0].param_pass_value,
        param_sid_name:data.deafult_sms_details[0].param_sid_name,
        param_sid_value:data.deafult_sms_details[0].param_sid_value,
        param_lang_name:data.deafult_sms_details[0].param_lang_name,
        param_lang_value:data.deafult_sms_details[0].param_lang_value,
        param_mobile_name:data.deafult_sms_details[0].param_mobile_name,
        param_message_name:data.deafult_sms_details[0].param_message_name,
        personal_sms_coins:'',

    },
    newsetting:{
      billing_check:'0',
      allocation_type:'self',
      total_channel:data.data[0].channel+data.data[0].obd_channel,
      ibd_channel:data.data[0].channel,
      obd_channel:data.data[0].obd_channel,
      coin_allocation:(function(allo_type){
return allo_type.toLowerCase();
      })(data.data[0].allocation_type),
    },
    crmsetting:{
    crm_name:'minavo',
    //crmsett:data.profile[0].crm_name,
    capture_call_status:'',
    capture_call_input:'',
    click_tocall_status:'',
    click_tocall_input:'',
    agentpopup_status:'',
    agentpopup_input:'',
    callrouting_status:'',
    callrouting_input:'',
    singlpopup_status:'',
    singlpopup_input:'',
    calldisposition_status:'',
    calldisposition_input:'',
    },
    finance_remarks:data.data[0].remarks,
    remarks:'',
    finance_code:data.data[0].finance_code,
    caf_id:data.data[0].caf_no,
    service_id:data.data[0].service_id,
     service_type:'VConvo',
     sub_service_type:'vco'

  })

  })

  }
  getallcommon(coins){
if(coins=='Common'){
return 'allcommon'
}
else if(coins=='Individual'){
return 'allself'
}
}
getOwner(owner){
    if(owner == 'Minavo Individual'){
return 'Minavo';
    }
    else{
return 'Reseller';
    }
}

  releasedid(){
this.didlist = false;
//this.didnumberbox= false;
  }
  freezedid(event,didvalue){

if(event.target.innerHTML=="Freeze" && didvalue!==''){
  this.http.get(" https://api.cerebra.quadrafort.in/freeze_vnum?didnum="+didvalue+"&type=did").subscribe((data:any)=>{
    //  console.log(data)
  });

   //this.vagentForm.get('plandetail').get('did_no').setAttribute('readonly', 'readonly');
      $("#freezelink").empty('');
      $("#freezelink").text('Unfreeze');

 }
    else{
      this.http.get(" https://api.cerebra.quadrafort.in/unfreeze_vnum?didnum="+didvalue+"&type=did").subscribe((data:any)=>{
        // console.log(data)
      });
     // this.vagentForm.get('plandetail').get('did_no').removeAttribute('readonly');
      $("#freezelink").empty('');
      $("#freezelink").text('Freeze');

    }

}

  releasebkpdid(){
this.bckdidlist = true;

//this.bkpdidnumberbox= false;
  }
  freezebkpdid(event,bkpdidvalue){
  this.didlist = true;
  if(event.target.innerHTML=="Freeze" && bkpdidvalue!==''){
// this.vagentForm.get('plandetail').get('did_no_bk').disable();
    $("#bkpdidlink").empty('');
    $("#bkpdidlink").text('Unfreeze');
    this.http.get(" https://api.cerebra.quadrafort.in/freeze_vnum?didnum="+bkpdidvalue+"&type=did").subscribe((data:any)=>{
   //   console.log(data)
  })
  }
  else{
  //  this.vagentForm.get('plandetail').get('did_no_bk').enable();
    $("#bkpdidlink").empty('');
    $("#bkpdidlink").text('Freeze');
    this.http.get(" https://api.cerebra.quadrafort.in/unfreeze_vnum?didnum="+bkpdidvalue+"&type=did").subscribe((data:any)=>{
     // console.log(data)
  })
  }
//this.didnumberbox= true;
  }
  releasevnumber(){
//this.virtualnumberbox = false;
this.virtualnumberradioBtn = true
  }
  freezevnumber(event,vnumber){
 if(event.target.innerHTML=="Freeze" && vnumber!==''){
      this.vagentForm.get('plandetail').get('virtual_number').disable();
      $("#vnumlink").empty('');
      $("#vnumlink").text('Unfreeze');
      this.http.get(" https://api.cerebra.quadrafort.in/freeze_vnum?didnum="+vnumber+"&type=vnum").subscribe((data:any)=>{
       // console.log(data)
    })
    }
    else{
      this.vagentForm.get('plandetail').get('virtual_number').enable();
      $("#vnumlink").empty('');
      $("#vnumlink").text('Freeze');
      this.http.get(" https://api.cerebra.quadrafort.in/unfreeze_vnum?didnum="+vnumber+"&type=vnum").subscribe((data:any)=>{
       // console.log(data)
    })
    }

  }

  sameasdid(){
    this.virtualnumbersec=false;
    this.vagentForm.get("plandetail").get("virtual_number").setValidators(null);
    this.vagentForm.get('plandetail').get('virtual_number').setValue(this.vagentForm.get('plandetail').get('did_no').value);
    this.vagentForm.get('plandetail').get('virtual_number').updateValueAndValidity();
  }
  choosevirnum(){
    this.virtualnumbersec=true;
    this.vagentForm.get("plandetail").get("virtual_number").setValidators(null);
    this.vagentForm.get('plandetail').get('virtual_number').setValue(this.records.data[0] && this.records.data[0].virtual_number);
    this.vagentForm.get('plandetail').get('virtual_number').updateValueAndValidity();
  }
  changeCRM(crmvalue:any){
    if(crmvalue=='minavo'){
      this.crmsettsec=false;
    }
  else{
    this.crmsettsec=true;
  }

  }
editform(){
 this.vagentForm.enable();
this.buttunhide = true;
  }

  checkdid(event){
    $(".fullpageloader").fadeIn('fast');
    this.vagentForm.get('plandetail').get('did_network').setValue('');
    this.vagentForm.get('plandetail').get('did_network').updateValueAndValidity();
   this.vagentForm.get('plandetail').get('did_outcall_cli').setValue('');
   this.vagentForm.get('plandetail').get('did_outcall_cli').updateValueAndValidity();
   this.nubmerfreezed='';
   this.didnetworkcli='';
    //$("#did_network").empty();
   // $("#did_outcall_cli").empty();
    //$("#did_network").append('<option value="" selected>Select DID Network </option><option *ngFor="let did_ntwork of nubmerfreezed?.operator" value="{{did_ntwork.id}}">{{did_ntwork.available_network}}</option>');
    //$("#did_outcall_cli").append('<option value="" selected>Select Outcall Cli </option><option *ngFor="let pilotno of didnetworkcli?.pilot" value="{{pilotno.pilot_number}}">{{pilotno.pilot_number}}</option><option *ngFor="let diditem of didnetworkcli?.did" value="{{diditem.did}}">{{diditem.did}}</option>');


    this.http.post(" https://api.cerebra.quadrafort.in/check_vnum",{vnum:event.target.value}).subscribe((data:any)=>{
      $(".fullpageloader").fadeOut('fast');
 this.nubmerfreezed= data;
if(this.nubmerfreezed.status=='success'){
  $("#freezelink").prop('disabled',false);
  }
  else{
    $("#freezelink").prop('disabled',true);
  }
  })
}
checkbackupdid(event){
  $(".fullpageloader").fadeIn('fast');
  this.vagentForm.get('plandetail').get('did_bkp_network').setValue('');
  this.vagentForm.get('plandetail').get('did_bkp_network').updateValueAndValidity();
 this.vagentForm.get('plandetail').get('bkpdid_outcall_cli').setValue('');
 this.vagentForm.get('plandetail').get('bkpdid_outcall_cli').updateValueAndValidity();
 this.backupdid='';
 this.didnetworkcli1='';
  this.http.post(" https://api.cerebra.quadrafort.in/check_vnum",{vnum:event.target.value}).subscribe((data:any)=>{
    $(".fullpageloader").fadeOut('fast');
this.backupdid= data;
if(this.backupdid.status=='success'){
  $("#bkpdidlink").prop('disabled',false);
  }
  else{
    $("#bkpdidlink").prop('disabled',true);
  }

})
}
checkvnum(event){
  $(".fullpageloader").fadeIn('fast');
  this.http.post(" https://api.cerebra.quadrafort.in/check_vnum",{vnum:event.target.value}).subscribe((data:any)=>{
    $(".fullpageloader").fadeOut('fast');
this.virtualnumber= data;
if(this.virtualnumber.status=='success'){
  $("#vnumlink").prop('disabled',false);
  }
  else{
    $("#vnumlink").prop('disabled',true);
  }

})
}

calculatecoin(ibd_call_rate:number,obd_call_rate:number,allocationtype,conf_type){
 allocationtype = allocationtype.toLowerCase();
  let ibdobd:number;
  let totalcoin:number;
  if(ibd_call_rate>0){
    ibdobd=ibd_call_rate;
  }
  else if(obd_call_rate>0){
    ibdobd=obd_call_rate;
  }
  else if(ibd_call_rate == 0 && obd_call_rate==0){
    ibdobd=0;
  }

   totalcoin =((this.records.data[0].service_amount)/(ibdobd/100))/this.records.data[0].service_validity;
   setTimeout(() => {
    if(ibdobd==0){
      totalcoin =0;
      this.vagentForm.get('plandetail').get('total_coins').setValue(totalcoin);
      this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
    }
    else{
      if(allocationtype=='monthly' || allocationtype=='monthly carryfwd'){
        totalcoin =((this.records.data[0].service_amount)/(ibdobd/100))/this.records.data[0].service_validity;
         this.vagentForm.get('plandetail').get('total_coins').setValue(Math.round(totalcoin));
        this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
      }
      else{
        totalcoin =((this.records.data[0].service_amount)/(ibdobd/100))
        this.vagentForm.get('plandetail').get('total_coins').setValue(Math.round(totalcoin));
        this.vagentForm.get('plandetail').get('total_coins').updateValueAndValidity();
      }
    }
  }, 1000);
  }

changedidnetwork(cdidval,didnum){
  $(".fullpageloader").fadeIn('fast');
if(cdidval.target.value!=''){
  this.vagentForm.get('plandetail').get('did_network_input').setValue(cdidval.target.options[cdidval.target.options.selectedIndex].text);
  this.vagentForm.get('plandetail').get('did_network_input').updateValueAndValidity();
}
else{
  this.vagentForm.get('plandetail').get('did_network_input').setValue('');
  this.vagentForm.get('plandetail').get('did_network_input').updateValueAndValidity();
}

//alert(cdidval.target.value+" "+didnum);
this.http.post(" https://api.cerebra.quadrafort.in/get_cli",{id:cdidval.target.value,did:didnum}).subscribe((data)=>{
  $(".fullpageloader").fadeOut('fast');
this.didnetworkcli = data
})
}
changedidnetwork1(cdidval,didnum){
  if(cdidval.target.value!=''){
    this.vagentForm.get('plandetail').get('bkpdid_network_input').setValue(cdidval.target.options[cdidval.target.options.selectedIndex].text);
    this.vagentForm.get('plandetail').get('bkpdid_network_input').updateValueAndValidity();
  }
  else{
    this.vagentForm.get('plandetail').get('bkpdid_network_input').setValue('');
    this.vagentForm.get('plandetail').get('bkpdid_network_input').updateValueAndValidity();
  }
  //alert(cdidval.target.value+" "+didnum);
  $(".fullpageloader").fadeIn('fast');
  this.http.post(" https://api.cerebra.quadrafort.in/get_cli",{id:cdidval.target.value,did:didnum}).subscribe((data)=>{
    $(".fullpageloader").fadeOut('fast');
  this.didnetworkcli1 = data
  })
  }

}
