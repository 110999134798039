import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';  
import {formatDate } from '@angular/common'; 

@Component({
  selector: 'app-didinventry',
  templateUrl: './didinventry.component.html',
  styleUrls: ['./didinventry.component.css']
})
export class DidinventryComponent implements OnInit {
didrecords:any;
itemsPerPage:any=15;
currentPage:number =1;
filterForm:FormGroup;
oprater:any;
circle:any;
status:any;
fromdate:any;
todate:any;
didtype:any;
currentUserloclstrage:any;

operaters=['Airtel','Reliance','Vodafone','BSNL','IDEA','Tata Indicom','Aircel','MTNL','Spice','MTNL'];
circles=['Andaman and Nicobar Islands','Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chandigarh','Chhattisgarh','Dadra and Nagar Haveli','Delhi','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu and Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Puducherry','Punjab','Rajasthan','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal'];
today= new Date();
jstoday = '';

constructor(private fb:FormBuilder, private http:HttpClient, private toastr:ToastrService ,private confirmDialogService:ConfirmDialogService) { 
 
}
ngOnInit() {
  this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
  this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
  this.createform();
  this.getRecord(this.currentPage);

  }
  createform(){
 
    this.filterForm = this.fb.group({
      oprater:[''],
      circle:[''],
      status:[''],
      didtype:[''],
      fromdate:'',
      todate:''
    })
  }
  getRecord(crpage:number){
    $(".fullpageloader").fadeIn('fast');
    this.http.post(" https://api.cerebra.quadrafort.in/did_inventry",{page:crpage,oprater:this.oprater,circle:this.circle,status:this.status,didtype:this.didtype,fromdate:this.fromdate,todate:this.todate}).subscribe((data:any)=>{
      console.log(data);
      this.didrecords=data;
      $(".fullpageloader").fadeOut('fast');
    })
  }
  pageChange(newpage:number){
    this.getRecord(newpage);
    this.currentPage = newpage;
      }

      deleteRecord(id:number,curpage=this.currentPage){
        var that = this;
         this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
             
              that.http.post(' https://api.cerebra.quadrafort.in/deletedid',{uid:id}).subscribe((data:any)=>{
              // console.log(data);
                if(data.status=='success'){
             
                 that.toastr.success('Record Deleted  succesfully!', 'Success!',{
                  positionClass:'toast-top-center',
                  timeOut: 3000
                 });
                 that.getRecord(curpage); 
                }
                
           })
          
            }, function () {  
              return false;
            }) 
           
       }
       serachRecords(sinpuval:any){
        if(sinpuval==''){
          $(".serachpanel").addClass('error');
        }
        else{
         
          $(".fullpageloader").show();
          this.http.post(" https://api.cerebra.quadrafort.in/did_inventry",{search:sinpuval,page:this.currentPage}).subscribe(
            (data:any)=>{
        this.didrecords = data;

          // console.log(data.length);
           $(".fullpageloader").hide();
          })
        }
          }

          onSubmit(){
            this.oprater=this.filterForm.value.oprater;
            this.circle=this.filterForm.value.circle;
            this.status=this.filterForm.value.status;
            this.didtype=this.filterForm.value.didtype;
           this.fromdate= $('#fromdate').val();
            this.todate= $('#todate').val();
           
            $('.fullpageloader').show('fast');
           this.http.post(' https://api.cerebra.quadrafort.in/did_inventry',{oprater:this.oprater,circle:this.circle,status:this.status,didtype:this.didtype,fromdate:this.fromdate,todate:this.todate,page:this.currentPage}).subscribe(
           (data:any)=>{
             this.didrecords=data;
              $('.cfillBox').slideUp('fast');
              $('.fullpageloader').hide('fast');
        })
         }
          
}
