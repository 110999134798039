import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-resellerrenewalextend',
  templateUrl: './resellerrenewalextend.component.html',
  styleUrls: ['./resellerrenewalextend.component.css']
})
export class ResellerrenewalextendComponent implements OnInit {
  resellerrenewalform:FormGroup;
  submitted:boolean = false;
  retailpanel:boolean = false;
   baseurl:any;
   resellerList:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {

  
  }

  ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');
this.getressellerlist();
this.createform();

this.resellerrenewalform.get('account_type').valueChanges
  .subscribe(account_type => {
 if(account_type=='extend'){
    this.retailpanel = true;
    this.resellerrenewalform.get('effective_date').setValidators(null);
    this.resellerrenewalform.get('days').setValidators([Validators.required]);


    this.resellerrenewalform.get('effective_date').updateValueAndValidity();
    this.resellerrenewalform.get('days').updateValueAndValidity();

  }
  else{
    this.retailpanel = false;
    this.resellerrenewalform.get('effective_date').setValidators([Validators.required]);
    this.resellerrenewalform.get('days').setValidators(null);


    this.resellerrenewalform.get('effective_date').updateValueAndValidity();
    this.resellerrenewalform.get('days').updateValueAndValidity();

  }

});


  }

  createform(){
    this.resellerrenewalform= this.fb.group({
      account_type:'renewal',
      resseller_id:['',[Validators.required]],
      effective_date:[''],
      days:[''],
     })
  }


  get f(){ return this.resellerrenewalform.controls;}
  onSubmit(){
  this.submitted = true;
  //this.vleadForm.get('validity').setValue($("#validitydate").val());
    var resellerrenewalformdata= JSON.stringify(this.resellerrenewalform.value);

    if(this.resellerrenewalform.invalid){
   return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"make_renewal_reseller",{data:resellerrenewalformdata}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         this.resellerrenewalform.patchValue({
          account_type:'renewal',
          resseller_id:'',
          effective_date:'',
          days:'',
         })
       // this.router.navigateByUrl('/servicelist');
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }

  getressellerlist(){
    this.http.get(this.baseurl+"get_reseller_list").subscribe((data:any)=>{
      this.resellerList = data.data;
    })
      }

}
