import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-addressvnumdidbulk',
  templateUrl: './addressvnumdidbulk.component.html',
  styleUrls: ['./addressvnumdidbulk.component.css']
})
export class AddressvnumdidbulkComponent implements OnInit {

  fileData: File = null;
   data:any;
  AOA:any;
  filedta:any;
  baseurl:any;
  resellerList:any;
 constructor(private http: HttpClient, private toastr:ToastrService,private router:Router) { }
  
  ngOnInit() {
  
    this.baseurl = localStorage.getItem('baseurl');
    this.getressellerlist();
 }

fileProgress(fileInput: any) {
 // console.log(fileInput.target.files[0]);
 
    var validExts = new Array(".xlsx", ".xls");
    var fileExt = fileInput.target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    $("#filepath").empty('');
    
    if (validExts.indexOf(fileExt) < 0) {
      $("#exeterror").fadeIn(400).delay(3000).fadeOut(400);
      $("#customFile").val('');
      return false;
    }
    else{
      $("#filepath").html(fileInput.target.files[0].name);
      this.fileData = <File>fileInput.target.files[0];

    const target: DataTransfer = <DataTransfer>(fileInput.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      let sheetdata =(XLSX.utils.sheet_to_json(ws, {header: 1}));
       this.data = sheetdata.filter(function(item:[]){
        return item.length !== 0;
      });
      this.data=JSON.stringify(this.data);
      console.log(this.data);
    };
    reader.readAsBinaryString(target.files[0]);
   // console.log(target.files[0]);
    this.filedta = target.files[0]
    
    }
}

onSubmit() {
 
  if($("#customFile").val()=='' && $("#resel_id").val()==''){
    this.toastr.error("Please select file and choose reseller.", 'Error!',{
                 positionClass:'toast-top-center',
                   timeOut: 3000
                 });
    return false;
  }
  
else{
  $(".fullpageloader").fadeIn('fast');
    const formData = new FormData();
    formData.append('exelfile', this.fileData);
    formData.append('exelfiledata',this.data);
    formData.append('resel_id', $("#resel_id").val());
    this.http.post(this.baseurl+'add_reseller_bulk_inventry', formData).subscribe(
    (res:any) => {
     // console.log(res);
     
        if(res.status=="success"){
        
           this.toastr.success(res.message, 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 10000
          });
          this.router.navigateByUrl('/resseller/resellervirtualinventry');
         $(".fullpageloader").fadeOut('fast');
        }
        else if(res.status=="fail"){
         console.log("fail");
         this.toastr.error(res.message, 'Error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
         
        }
      $(".fullpageloader").fadeOut('fast');
      },
      error => {
        this.toastr.error(error, 'error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        $(".fullpageloader").fadeOut('fast');
    }
   
  );
   
}
}
getressellerlist(){
  this.http.get(this.baseurl+"get_reseller_list").subscribe((data:any)=>{
    this.resellerList = data.data;
  })
    }
}
