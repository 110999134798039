import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-createdemo',
  templateUrl: './createdemo.component.html',
  styleUrls: ['./createdemo.component.css']
})
export class CreatedemoComponent implements OnInit {
  Createdemo:FormGroup;
  submitted:boolean = false;
  subtypesec:boolean = false;
  baseurl:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {

  
  }

  ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');
this.createform();
this.Createdemo.get('service_type').valueChanges
  .subscribe(service_type => {
    if(service_type=='Click2Call'){
   this.subtypesec = true,
   this.Createdemo.get("sub_type").setValidators([Validators.required]);
  this.Createdemo.get('sub_type').updateValueAndValidity();
   
    }
    else{
      this.subtypesec = false,
      this.Createdemo.get("sub_type").setValidators(null);
     this.Createdemo.get('sub_type').updateValueAndValidity();
    }
  })
  }

  createform(){
    this.Createdemo= this.fb.group({
      service_type:['',[Validators.required]],
      companyname:['',[Validators.required]],
      fname:['',[Validators.required]],
      mobile:['',[Validators.required]],
      email:['',[Validators.required,Validators.email]],
      sub_type:['']
    })
  }


  get f(){ return this.Createdemo.controls;}
  onSubmit(){
  this.submitted = true;
  //this.vleadForm.get('validity').setValue($("#validitydate").val());
    var createdemo= JSON.stringify(this.Createdemo.value);

    if(this.Createdemo.invalid){
   return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"create_demo",{data:createdemo}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
        this.router.navigateByUrl('/servicelist');
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }


}
