import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-clientdetailreport',
  templateUrl: './clientdetailreport.component.html',
  styleUrls: ['./clientdetailreport.component.css']
})
export class ClientdetailreportComponent implements OnInit {

  servicelog;
  itemsPerPage:any=15;
 currentPage:number =1;
   filterform:FormGroup;
 submitted=false;
 today= new Date();
 jstoday = ''; 
 jstoday1 = '';

 constructor(private http:HttpClient,private fb:FormBuilder) { 

this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
this.jstoday1 = formatDate(this.today, 'yyyy-MM-01', 'en-US', '+0530');
   this.filterform = this.fb.group({
   
     fromdate: [''],
     todate: [''],
     service_type:[''],
     status:''
     
   })

 }

 ngOnInit() {

  this.getdata(this.currentPage,'','','','')
$("#reptype").on('change',function(){
// alert($(this).val());

})
 }

onSubmit()
{
  this.submitted=true;
 //console.log(this.filterform.value);

 let  service_type=this.filterform.value.service_type;
 let  status=this.filterform.value.status;
 
 let fromdate=$('#fromdate').val();
 let todate=$('#todate').val()
 
  $('.fullpageloader').show('fast');
   this.http.get(' https://api.cerebra.quadrafort.in/client_detail_report?servicetype='+service_type+'&fromdate='+fromdate+'&todate='+todate+'&status='+status).subscribe(
   (data)=>{
     this.servicelog=data;
       $('.cfillBox').slideUp('fast');
      $('.fullpageloader').hide('fast');

   })

}

getdata(crpage:number,service_type,fromdate,todate,status)
 {
   
   $('.fullpageloader').show('fast');
    this.http.get(' https://api.cerebra.quadrafort.in/client_detail_report?page='+crpage+'&servicetype='+service_type+'&fromdate='+fromdate+'&todate='+todate+'&status='+status).subscribe(
    (data)=>{
       this.servicelog=data;
       $('.fullpageloader').hide('fast');
    });
 }

  pageChange(newpage:number){
    let  service_type=this.filterform.value.service_type;
    let  status=this.filterform.value.status;
    let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
   this.getdata(newpage,service_type,fromdate,todate,status);
   this.currentPage = newpage;
     }

}
