import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-topup',
  templateUrl: './topupsms.component.html',
  styleUrls: ['./topupsms.component.css']
})
export class TopupsmsComponent implements OnInit {

  submitted:boolean = false;
  topupForm:FormGroup;
  top_id:any;
  user_id:any;
  service_type:any;
  topup:any;
  topup_type:any;
  amount:any;
  sms_rate:any;
  dlt_rate:any;

  topup_remarks:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute){
    this.top_id = this.route.snapshot.paramMap.get("id");
    this.user_id = this.route.snapshot.paramMap.get("user_id");
    this.service_type = this.route.snapshot.paramMap.get("service_type");
    this.topup = this.route.snapshot.paramMap.get("topup");
    this.topup_type = this.route.snapshot.paramMap.get("topup_type");
    this.amount = this.route.snapshot.paramMap.get("amount");
    this.topup_remarks = this.route.snapshot.paramMap.get("topup_remarks");
    this.sms_rate = this.route.snapshot.paramMap.get("sms_rate");
    this.dlt_rate = this.route.snapshot.paramMap.get("dlt_rate");
    
  } 

  ngOnInit() {
this.createForm();
  }
createForm(){
this.topupForm = this.fb.group({
  amount:this.amount,
  coins:function(ammount,smsrate,dlt_rate){
    return Math.round(ammount/((parseFloat(smsrate)+ parseFloat(dlt_rate))/100));
  }(this.amount,this.sms_rate,this.dlt_rate),
  smsrate:function(sms_rate,dlt_rate){ return parseFloat(sms_rate)+parseFloat(dlt_rate)}(this.sms_rate,this.dlt_rate),
  validity:['',[Validators.required]],
  remark:this.topup_remarks,
  top_id:this.top_id,
  user_id:this.user_id,
  service_type:this.service_type,
  session_id:JSON.parse(localStorage.getItem('currentUser')).user_id
})
  }

get f(){return this.topupForm.controls}
onSubmit(){
this.submitted= true;
let topupFormdata = JSON.stringify(this.topupForm.value);
if(this.topupForm.invalid){
return false;
}
else{
  
  $(".fullpageloader").fadeIn('fast');
  this.http.post(" https://api.cerebra.quadrafort.in/maketopup",{data:topupFormdata}).subscribe((data:any)=>{
   
    if(data.status=='success'){
      this.toastr.success('Data Submitted  succesfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      this.router.navigateByUrl('crequest/topuprequest');
    }
    else if(data.status=="fail"){
      console.log("fail");
      this.toastr.error(data.message, 'Error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
      
     }
  }, error => {
    this.toastr.error(error, 'error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
  }
  )
  
  }
}

}
