import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service'; 
import {formatDate } from '@angular/common'; 

@Component({
  selector: 'app-campaignlist',
  templateUrl: './campaignlist.component.html',
  styleUrls: ['./campaignlist.component.css']
})

export class CampaignlistComponent implements OnInit 
{
filterForm:FormGroup;
campaigntype:any
company:any;
userid:any;
campaignid:any;
status:any;
fromdate:any;
todate:any;
campaign;
currentUserloclstrage:any;

  constructor(private fb:FormBuilder, private http:HttpClient, private toastr:ToastrService ,private confirmDialogService:ConfirmDialogService,private router:Router ) { }
  today= new Date();
  jstoday = '';
  ngOnInit() 
  {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    this.createform();
     this.getdata();

  }

  createform(){
 
    this.filterForm = this.fb.group({
      campaigntype:[''],
      company:[''],
      userid:[''],
      campaignid:[''],
      status:[''],
      fromdate:'',
      todate:''
    })
  }
  getdata()
  {

   $('.fullpageloader').fadeIn('fast');
    this.http.get(' https://api.cerebra.quadrafort.in/campaign').subscribe(
    (data)=>{
      this.campaign=data;
    
      $('.fullpageloader').fadeOut('fast');
      });
  }

  onSubmit(){
    
    this.campaigntype=this.filterForm.value.campaigntype;
    this.company=this.filterForm.value.company;
    this.userid=this.filterForm.value.userid;
    this.campaignid=this.filterForm.value.campaignid;
    
    this.status=this.filterForm.value.status;
    this.fromdate= $('#fromdate').val();
    this.todate= $('#todate').val();
   
    $('.fullpageloader').show('fast');
   
  this.http.get(" https://api.cerebra.quadrafort.in/campaign?campaigntype="+this.campaigntype+"&company="+this.company+"&userid="+this.userid+"&campaignid="+this.campaignid+"&status="+this.status+"&fromdate="+this.fromdate+"&todate="+this.todate).subscribe(
   (data:any)=>{
     this.campaign=data;
      $('.cfillBox').slideUp('fast');
      $('.fullpageloader').hide('fast');
})
 }

 changestatus(campaignid,status){
 
  $(".fullpageloader").fadeIn('fast');
  this.http.get(' https://api.cerebra.quadrafort.in/change_status_campaign?campaign_id='+campaignid+'&status='+status).subscribe(
    (data:any)=>{
    
      if(data.status=="success"){
        $(".fullpageloader").fadeOut('fast');
          this.toastr.success('Status Change  succesfully!', 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        this.getdata();
        
        }
      else if(data.status=="fail"){
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
       
      }
 },
 error => {
  $(".fullpageloader").fadeOut('fast');
   this.toastr.error(error, 'error!',{
     positionClass:'toast-top-center',
     timeOut: 3000
   });
})
  
 }

}
