import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-resellerviewplan',
  templateUrl: './resellerviewplan.component.html',
  styleUrls: ['./resellerviewplan.component.css']
})
export class ResellerviewplanComponent implements OnInit {

  reseller_id:any;
  plan_id:any;
  resellerplanform:FormGroup;
  submitted:boolean = false;
  retailpanel:boolean = false;
   baseurl:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
    this.reseller_id =  this.route.snapshot.paramMap.get('ress_id');
    this.plan_id =  this.route.snapshot.paramMap.get('plan_id');
    }

  ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');
this.createform();
this.getrecords();
}

  createform(){
    this.resellerplanform= this.fb.group({
      plan_name:[{value: '', disabled: true}],
      plan_term:[{value: '', disabled: true}],
      amount:[{value: '', disabled: true}],
      channel:[{value: '', disabled: true}],
      service_type:[{value: '', disabled: true}],
      call_rate:[{value: '', disabled: true}],
      monthly_coin:[{value: '', disabled: true}],
      ibd_coin:[{value: '', disabled: true}],
      ibd_duration:[{value: '', disabled: true}],
      ibd_ded:[{value: '', disabled: true}],
      obd_coin:[{value: '', disabled: true}],
      obd_duration:[{value: '', disabled: true}],
      obd_ded:[{value: '', disabled: true}],
      sms_type:[{value: '', disabled: true}],
      sms_coin:[{value: '', disabled: true}],
      sms_count:[{value: '', disabled: true}],
      sms_ded:[{value: '', disabled: true}],
      email_coin:[{value: '', disabled: true}],
      email_count:[{value: '', disabled: true}],
      email_ded:[{value: '', disabled: true}],
     })
  }

  get f(){ return this.resellerplanform.controls;}
  getrecords(){
    console.log(this.reseller_id+""+this.plan_id);
    $(".fullpageloader").fadeIn("fast");
this.http.get(this.baseurl+"view_approve_plandetail?resel_id="+this.reseller_id+"&plan_id="+this.plan_id).subscribe((data:any)=>{
  //alert(JSON.stringify(data));
if(data.status=='success'){
  $(".fullpageloader").fadeOut("fast");
  this.resellerplanform.setValue({
    plan_name:data.data[0].plan_name,
    plan_term:data.data[0].plan_term,
    amount:data.data[0].plan_price,
    channel:data.data[0].free_chanel,
    service_type:data.data[0].service_type,
    call_rate:data.data[0].call_rate,
    monthly_coin:data.data[0].total_coins,
    ibd_coin:data.data[0].ibd_coins,
    ibd_duration:data.data[0].ibd_ded_sec,
    ibd_ded:data.data[0].ibd_ded_rate,
    obd_coin:data.data[0].obd_coins,
    obd_duration:data.data[0].obd_ded_sec,
    obd_ded:data.data[0].obd_ded_rate,
    sms_type:'common',
    sms_coin:data.data[0].sms_coins,
    sms_count:data.data[0].personal_sms_ded_cnt,
    sms_ded:data.data[0].sms_ded__cnt,
    email_coin:data.data[0].email_coins,
    email_count:data.data[0].email_ded_rate,
    email_ded:data.data[0].email_ded_cnt,
  });
}
})
  }

  approvePlan(resel_id,plan_id,status){
   
    $(".fullpageloader").fadeIn('fast');
this.http.get(this.baseurl+"approve_plan?resel_id="+resel_id +"&plan_id="+plan_id+"&status="+status).subscribe((data:any)=>{
  if(data.status=='success'){
   
    $(".fullpageloader").fadeOut('fast');
    this.toastr.success(data.message, 'Success!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
   this.router.navigateByUrl('/resseller/resellerapproveplan');
  }
  else if(data.status=="fail"){
      
    this.toastr.error(data.message, 'Error!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });
     $(".fullpageloader").fadeOut('fast');

   }
}, error => {
  this.toastr.error(error, 'error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
  $(".fullpageloader").fadeOut('fast');
})
  }


}
