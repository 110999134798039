import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-servicealertlog',
  templateUrl: './servicealertlog.component.html',
  styleUrls: ['./servicealertlog.component.css']
})
export class ServicealertlogComponent implements OnInit {
  
   servicelog;
   itemsPerPage:any=15;
  currentPage:number =1;
    filterform:FormGroup;
  submitted=false;
  today= new Date();
  jstoday = '';
  baseurl:any;

  constructor(private http:HttpClient,private fb:FormBuilder) { 

 this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    this.filterform = this.fb.group({
      user_id: ['', ],
      fromdate: [this.jstoday],
      todate: [this.jstoday],
      mobile:['',[Validators.minLength(10),Validators.maxLength(10)]],
      email:['',[Validators.email]],
      entitytype:'',
      eventtype:'',
      status:''
    })

  }

  ngOnInit() {
    var self = this;
 this.baseurl = localStorage.getItem('baseurl');
 console.log(this.baseurl);
this.getdata(this.currentPage,'','','','','','','','')
$("#reptype").on('change',function(){
  //alert($(this).val());
  switch($(this).val()){
    case 'bymobile':
      $("#mobilno,#emailid,#user_id").val('');
    self.filterform.value.user_id ='';
    self.filterform.value.mobile='';
    self.filterform.value.email='';
  $("#bymobile").show('fast');
  $("#byemail,#byuserid").hide('fast');
break;
  case 'byemail':
    $("#mobilno,#emailid,#user_id").val('');
    self.filterform.value.user_id ='';
    self.filterform.value.mobile='';
    self.filterform.value.email='';
  $("#byemail").show('fast');
  $("#bymobile,#byuserid").hide('fast');

break;
  case 'byuserid':
    $("#mobilno,#emailid,#user_id").val('');
    self.filterform.value.user_id ='';
    self.filterform.value.mobile='';
    self.filterform.value.email='';
  $("#byuserid").show('fast');
  $("#byemail,#bymobile").hide('fast');

 break;
  default:
    $("#mobilno,#emailid,#user_id").val('');
    self.filterform.value.user_id ='';
    self.filterform.value.mobile='';
    self.filterform.value.email='';
 $("#byemail,#byuserid,#bymobile").hide('fast');
  }
})
  }

onSubmit()
{
   this.submitted=true;
  //console.log(this.filterform.value);
   let user_id=this.filterform.value.user_id;
   let  mobile=this.filterform.value.mobile;
    let  email=this.filterform.value.email;
    let  status=this.filterform.value.status;
    let  entitytype=this.filterform.value.entitytype;
    let  eventtype=this.filterform.value.eventtype;
    
   let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
  
   $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'service_alert_logs?user_id='+user_id+'&fromdate='+fromdate+'&todate='+todate+'&email='+email+'&mobile='+mobile+'&status='+status+'&entitytype='+entitytype+'&eventtype='+eventtype).subscribe(
    (data)=>{
      this.servicelog=data;
        $('.cfillBox').slideUp('fast');
       $('.fullpageloader').hide('fast');

    })

}

  getdata(crpage:number,user_id,mobile,email,fromdate,todate,status,entitytype,eventtype)
  {
    $('.fullpageloader').show('fast');
     this.http.get(this.baseurl+'service_alert_logs?page='+crpage+'&user_id='+user_id+'&fromdate='+fromdate+'&todate='+todate+'&email='+email+'&mobile='+mobile+'&status='+status+'&entitytype='+entitytype+'&eventtype='+eventtype).subscribe(
     (data)=>{
        this.servicelog=data;
        $('.fullpageloader').hide('fast');
     });
  }

   pageChange(newpage:number){
    let user_id=this.filterform.value.user_id;
    let  mobile=this.filterform.value.mobile;
     let  email=this.filterform.value.email;
     let  status=this.filterform.value.status;
     let  entitytype=this.filterform.value.entitytype;
     let  eventtype=this.filterform.value.eventtype;
     
    let fromdate=$('#fromdate').val();
    let todate=$('#todate').val();
    this.getdata(newpage,user_id,mobile,email,fromdate,todate,status,entitytype,eventtype);
    this.currentPage = newpage;
      }

}
