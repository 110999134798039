﻿
import { Routes, RouterModule,PreloadAllModules } from '@angular/router';

import { HomeComponent } from './home';
import { AdminComponent } from './admin';
import { LoginComponent } from './login';
import { ContentrequestComponent } from './contentrequest/contentrequest.component';
import { ServicerequestComponent } from './servicerequest/servicerequest.component';
import { TopuprequestComponent } from './topuprequest/topuprequest.component';
import { RenewalrequestComponent } from './renewalrequest/renewalrequest.component';
import { UpgraderequestComponent } from './upgraderequest/upgraderequest.component';
import { VirtualinventryComponent } from './virtualinventry/virtualinventry.component';
import { DidinventryComponent } from './didinventry/didinventry.component';
import { AddvnumsingleComponent } from './addvnumsingle/addvnumsingle.component';
import { AddvnumbulkComponent } from './addvnumbulk/addvnumbulk.component';
import { AdddidsingleComponent } from './adddidsingle/adddidsingle.component';
import { AdddidbulkComponent } from './adddidbulk/adddidbulk.component';
import { ServicelistComponent } from './servicelist/servicelist.component';
import { AgentComponent } from './agent/agent.component';
import { AddagentComponent } from './addagent/addagent.component';
import { SettleamtlistComponent } from './settleamtlist/settleamtlist.component';
import { SettleamountComponent } from './settleamount/settleamount.component';
import { CampaignlistComponent } from './campaignlist/campaignlist.component';
import { CampaginviewComponent } from './campaginview/campaginview.component';
import { VnumserviceComponent } from './vnumservice/vnumservice.component';
import { TollfreeserviceComponent } from './tollfreeservice/tollfreeservice.component';
import { ObdserviceComponent } from './obdservice/obdservice.component';
import { SmsserviceComponent } from './smsservice/smsservice.component';
import { ClicktocallserviceComponent } from './clicktocallservice/clicktocallservice.component';
import { VleadserviceComponent } from './vleadservice/vleadservice.component';
import { VmanageserviceComponent } from './vmanageservice/vmanageservice.component';
import { EmailaccsettComponent } from './emailaccsett/emailaccsett.component';
import { SmsaccsettComponent } from './smsaccsett/smsaccsett.component';
import { MinavomanagementComponent } from './minavomanagement/minavomanagement.component';
import { AddminavomgntComponent } from './addminavomgnt/addminavomgnt.component';
import { CrmComponent } from './crm/crm.component';
import { ServicealertlogComponent } from './servicealertlog/servicealertlog.component'
import { ApierrorlogComponent } from './apierrorlog/apierrorlog.component';
import { CrmapilogComponent } from './crmapilog/crmapilog.component';
import { AfterdisconnectapilogComponent } from './afterdisconnectapilog/afterdisconnectapilog.component';
import { ApilogComponent } from './apilog/apilog.component';
import { ExecmanagementComponent } from './execmanagement/execmanagement.component';
import { MsvnumserviceComponent } from './msvnumservice/msvnumservice.component';
import { MstollfreeserviceComponent } from './mstollfreeservice/mstollfreeservice.component';
import { MsobdserviceComponent } from './msobdservice/msobdservice.component';
import { MssmsserviceComponent } from './mssmsservice/mssmsservice.component';
import { MsclicktocallserviceComponent } from './msclicktocallservice/msclicktocallservice.component';
import { MsvleadserviceComponent } from './msvleadservice/msvleadservice.component';
import { MsvmanageserviceComponent } from './msvmanageservice/msvmanageservice.component';
//report sec start
import { CallreportComponent } from './reports/callreport/callreport.component';
import { CalloverallComponent } from './reports/calloverall/calloverall.component';
import { CallviewreportComponent } from './reports/callviewreport/callviewreport.component';
import { CalloverallviewComponent } from './reports/calloverallview/calloverallview.component';
import { SmsreportComponent } from './reports/smsreport/smsreport.component';
import { OverallsmsComponent } from './reports/overallsms/overallsms.component';
import { PendingrenewalComponent } from './reports/pendingrenewal/pendingrenewal.component';
import { TopupreportComponent } from './reports/topupreport/topupreport.component';
import { RenewalextendComponent } from './reports/renewalextend/renewalextend.component';
import { DemoaccountComponent } from './reports/demoaccount/demoaccount.component';
import { DemodetailsComponent } from './reports/demodetails/demodetails.component';
import { CappedcallComponent } from './reports/cappedcall/cappedcall.component';
import { CappedcallviewComponent } from './reports/cappedcallview/cappedcallview.component';
import { EmailreportComponent } from './reports/emailreport/emailreport.component';
import { EmailoverallreportComponent } from './reports/emailoverallreport/emailoverallreport.component';
import { ClientdetailreportComponent } from './reports/clientdetailreport/clientdetailreport.component';
import { ServerpulsereportComponent } from './reports/serverpulsereport/serverpulsereport.component';
import { VirtualnumreportComponent } from './reports/virtualnumreport/virtualnumreport.component';
import { ClientwiseComponent } from './reports/clientwise/clientwise.component';
import { TopupComponent } from './topup/topup.component';
import { TopupsmsComponent } from './topupsms/topupsms.component';
import { RenewalComponent } from './renewal/renewal.component';
import { UpgradeplanComponent } from './upgradeplan/upgradeplan.component';
import { UpgradeplansmsComponent } from './upgradeplansms/upgradeplansms.component';
import { NetworkdidComponent } from './networkdid/networkdid.component';
import { NetworkviewComponent } from './networkview/networkview.component';
import { VconvoserviceComponent } from './vconvoservice/vconvoservice.component';
import { MsvconvoserviceComponent } from './msvconvoservice/msvconvoservice.component';
import { SupportlistComponent } from './support/supportlist/supportlist.component';
import { NewticketComponent } from './support/newticket/newticket.component';
import { CreatedemoComponent } from './createdemo/createdemo.component';
import { EditrequestComponent } from './editrequest/editrequest.component';
import { EditdetailComponent } from './editdetail/editdetail.component';
import { Obdc2ccliComponent } from './obdc2ccli/obdc2ccli.component';
import { CdrComponent } from './cdr/cdr.component';


import { RessellerresistrationComponent } from './ressellerresistration/ressellerresistration.component';
import { ManageresselerComponent } from './manageresseler/manageresseler.component';
import { ResellercreditbackComponent } from './resellercreditback/resellercreditback.component';
import { ResellerrenewalextendComponent } from './resellerrenewalextend/resellerrenewalextend.component';
import { ResellerapproveplanComponent } from './resellerapproveplan/resellerapproveplan.component';
import { ResellercreateplanComponent } from './resellercreateplan/resellercreateplan.component';
import { ResellerviewplanComponent } from './resellerviewplan/resellerviewplan.component';
import { ResellerprofileeditComponent } from './resellerprofileedit/resellerprofileedit.component';
import { ResellersettingComponent } from './resellersetting/resellersetting.component';
import { ResellerplanlistComponent } from './resellerplanlist/resellerplanlist.component';
import { ResellerplanlistviewComponent } from './resellerplanlistview/resellerplanlistview.component';
import { ResellervirtualinventryComponent } from './resellervirtualinventry/resellervirtualinventry.component';
import { AddressvnumdidsingleComponent } from './addressvnumdidsingle/addressvnumdidsingle.component';
import { AddressvnumdidbulkComponent } from './addressvnumdidbulk/addressvnumdidbulk.component';
import { ResellerrequestComponent } from './resellerrequest/resellerrequest.component';
import { TestcallComponent } from './testcall/testcall.component';
import { LivechannelComponent } from './livechannel/livechannel.component';
import { HindbotComponent } from './hindbot/hindbot.component';
import { MshindbotComponent } from './mshindbot/mshindbot.component';
import { AuthGuard } from './_guards';
import { Role } from './_models';

const appRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent
   },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
  {
     path: 'crequest',
  // canActivate: [AuthGuard],
    children: [ 
      {
       path: '',
       redirectTo: 'crequest', 
       pathMatch: 'full'
      },                        
      {
          path:'contentrequest',
          component: ContentrequestComponent,
          canActivate: [AuthGuard],
      },
      {
          path:'servicerequest',
          component: ServicerequestComponent,
          canActivate: [AuthGuard],
      },
      {
          path:'topuprequest',
          component: TopuprequestComponent,
          canActivate: [AuthGuard],
      },
      { path: 'resellerrequest', 
      component: ResellerrequestComponent,
      canActivate: [AuthGuard]
    },
      {
 
        path:'topup/:id/:user_id/:service_type/:topup/:topup_type/:amount/:topup_remarks',
        component: TopupComponent,
        canActivate: [AuthGuard],
    },
    {
 
      path:'topupsms/:id/:user_id/:service_type/:topup/:topup_type/:amount/:topup_remarks/:sms_rate/:dlt_rate',
      component: TopupsmsComponent,
      canActivate: [AuthGuard],
  },
    
    {
     
      path:'renewal/:id/:user_id/:service_type/:validity/:contract/:agent',
      component: RenewalComponent,
      canActivate: [AuthGuard],
  },
      {
          path:'renewalrequest',
          component: RenewalrequestComponent,
          canActivate: [AuthGuard],
      },
   {
        path:'upgraderequest',
        component: UpgraderequestComponent,
        canActivate: [AuthGuard],
    },
   {
      path:'upgradeplan/:id/:user_id/:service_type',
      component: UpgradeplanComponent,
      canActivate: [AuthGuard],
  },
  {
    path:'upgradeplansms/:id/:user_id/:service_type',
    component: UpgradeplansmsComponent,
    canActivate: [AuthGuard],
},
  {
    path:'editrequest',
    component: EditrequestComponent,
    canActivate: [AuthGuard],
},
  {
    path:'editdetail/:edit_id',
    component: EditdetailComponent,
    canActivate: [AuthGuard],
},
    {
      path:'vnumservice',
      component: VnumserviceComponent,
      canActivate: [AuthGuard],
   },
   {
    path:'vconvoservice/:service_id',
    component:VconvoserviceComponent,
    canActivate:[AuthGuard],
   }, 
   {
    path:'vnumservice/:service_id',
    component:VnumserviceComponent,
    canActivate:[AuthGuard],
   }, 
  {
    path:'tollfreeservice',
    component: TollfreeserviceComponent,
    canActivate: [AuthGuard],
  },
  {
  path:'obdservice',
  component: ObdserviceComponent,
  canActivate: [AuthGuard],
  },
  {
    path:'obdservice/:service_id',
    component:ObdserviceComponent,
    canActivate:[AuthGuard],
   },
 {
  path:'smsservice',
  component: SmsserviceComponent,
  canActivate: [AuthGuard],
 },
 {
  path:'smsservice/:service_id',
  component:SmsserviceComponent,
  canActivate:[AuthGuard],
 },
{
  path:'clicktocallservice',
  component:ClicktocallserviceComponent,
  canActivate: [AuthGuard],
},
{
  path:'clicktocallservice/:service_id',
  component:ClicktocallserviceComponent,
  canActivate:[AuthGuard],
 }, 
{
path:'vleadservice',
component: VleadserviceComponent,
canActivate: [AuthGuard],
},
{
  path:'vleadservice/:service_id',
  component:VleadserviceComponent,
  canActivate:[AuthGuard],
 }, 
{
path:'vmanageservice',
component: VmanageserviceComponent,
canActivate: [AuthGuard],
},
{
  path:'vmanageservice/:service_id',
  component:VmanageserviceComponent,
  canActivate:[AuthGuard],
 },
 {
  path:'hindbot',
  component: HindbotComponent,
  canActivate: [AuthGuard],
  },
  {
    path:'hindbot/:service_id',
    component:HindbotComponent,
    canActivate:[AuthGuard],
   },
 
]

  },
 
  {
    path: 'numinventry',
 // canActivate: [AuthGuard],
   children: [ 
     {
      path: '',
      redirectTo: 'virtualinventry', 
      pathMatch: 'full'
     },
     {
      path:'virtualinventry',
      component: VirtualinventryComponent,
      canActivate: [AuthGuard],
},
{
  path: 'addvnum',
  children:[ 
    {
     path: '',
     redirectTo: 'addvnumsingle', 
     pathMatch: 'full'
    },
    {
     path:'addvnumsingle',
     component: AddvnumsingleComponent,
     canActivate: [AuthGuard],
   },
   {
    path:'addvnumsingle/:id',
    component: AddvnumsingleComponent,
    canActivate: [AuthGuard],
  },
   {
    path:'addvnumbulk',
    component: AddvnumbulkComponent,
    canActivate: [AuthGuard],
  },
   ]
},
{
  path:'didinventry',
  component: DidinventryComponent,
  canActivate: [AuthGuard],
  
},{
  path: 'adddidnum',
  children: [ 
    {
     path: '',
     redirectTo: 'adddidsingle', 
     pathMatch: 'full'
    },
    {
     path:'adddidsingle',
     component: AdddidsingleComponent,
     canActivate: [AuthGuard],
   },
   {
    path:'adddidsingle/:id',
    component: AdddidsingleComponent,
    canActivate: [AuthGuard],
  },
   {
    path:'adddidbulk',
    component: AdddidbulkComponent,
    canActivate: [AuthGuard],
  },
   ]
},
{
  path:'execmanagement',
  component: ExecmanagementComponent,
  canActivate: [AuthGuard],
  
},
    ]}, 
    {
path:'servicelist',
component:ServicelistComponent,
canActivate:[AuthGuard],
    },
    {
      path:'servicelist/:id',
      component:ServicelistComponent,
      canActivate:[AuthGuard],
    },
    {
      path:'obdc2ccli',
      component:Obdc2ccliComponent,
      canActivate:[AuthGuard],
      },
    {
      path:'networkdid',
      component:NetworkdidComponent,
      canActivate:[AuthGuard],
      },
      {
        path:'cdr',
        component:CdrComponent,
        canActivate:[AuthGuard],
      },
      
      {
        path:'createdemo',
        component:CreatedemoComponent,
        canActivate:[AuthGuard],
        },
        {
          path:'testcall',
          component:TestcallComponent,
          canActivate:[AuthGuard],
          },
          {
            path:'livechannel',
            component:LivechannelComponent,
            canActivate:[AuthGuard],
            },
      
      {
        path: 'networkdid',
        children:[ 
          {
            path:'networkview/:roleid',
            component:NetworkviewComponent,
           canActivate:[AuthGuard],
          },
        ]},
      {
   path:'msvnumservice/:userid/:subtype/:stype',
   component:MsvnumserviceComponent,
   canActivate:[AuthGuard],
  }, 
  {
    path:'msvconvoservice/:userid/:subtype/:stype',
    component:MsvconvoserviceComponent,
    canActivate:[AuthGuard],
   }, 
  {
    path:'mstollfreeservice/:userid/:subtype/:stype',
    component:MstollfreeserviceComponent,
    canActivate:[AuthGuard],
      },
  {
    path:'msobdservice/:userid/:subtype/:stype',
    component:MsobdserviceComponent,
    canActivate:[AuthGuard],
  },
  {
    path:'mssmsservice/:userid/:stype',
    component:MssmsserviceComponent,
    canActivate:[AuthGuard],
   },
   {
    path:'msclicktocallservice/:userid/:subtype/:stype',
    component:MsclicktocallserviceComponent,
    canActivate:[AuthGuard],
   },
   {
     path:'msvleadservice/:userid/:stype',
     component:MsvleadserviceComponent,
     canActivate:[AuthGuard],
   },
    {
      path:'msvmanageservice/:userid/:stype',
      component:MsvmanageserviceComponent,
      canActivate:[AuthGuard],
     },
     {
      path:'mshindbot/:userid/:subtype/:stype',
      component:MshindbotComponent,
      canActivate:[AuthGuard],
     },
     {
      path:'agent',
      component:AgentComponent,
      canActivate:[AuthGuard],
    },{
      path: 'agent',
      children: [ 
        {
         path: '',
         redirectTo: 'addagent', 
         pathMatch: 'full'
        },                        
        {
            path:'addagent',
            component: AddagentComponent,
            canActivate: [AuthGuard],
        },
        {
          path:'addagent/:id',
          component: AddagentComponent,
          canActivate: [AuthGuard],
      },
      ]
    }, 
    {
      path:'settleamtlist',
      component: SettleamtlistComponent,
      canActivate: [AuthGuard],
      
    },
    {
      path: 'settleamtlist',
      children: [ 
        {
         path: '',
         redirectTo: 'settleamount', 
         pathMatch: 'full'
        },                        
        {
            path:'settleamount',
            component: SettleamountComponent,
            canActivate: [AuthGuard],
        }
      ]
    }, 
    {
      path: 'setting',
      children: [ 
        {
         path: '',
         redirectTo: 'emailaccsett', 
         pathMatch: 'full'
        },                        
        {
            path:'emailaccsett',
            component: EmailaccsettComponent,
            canActivate: [AuthGuard],
        },{
          path:'smsaccsett',
          component: SmsaccsettComponent,
          canActivate: [AuthGuard],
        },
        {
          path:'minavomanagement',
          component: MinavomanagementComponent,
          canActivate: [AuthGuard],

        },{
        path: 'minavomanagement',
        children: [ 
          {
            path: '',
            redirectTo: 'addminavomgnt', 
            pathMatch: 'full'
           },
          {
          path:'addminavomgnt',
          component: AddminavomgntComponent,
          canActivate: [AuthGuard],
      },
      {
        path:'addminavomgnt/:id',
        component: AddminavomgntComponent,
        canActivate: [AuthGuard],
    }
    ]
  },{
          path:'crm',
          component: CrmComponent,
          canActivate: [AuthGuard],
          
        }
      ]
    },
    {
      path:'campaignlist',
      component: CampaignlistComponent,
      canActivate: [AuthGuard],
      
    },
    {
      path:'campaginview/:id',
      component: CampaginviewComponent,
      canActivate: [AuthGuard],
      
    },
    
    {
      path: 'log',
   // canActivate: [AuthGuard],
     children: [ 
       {
        path: '',
        redirectTo: 'servicealertlog', 
        pathMatch: 'full'
       },                        
       {
           path:'servicealertlog',
           component: ServicealertlogComponent,
           canActivate: [AuthGuard],
       },
       {
        path:'apierrorlog',
        component: ApierrorlogComponent,
        canActivate: [AuthGuard],
    },
    {
      path:'crmapilog',
      component: CrmapilogComponent,
      canActivate: [AuthGuard],
  },
    {
    path:'afterdisconnectapilog',
    component: AfterdisconnectapilogComponent,
    canActivate: [AuthGuard],
   },
  {
  path:'apilog',
  component: ApilogComponent,
  canActivate: [AuthGuard],
   },
      ]
      },
    
 {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] }
    },
    {
      path: 'reports',
      children: [ 
        { path: 'callreport', component: CallreportComponent, canActivate: [AuthGuard] },
        { path: 'calloverall', component: CalloverallComponent ,canActivate: [AuthGuard]},
        { path: 'callview/:uid/:cdate/:id', component: CallviewreportComponent,canActivate: [AuthGuard] },
        { path: 'calloverallview/:cdate/:id', component: CalloverallviewComponent,canActivate: [AuthGuard] },
        { path: 'smsreport', component: SmsreportComponent ,canActivate: [AuthGuard]},
        { path: 'smsoverall', component: OverallsmsComponent,canActivate: [AuthGuard] },
        { path: 'pendingrenewal', component: PendingrenewalComponent,canActivate: [AuthGuard] },
        { path: 'topupreport', component: TopupreportComponent,canActivate: [AuthGuard] },
        { path: 'renewalextend', component: RenewalextendComponent,canActivate: [AuthGuard] },
        { path: 'demoaccount', component: DemoaccountComponent,canActivate: [AuthGuard] },
        { path: 'demodetails', component: DemodetailsComponent ,canActivate: [AuthGuard]},
        { path: 'cappedcall', component: CappedcallComponent,canActivate: [AuthGuard] },
        { path: 'cappedcallview/:id', component: CappedcallviewComponent,canActivate: [AuthGuard]},
        { path: 'emailreport', component: EmailreportComponent,canActivate: [AuthGuard] },
        { path: 'emailoverallreport', component: EmailoverallreportComponent,canActivate: [AuthGuard] },
        { path: 'clientdetailreport', component: ClientdetailreportComponent,canActivate: [AuthGuard]},
        { path: 'serverpulsereport', component: ServerpulsereportComponent ,canActivate: [AuthGuard]},
        { path: 'virtualnumreport', component: VirtualnumreportComponent ,canActivate: [AuthGuard]},
        { path: 'clientwisereport', component: ClientwiseComponent ,canActivate: [AuthGuard]},
        
        ]
      },
      {
        path:'support/supportlist',
        component: SupportlistComponent,
        canActivate: [AuthGuard],
      },
    {
        path:'support/newticket',
        component:  NewticketComponent,
        canActivate: [AuthGuard],
        
      },
      {
        path: 'resseller',
        children: [ 
          { path: 'ressellerresistration', component: RessellerresistrationComponent, canActivate: [AuthGuard] },
          { path: 'ressellerresistration/:id', component: RessellerresistrationComponent, canActivate: [AuthGuard] },
          { path: 'manageresseler', component: ManageresselerComponent, canActivate: [AuthGuard] },
          { path: 'resellercreditback', component: ResellercreditbackComponent, canActivate: [AuthGuard] },
          { path: 'resellerrenewalextend', component: ResellerrenewalextendComponent, canActivate: [AuthGuard] },
          { path: 'resellerapproveplan', component: ResellerapproveplanComponent, canActivate: [AuthGuard] },
          { path: 'resellerplanlist', component: ResellerplanlistComponent, canActivate: [AuthGuard] },
          { path: 'resellercreateplan', component: ResellercreateplanComponent, canActivate: [AuthGuard] },
          { path: 'resellerviewplan/:ress_id/:plan_id', component: ResellerviewplanComponent, canActivate: [AuthGuard] },
          { path: 'resellerprofileedit/:ress_id', component: ResellerprofileeditComponent, canActivate: [AuthGuard] },
          { path: 'resellersetting/:ress_id', component: ResellersettingComponent, canActivate: [AuthGuard] },
          { path: 'resellerplanlistview/:ress_id/:plan_id', component: ResellerplanlistviewComponent, canActivate: [AuthGuard] },
          { path: 'resellervirtualinventry', component: ResellervirtualinventryComponent, canActivate: [AuthGuard] },
          { path: 'addressvnumdidbulk', component: AddressvnumdidbulkComponent, canActivate: [AuthGuard] },
          { path: 'addressvnumdidsingle', component: AddressvnumdidsingleComponent, canActivate: [AuthGuard] },
          { path: 'addressvnumdidsingle/:id', component: AddressvnumdidsingleComponent, canActivate: [AuthGuard] },
          
  ]
        },
 // otherwise redirect to home
{ path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes,{ preloadingStrategy: PreloadAllModules });