import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-smsservice',
  templateUrl: './smsservice.component.html',
  styleUrls: ['./smsservice.component.css']
})
export class SmsserviceComponent implements OnInit {
  vmessageForm:FormGroup;
  submitted:boolean = false;
  service_id:any;
  stype:any;
  acctype=['Demo','Premium'];
  records:any;
  usernamestatus:boolean=false;
  smsplansresult;

 constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
  this.service_id = this.route.snapshot.paramMap.get("service_id");

}

  ngOnInit() {
   
    this.creatForm();
    this.getdata();

  $("#validitydate").datepicker({
     defaultDate: "+1w",
      minDate:0,
      changeMonth: true,
      changeYear: true,
      dateFormat: 'yy-mm-dd',
      numberOfMonths: 1,
      showOn: "button",
      buttonImage: "assets/img/cal_icon.png",
      buttonImageOnly: true,
  });

  // $(".acchead").click(function() {
  //     $(this).next('.accbox').slideToggle('fast');
  //     $(this).toggleClass('active');
  // });

  }

  creatForm(){
    this.vmessageForm= this.fb.group({
      companyname:[{ value: ''},Validators.required],
      fname:[{ value: ''},Validators.required],
      lname:[{ value: ''}],
      username:[{ value: ''},[Validators.required,Validators.minLength(4)]],
      password:[{ value: ''},[Validators.required,Validators.pattern("((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,25})"),Validators.minLength(8)]],
      mobile:[{ value: ''},[Validators.required,Validators.maxLength(10),Validators.minLength(10)]],
      email:[{ value: ''},[Validators.required,Validators.email]],
      designation:[{ value: '',disabled:true},Validators.required],
      industry:[{ value: '',disabled:true},Validators.required],
      type_of_client:[{ value: '',disabled:true},Validators.required],
      gender:[{ value: ''}],
      address:[{ value: ''}],
      address2:[{ value: ''}],
      pincode:[{ value: ''}],
      country:[{ value: ''}],
      state:[{ value: ''}],
      city:[{ value: ''}],
      salesexec:[{ value: ''}],
      tsalesexec:[{ value: ''}],
      templcheck:[{ value: ''}],
      acctype:[{ value: ''}],
      contract:[{ value: ''},[Validators.required]],
      service_validity:[{ value: ''},[Validators.required]],
      sms_rate:[{ value: ''},[Validators.required]],
      totalsms_coin:[{ value: ''},[Validators.required]],
      smsoprater:[{ value: ''},[Validators.required]],
      coins:[{ value: ''},[Validators.required]],
      service_amount:[{ value: ''},[Validators.required]],
      smstype:[{ value: ''},[Validators.required]],
      plan_id:[{ value: ''},[Validators.required]],
      dedtype:[{ value: ''},[Validators.required]],
      remarks:[{ value: ''},Validators.required],
      vfinremarks:[{ value: ''},Validators.required],
      finance_code:[],
      caf_id:[],
      service_id:[],
      sub_service_type:'',
    })

  }
getdata(){
  $(".fullpageloader").fadeIn('fast');
  this.http.get(" https://api.cerebra.quadrafort.in/service_request_deatils?service_id="+this.service_id).subscribe((data:any)=>{

this.records = data;
 this.getplan(this.records.data[0].sms_type);
  $(".fullpageloader").fadeOut('fast');
this.vmessageForm.patchValue({
  companyname:data.data[0].company_name,
  fname:data.data[0].contact_person,
  lname:'',
  username:'',
  password:'',
  mobile:data.data[0].mobile,
  gender:'Male',
  email:data.data[0].email,
  designation:data.data[0].designation,
  industry:data.data[0].industry,
  type_of_client:data.data[0].type_of_client,
  address:data.data[0].address1,
  address2: data.data[0].address2,
  pincode:data.data[0].pincode,
  country:"India",
  state:data.data[0].state,
  city:data.data[0].city,
  salesexec:data.data[0].sale_person,
  tsalesexec:data.data[0].telesale_person,
  templcheck:'1',
  smstype:data.data[0].sms_type,
  plan_id:'',
  smsoprater:'',
  dedtype:'Sent',
  acctype:'Premium',
  contract:data.data[0].contract,
  service_validity:data.data[0].service_validity,
  sms_rate:(function(smsrate,dltrate){ return parseFloat(smsrate)+ parseFloat(dltrate)})(data.data[0].sms_rate,data.data[0].dlt_rate_in_paisa),
  totalsms_coin:function(totalamnt,smsrate,dlt_rate){
   return Math.round(totalamnt/((parseFloat(smsrate)+ parseFloat(dlt_rate))/100));
  }(this.records.data[0].service_amount,this.records.data[0].sms_rate,this.records.data[0].dlt_rate_in_paisa),
  coins:data.data[0].coins,
  service_amount:data.data[0].service_amount,
  remarks:'',
  vfinremarks:data.data[0].remarks,
  finance_code:data.data[0].finance_code,
  caf_id:data.data[0].caf_no,
  service_id:data.data[0].service_id,
  sub_service_type:"vmessage",
})
})

}
  get f(){ return this.vmessageForm.controls;}
  onSubmit(){
  this.submitted = true;
  //this.vmessageForm.get('validity').setValue($("#validitydate").val());
    var vmessagedata= JSON.stringify(this.vmessageForm.value);

    if(this.vmessageForm.invalid){
return false;
    }
    else{ 
  // alert(vmessagedata);
  $(".fullpageloader").fadeIn("fast");
      this.http.post(" https://api.cerebra.quadrafort.in/service_request_deatils_insert",{data:vmessagedata}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         this.router.navigateByUrl('/crequest/servicerequest');
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }
  getUsername(event:any){
    var serchval=event.target.value;
   // console.log(serchval);
    if(serchval.length>3){
       this.http.post(" https://api.cerebra.quadrafort.in/check_vsms_username",{username:serchval}).subscribe((data:any)=>{
       // console.log(data.status);
        if(data.status=='success'){
          this.usernamestatus=false;
        }
        else{
          this.usernamestatus=true;
        }
        
          })
    }
 
  }
  getplan(planname){
    this.http.post(" https://api.cerebra.quadrafort.in/getplan",{type:planname}).subscribe((data:any)=>{
      // console.log(data.status);
      this.smsplansresult = data;
   
         })
    
  }
  // getplanonchange(smstype){
  //   alert(smstype);
  //   this.http.post(" https://api.cerebra.quadrafort.in/getplan",{type:smstype}).subscribe((data:any)=>{
  //     console.log(data.status);
  //     this.smsplansresult = data;
     
  //        })
  // }
}
