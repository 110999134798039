import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mshindbot',
  templateUrl: './mshindbot.component.html',
  styleUrls: ['./mshindbot.component.css']
})
export class MshindbotComponent implements OnInit {
  hindotForm:FormGroup;
  demorenewform:FormGroup;
  submitted:boolean = false;
  submitted2:boolean = false;
  user_id:any;
  stype:any;
  records:any;
  buttunhide:boolean=false;
  serviceList:any;
  currentUserloclstrage:any;
  baseurl:any;
  remarksrecords:any;
  subtype:any;
  vagentcurdata=[];
  c2ccurdata=[];
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
  this.user_id = this.route.snapshot.paramMap.get("userid");
  this.stype = this.route.snapshot.paramMap.get("stype");
  this.subtype = this.route.snapshot.paramMap.get("subtype");
  console.log(`${this.user_id} ${this.stype}`)
}


ngOnInit() {
  // $(".select2").select2({
  //  minimumResultsForSearch: Infinity,
  // });
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.baseurl = localStorage.getItem('baseurl');
    this.getserviclist();
    this.creatForm();
    this.creatrenwaldemoform();
    this.getdata();

  }

  creatForm(){
    this.hindotForm= this.fb.group({
      companyname:[{ value: ''},Validators.required],
      password:[{ value: ''},[Validators.required,Validators.minLength(6)]],
      mobile:[{ value: ''},[Validators.required,Validators.maxLength(10),Validators.minLength(10)]],
      email:[{ value: ''},[Validators.required,Validators.email]],
      cperson:'',
      salesexec:[{ value: ''}],
      tsaleexec:[{ value: ''}],
      acctype:[{ value: ''}],
      service_validity:[{ value: ''},[Validators.required]],
      agent_allowed:[{ value: ''},[Validators.required]],
      bots_allowed:[{ value: ''},[Validators.required]],
      service_subtype:[{ value: ''},[Validators.required]],
      billing:[{ value: ''},[Validators.required]],
      chat_coins:[{ value: ''},[Validators.required]],
      plan_name:[{ value: ''},[Validators.required]],
      chats_allocation:[{ value: ''},[Validators.required]],
      remarks:[{ value: ''}],
      userid:'',
      stype:'hindbot',
    })
 }
getdata(){
  $(".fullpageloader").fadeIn('fast');
  this.http.get(this.baseurl+"get_service_details?user_id="+this.user_id+"&type="+this.stype).subscribe((data:any)=>{
  this.records=data;

  $(".fullpageloader").fadeOut('fast');
this.hindotForm.setValue({
  companyname:data.profile[0].company,
  mobile:data.profile[0].mobile,
  email:data.profile[0].email,
  password:data.profile[0].password,
  cperson:data.profile[0].first_name,
  salesexec:data.profile[0].sale_exc_name,
  tsaleexec:function(tsales){
    if(tsales=='undefined' || tsales=='' || tsales==undefined){
return '';
    }
    else{
return tsales
    }
  }(data.profile[0].telesale_exc_name),
  acctype:data.profile[0].account_type,
  billing:data.hindbot_plan[0].service_plan,
  service_validity:data.hindbot_plan[0].plan_term,
  agent_allowed:data.profile[0].allowed_client,
  bots_allowed:data.hindbot_plan[0].allow_bot,
  service_subtype:data.hindbot_plan[0].service_billing,
  chat_coins:data.hindbot_plan[0].chat_count,
  plan_name:data.hindbot_plan[0].plan_name,
  chats_allocation:data.hindbot_plan[0].allocation_type,
  remarks:'',
  userid:data.profile[0].user_id,
  stype:'hindbot'

  })
})

}
  get f(){ return this.hindotForm.controls;}
  onSubmit(){
  this.submitted = true;
    var mshindotdta= JSON.stringify(this.hindotForm.value);

    if(this.hindotForm.invalid){
return false;
    }
    else{
 $(".fullpageloader").fadeIn("fast");
     this.http.post(this.baseurl+"update_service",{data:mshindotdta}).subscribe((data:any)=>{
      if(data.status=="success"){
        $(".fullpageloader").fadeOut('fast');
          this.toastr.success('Data Submitted  succesfully!', 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        this.router.navigateByUrl('/servicelist');

      }
      else if(data.status=="fail"){
        $(".fullpageloader").fadeOut('fast');
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });

      }

    },
    error => {
      $(".fullpageloader").fadeOut('fast');
      this.toastr.error(error, 'error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  })
    }
  }

  getserviclist(){
    this.http.get(this.baseurl+"get_serice_list").subscribe((data:any)=>{
      this.serviceList=data.service;

  })
  }
  getremarkslist(user_id){
    this.http.get(this.baseurl+"get_remarks_list?user_id="+user_id).subscribe(
      (result:any)=>{
        console.log(result);
      this.remarksrecords= result;
     });
  }
  creatrenwaldemoform(){
    this.demorenewform = this.fb.group({
      renew_type:[{value:''},[Validators.required]],
      remarks:[{value:''},[Validators.required]],
      user_id:this.user_id,
      service_type:"vlead",
      loginid:this.currentUserloclstrage.user_id
    })
    }
    get h(){
      return this.demorenewform.controls
    }
    demorenew(){
      this.submitted2 = true;
      let renewdata= JSON.stringify(this.demorenewform.value);
      if(this.demorenewform.invalid){
    return false
      }
      else{
        $(".fullpageloader").fadeIn('fast');
        this.http.post(this.baseurl+"demo_renewal",{data:renewdata}).subscribe((data:any)=>{
          console.log(data);
          if(data.status=="success"){
            $("#renewdemo").modal('hide');
            $(".fullpageloader").fadeOut('fast');
              this.toastr.success(data.message, 'Success!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
            this.demorenewform.patchValue({
              renew_type:"",
              remarks:"",
              user_id:this.user_id,
              service_type:"vlead",
              loginid:this.currentUserloclstrage.user_id
            })
            }
          else if(data.status=="fail"){
            $(".fullpageloader").fadeOut('fast');
           this.toastr.error(data.message, 'Error!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });

          }
        },
        error => {
         $(".fullpageloader").fadeOut('fast');
          this.toastr.error(error, 'error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
      });
      }
    }


  clickEventHandler(){
    this.buttunhide = true;
   }

}
