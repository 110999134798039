import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-emailreport',
  templateUrl: './emailreport.component.html',
  styleUrls: ['./emailreport.component.css']
})
export class EmailreportComponent implements OnInit {

    servicelog;
    itemsPerPage:any=15;
   currentPage:number =1;
     filterform:FormGroup;
   submitted=false;
   today= new Date();
   jstoday = '';
   constructor(private http:HttpClient,private fb:FormBuilder) { 
 
  this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
     this.filterform = this.fb.group({
     
       fromdate: [''],
       todate: [''],
       userid:[''],
     })
 
   }
 
   ngOnInit() {
  
    this.getdata(this.currentPage,'','','')
 $("#reptype").on('change',function(){
  // alert($(this).val());
   switch($(this).val()){
   case 'byuserid':
   $("#byuserid").show('fast');
  break;
   default:
  $("#byuserid").hide('fast');
 
   }
 })
   }
 
 onSubmit()
 {
    this.submitted=true;
   //console.log(this.filterform.value);
  
   let  userid=this.filterform.value.userid;
   let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
   
    $('.fullpageloader').show('fast');
     this.http.get(' https://api.cerebra.quadrafort.in/client_wise_email_report?userid='+userid+'&fromdate='+fromdate+'&todate='+todate).subscribe(
     (data)=>{
       this.servicelog=data;
         $('.cfillBox').slideUp('fast');
        $('.fullpageloader').hide('fast');
 
     })
 
 }
 
 getdata(crpage:number,userid,fromdate,todate)
   {
     $('.fullpageloader').show('fast');
      this.http.get(' https://api.cerebra.quadrafort.in/client_wise_email_report?page='+crpage+'&userid='+userid+'&fromdate='+fromdate+'&todate='+todate).subscribe(
      (data)=>{
         this.servicelog=data;
         $('.fullpageloader').hide('fast');
      });
   }
 
    pageChange(newpage:number){
      let  userid=this.filterform.value.userid;
      let fromdate=$('#fromdate').val();
     let todate=$('#todate').val()
     this.getdata(newpage,userid,fromdate,todate);
     this.currentPage = newpage;
       }
}
