import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../_services';
import { User, Role } from '../_models';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
   notificationcheck:boolean =  false;
   homesett:boolean;
   numinventry:boolean;
   mnageservice:boolean;
   currentUser: User;
   currentUserloclstrage;
   smenunav1:boolean = true;
   smenunav2:boolean = true;
   smenunav3:boolean = true;
   smenunav4:boolean = true;
   smenunav5:boolean = true;
   smenunav6:boolean = true;
   notfidata=[];
   user_id:any;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private http:HttpClient,
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    get isAdmin() {
        return this.currentUser && this.currentUser.role === Role.Admin;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
   
  ngOnInit() {
    
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id  = this.currentUserloclstrage.user_id;
   // console.log(this.user_id);
  
  setTimeout(() => {
    this.getnotification();
  }, 3000);
     setTimeout(() => {
      $(".mainnav").mCustomScrollbar({axis:'y',theme:"minimal-dark"});
     }, 200);

  this.inactivityTime();
  }
  inactivityTime () {
    var that = this;
     var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    function resetTimer() {
        clearTimeout(time);
        time = setTimeout(function(){
      that.authenticationService.logout();
       that.router.navigate(['/login']);
     }, 36000000)
    }
}; 
  smenu1(){
    this.smenunav1 =!this.smenunav1;
    this.smenunav2 =true;
    this.smenunav3 =true;
    this.smenunav4 =true;
    this.smenunav5 =true;
    this.smenunav6 =true;
    return false;
 
  }
  smenu2(){
    this.smenunav2 =!this.smenunav2;
    this.smenunav1 =true;
    this.smenunav3 =true;
    this.smenunav4 =true;
    this.smenunav5 =true;
    this.smenunav6 =true;
  
  }
  smenu3(){
    this.smenunav3 =!this.smenunav3;
    this.smenunav2 =true;
    this.smenunav1 =true;
    this.smenunav4 =true;
    this.smenunav5 =true;
    this.smenunav6 =true;
   
  }
  smenu4(){
    this.smenunav4 =!this.smenunav4;
    this.smenunav2 =true;
    this.smenunav3 =true;
    this.smenunav1 =true;
    this.smenunav5 =true;
    this.smenunav6 =true;
  
  }
  smenu5(){
    this.smenunav5 =!this.smenunav5;
    this.smenunav2 =true;
    this.smenunav3 =true;
    this.smenunav4 =true;
    this.smenunav1 =true;
    this.smenunav6 =true;
  }
  smenu6(){
    this.smenunav6 =!this.smenunav6;
    this.smenunav2 =true;
    this.smenunav3 =true;
    this.smenunav4 =true;
    this.smenunav5 =true;
    this.smenunav1 =true;
  
  }
  ngAfterContentInit(){
    let self = this;
    $(function(){

      $(".hmb_icon").on("click", function() {
        if (!$(this).hasClass('active')) {
            $(".sidenav").animate({ left: '0' });
            $(this).addClass("active");
           
        } else {
            $(".sidenav").animate({ left: '-220px' });
            $(this).removeClass("active");
        }
    });
    // $("body *:not(.allnotif)").click(function() {
    //   self.notificationcheck = false;
    //       });
    
    });

  }
getnotification(){
  this.http.get(" https://api.cerebra.quadrafort.in/get_notification").subscribe((data:any)=>{
    this.notfidata = data.data;
    console.log(data);
   
  })
}


readall(){
  var id =  $(".notifsec ul li.read0")
  .map(function() {
    return $(this).attr('id');
  })
  .get()
  .join();
  //alert(id);
  this.http.get(" https://api.cerebra.quadrafort.in/unread_notification?id="+id+"&type=all").subscribe(
    (data:any)=>{
      console.log(data);
      if(data.status=='expired'){
         $(".fullpageloader").fadeOut();
        this.authenticationService.logout();
   }
        else if(data.status=='success'){
          $(".notfcount").text('0');
          $(".notifsec ul li.read0").addClass('read1');
          $(".notifsec ul li.read0").removeClass('read0');
      // console.log(data);
      // this.balancecoin=data;
    }
    
});

}
readnoti(event){

 let classname = event.target.className;
  let id = event.target.id;
if(classname=='read0'){
    this.http.get(" https://api.cerebra.quadrafort.in/unread_notification?id="+id+"&type=single").subscribe(
      (data:any)=>{
       console.log("hi"+data);
        if(data.status=='expired'){
           $(".fullpageloader").fadeOut();
          this.authenticationService.logout();
    
     }
          else if(data.status=='success'){
         
            $("#"+id).addClass('read1');
            $("#"+id).removeClass('read0');
            $(".notfcount").text($(".notfcount").text()-1);
          
            
            
        }
      
    }) 
  }
  else{
return false;
  }
 
}
notiopen(){
this.notificationcheck = !this.notificationcheck;
  }

  navigateto(ticketId,user_id,ticket_status,issue_type){
    this.notificationcheck = false;
   this.router.navigate(['/support/supportlist'],{ queryParams: { ticketid: ticketId,user_id:user_id,ticket_status:ticket_status,issue_type:issue_type} });
  }

}

