import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addvnumsingle',
  templateUrl: './addvnumsingle.component.html',
  styleUrls: ['./addvnumsingle.component.css']
})
export class AddvnumsingleComponent implements OnInit {
  addvnumsingleForm:FormGroup;
  submitted:boolean= false;
  u_id:any;

  operaters=['Airtel','Reliance','Vodafone','BSNL','IDEA','Tata Indicom','Aircel','MTNL','Spice','MTNL'];
  circles=['Andaman and Nicobar Islands','Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chandigarh','Chhattisgarh','Dadra and Nagar Haveli','Delhi','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu and Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Puducherry','Punjab','Rajasthan','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal'];
  
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
   this.u_id = this.route.snapshot.paramMap.get("id");
    console.log(this.u_id);
    if (this.u_id !== null){
    this.editform(this.u_id)
    }

   }

  ngOnInit() {
this.createForm();
  }
createForm(){
  this.addvnumsingleForm = this.fb.group({
    virtual_number:['',[Validators.required]],
    imsi:['',[Validators.required]],
    operator:['',[Validators.required]],
    circle:['',[Validators.required]],
    class:['',[Validators.required]],
    sim_id:['',[Validators.required]],
    remarks:[''],
    uid:[''],
  })
}

editform(id:number){
  $(".fullpageloder").fadeIn('fast');
this.http.get(" https://api.cerebra.quadrafort.in/edit_vnum?uid="+id).subscribe((data:any)=>{
//console.log(data);
this.addvnumsingleForm.setValue({
  virtual_number:data[0].mobile,
  imsi:data[0].imsi,
  operator:data[0].operator,
  circle:data[0].circle,
  class:data[0].number_class,
  sim_id:data[0].sim_pack_id,
  remarks:data[0].remarks,
  uid:data[0].uid,
});
$(".fullpageloder").fadeOut('fast');
})
}
get f(){return this.addvnumsingleForm.controls;}
onSubmit(){
this.submitted=true;
let addvnumsformdata = JSON.stringify(this.addvnumsingleForm.value);
if(this.addvnumsingleForm.invalid){
 //alert(this.addvnumsingleForm.value) 
return false;
}
else{
$(".fullpageloader").fadeIn('fast');
this.http.post(" https://api.cerebra.quadrafort.in/add_vnum_single",{data:addvnumsformdata}).subscribe((data:any)=>{
 
  if(data.status=='success'){
    $(".fullpageloader").fadeOut('fast');
    this.toastr.success('Data Submitted  succesfully!', 'Success!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
    this.router.navigateByUrl('/numinventry/virtualinventry');
  }
  else if(data.status=="fail"){
    console.log("fail");
    $(".fullpageloader").fadeOut('fast');
    this.toastr.error(data.message, 'Error!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });
    
   }
}, error => {
  $(".fullpageloader").fadeOut('fast');
  this.toastr.error(error, 'error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
}
)

}
}
}
