import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'; 
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-resellerrequest',
  templateUrl: './resellerrequest.component.html',
  styleUrls: ['./resellerrequest.component.css']
})
export class ResellerrequestComponent implements OnInit {
  submitted1:boolean = false;
  submitted:boolean = false;
  itemsPerPage:any=15;
  currentPage:number =1;
  baseurl:any;
  plansrecords:any;
  today= new Date();
  jstoday = '';
  constructor(private fb:FormBuilder, private http:HttpClient, private toastr:ToastrService, private router:Router , private route:ActivatedRoute) {



   }

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
   
this.getrecords();
  }

  getrecords(){
//$(".fullpageloader").fadeIn('fast');
this.http.get(this.baseurl+"reseller_request").subscribe((data:any)=>{
  if(data.status=='success'){
   // $(".fullpageloader").fadeOut('fast');
    this.plansrecords = data;
  }

})
  }

  


  // pageChange(newpage:number){
  //   this.getrecords(newpage);
  //   this.currentPage = newpage;
  //     }
      navogateUrl(partner_id,plan_id){
      
        this.router.navigate(['/resseller/ressellerresistration',partner_id]);
     
     }

}
