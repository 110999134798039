import { Component, OnInit,ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {formatDate } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-settleamount',
  templateUrl: './settleamount.component.html',
  styleUrls: ['./settleamount.component.css']
})
export class SettleamountComponent implements OnInit {
  service:any;
  searchForm:FormGroup;
  settleamtForm:FormGroup;
  baseurl:any;
  submitted:boolean=false;
  submitted1:boolean=false;
  section1:boolean = true;
  section2:boolean = false;
  section3:boolean = false;
  section4:boolean = false;
  section5:boolean = false;
  settlenowsec:boolean = false;
  addaysec:boolean = true;
  addatesec:boolean = false;
  
  
  constructor(private http:HttpClient, private fb:FormBuilder, private toastr:ToastrService, private route:Router,private _host: ElementRef) { 
 
   }
  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
    this.createsettleform();
    this.createsearchform();
    this.datepickerfun();
    this.datepickerfun1();
    $(".searchlist a").click(function(e) {
      $(".slistdrwn").slideToggle('fast');
     });
  
   
     $(".slistdrwn li").on('click',function(e) {
   
        var sval = "Enter" + " " + $(this).text();
         //alert(sval);
         $(".searchinput").attr('placeholder', sval);
         $(".searchinput1").val($(this).text());
         $(".slistdrwn").slideUp('fast');
         
   });

  
   this.settleamtForm.get('account_type').valueChanges
  .subscribe(account_type => {
   
 if(account_type=='adjust_activation_date'){
   this.section1 = true;
   this.section2 = false;
   this.section3 = false;
   this.section4 = false;
   this.section5 = false;

  
   this.settleamtForm.get('newactvtiondate').setValidators([Validators.required]);
   this.settleamtForm.get('newcallrate').setValidators(null);
   this.settleamtForm.get('newclientsettig').setValidators(null);
   this.settleamtForm.get('coins').setValidators(null);
   this.settleamtForm.get('amount').setValidators(null);
   this.settleamtForm.get('cointype').setValidators(null);
   this.settleamtForm.get('coinadjusttype').setValidators(null);
   this.settleamtForm.get('coinadjustfor').setValidators(null);
   this.settleamtForm.get('vdays').setValidators(null);
   this.settleamtForm.get('vdates').setValidators(null);

   
   this.settleamtForm.get('vdays').updateValueAndValidity();
   this.settleamtForm.get('vdates').updateValueAndValidity();
   this.settleamtForm.get('newactvtiondate').updateValueAndValidity();
   this.settleamtForm.get('newcallrate').updateValueAndValidity();
   this.settleamtForm.get('newclientsettig').updateValueAndValidity();
   this.settleamtForm.get('coins').updateValueAndValidity();
   this.settleamtForm.get('amount').updateValueAndValidity();
   this.settleamtForm.get('cointype').updateValueAndValidity();
   this.settleamtForm.get('coinadjusttype').updateValueAndValidity();
   this.settleamtForm.get('coinadjustfor').updateValueAndValidity();

   


   setTimeout(() => {
    this.datepickerfun();
   }, 300);

  }
  else if(account_type=='adjust_validity'){
   this.section1 = false;
   this.section2 = true;
   this.section3 = false;
   this.section4 = false;
   this.section5 = false;
   
   this.settleamtForm.get('newactvtiondate').setValidators(null);
   this.settleamtForm.get('newcallrate').setValidators(null);
   this.settleamtForm.get('newclientsettig').setValidators(null);
   this.settleamtForm.get('coins').setValidators(null);
   this.settleamtForm.get('amount').setValidators(null);
   this.settleamtForm.get('cointype').setValidators(null);
   this.settleamtForm.get('coinadjusttype').setValidators(null);
   this.settleamtForm.get('coinadjustfor').setValidators(null);
  
  // if(this.settleamtForm.get('validityadday').value=='date'){

  //   this.settleamtForm.get('vdates').setValidators([Validators.required]);
  //   this.settleamtForm.get('vdays').setValidators(null);
  //  }
  //  else{
  //   this.settleamtForm.get('vdays').setValidators([Validators.required]);
  //   this.settleamtForm.get('vdates').setValidators(null);
  //  }
   
  //  this.settleamtForm.get('vdays').updateValueAndValidity();
  //  this.settleamtForm.get('vdates').updateValueAndValidity();
   this.settleamtForm.get('newactvtiondate').updateValueAndValidity();
   this.settleamtForm.get('newcallrate').updateValueAndValidity();
   this.settleamtForm.get('newclientsettig').updateValueAndValidity();
   this.settleamtForm.get('coins').updateValueAndValidity();
   this.settleamtForm.get('amount').updateValueAndValidity();
   this.settleamtForm.get('cointype').updateValueAndValidity();
   this.settleamtForm.get('coinadjusttype').updateValueAndValidity();
   this.settleamtForm.get('coinadjustfor').updateValueAndValidity();

   
   setTimeout(() => {
    this.datepickerfun1();
   }, 300);
  }
  else if(account_type=='adjust_callrate'){
    this.section1 = false;
   this.section2 = false;
   this.section3 = true;
   this.section4 = false;
   this.section5 = false;
   
   this.settleamtForm.get('newactvtiondate').setValidators(null);
   this.settleamtForm.get('newcallrate').setValidators([Validators.required]);
   this.settleamtForm.get('newclientsettig').setValidators(null);
   this.settleamtForm.get('coins').setValidators(null);
   this.settleamtForm.get('amount').setValidators(null);
   this.settleamtForm.get('cointype').setValidators(null);
   this.settleamtForm.get('coinadjusttype').setValidators(null);
   this.settleamtForm.get('coinadjustfor').setValidators(null);
   this.settleamtForm.get('vdays').setValidators(null);
   this.settleamtForm.get('vdates').setValidators(null);

   
   this.settleamtForm.get('vdays').updateValueAndValidity();
   this.settleamtForm.get('vdates').updateValueAndValidity();
   this.settleamtForm.get('newactvtiondate').updateValueAndValidity();
   this.settleamtForm.get('newcallrate').updateValueAndValidity();
   this.settleamtForm.get('newclientsettig').updateValueAndValidity();
   this.settleamtForm.get('coins').updateValueAndValidity();
   this.settleamtForm.get('amount').updateValueAndValidity();
   this.settleamtForm.get('cointype').updateValueAndValidity();
   this.settleamtForm.get('coinadjusttype').updateValueAndValidity();
   this.settleamtForm.get('coinadjustfor').updateValueAndValidity();
  }
  else if(account_type=='adjust_coin_allo_type'){
    this.section1 = false;
   this.section2 = false;
   this.section3 = false;
   this.section4 = true;
   this.section5 = false;

   this.settleamtForm.get('newactvtiondate').setValidators(null);
   this.settleamtForm.get('newcallrate').setValidators(null);
   this.settleamtForm.get('newclientsettig').setValidators([Validators.required]);
   this.settleamtForm.get('coins').setValidators(null);
   this.settleamtForm.get('amount').setValidators(null);
   this.settleamtForm.get('cointype').setValidators(null);
   this.settleamtForm.get('coinadjusttype').setValidators(null);
   this.settleamtForm.get('coinadjustfor').setValidators(null);
   this.settleamtForm.get('vdays').setValidators(null);
   this.settleamtForm.get('vdates').setValidators(null);

   
   this.settleamtForm.get('vdays').updateValueAndValidity();
   this.settleamtForm.get('vdates').updateValueAndValidity();
   this.settleamtForm.get('newactvtiondate').updateValueAndValidity();
   this.settleamtForm.get('newcallrate').updateValueAndValidity();
   this.settleamtForm.get('newclientsettig').updateValueAndValidity();
   this.settleamtForm.get('coins').updateValueAndValidity();
   this.settleamtForm.get('amount').updateValueAndValidity();
   this.settleamtForm.get('cointype').updateValueAndValidity();
   this.settleamtForm.get('coinadjusttype').updateValueAndValidity();
   this.settleamtForm.get('coinadjustfor').updateValueAndValidity();
  }
  else{
    this.section1 = false;
    this.section2 = false;
    this.section3 = false;
    this.section4 = false;
    this.section5 = true;

    this.settleamtForm.get('newactvtiondate').setValidators(null);
   this.settleamtForm.get('newcallrate').setValidators(null);
   this.settleamtForm.get('newclientsettig').setValidators(null);
   this.settleamtForm.get('coins').setValidators([Validators.required]);
   this.settleamtForm.get('amount').setValidators([Validators.required]);
   this.settleamtForm.get('cointype').setValidators([Validators.required]);
   this.settleamtForm.get('coinadjusttype').setValidators([Validators.required]);
   this.settleamtForm.get('coinadjustfor').setValidators([Validators.required]);
   this.settleamtForm.get('vdays').setValidators(null);
   this.settleamtForm.get('vdates').setValidators(null);

   
   this.settleamtForm.get('vdays').updateValueAndValidity();
   this.settleamtForm.get('vdates').updateValueAndValidity();
   this.settleamtForm.get('newactvtiondate').updateValueAndValidity();
   this.settleamtForm.get('newcallrate').updateValueAndValidity();
   this.settleamtForm.get('newclientsettig').updateValueAndValidity();
   this.settleamtForm.get('coins').updateValueAndValidity();
   this.settleamtForm.get('amount').updateValueAndValidity();
   this.settleamtForm.get('cointype').updateValueAndValidity();
   this.settleamtForm.get('coinadjusttype').updateValueAndValidity();
   this.settleamtForm.get('coinadjustfor').updateValueAndValidity();
  }

});

this.settleamtForm.get('validityadday').valueChanges
  .subscribe(validityadday => {
    
if(validityadday=='date'){
 this.addaysec = false;
this.addatesec= true;
this.settleamtForm.get('vdates').setValidators([Validators.required]);
this.settleamtForm.get('vdays').setValidators(null);

this.settleamtForm.get('vdays').updateValueAndValidity();
this.settleamtForm.get('vdates').updateValueAndValidity();

setTimeout(() => {
  this.datepickerfun1();
 }, 300);
}
else{
  this.addaysec= true;
  this.addatesec= false;
this.settleamtForm.get('vdates').setValidators(null);
this.settleamtForm.get('vdays').setValidators([Validators.required]);

this.settleamtForm.get('vdays').updateValueAndValidity();
this.settleamtForm.get('vdates').updateValueAndValidity();
}
  });
  }
  createsearchform(){
    this.searchForm = this.fb.group({
        searchtext:['',[Validators.required]],
        searchinput:[''],
       
     })
    }
    get g(){return this.searchForm.controls;}
searchFilter(searchdata:any){
 this.submitted1= true;
 if(this.searchForm.invalid){
  $("#forminvalid").fadeIn(200).fadeOut(6000);
  $(".searchinput").css('border-color','#ff0000');
setTimeout(() => {
  $(".searchinput").css('border-color','#b8b8b8');
}, 6000);
   return false;
}
  else{
    var searchtext = searchdata.searchtext;
   var searchtype = $(".searchinput1").val();
   var stype = 'vco';
    $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+"service?type="+stype+"&searchtext="+searchtext+"&searchtype="+searchtype).subscribe(
    (result:any)=>{
    this.service=result;
    this.settleamtForm.get('user_id').setValue('');
  this.settlenowsec = false;
    $('.fullpageloader').hide('fast');

    });
  }
 
}
createsettleform(){
this.settleamtForm= this.fb.group({
  account_type:'adjust_activation_date',
  newactvtiondate:['',[Validators.required]],
  activ_status:'1',
  validity_status:'1',
  validityadday:'month',
  vdays:'',
  vdates:'',
  newcallrate:'',
  callrate_status:'1',
  newclientsettig:'one time no deduction',
  coinallo_status:'1',
  coins:'',
  amount:'',
  cointype:'',
  coinadjusttype:'',
  coinadjustfor:'',
  adjustcoin_status:'1',
  cremarks:['',[Validators.required]],
  mremarks:['',[Validators.required]],
  user_id:''
  })
}
get f(){return this.settleamtForm.controls;}
submtform(val){

 this.submitted = true;
 this.settleamtForm.get('newactvtiondate').setValue($("#todate").val());
 this.settleamtForm.get('vdates').setValue($("#todate1").val());
 var settleamtFormdata= JSON.stringify(this.settleamtForm.value);
if(this.settleamtForm.invalid){
return false;
}
else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"reset_account_activity",{data:settleamtFormdata}).subscribe((data:any)=>{
        alert(JSON.stringify(data));
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
}
settlenow(id:any){
  this.settleamtForm.get('user_id').setValue(id);
  this.settlenowsec = true;
 setTimeout(() => {
  this.datepickerfun();
 }, 300);
}

datepickerfun(){
  $(".datepickericon1 input[type='text']").datepicker({
    defaultDate: "+1w",
    changeMonth: true,
    changeYear: true,
    numberOfMonths: 1,
    showOn: "button",
    buttonImage: "assets/img/cal_icon.png",
    buttonImageOnly: true,
    dateFormat: "yy-mm-dd",
    yearRange: "2014:+0",
    maxDate: 0
  })
}
datepickerfun1(){
  $(".datepickericon2 input[type='text']").datepicker({
    defaultDate: "+1w",
    changeMonth: true,
    changeYear: true,
    numberOfMonths: 1,
    showOn: "button",
    buttonImage: "assets/img/cal_icon.png",
    buttonImageOnly: true,
    dateFormat: "yy-mm-dd",
    yearRange: "2014:+20",
    minDate: 0
  })
}


}
