import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hindbot',
  templateUrl: './hindbot.component.html',
  styleUrls: ['./hindbot.component.css']
})
export class HindbotComponent implements OnInit {
  hindotForm:FormGroup;
  submitted:boolean = false;
  service_id:any;
  stype:any;
  acctype=['Demo','Premium'];
 records:any;
 userList:boolean=false;
 serviceList:any;

 constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
  this.service_id = this.route.snapshot.paramMap.get("service_id");

}

ngOnInit() {
  this.getserviclist();
  this.creatForm();
  this.getdata();

$("#validitydate").datepicker({
   defaultDate: "+1w",
    minDate:0,
    changeMonth: true,
    changeYear: true,
    dateFormat: 'yy-mm-dd',
    numberOfMonths: 1,
    showOn: "button",
    buttonImage: "assets/img/cal_icon.png",
    buttonImageOnly: true,
});
$(".acchead").click(function() {
    $(this).next('.accbox').slideToggle('fast');
    $(this).toggleClass('active');
});

$(".select2").select2({
  minimumResultsForSearch: -1
});

}

creatForm(){
  this.hindotForm= this.fb.group({
    
 
   
    companyname:[{ value: ''},Validators.required],
    fname:[{ value: ''},Validators.required],
    lname:[{ value: ''}],
    username:[{ value: ''},Validators.required],
    password:[{ value: ''},[Validators.required,Validators.minLength(6)]],
    mobile:[{ value: ''},[Validators.required,Validators.maxLength(10),Validators.minLength(10)]],
    email:[{ value: ''},[Validators.required,Validators.email]],
    designation:[{ value: ''},Validators.required],
    industry:[{ value: ''},Validators.required],
    
    gender:[{ value: ''}],
    address:[{ value: ''},[Validators.required]],
    address2:[{ value: ''},[Validators.required]],
    pincode:[{ value: ''},[Validators.required,Validators.maxLength(6),Validators.minLength(6)]],
    country:[{ value: ''},[Validators.required]],
    state:[{ value: ''},[Validators.required]],
    city:[{ value: ''},[Validators.required]],
    salesexec:[{ value: ''}],
    tsalesexec:[{ value: ''}],
    acctype:[{ value: ''}],
    contract:[{ value: ''},[Validators.required]],
    service_validity:[{ value: ''},[Validators.required]],
    agent_allowed:[{ value: ''},[Validators.required]],
    bots_allowed:[{ value: ''},[Validators.required]],
    service_subtype:[{ value: ''},[Validators.required]],
    billing:[{ value: ''},[Validators.required]],
    chat_coins:[{ value: ''},[Validators.required]],
    plan_name:[{ value: ''},[Validators.required]],
    chats_allocation:[{ value: ''},[Validators.required]],
    remarks:[{ value: ''}],
    vfinremarks:[{ value: ''},Validators.required],
    finance_code:'',
    caf_id:'',
    service_id:'',
    sub_service_type:'',
  })

}
getdata(){
$(".fullpageloader").fadeIn('fast');
this.http.get(" https://api.cerebra.quadrafort.in/service_request_deatils?service_id="+this.service_id).subscribe((data:any)=>{

this.records = data;
console.log(this.records);

$(".fullpageloader").fadeOut('fast');
this.hindotForm.setValue({


companyname:data.data[0].company_name,
fname:data.data[0].contact_person,
lname:'',
username:data.data[0].contact_person,
password:'',
mobile:data.data[0].mobile,
gender:'Male',
email:data.data[0].email,
designation:data.data[0].designation,
industry:data.data[0].industry,
address:data.data[0].address1,
address2: data.data[0].address2,
pincode:data.data[0].pincode,
country:"India",
state:data.data[0].state,
city:data.data[0].city,
salesexec:data.data[0].sale_person,
tsalesexec:data.data[0].telesale_person,
acctype:'Premium',
contract:data.data[0].contract,
service_validity:data.data[0].service_validity,
agent_allowed:data.data[0].allow_agent,
bots_allowed:data.data[0].channel,
service_subtype:data.data[0].service_subtype,
billing:data.data[0].billing,
chat_coins:data.data[0].total_coins,
plan_name:data.data[0].plan_name,
chats_allocation:data.data[0].allocation_type,
remarks:'',
vfinremarks:data.data[0].remarks,
finance_code:data.data[0].finance_code,
caf_id:data.data[0].caf_no,
service_id:data.data[0].service_id,
sub_service_type:"HindBot",

})
})

}
get f(){ return this.hindotForm.controls;}
onSubmit(){
this.submitted = true;

  var hindbotdata= JSON.stringify(this.hindotForm.value);
alert(hindbotdata);
  if(this.hindotForm.invalid){
return false;
  }
  else{
$(".fullpageloader").fadeIn("fast");
    this.http.post(" https://api.cerebra.quadrafort.in/service_request_deatils_insert",{data:hindbotdata}).subscribe((data:any)=>{
     if(data.status=="success"){
       $(".fullpageloader").fadeOut('fast');
         this.toastr.success('Data Submitted  succesfully!', 'Success!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
       this.router.navigateByUrl('/crequest/servicerequest');
      
     }
     else if(data.status=="fail"){
       $(".fullpageloader").fadeOut('fast');
      this.toastr.error(data.message, 'Error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
     
    }
    
  },
   error => {
     $(".fullpageloader").fadeOut('fast');
     this.toastr.error(error, 'error!',{
       positionClass:'toast-top-center',
       timeOut: 3000
    });
})
  }
}

getserviclist(){
  this.http.get(" https://api.cerebra.quadrafort.in/get_serice_list").subscribe((data:any)=>{
    this.serviceList=data.service;

})
}

}
