import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-campaginview',
  templateUrl: './campaginview.component.html',
  styleUrls: ['./campaginview.component.css']
})

export class CampaginviewComponent implements OnInit
{
  campaignForm:FormGroup;
campaigntype:any
company:any;
userid:any;
campaignid:any;
status:any;
fromdate:any;
todate:any;
campaignview;
campaign_id:any
submitted:boolean=false;
baseurl:any;
noinputSoundSec:boolean=false;
holdsoundsec:boolean=false;
wronginputSec:boolean=false;
smssettinsec:boolean=false;
dcalltrantable:boolean=false;
dtmfwcalltable:boolean=false;
hideactionBtn:boolean=false;
hdeshowBtn:boolean=false;
dtmfwsoundtable:boolean=false;
networkList=[];
outcliList=[];
nubmerfreezed=[];
serverList:any;
OBDnetworkList=[];
selectedCli=[];
currentUserloclstrage:any;
session_id:any;
user_id:any;
duplicateNum:boolean = false;
alreadyassign:boolean = false;
dtmfcallsound:boolean = false;
nav_sound:boolean = false;
agentListnetwork=[];
agentListsamecase=[];
constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
  this.campaign_id = this.route.snapshot.paramMap.get("id");
}
  today= new Date();
  jstoday = '';
  ngOnInit()
  {

    this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    $(".pool_network").select2();
    $(".outcli_select").select2();
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.baseurl = localStorage.getItem('baseurl');
    this.session_id =  this.currentUserloclstrage.user_id;
    //this.user_id =  this.currentUserloclstrage.user_id;
    this.getc2cserver();
    this.createform();
    this.getdata();
     $("#agentList,#agentLis1").select2();



$(function(){
  var endDateTextBox = $('#enddate');
   $.timepicker.datetimeRange(
                     endDateTextBox, {
                      minInterval: (1000 * 60 * 5), // 1hr
                      dateFormat: 'dd M yy',
                      timeFormat: 'HH:mm',
                      minDateTime: 0,
                      start: {
                          hourMin: 8,
                          // minuteMin: mintime,
                          hourMax: 19,
                          minInterval: (1000 * 60 * 5),
                      }, // start picker options
                      end: {
                          hourMin: 8,
                          //  minuteMin: mintime,
                          hourMax: 19,
                          minInterval: (1000 * 60 * 5),
                      } // end picker options
                  }
              );

   });

  }

  getdata()
  {

   $('.fullpageloader').fadeIn('fast');
    this.http.get(" https://api.cerebra.quadrafort.in/get_campaign_details?campaign_id="+this.campaign_id).subscribe(
    (data)=>{

      this.campaignview=data;
      this.user_id=this.campaignview.camp_details[0].user_id;
     // this.serverList=this.campaignview.server_ip;
      this.OBDnetworkList=this.campaignview.obd_network;
    for(var scli=0;this.campaignview.obd_network.length>scli;scli++){
     if(this.campaignview.obd_network[scli].cli!=''){
      var splititem=this.campaignview.obd_network[scli].cli.split(',');
      this.selectedCli[scli]=splititem;
     }
     else{
      this.selectedCli[scli]=[];
     }
    }


      switch(this.campaignview.camp_details[0]["ivr_template"]){
     case '0':
      this.noinputSoundSec=false;
      this.holdsoundsec=false;
      this.wronginputSec=false;
      this.smssettinsec=true;
      this.dcalltrantable=false;
      this.dtmfwcalltable=false;
      this.dtmfwsoundtable=false;
    this.dtmfcallsound=true;
    this.nav_sound=false;
     break;
     case '1':
      this.noinputSoundSec=true;
      this.holdsoundsec=true;
      this.wronginputSec=true;
      this.smssettinsec=true;
      this.dcalltrantable=false;
      this.dtmfwcalltable=true;
      this.dtmfcallsound=false;
      this.nav_sound=true;
    break;
    case '2':
      this.noinputSoundSec=true;
      this.holdsoundsec=false;
      this.wronginputSec=true;
      this.smssettinsec=true;
      this.dcalltrantable=false;
      this.dtmfwcalltable=false;
      this.dtmfwsoundtable=true;
      this.dtmfcallsound=false;
      this.nav_sound=true;
    break;
     default:
      this.noinputSoundSec=false;
      this.holdsoundsec=false;
      this.wronginputSec=false;
      this.smssettinsec=false;
      this.dcalltrantable=true;
      this.dtmfwcalltable=false;
      this.dtmfwsoundtable=false
      this.nav_sound=false;
    }
  //  console.log(this.campaignview)
  this.campaignForm.patchValue({
      campaign_id:this.campaign_id,
      template_type:this.campaignview.camp_details[0].ivr_template,
      ivrtype:this.campaignview.camp_details[0].campaign_type,
      campaignname:this.campaignview.camp_details[0].campaign_name,
      soundfile:this.campaignview.camp_details[0].sound_id,
      navigationsound:this.campaignview.camp_details[0].nav_sound_id,
      callsuccmsg:this.campaignview.camp_details[0].caller_success,
      callfailmsg:this.campaignview.camp_details[0].caller_fail,
      agentsuccmsg:this.campaignview.camp_details[0].agent_success,
      agentfailmsg:this.campaignview.camp_details[0].agent_fail,
      agent:(function(agent){
        if(agent=='All'){
        return 'All'
        }
        else{
          return 'Custom'
        }
      })(this.campaignview.camp_details[0].agent_number),
      agentsname:function(sid){
        var $c2cid = $("#agentList").select2();
        if(sid==null || sid==undefined || sid==''){
          $c2cid.val(sid).trigger('change');
         return '';
        }
        else{
          var siddata=sid.split(",");
        //  $c2cid.val(["1","2"]).trigger('change');

          setTimeout(() => {
       $c2cid.val('val',siddata).trigger('change');

        }, 50);
          return sid;
        }
      }(this.campaignview.camp_details[0].agent_number),
      adminsuccmsg:this.campaignview.camp_details[0].admin_success,
      adminfailmsg:this.campaignview.camp_details[0].admin_fail,
      ownersuccmsg:this.campaignview.camp_details[0].owner_success,
      ownerfailmsg:this.campaignview.camp_details[0].owner_fail,
      callsuccmsg1:this.campaignview.camp_details[0].caller_success_email,
      callfailmsg1:this.campaignview.camp_details[0].caller_fail_email,
      agentsuccmsg1:this.campaignview.camp_details[0].agent_success_email,
      agentfailmsg1:this.campaignview.camp_details[0].agent_fail_email,
      agent1:(function(agent){
        if(agent=='All'){
        return 'All'
        }
        else{
          return 'Custom'
        }
      })(this.campaignview.camp_details[0].agent_email),
      agentsname1:function(sid){
        var $c2cid = $("#agentList1").select2();
        if(sid==null || sid==undefined || sid==''){
          $c2cid.val(sid).trigger('change');
         return '';
        }
        else{
          var siddata=sid.split(",");
        //  $c2cid.val(["1","2"]).trigger('change');

          setTimeout(() => {
       $c2cid.val('val',siddata).trigger('change');

        }, 50);
          return sid;
        }
      }(this.campaignview.camp_details[0].agent_email),
      adminsuccmsg1:this.campaignview.camp_details[0].admin_success_email,
      adminfailmsg1:this.campaignview.camp_details[0].admin_fail_email,
      ownersuccmsg1:this.campaignview.camp_details[0].owner_success_email,
      ownerfailmsg1:this.campaignview.camp_details[0].owner_fail_email,
      noinputsound:this.campaignview.camp_details[0].no_sound_id,
      holdsound:this.campaignview.camp_details[0].hold_sound_id,
      wrongsound:this.campaignview.camp_details[0].wrong_sound_id,
      duration:this.campaignview.camp_details[0].retry_duration,
      retry:this.campaignview.camp_details[0].retry_counter,
       enddatetime:'',
       startretry:this.campaignview.camp_details[0].retry_start,
  })


      setTimeout(() => {
        $(".agentnumList").select2();
        for(var anum=0; this.campaignview.rule.length>anum;anum++){
          var diragentnum =  $("#diragentnum"+anum).select2();
          var agentnummbers =  $("#agentnummbers"+anum).select2();
          var diragentemail =  $("#diragentemail"+anum).select2();

          if(this.campaignview.rule[anum].agent_number==null || this.campaignview.rule[anum].agent_number==undefined || this.campaignview.rule[anum].agent_number==''){
            diragentnum.val(this.campaignview.rule[anum].agent_number).trigger("change");
          }
          else{
            var anumlist = this.campaignview.rule[anum].agent_number.split(",");
            diragentnum.val(anumlist).trigger("change");

          }
          if(this.campaignview.rule[anum].mobile==null || this.campaignview.rule[anum].mobile==undefined || this.campaignview.rule[anum].mobile==''){
            agentnummbers.val(this.campaignview.rule[anum].mobile).trigger("change");
          }
          else{
            var agentnum = this.campaignview.rule[anum].mobile.split(",");
            agentnummbers.val(agentnum).trigger("change");
          }
          if(this.campaignview.rule[anum].agent_email==null || this.campaignview.rule[anum].agent_email==undefined || this.campaignview.rule[anum].agent_email=='' || this.campaignview.rule[anum].agent_email==""){
            diragentemail.val(this.campaignview.rule[anum].agent_email).trigger("change");
            console.log(this.campaignview.rule[anum].agent_email+"--"+typeof this.campaignview.rule[anum].agent_email);
          }
          else{
            var anumemaillist = this.campaignview.rule[anum].agent_email.split(",");
            diragentemail.val(anumemaillist).trigger("change");

          }

        }


      }, 600);

     this.allnetwork();
 });
  }
  createform(){
    this.campaignForm=this.fb.group({
      campaign_id:this.campaign_id,
      template_type:'',
      ivrtype:[{value:''}],
      campaignname:['',Validators.required],
      soundfile:['',Validators.required],
      navigationsound:'',
      callsuccmsg:'',
      callfailmsg:'',
      agentsuccmsg:'',
      agentfailmsg:'',
      ownersuccmsg:'',
      ownerfailmsg:'',
      agent:'',
      agentsname:'',
      adminsuccmsg:'',
      adminfailmsg:'',
      callsuccmsg1:'',
      callfailmsg1:'',
      agentsuccmsg1:'',
      agentfailmsg1:'',
      ownersuccmsg1:'',
      ownerfailmsg1:'',
      agent1:'',
      agentsname1:'',
      adminsuccmsg1:'',
      adminfailmsg1:'',
      noinputsound:'',
      holdsound:'',
      wrongsound:'',
      duration:'',
      retry:'',
      enddatetime:'',
      startretry:'',
      networksdata:this.fb.array([this.addnetworks()]),
    })
  }

 addnetworks(): FormGroup {
  return this.fb.group({
    server:['',Validators.required],
    network:['',Validators.required],
    pool_network:[''],
    outcli:[''],
    channelno:[''],
    retrysel:['',Validators.required],
  });
}
addnetwork(): void {
 (<FormArray>this.campaignForm.get('networksdata')).push(this.addnetworks());
  setTimeout(() => {
    $(".pool_network").select2();
    }, 10);
}

removenetwork(i: number){
this.campaignForm.controls['networksdata']['controls'].splice(i, 1);
this.campaignForm.controls['networksdata'].updateValueAndValidity();
}
  get f(){ return this.campaignForm.controls;}
  onSubmit(){
    var cliitemarra=[];
    const cliitemarralength = (<FormArray>this.campaignForm.get('networksdata')).length;
  for(var kt= 0;cliitemarralength>kt;kt++){
    var outcliarray = $("#outcli_select"+kt).val();
    cliitemarra.push(outcliarray);
   }

  var result = [];

  for (var idk=0, iLen=cliitemarra.length; idk<iLen; idk++) {
    result = result.concat(cliitemarra[idk]);
  }
  //console.log(result);


  //let checkcli =  outclicurarray.some(i => result.includes(i));
  var checkcli = result.some(function(item, idx){
    return result.indexOf(item) != idx
  });
  this.duplicateNum=checkcli;

  if(this.alreadyassign==true){
    this.toastr.error("Out CLI already assigend. Please choose Another.", 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
return false;
  }
 else if(this.duplicateNum==true){
  this.toastr.error("You can not choose same Out CLI. Please choose Another.", 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  return false;
 }
 else{
  this.campaignForm.get('enddatetime').setValue($("#enddate").val());
  this.campaignForm.get('enddatetime').updateValueAndValidity();
 if(this.campaignForm.get('agent').value=='Custom'){

   var c2clists = $("#agentList").val();
   var c2clists = $("#agentList1").val();

 if(c2clists!=undefined || c2clists!='' || c2clists!=null){

 var vglvals1final = c2clists.map(function(item){
 return item
 }).join(",");

 }
 else{
   vglvals1final='';
 }
 if(c2clists!=undefined || c2clists!='' || c2clists!=null){

  var agentemaillist = c2clists.map(function(item){
  return item
  }).join(",");

  }
  else{
    agentemaillist='';
  }
 this.campaignForm.get('agentsname').setValue(vglvals1final);
 this.campaignForm.get('agentsname1').setValue(agentemaillist);
 }

 const totallength = (<FormArray>this.campaignForm.get('networksdata')).length;
 for(var tl= 0;totallength>tl;tl++){
   var clisec = $("#outcli_select"+tl).val();
   var pool_networksec =  $("#pool_network"+tl).val();
  if(clisec!=undefined || clisec!='' || clisec!=null){

    var clisecfinal = clisec.map(function(item){
    return item
    }).join(",");

    }
    else{
      clisecfinal='';
    }
    if(pool_networksec!=undefined || pool_networksec!='' || pool_networksec!=null){

      var pool_networksecfinal = pool_networksec.map(function(item){
      return item
      }).join(",");

      }
      else{
        pool_networksecfinal='';
      }
    this.campaignForm.get('networksdata')['controls'][tl]['controls']['outcli'].setValue(clisecfinal);
    this.campaignForm.get('networksdata')['controls'][tl]['controls']['outcli'].updateValueAndValidity();
    this.campaignForm.get('networksdata')['controls'][tl]['controls']['pool_network'].setValue(pool_networksecfinal);
    this.campaignForm.get('networksdata')['controls'][tl]['controls']['pool_network'].updateValueAndValidity();

  }

   this.submitted = true;
   var campaigndata= JSON.stringify(this.campaignForm.value);
console.log(campaigndata);
   if(this.campaignForm.invalid){
     return false;
         }
         else{
      $(".fullpageloader").fadeIn("fast");
          this.http.post(this.baseurl+"update_campaign",{data:campaigndata}).subscribe((data:any)=>{
            console.log(JSON.stringify(data));
           if(data.status=="success"){
             $(".fullpageloader").fadeOut('fast');
               this.toastr.success('Data Submitted  succesfully!', 'Success!',{
               positionClass:'toast-top-center',
               timeOut: 3000
             });
             this.router.navigateByUrl('/campaignlist');

           }
           else if(data.status=="fail"){
             $(".fullpageloader").fadeOut('fast');
            this.toastr.error(data.message, 'Error!',{
               positionClass:'toast-top-center',
               timeOut: 3000
             });

           }

         },
         error => {
           $(".fullpageloader").fadeOut('fast');
           this.toastr.error(error, 'error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
       })
         }
 }
  }
  editBtn(){
    this.hdeshowBtn=true;
    this.hideactionBtn=true;
  }
  updatedircalltran(srno,enr_id,campgain_id,ivr_template){

    var extention= $("#seleextention"+srno).val();
    var soundselected= $("#soundselected"+srno).val();
   var agentnummbers =  $("#agentnummbers"+srno).val();
    var algo = $("#diralgo"+srno).val();
    var dircallersuccsms = $("#dircallersuccsms"+srno).val();
    var diragentsuccsms = $("#diragentsuccsms"+srno).val();
    var diragentnum = $("#diragentnum"+srno).val();
    var diradminsuccsms = $("#diradminsuccsms"+srno).val();
    var dirfailsms = $("#dirfailsms"+srno).val();
   var dirownersuccsms= $("#dirownersuccsms"+srno).val();
   var dircallersuccemail= $("#dircallersuccemail"+srno).val();
   var diragentsuccemail= $("#diragentsuccemail"+srno).val();
   var diragentemail= $("#diragentemail"+srno).val();
   var diradminsuccemail= $("#diradminsuccemail"+srno).val();
   var dirownersuccemail= $("#dirownersuccemail"+srno).val();
   var dirfailemail= $("#dirfailemail"+srno).val();


var callerfailsms = $("#callerfailsms"+srno).val();
var agentfailsms = $("#agentfailsms"+srno).val();
var adminfailsms = $("#adminfailsms"+srno).val();
var ownerfailsms = $("#ownerfailsms"+srno).val();
var callerfailemail = $("#callerfailemail"+srno).val();
var agentfailemail = $("#agentfailemail"+srno).val();
var adminfailemail = $("#adminfailemail"+srno).val();
var ownerfailemail = $("#ownerfailemail"+srno).val();

   //alert(srno+"---"+enr_id+"---"+campgain_id+"---"+ivr_template+"---"+agentnummbers+"---"+algo+"---"+dircallersuccsms+"---"+diragentsuccsms+"---"+diragentnum+"---"+diradminsuccsms+"---"+dirfailsms);
   if(ivr_template==3){
 if($("#agentnummbers"+srno).val()!=''){

$(".fullpageloader").fadeIn("fast");
this.http.post(this.baseurl+"update_single_row",{extention:extention,sound:soundselected,agentnummbers:agentnummbers,enr_id:enr_id,campaign_id:campgain_id,algo:algo,dircallersuccsms:dircallersuccsms,diragentsuccsms:diragentsuccsms,diragentnum:diragentnum,diradminsuccsms:diradminsuccsms,ivr_template:ivr_template,dirownersuccsms:dirownersuccsms,dircallersuccemail:dircallersuccemail,diragentsuccemail:diragentsuccemail,diragentemail:diragentemail,diradminsuccemail:diradminsuccemail,dirownersuccemail:dirownersuccemail,callerfailsms:callerfailsms,agentfailsms:agentfailsms,adminfailsms:adminfailsms,ownerfailsms:ownerfailsms,callerfailemail:callerfailemail,agentfailemail:agentfailemail,adminfailemail:adminfailemail,ownerfailemail:ownerfailemail}).subscribe((data:any)=>{
  console.log(JSON.stringify(data));
  if(data.status=="success"){
     this.getdata();
   //  $(".fullpageloader").fadeOut('fast');
       this.toastr.success('Data Submitted  succesfully!', 'Success!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });
   //  this.router.navigateByUrl('/campaignlist');

   }
   else if(data.status=="fail"){
     $(".fullpageloader").fadeOut('fast');
    this.toastr.error(data.message, 'Error!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });

   }

 },
 error => {
   $(".fullpageloader").fadeOut('fast');
   this.toastr.error(error, 'error!',{
     positionClass:'toast-top-center',
     timeOut: 3000
   });
})
 }
 else{
  this.toastr.error("Agent Numbers should not be blank.", 'Error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
 }
   }
   else if(ivr_template==1){
    if($("#agentnummbers"+srno).val()!='' && $("#seleextention"+srno).val()!=''){

$(".fullpageloader").fadeIn("fast");
this.http.post(this.baseurl+"update_single_row",{extention:extention,sound:soundselected,agentnummbers:agentnummbers,enr_id:enr_id,campaign_id:campgain_id,algo:algo,dircallersuccsms:dircallersuccsms,diragentsuccsms:diragentsuccsms,diragentnum:diragentnum,diradminsuccsms:diradminsuccsms,ivr_template:ivr_template,dirownersuccsms:dirownersuccsms,dircallersuccemail:dircallersuccemail,diragentsuccemail:diragentsuccemail,diragentemail:diragentemail,diradminsuccemail:diradminsuccemail,dirownersuccemail:dirownersuccemail,callerfailsms:callerfailsms,agentfailsms:agentfailsms,adminfailsms:adminfailsms,ownerfailsms:ownerfailsms,callerfailemail:callerfailemail,agentfailemail:agentfailemail,adminfailemail:adminfailemail,ownerfailemail:ownerfailemail}).subscribe((data:any)=>{
   if(data.status=="success"){
     this.getdata();
   //  $(".fullpageloader").fadeOut('fast');
       this.toastr.success('Data Submitted  succesfully!', 'Success!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });
   //  this.router.navigateByUrl('/campaignlist');

   }
   else if(data.status=="fail"){
     $(".fullpageloader").fadeOut('fast');
    this.toastr.error(data.message, 'Error!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });

   }

 },
 error => {
   $(".fullpageloader").fadeOut('fast');
   this.toastr.error(error, 'error!',{
     positionClass:'toast-top-center',
     timeOut: 3000
   });
})
    }
    else{
      this.toastr.error("Agent Numbers and extention should not be blank.", 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
    }
      }
      else{
        if($("#soundselected"+srno).val()!='' && $("#seleextention"+srno).val()!=''){

$(".fullpageloader").fadeIn("fast");
this.http.post(this.baseurl+"update_single_row",{extention:extention,sound:soundselected,agentnummbers:agentnummbers,enr_id:enr_id,campaign_id:campgain_id,algo:algo,dircallersuccsms:dircallersuccsms,diragentsuccsms:diragentsuccsms,diragentnum:diragentnum,diradminsuccsms:diradminsuccsms,ivr_template:ivr_template,dirownersuccsms:dirownersuccsms,dircallersuccemail:dircallersuccemail,diragentsuccemail:diragentsuccemail,diragentemail:diragentemail,diradminsuccemail:diradminsuccemail,dirownersuccemail:dirownersuccemail}).subscribe((data:any)=>{
   if(data.status=="success"){
     this.getdata();
   //  $(".fullpageloader").fadeOut('fast');
       this.toastr.success('Data Submitted  succesfully!', 'Success!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });
   //  this.router.navigateByUrl('/campaignlist');

   }
   else if(data.status=="fail"){
     $(".fullpageloader").fadeOut('fast');
    this.toastr.error(data.message, 'Error!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });

   }

 },
 error => {
   $(".fullpageloader").fadeOut('fast');
   this.toastr.error(error, 'error!',{
     positionClass:'toast-top-center',
     timeOut: 3000
   });
})
        }
        else{
          this.toastr.error("Sound and Extention should not be blank.", 'Error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
        }
          }

  }
  deletedircalltran(){

  }

  getc2cclic(network,ip,i,gcstatus){
    var that = this;
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+'get_c2c_cli',{ip:ip,network:network,session_id:this.session_id}).subscribe((data:any)=>{

      if(gcstatus==0){
     this.campaignForm.get('networksdata')['controls'][i]['controls']['outcli'].setValue("");
      this.campaignForm.get('networksdata')['controls'][i]['controls']['outcli'].updateValueAndValidity();
      }

      this.outcliList[i]= data.cli_list;
      this.nubmerfreezed[i]='';

      var $outcli =$("#outcli_select"+i).select2();
      $outcli.on("change", function(){
      that.checkdid($(this).val(),$(this).data('id'));
      });
      $(".fullpageloader").fadeOut('fast');
  })
  }
  getc2cnetwork(ip,scnt,gtstatus){
  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+'get_c2c_network',{ip:ip,session_id:this.session_id}).subscribe((data:any)=>{
  this.campaignForm.get('networksdata')['controls'][scnt]['controls']['network'].setValue("");
  this.campaignForm.get('networksdata')['controls'][scnt]['controls']['network'].updateValueAndValidity();
  this.campaignForm.get('networksdata')['controls'][scnt]['controls']['pool_network'].setValue("");
  this.campaignForm.get('networksdata')['controls'][scnt]['controls']['pool_network'].updateValueAndValidity();
  this.campaignForm.get('networksdata')['controls'][scnt]['controls']['outcli'].setValue("");
  this.campaignForm.get('networksdata')['controls'][scnt]['controls']['outcli'].updateValueAndValidity();
  if(gtstatus==0){
    this.selectedCli[scnt]=[];
  }
  $("#outcli_select"+scnt).val(null).trigger('change');
  $("#pool_network"+scnt).val(null).trigger('change');
  this.nubmerfreezed[scnt]='';
  this.networkList[scnt]= data.network_list;
  this.outcliList[scnt]=[];
  $(".fullpageloader").fadeOut('fast');
  if(scnt==0){
  if(this.campaignForm.get("server_type").value=='0'){
  this.agentListnetwork=data.network_list;
  this.agentListsamecase=data.network_list;
  }
  else{
  this.agentListnetwork=[];
  }
  }


  })

  }
  changeserver(i,serrverval){
   let scnt=i;
   this.getc2cnetwork(serrverval,scnt,1);
  }
  changeserver1(i,serrverval){
    let scnt=i;
    this.getc2cnetwork(serrverval,scnt,0);
   }
    changenetwork(netwrkval,ips,i){

    this.getc2cclic(netwrkval,ips,i,1);
  }
  changenetwork1(netwrkval,ips,i){

      this.getc2cclic(netwrkval,ips,i,0);
    }

   checkdid(clicval,i) {

   var cliitemarra=[];
    const cliitemarralength = (<FormArray>this.campaignForm.get('networksdata')).length;
  for(var kt= 0;cliitemarralength>kt;kt++){
    var outcliarray = $("#outcli_select"+kt).val();
    cliitemarra.push(outcliarray);
   }

  var result = [];

  for (var idk=0, iLen=cliitemarra.length; idk<iLen; idk++) {
    result = result.concat(cliitemarra[idk]);
  }

  var checkcli = result.some(function(item, idx){
    return result.indexOf(item) != idx
  });
  this.duplicateNum=checkcli;
  if(clicval!=''){
    if(clicval!=undefined || clicval!='' || clicval!=null){
     var clinum = clicval.map(function(item){
      return item
      }).join(",");

      }

    $(".fullpageloader").fadeIn('fast');
    this.nubmerfreezed[i]='';
   this.http.post(this.baseurl+'check_vnum_c2c',{vnum:clinum,user_id:this.user_id}).subscribe((data:any)=>{
        $(".fullpageloader").fadeOut('fast');
   this.nubmerfreezed[i]= data;

  if(this.nubmerfreezed[i].status == 'fail'){
    this.alreadyassign=true;
   //  this.nubmerfreezed[i]='';
    this.toastr.error("Out CLI already assigend. Please choose Another.", 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
  }
  this.alreadyassign=false;
    })
   }
   else{
    this.nubmerfreezed[i]='';
   }

  }
  getc2cserver(){
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+'get_c2c_server',{session_id:this.session_id}).subscribe((data:any)=>{
     this.serverList= data.server_ip;
     //this.nubmerfreezed='';
   $(".fullpageloader").fadeOut('fast');
  })
  }
allnetwork(){
  let that = this;

for(var scli=0;this.campaignview.obd_network.length>scli;scli++){
  if(this.campaignview.obd_network[scli].cli!=''){
   var splititem=this.campaignview.obd_network[scli].cli.split(',');
   this.selectedCli[scli]=splititem;
  }
  else{
   this.selectedCli[scli]=[];
  }
 }
this.campaignview.obd_network.forEach((element,index) => {
 this.changeserver(index,element.server);
 this.changenetwork(element.network,element.server,index);
});


if(this.OBDnetworkList.length>0){
  this.campaignForm.setControl('networksdata',
  this.fb.array(this.OBDnetworkList.map(
  id => this.fb.group({server: [id.server,Validators.required],network: [id.network,Validators.required],pool_network: '',outcli: [id.cli],channelno: [id.channel], retrysel:[id.agent_retry,Validators.required],})
  ) || [])
  );

}else{
  let dummyobddata = [{server:'',network:'',pool_network:'',outcli:'',channelno:'',retrysel:''}];
  this.campaignForm.setControl('networksdata',
  this.fb.array(dummyobddata.map(
  id => this.fb.group({server: [id.server,Validators.required],network: [id.network,Validators.required],pool_network: '',outcli: [id.outcli],channelno: [id.channelno], retrysel:[id.retrysel,Validators.required],})
  ) || [])
  );
  setTimeout(() => {
    $(".pool_network").select2();
  }, 10);
}



 var function1 = (callback1) => {

  setTimeout(() => {
  this.OBDnetworkList.forEach((element,index) => {
  this.campaignForm.get('networksdata')['controls'][index]['controls']['server'].setValue(element.server);
  this.campaignForm.get('networksdata')['controls'][index]['controls']['server'].updateValueAndValidity();
  this.campaignForm.get('networksdata')['controls'][index]['controls']['network'].setValue(element.network);
  this.campaignForm.get('networksdata')['controls'][index]['controls']['network'].updateValueAndValidity();
  this.campaignForm.get('networksdata')['controls'][index]['controls']['pool_network'].setValue(element.pooling_network);
  this.campaignForm.get('networksdata')['controls'][index]['controls']['pool_network'].updateValueAndValidity();
  this.campaignForm.get('networksdata')['controls'][index]['controls']['outcli'].setValue(element.cli);
  this.campaignForm.get('networksdata')['controls'][index]['controls']['outcli'].updateValueAndValidity();
  this.campaignForm.get('networksdata')['controls'][index]['controls']['retrysel'].setValue(element.agent_retry);
  this.campaignForm.get('networksdata')['controls'][index]['controls']['retrysel'].updateValueAndValidity();
  var $vagentid = $("#pool_network"+index).select2();
  var $outcli_select =$("#outcli_select"+index).select2();
  var siddata = this.OBDnetworkList[index].pooling_network.split(",");
  var outcli_selectdata = this.OBDnetworkList[index].cli.split(",");
  $vagentid.val(siddata).trigger('change');
  $outcli_select.val(outcli_selectdata).trigger('change');
  });
  callback1()
}, this.OBDnetworkList.length*900);
}

var function2 = () => {
 setTimeout(() => {
 var $outcli =$(".outcli_select").select2();
  $outcli.on("change", function(){
    that.checkdid($(this).val(),$(this).data('id'));
  });
 }, this.OBDnetworkList.length*1000);
}

function1(function2);
}
}
