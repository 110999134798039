import { Component, ElementRef, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-supportlist',
  templateUrl: './supportlist.component.html',
  styleUrls: ['./supportlist.component.css']
})
export class SupportlistComponent implements OnInit {
filterForm:FormGroup;
records:any;
viewrecords:any;
itemsPerPage:any=15;
currentPage:number =1;
comapanydata:any;
comphideshow:boolean = false;
ticket_id:any;
sub:any;
ticket_idparam:any;
useridparam:any;
statusparam:any;
issueparam:any;
currentUserloclstrage:any;

  constructor(private http:HttpClient,private elRef: ElementRef,private toastr:ToastrService, private fb:FormBuilder,private route: ActivatedRoute) {
  
   }

  ngOnInit() {
    $("#replytempl").change(function(){
      var trimStr = $.trim($(this).val()); 
    $("#msgtext").val('');
    $("#msgtext").val(trimStr);
    });
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.alltickets(this.currentPage);
    this.ticket_idparam = this.route.snapshot.queryParamMap.get('ticketid');
    this.useridparam = this.route.snapshot.queryParamMap.get('user_id');
    this.statusparam = this.route.snapshot.queryParamMap.get('ticket_status');
    this.issueparam = this.route.snapshot.queryParamMap.get('issue_type');
     if(this.ticket_idparam===null){
     return false;
    }
    else{
      this.viewdetail(this.ticket_idparam,this.useridparam,this.statusparam,this.issueparam);
    }
   
    $(".select2").select2();
    $(".trow .tcol .ccol").not('.ccol.not').on('click', function() {
    $(this).parent().next(".texpand").slideToggle('fast');
      $(this).parent().toggleClass('active');

  });
      


this.createform();
this.getrecords();
  }

  getrecords(){
    $(".fullpageloader").fadeIn('fast');
   this.http.get(" https://api.cerebra.quadrafort.in/get_company").subscribe((data:any)=>{
this.comapanydata = data;
$(".fullpageloader").fadeOut('fast');
   })
  }

  createform(){
 
    this.filterForm = this.fb.group({
      user_id:[''],
      ticket_id:[''],
      agent:[''],
      contact:[''],
      issuetype:[''],
      service_type:[''],
      status:[''],
      fromdate:'',
      todate:''
    })
  }

  onSubmit(){

    let user_id = this.filterForm.value.user_id;
    let ticket_id = this.filterForm.value.ticket_id;
    let agent = this.filterForm.value.agent;
    let contact = this.filterForm.value.contact;
    let issuetype = this.filterForm.value.issuetype;
    let status=this.filterForm.value.status;
    let service_type = this.filterForm.value.service_type;
    let fromdate= $('#fromdate').val();
    let todate= $('#todate').val();

    $('.fullpageloader').show('fast');
   
  this.http.get(" https://api.cerebra.quadrafort.in/get_all_ticket?ticket_id="+ticket_id+"&service_type="+service_type+"&user_id="+user_id+"&agent="+agent+"&contact="+contact+"&issuetype="+issuetype+"&status="+status+"&fromdate="+fromdate+"&todate="+todate).subscribe(
   (data:any)=>{
     this.records=data;
      $('.cfillBox').slideUp('fast');
      $('.fullpageloader').hide('fast');
})
 }

  reply(event){
  
if($("#msgtext").val()!=''){
  $(".fullpageloader").fadeIn('fast');
  let user_id =  $("#selectcompany").val();
 let msg =  $("#msgtext").val();
 let issue_type = $("#issue_type").val();
 let ticket_status = $("#ticket_status").val();
 let b_id =  $("#b_id").val();
 let ticket_id =  $("#ticket_id").val();
 let tomailtext = $("#toemail").text();
 let toemail = tomailtext+","+$("#toemailtext").val();
 toemail = toemail.replace(/^,|,$/g,'');
 let ccmailtext = $("#ccemail").text();
 let ccemail = ccmailtext+","+$("#ccemailtext").val();
 ccemail = ccemail.replace(/^,|,$/g,'');

 this.http.post(' https://api.cerebra.quadrafort.in/ticket_reply',{chat_text:msg,issue_type:issue_type,ticket_status:ticket_status,ticket_id:ticket_id,a_id:0,b_id:b_id,company:user_id,to_email:toemail,cc_email:ccemail,session_id: this.currentUserloclstrage.user_id}).subscribe((data:any)=>{
  if(data.status=='success'){
    console.log(data);
  $(".fullpageloader").fadeOut('fast');
  $("#msgtext").val('');
  $("#issuedetail").modal('hide');
  let curtab= $(".mreqnav ul li a.active").text();
   
    if(curtab=='All'){
 this.alltickets(this.currentPage);
    }
    else if(curtab=='Open'){
     this.getopenticket(this.currentPage);
    }
    else if(curtab=='Closed'){
      this.getcloseticket(this.currentPage);
    }
    else if(curtab=='Invalid Ticket'){
      this.invalidticket(this.currentPage);
    }
    else{
     this.unasignedticket(this.currentPage);
    }
    this.toastr.success(data.message,'Success!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
  }
  else if(data.status=='fail'){
    $(".fullpageloader").fadeOut('fast');
    this.toastr.error(data.message,'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  }
 } ,(err) => {
  $(".fullpageloader").fadeOut('fast');
  this.toastr.error(err,'Error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
})
}
else{
  $("#msgtext").addClass('');
  $("#msgtext").addClass('form-control is-invalid');
  setTimeout(() => {
    $("#msgtext").addClass('');
    $("#msgtext").addClass('form-control')
  }, 5000);
}

  }

  getopenticket(crpage){
    let crpage1 = (crpage===undefined)?1:crpage;
    $(".fullpageloader").fadeIn('fast');
    this.http.get(" https://api.cerebra.quadrafort.in/get_open_ticket?page="+crpage1).subscribe((data:any)=>{
      this.records = data;
      $(".fullpageloader").fadeOut('fast');
      $("#closed,#all,#unasignedticket,#invalidticket").removeClass('active');
       $("#open").addClass('active');
    })
  }
  getcloseticket(crpage){
    let crpage1 = (crpage===undefined)?1:crpage;

    $(".fullpageloader").fadeIn('fast');
    this.http.get(" https://api.cerebra.quadrafort.in/get_close_ticket?page="+crpage1).subscribe((data:any)=>{
      this.records = data;
      $(".fullpageloader").fadeOut('fast');
      $("#open,#all,#unasignedticket,#invalidticket").removeClass('active');
      $("#closed").addClass('active');
    })
  }
  alltickets(crpage){
  
    let crpage1:Number = (crpage===undefined)?1:crpage;
   
   $(".fullpageloader").fadeIn('fast');
    this.http.get(" https://api.cerebra.quadrafort.in/get_all_ticket?page="+crpage1).subscribe((data:any)=>{
      this.records = data;
      $(".fullpageloader").fadeOut('fast');
      $("#open,#closed,#unasignedticket,#invalidticket").removeClass('active');
      $("#all").addClass('active');
    })
  }
  unasignedticket(crpage){
    let crpage1 = (crpage===undefined)?1:crpage;
  
    $(".fullpageloader").fadeIn('fast');
    this.http.get(" https://api.cerebra.quadrafort.in/get_unassign_ticket?page="+crpage1).subscribe((data:any)=>{
      this.records = data;
      $(".fullpageloader").fadeOut('fast');
      $("#open,#closed,#all,#invalidticket").removeClass('active');
      $("#unasignedticket").addClass('active');
    })
  }
  invalidticket(crpage){
    let crpage1 = (crpage===undefined)?1:crpage;
  
    $(".fullpageloader").fadeIn('fast');
    this.http.get(" https://api.cerebra.quadrafort.in/get_invalidticket_ticket?page="+crpage1).subscribe((data:any)=>{
      this.records = data;
      $(".fullpageloader").fadeOut('fast');
      $("#open,#closed,#all,#unasignedticket").removeClass('active');
      $("#invalidticket").addClass('active');
    })
  }

  viewdetail(ticketId:number,user_id,ticket_status,ticket_issue){
   $("#replytempl").select();
    if(user_id==null || user_id==0){
  this.comphideshow = true;
 $("#selectcompany").val('');

    }
    else{
      this.comphideshow = false;
    }
  
   $(".fullpageloader").fadeIn('fast');
this.http.get(" https://api.cerebra.quadrafort.in/get_ticket_detail?ticket_id="+ticketId).subscribe((data:any)=>{
  $(".fullpageloader").fadeOut('fast');
  this.viewrecords = data;
$("#b_id").val(user_id);
$("#ticket_id").val(ticketId);
//alert(JSON.stringify($("#ticket_id").val(ticketId)));
$("#ticket_status option[value='"+ticket_status+"']").prop('selected',true);
$("#issue_type option[value='"+ticket_issue+"']").prop('selected',true);
$("#issuedetail").modal({backdrop: 'static', keyboard: false});
  console.log(data);
})
  }

  pageChange(newpage:number){
    let curtab= $(".mreqnav ul li a.active").text();
   
    if(curtab=='All'){
 this.alltickets(newpage);
    }
    else if(curtab=='Open'){
     this.getopenticket(newpage);
    }
    else if(curtab=='Closed'){
      this.getcloseticket(newpage);
    }
    else if(curtab=='Invalid Ticket'){
      this.invalidticket(newpage);
    }
    else{
     this.unasignedticket(newpage);
    }
    this.currentPage = newpage;
   }

}
