import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addminavomgnt',
  templateUrl: './addminavomgnt.component.html',
  styleUrls: ['./addminavomgnt.component.css']
})
export class AddminavomgntComponent implements OnInit {
  mgmtForm:FormGroup;
  empsettings:FormArray;
  submitted= false;
  Emp_id:any;
servicetype=['Vagent','Tollfree','OBD','Click2Call','Vmanage','Vmessage','Vlead'];

allsettings=[    
            {'settname':'New Demo' },
            {'settname':'Demo Expire'},    
            {'settname':'Demo Followup'},  
            {'settname':'Demo Upgrade' },
            {'settname':'Service Renewal'},    
            {'settname':'Service Suspend'}, 
            {'settname':'Low Balance' },
            {'settname':'New Topup'},    
            {'settname':'Approve Plan'}
            ] ;
  constructor(private fb:FormBuilder, private http:HttpClient, private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
    this.Emp_id = this.route.snapshot.paramMap.get("id");
    console.log(this.Emp_id);
    if (this.Emp_id !== null){
     this.editRecord(this.Emp_id);
    }
   }

  ngOnInit() {
this.creatForm();
  }

creatForm(){
let settarr = [];

for(let i=0; i<this.allsettings.length;i++){
  settarr.push(this.dynamicsett(this.allsettings[i]))
}

this.mgmtForm = this.fb.group(
  {
    empdetails:this.fb.group({
      empname:['',Validators.required],
      empmobile:['', [Validators.required,Validators.maxLength(10),Validators.minLength(10)]],
      empemail:['',[Validators.required, Validators.email]],
      product:['',Validators.required],
      emp_id:[]
    }),
    empsettings:this.fb.array(settarr),

  }
)
}
 
dynamicsett(sett:any):FormGroup{
 return this.fb.group({
  settname:[sett.settname],
  email:false,
  sms:false
})
}
editRecord(empid:any){
$(".fullpageloader").show('fast');
this.http.get(" https://api.cerebra.quadrafort.in/edit_mgmt?emp_id="+empid).subscribe((data:any)=>{
//data = JSON.stringify(data);
console.log(data.empsettings[0].settname+''+data.empsettings[0].email);
console.log(data);
this.mgmtForm.setValue({
empdetails:{
  empname:data.empdetails.empname,
  empmobile:data.empdetails.empmobile,
  empemail:data.empdetails.empemail,
  product:data.empdetails.product,
  emp_id:data.empdetails.emp_id,
},
empsettings:[
 {settname: data.empsettings[0].settname, email: data.empsettings[0].email, sms: data.empsettings[0].sms},
 {settname: data.empsettings[1].settname, email: data.empsettings[1].email, sms: data.empsettings[1].sms},
 {settname: data.empsettings[2].settname, email: data.empsettings[2].email, sms: data.empsettings[2].sms},
 {settname: data.empsettings[3].settname, email: data.empsettings[3].email, sms: data.empsettings[3].sms},
 {settname: data.empsettings[4].settname, email: data.empsettings[4].email, sms: data.empsettings[4].sms},
 {settname: data.empsettings[5].settname, email: data.empsettings[5].email, sms: data.empsettings[5].sms},
 {settname: data.empsettings[6].settname, email: data.empsettings[6].email, sms: data.empsettings[6].sms},
 {settname: data.empsettings[7].settname, email: data.empsettings[7].email, sms: data.empsettings[7].sms},
 {settname: data.empsettings[8].settname, email: data.empsettings[8].email, sms: data.empsettings[8].sms}

]
});
$(".fullpageloader").hide('fast');
}
 )

}

get f() { return this.mgmtForm.controls };

onSubmit(){
  this.submitted = true;
  let mgmtFormdta = JSON.stringify(this.mgmtForm.value);
  if(this.mgmtForm.invalid){
 return false;
  }
  else{
    $(".fullpageloader").show('fast');
    this.http.post(' https://api.cerebra.quadrafort.in/add_mgmt',{data:mgmtFormdta}).subscribe((data:any)=>{
      
    $(".fullpageloader").hide('fast');
      if(data.status=="success"){
        
        this.toastr.success('Data Submitted  succesfully!', 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        this.router.navigateByUrl('/setting/minavomanagement');
        
      }
      else if(data.status=="fail"){
       console.log("fail");
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
       
      }
      
    },
    error => {
      this.toastr.error(error, 'error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  }
    )

  }

}

}
