import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-topup',
  templateUrl: './topup.component.html',
  styleUrls: ['./topup.component.css']
})
export class TopupComponent implements OnInit {

  submitted:boolean = false;
  topupForm:FormGroup;
  top_id:any;
  user_id:any;
  service_type:any;
  topup:any;
  topup_type:any;
  amount:any;

  customizesec:boolean=false;
  defaultsec:boolean=true;
topupplans:any;
topup_remarks:any;
  
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute){
    this.top_id = this.route.snapshot.paramMap.get("id");
    this.user_id = this.route.snapshot.paramMap.get("user_id");
    this.service_type = this.route.snapshot.paramMap.get("service_type");
    this.topup = this.route.snapshot.paramMap.get("topup");
    this.topup_type = this.route.snapshot.paramMap.get("topup_type");
    this.amount = this.route.snapshot.paramMap.get("amount");
    this.topup_remarks = this.route.snapshot.paramMap.get("topup_remarks");
  } 

  ngOnInit() {
  
    this.getplan();
    this.createForm();
  this.topupForm.get('planccoin').valueChanges
   .subscribe(planccoin => {

    if(planccoin=='customize'){
  
      this.customizesec = true;
      this.defaultsec = false;
      this.topupForm.get('amount').setValidators([Validators.required]);
      this.topupForm.get('coins').setValidators([Validators.required]);
      this.topupForm.get('amount').setValue(this.amount);
      this.topupForm.get('topupvalue').setValidators(null);
      this.topupForm.get('amount').updateValueAndValidity();
      this.topupForm.get('coins').updateValueAndValidity();
      this.topupForm.get('topupvalue').updateValueAndValidity();
      
     }
   else{
   
      this.customizesec = false;
      this.defaultsec = true;
      this.topupForm.get('topupvalue').setValidators([Validators.required]);
      this.topupForm.get('amount').setValidators(null);
      this.topupForm.get('coins').setValidators(null);
      this.topupForm.get('amount').setValue('');
      this.topupForm.get('coins').setValue('');
      this.topupForm.get('topupvalue').updateValueAndValidity();
      this.topupForm.get('amount').updateValueAndValidity();
      this.topupForm.get('coins').updateValueAndValidity();
   
   }
   })
  }
createForm(){
this.topupForm = this.fb.group({
  topuptype:this.topup,
  planccoin:this.topup_type.toLowerCase(),
  customize:'',
  amount:this.amount,
  coins:'',
  topupvalue:['',[Validators.required]],
  remark:this.topup_remarks,
  top_id:this.top_id,
  user_id:this.user_id,
  service_type:this.service_type,
  session_id:JSON.parse(localStorage.getItem('currentUser')).user_id
})
  }

get f(){return this.topupForm.controls}
onSubmit(){
this.submitted= true;
let topupFormdata = JSON.stringify(this.topupForm.value);
if(this.topupForm.invalid){
return false;
}
else{
 // alert(topupFormdata);
  $(".fullpageloader").fadeIn('fast');
  this.http.post(" https://api.cerebra.quadrafort.in/maketopup",{data:topupFormdata}).subscribe((data:any)=>{
   console.log(data);
    if(data.status=='success'){
      $(".fullpageloader").fadeOut('fast');
      this.toastr.success('Data Submitted  succesfully!', 'Success!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      this.router.navigateByUrl('crequest/topuprequest');
    }
    else if(data.status=="fail"){
      console.log("fail");
      $(".fullpageloader").fadeOut('fast');
      console.log(data.message);
      this.toastr.error(data.message, 'Error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
      
     }
  }, error => {
    $(".fullpageloader").fadeOut('fast');
    this.toastr.error(error, 'error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
  }
  )
  
  }
}
getplan(){
  $('.fullpageloader').show();
  this.http.get(' https://api.cerebra.quadrafort.in/topup_plan').subscribe(
  data=>{
   this.topupplans=data;
    $('.fullpageloader').hide();
 
  });
}
}
