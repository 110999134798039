import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';  
@Component({
  selector: 'app-emailaccsett',
  templateUrl: './emailaccsett.component.html',
  styleUrls: ['./emailaccsett.component.css']
})
export class EmailaccsettComponent implements OnInit {
   emailaccForm:FormGroup;
   submitted = false;
   emailaccunts:any;
   currentUserloclstrage:any;
   
   totalservice:string[]=['VAgent','Vmanage','Click2Call','OBD','Vmanage','Vlead'];
   eventtype:string[]=['demo alerts','voicemail','voicemail1','IVR','IVR1','service alert','payment alerts'];

  constructor(private fb: FormBuilder,private toastr: ToastrService, public http:HttpClient,private confirmDialogService: ConfirmDialogService,) {
    this.emailaccForm = this.fb.group({
    //  firstName: new FormControl()
      eventtype: ['', Validators.required],
      servicetype:['',Validators.required],
      host:['smtpout.secureserver.net:80',Validators.required],
      smtpauth:[true,Validators.required],
      username:['support@minavo.in',Validators.required],
      passwd:['Minavo@123',Validators.required],
      ad_email_id:[],
   });
  
   }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.getdata();
  }
  get f() { return this.emailaccForm.controls; }
 
    onSubmit() {
        this.submitted = true;
let emailaccFormdata = JSON.stringify(this.emailaccForm.value);
        // stop here if form is invalid
        if (this.emailaccForm.invalid) {
            return;
        }
        else{
          $(".fullpageloader").show('fast');
          this.http.post(" https://api.cerebra.quadrafort.in/addemail",{data:emailaccFormdata}).subscribe((data:any)=>{
          console.log(data.status);
            if(data.status=="success"){
              this.toastr.success('Data Submitted  succesfully!', 'Success!',{
                positionClass:'toast-top-center',
                timeOut: 3000
              });
              this.getdata();
             }
            else if(data.status=="fail"){
             console.log("fail");
             this.toastr.error(data.message, 'Error!',{
                positionClass:'toast-top-center',
                timeOut: 3000
              });
             
            }
          },error=>{
            this.toastr.error('Error is coming!', 'Error!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });
          })
        $("#addemailsett").modal('hide');
        //  this.emailaccForm.reset();
        }
      
    }

    getdata(){
      $(".fullpageloader").show("fast");
      this.http.get(" https://api.cerebra.quadrafort.in/emailaccsett").subscribe((data:any)=>{
      this.emailaccunts = data;
      $(".fullpageloader").hide("fast");
    })
          }
    userEdit(id:number) {
       
        this.http.post(" https://api.cerebra.quadrafort.in/editemail",{ad_email_id:id}).subscribe((data:any)=>{
          
           this.emailaccForm.setValue(
             {
             eventtype:data[0].email_event,
             servicetype:data[0].service_type,
             host:data[0].host,
             smtpauth:data[0].smtpauth,
           username:data[0].username,
           passwd:data[0].password,
           ad_email_id:data[0].ad_email_id
           });
});
$("#addemailsett").modal({
  backdrop: 'static',
  keyboard: true, 
  show: true
})

  }
deleteRecord(id:number){
  
  var that = this;
 this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
     
      that.http.post(' https://api.cerebra.quadrafort.in/deleteeamail',{ad_email_id:id}).subscribe((data:any)=>{
        
        that.toastr.success('Record Deleted  succesfully!', 'Success!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
       
      that.getdata(); 
   })

    }, function () {  
      return false;
    }) 

}
    // CloseModal(){
    //   this.emailaccForm.reset();
    // }

}
