import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mssmsservice',
  templateUrl: './mssmsservice.component.html',
  styleUrls: ['./mssmsservice.component.css']
})
export class MssmsserviceComponent implements OnInit {

  vmessageForm:FormGroup;
  submitted:boolean = false;
  user_id:any;
  subtype:any;
  stype:any;
  buttunhide:boolean=false;
  acctype=['Demo','Premium'];
  dedtypelist=['Sent','Delivered'];
  operaterList:any;
  records:any;
  currentUserloclstrage:any;
  planlist:any;

  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
  this.user_id = this.route.snapshot.paramMap.get("userid");
  this.stype = this.route.snapshot.paramMap.get("stype");

}

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.creatForm();
    this.getdata();

  $("#validitydate").datepicker({
     defaultDate: "+1w",
      minDate:0,
      changeMonth: true,
      changeYear: true,
      dateFormat: 'yy-mm-dd',
      numberOfMonths: 1,
      showOn: "button",
      buttonImage: "assets/img/cal_icon.png",
      buttonImageOnly: true,
  });
  // $(".acchead").click(function() {
  //     $(this).next('.accbox').slideToggle('fast');
  //     $(this).toggleClass('active');
  // });

// this.vleadForm.get('exeuser').valueChanges
// .subscribe(exeuser => {
//   this.checkstatus = exeuser;

//   if(exeuser==1){
// this.checkstatus = true;
// this.vleadForm.get('servicename').setValidators([Validators.required]);
// this.vleadForm.get('serviceid').setValidators([Validators.required]);
//   }
//   else{
//     this.checkstatus = false;
//     this.vleadForm.get('servicename').setValidators(null);
// this.vleadForm.get('serviceid').setValidators(null);
//   }
// })
  }

  creatForm(){
    this.vmessageForm= this.fb.group({
      companyname:[{ value: '', disabled: true },Validators.required],
      fname:[{ value: '', disabled: true },Validators.required],
      lname:[{ value: '', disabled: true }],
      username:[{ value: '', disabled: true },Validators.required],
      mobile:[{ value: '', disabled: true },[Validators.required,Validators.maxLength(10),Validators.minLength(10)]],
      email:[{ value: '', disabled: true },[Validators.required,Validators.email]],
      gender:[{ value: '', disabled: true }],
      address:[{ value: '', disabled: true },[Validators.required]],
      pincode:[{ value: '', disabled: true }],
      country:[{ value: '', disabled: true }],
      state:[{ value: '', disabled: true }],
      city:[{ value: '', disabled: true }],
      salesexec:[{ value: '', disabled: true }],
      tsalesexec:[{ value: '', disabled: true }],
      oprater:[{ value: '', disabled: true }],
      sms_type:[{ value: '', disabled: true },[Validators.required]],
      plan_name:[{ value: '',disabled: true },[Validators.required]],
      dedtype:[{ value: '', disabled: true }],
      templcheck:[{ value: '', disabled: true }],
      acctype:[{ value: '', disabled: true }],
      remarks:[{ value: '', disabled: true },Validators.required],
      userid:[],
      stype:[],
    })

  }
getdata(){
  $(".fullpageloader").fadeIn('fast');
  this.http.get(" https://api.cerebra.quadrafort.in/get_service_details?user_id="+this.user_id+"&type="+this.stype).subscribe((data:any)=>{
 this.operaterList= data.operator_list;
 //data.profile[0].gender;
 console.log(data);
 this.planlist = data.plan_list;
this.records = data.profile[0];
 console.log(this.records);

 //console.log(this.operaterList);
  $(".fullpageloader").fadeOut('fast');
this.vmessageForm.setValue({
  companyname:data.profile[0].company,
  fname:data.profile[0].first_name+" "+data.profile[0].last_name,
  lname:'',
  username:data.profile[0].username,
  mobile:data.profile[0].mobile,
  gender:data.profile[0].gender,
  email:data.profile[0].email_id,
  address:data.profile[0].address,
  pincode:data.profile[0].zip,
  country:data.profile[0].country,
  state:data.profile[0].state,
  city:data.profile[0].city,
  salesexec:data.profile[0].sale_exc_name,
  tsalesexec:data.profile[0].telesale_exc_name,
  sms_type:data.profile[0].sms_type,
  plan_name:data.profile[0].plan_id,
  oprater:data.profile[0].operator_id,
  dedtype:data.profile[0].ded_type,
  templcheck:function(template_status){
    if(template_status==1){
return "1";
    }
    else{
      return "0";
    }
  }(data.profile[0].template_status),
  acctype:data.profile[0].account_type,
  remarks:data.profile[0].remarks,
  userid:data.profile[0].user_id,
  stype:this.stype,
})
})

}
  get f(){ return this.vmessageForm.controls;}
  onSubmit(){
  this.submitted = true;
  //this.vmessageForm.get('validity').setValue($("#validitydate").val());
    var vmessagedata= JSON.stringify(this.vmessageForm.value);

    if(this.vmessageForm.invalid){
return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(" https://api.cerebra.quadrafort.in/update_service",{data:vmessagedata}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         this.router.navigateByUrl('/servicelist');
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }
  clickEventHandler(){
 
    this.vmessageForm.enable();
    // this.vmessageForm.get('plan_name').enable();
    
    // this.vmessageForm.get('dedtype').enable();
    // this.vmessageForm.get('templcheck').enable();
   
    // this.vmessageForm.get('remarks').enable();
    this.buttunhide = true;
  }
}
