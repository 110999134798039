import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {formatDate } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-callreport',
  templateUrl: './callreport.component.html',
  styleUrls: ['./callreport.component.css']
})
export class CallreportComponent implements OnInit {

    callreport;
    itemsPerPage:any=15;
   currentPage:number =1;
     filterform:FormGroup;
   submitted=false;
   today= new Date();
   jstoday = '';
   constructor(private http:HttpClient,private fb:FormBuilder, private route:Router) { 
 
  this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
     this.filterform = this.fb.group({
       user_id: ['', ],
       fromdate: [''],
       todate: [''],
       mobile:[''],
       email:['']
     })
 
   }
 
   ngOnInit() {
  
 this.getdata(this.currentPage,'','');
 $("#reptype").on('change',function(){
   //alert($(this).val());
   switch($(this).val()){
     case 'bymobile':
   $("#bymobile").show('fast');
   $("#byemail,#byuserid").hide('fast');
   break;
   case 'byemail':
   $("#byemail").show('fast');
   $("#bymobile,#byuserid").hide('fast');
   break;
   case 'byuserid':
   $("#byuserid").show('fast');
   $("#byemail,#bymobile").hide('fast');
   break;
   default:
  $("#byemail,#byuserid,#bymobile").hide('fast');
 
   }
 })
   }
 
 onSubmit()
 {
    this.submitted=true;
   //console.log(this.filterform.value);
    let fromdate=$('#fromdate').val();
    let todate=$('#todate').val()
   
    $('.fullpageloader').show('fast');
     this.http.get(' https://api.cerebra.quadrafort.in/client_call_report?from_date='+fromdate+'&to_date='+todate).subscribe(
     (data)=>{
       this.callreport=data;
         $('.cfillBox').slideUp('fast');
        $('.fullpageloader').hide('fast');
 
     })
 
 }

 search_filter(value)
 {
   if(value)
   {
      this.submitted=true;
     //console.log(this.filterform.value);
      let fromdate=$('#fromdate').val();
      let todate=$('#todate').val()
     
      $('.fullpageloader').show('fast');
       this.http.get(' https://api.cerebra.quadrafort.in/client_call_report?value='+value).subscribe(
       (data)=>{
         this.callreport=data;
           $('.cfillBox').slideUp('fast');
          $('.fullpageloader').hide('fast');
   
       })
     
    }

 }
 
   getdata(crpage:number,fromdate,todate)
   {
     $('.fullpageloader').show('fast');

     if(fromdate && todate)
     {
      this.http.get(' https://api.cerebra.quadrafort.in/client_call_report?page='+crpage+'&from_date='+fromdate+'&to_date='+todate).subscribe(
     
      (data)=>{

         this.callreport=data;
         $('.fullpageloader').hide('fast');
      });
     }
     else
     {
       this.http.get(' https://api.cerebra.quadrafort.in/client_call_report?page='+crpage).subscribe(
       
        (data)=>{

           this.callreport=data;
           $('.fullpageloader').hide('fast');
        });

     }
   }
 
    pageChange(newpage:number){
   
      let fromdate=$('#fromdate').val();
    let todate=$('#todate').val();

      this.getdata(newpage,fromdate,todate);

     this.currentPage = newpage;
       }
       navigateRoute(userid:number,cdate:string,id:string,){

        this.route.navigate(['/reports/callview',userid,cdate,id]);
    
    }
 
}
