import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-cdr',
  templateUrl: './cdr.component.html',
  styleUrls: ['./cdr.component.css']
})
export class CdrComponent implements OnInit {
cdrlog:any;
baseurl:any;
constructor(private http:HttpClient) { }
ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');

$("#service_type").on('change',function(){
switch($(this).val()){
case 'VAGENT_API_logs':
$("#uuid").attr('placeholder',"Enter UUID");
$("#uuid").val('');
$("#uuidsec").show('fast');
$("#useridsec,#camp_idsec,#vconvo_typesec").hide('fast');
break;
case 'OBD_logs':
$("#uuidsec,#vconvo_typesec").hide('fast');
$("#useridsec,#camp_idsec").show('fast');
break;
case 'Vconvo':
$("#vconvo_typesec").show('fast');
$("#useridsec,#camp_idsec,#uuidsec").hide('fast');
break;
default:
$("#uuidsec").hide('fast');
$("#useridsec,#camp_idsec").show('fast');

}
})

$("#vconvo_type").on('change',function(){
  switch($(this).val()){
  case 'Indial':
  $("#uuid").val('');
  $("#uuid").attr('placeholder',"Enter UUID");
  $("#uuidsec").show('fast');
  $("#useridsec,#camp_idsec").hide('fast');
  break;
  case 'outdialincoming':
    $("#uuid").val('');
    $("#uuid").attr('placeholder',"Enter UUID");
    $("#uuidsec").show('fast');
    $("#useridsec,#camp_idsec").hide('fast');
    break;
  case 'outdialtrigger':
      $("#uuid").val('');
      $("#uuid").attr('placeholder',"Enter Conf ID");
      $("#uuidsec").show('fast');
      $("#useridsec,#camp_idsec").hide('fast');
      break;
  default:
  $("#uuid").val('');
  $("#uuid").attr('placeholder',"Enter Conf ID"); 
  $("#uuidsec").show('fast');
  $("#useridsec,#camp_idsec").hide('fast');
  
  }
  })
  }
  viewCdr(){
if($("#service_type").val()=='VAGENT_API_logs'){

    let url = $("#server_type").val()+""+$("#service_type").val()+"/"+$("#uuid").val()+".txt";
    window.open(url, "_blank"); 
    this.http.post(this.baseurl+"read_cdr",{url:url,service_type:$("#service_type option:selected").text()}).subscribe((data:any) => {
    this.cdrlog= JSON.stringify(data,undefined,1);
     $("#textdata").show('hide');
     $(".fullpageloader").fadeOut('fast');
     });
  
}
if($("#service_type").val()=='OBD_logs'){

  let url = $("#server_type").val()+""+$("#service_type").val()+"/"+$("#userid").val()+"/"+$("#camp_id").val()+".txt";

  window.open(url, "_blank"); 
  // this.http.post(this.baseurl+"read_cdr",{url:url,service_type:$("#service_type option:selected").text()}).subscribe((data:any) => {
  //    this.cdrlog= data
  //  $("#textdata").show('hide');
  //  $(".fullpageloader").fadeOut('fast');
  //  });
}
if($("#service_type").val()=='Vconvo'){
if($("#vconvo_type").val()=='Indial'){
  let url = $("#server_type").val()+""+"/VAGENT_API_logs"+"/"+$("#uuid").val()+".txt";
  window.open(url, "_blank"); 
  this.http.post(this.baseurl+"read_cdr",{url:url,service_type:$("#service_type option:selected").text()}).subscribe((data:any) => {
     this.cdrlog= data
   $("#textdata").show('hide');
  
   });
}
if($("#vconvo_type").val()=='outdialincoming'){
  let url = $("#server_type").val()+""+"/VAGENT_API_logs"+"/"+$("#uuid").val()+".txt";
  window.open(url, "_blank"); 
  this.http.post(this.baseurl+"read_cdr",{url:url,service_type:$("#service_type option:selected").text()}).subscribe((data:any) => {
     this.cdrlog= data
   $("#textdata").show('hide');

   });
}
if($("#vconvo_type").val()=='outdialtrigger'){
  let url = $("#server_type").val()+""+"/Conference_log"+"/"+$("#uuid").val()+".txt";
  window.open(url, "_blank"); 
  this.http.post(this.baseurl+"read_cdr",{url:url,service_type:$("#service_type option:selected").text()}).subscribe((data:any) => {
     this.cdrlog= data
   $("#textdata").show('hide');
  
   });
}
if($("#vconvo_type").val()=='outdialschedule'){
  let url = $("#server_type").val()+""+"/Conference_log"+"/"+$("#uuid").val()+".txt";
  window.open(url, "_blank"); 
  this.http.post(this.baseurl+"read_cdr",{url:url,service_type:$("#service_type option:selected").text()}).subscribe((data:any) => {
     this.cdrlog= data
   $("#textdata").show('hide');

   });
}
}
  }

}
