import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apierrorlog',
  templateUrl: './networkdid.component.html',
  styleUrls: ['./networkdid.component.css']
})
export class NetworkdidComponent implements OnInit {
 networkresult;

 networkForm:FormGroup;
  submitted=false;
  today= new Date();
  jstoday = '';
  getserverntwk:any;
  outcallcli:any;

  constructor(private http:HttpClient,private fb:FormBuilder,private toastr:ToastrService, private route:Router) {
  
   this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
  
   }

  ngOnInit() {
  this.getserver();
  this.getdata();
  this.createform();
   }

   createform(){
  this.networkForm = this.fb.group({
    fromnetwork:['',Validators.required],
    tonetwork:['',Validators.required],
  //  userid:[''],
  //  type:[''],
  //  status:[''],

  })
}

get f(){return this.networkForm.controls;}
onSubmit(){
  this.submitted = true;
  if(this.networkForm.invalid){
   
   return false;
  }
  else{
   $(".fullpageloader").fadeIn('fast');
   this.http.post(" https://api.cerebra.quadrafort.in/mirgate_network",{server_id:this.networkForm.get("fromnetwork").value,pilot_num:this.networkForm.get("tonetwork").value}).subscribe((data:any)=>{
   
     if(data.status=='success'){
       this.getdata();
       this.toastr.success(data.message, 'Success!',{
         positionClass:'toast-top-center',
         timeOut: 3000
       });
     //  this.route.navigate('/servicelist');
       $("#migratenetwork").modal('hide');
       $(".fullpageloader").fadeOut('fast');
      
     }
     else if(data.status=="fail"){
     
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        $(".fullpageloader").fadeOut('fast');

      }
   }, error => {
     this.toastr.error(error, 'error!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });
     $(".fullpageloader").fadeOut('fast');
   }
   )
   
   }
}
getserver(){
  this.http.get(' https://api.cerebra.quadrafort.in/get_server').subscribe(
    data=>{
     
     this.getserverntwk=data;
   
      });

}

  getdata()
  {
   $('.fullpageloader').show();
     this.http.get(' https://api.cerebra.quadrafort.in/migration_list').subscribe(
     data=>{
      // console.log(data);
      this.networkresult=data;
       $('.fullpageloader').hide();
    
     });
  }
  changeserver(event){
    $('.fullpageloader').fadeIn('fast');
    const selectEl = event.target;
    this.http.post(' https://api.cerebra.quadrafort.in/get_outcall_cli',{server_ip:selectEl.options[selectEl.selectedIndex].dataset.ip}).subscribe(
    data=>{
     
     this.outcallcli=data;
     $('.fullpageloader').fadeOut('fast');
   
    });
  }
  rollback(id){
    $(".fullpageloader").fadeIn('fast');
    this.http.post(' https://api.cerebra.quadrafort.in/rollback',{role_id:id}).subscribe(
      (data:any)=>{
       // console.log(data);
        if(data.status=='success'){
         
          $(".fullpageloader").fadeOut('fast');
          this.getdata();
          this.toastr.success(data.message, 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
         
        }
        else if(data.status=="fail"){
          $(".fullpageloader").fadeOut('fast');
          this.toastr.error(data.message, 'Error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
          
         }
     
      }, error => {
        this.toastr.error(error, 'error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        $(".fullpageloader").fadeOut('fast');
      });
  }
  deleteId(id){
    this.http.get(' https://api.cerebra.quadrafort.in/delete_rollback?role_id='+id).subscribe(
      (data:any)=>{
       console.log(data);
        if(data.status=='success'){
         
          $(".fullpageloader").fadeOut('fast');
          this.getdata();
          this.toastr.success(data.message, 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
         
        }
        else if(data.status=="fail"){
          $(".fullpageloader").fadeOut('fast');
          this.toastr.error(data.message, 'Error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
          
         }
     
      }, error => {
        this.toastr.error(error, 'error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        $(".fullpageloader").fadeOut('fast');
      });
  }

  viewrecord(roleid:any){
this.route.navigate(['/networkdid/networkview',roleid]);
  }
  
}
