import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import {ConfirmDialogService} from '../confirm-dialog/confirm-dialog.service';   

@Component({
  selector: 'app-minavomanagement',
  templateUrl: './minavomanagement.component.html',
  styleUrls: ['./minavomanagement.component.css']
})
export class MinavomanagementComponent implements OnInit {
  mngtrecords:any;
  currentUserloclstrage:any;
  constructor(private http:HttpClient, private toastr: ToastrService,private confirmDialogService: ConfirmDialogService) {

   }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.getRecord()
  }

getRecord(){
  $(".fullpageloader").fadeIn('fast');
this.http.get(" https://api.cerebra.quadrafort.in/minavomanagement").subscribe((data:any)=>{
  this.mngtrecords = data;
  $(".fullpageloader").fadeOut('fast');
})
  }
  deleteRecord(id:number){
  
    var that = this;
   this.confirmDialogService.confirmThis("Are you sure to delete?", function () {  
       
        that.http.post(' https://api.cerebra.quadrafort.in/delete_mgmt',{emp_id:id}).subscribe((data:any)=>{
          
          that.toastr.success('Record Deleted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         
        that.getRecord(); 
     })
  
      }, function () {  
        return false;
      }) 
  
  }
}
