import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-servicerequest',
  templateUrl: './servicerequest.component.html',
  styleUrls: ['./servicerequest.component.css']
})
export class ServicerequestComponent implements OnInit {
   
  service;
  service_id;
  baseurl:any;
  currentUserloclstrage;
  sheet_no:any;
  servicetimer;
  constructor( private http:HttpClient,private _router:Router,private toastr:ToastrService) { }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    this.baseurl=localStorage.getItem('baseurl');
  this.getdata();
  this.servicetimer = setInterval(() => {
    this.getdata(); 
    }, 10000);
  }

  getdata()
  {
  //$('.fullpageloader').show();
     this.http.get(this.baseurl+'service_request').subscribe(
     data=>{
     this.service=data;
     $('.fullpageloader').hide();

     })
  }

  getserviceid(service_id){
this.service_id = service_id;
  }
  servicename(servicename){
    if(servicename=='vleadservice'){
      this._router.navigate(['/crequest/vleadservice', this.service_id]);
    }
    if(servicename=='vmanageservice'){
      this._router.navigate(['/crequest/vmanageservice', this.service_id]);
    }
    if(servicename=='nodelivered'){
      this._router.navigate(['/crequest/nodelivered', this.service_id]);
    }
  }

  disapprove(sheet_id)
  {
  this.sheet_no=sheet_id;
  $('#disapprovedremark').val('');
  $("#disapprovedmodal").modal('show');

  } 

  disapproveBtn(){
    if($('#disapprovedremark').val()!=''){
      $('.fullpageloader').show();
      this.http.post(this.baseurl+'disapprove_service',{sheet_id:this.sheet_no,remarks:$('#disapprovedremark').val(),type:'service'}).subscribe(
     (data:any)=>{
      
     if(data.status=='success')
     {
        this.toastr.success(data.message, 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        $("#disapprovedmodal").modal('hide');
     }
     else
     {
        this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
    
     }
     $('.fullpageloader').hide();

     })
    }
    else{
   $("#disapprovedremark").addClass("is-invalid");
   setTimeout(() => {
    $("#disapprovedremark").removeClass("is-invalid");
   }, 4000);
    }
  }
  ngOnDestroy(){
    clearInterval(this.servicetimer);
  }

}
