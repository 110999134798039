import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resellerprofileedit',
  templateUrl: './resellerprofileedit.component.html',
  styleUrls: ['./resellerprofileedit.component.css']
})
export class ResellerprofileeditComponent implements OnInit {
  resslrenrollform:FormGroup;
  submitted:boolean = false;
  submitbtn:boolean = false;
  baseurl:any;
  reseller_id:any;
  result:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
this.reseller_id =  this.route.snapshot.paramMap.get('ress_id');
  
  }

  ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');
this.createform();
this.getrecords();
  }

  createform(){
    this.resslrenrollform= this.fb.group({
      name:{value: '', disabled: true},
      companyname:{value: '', disabled: true},
      mobile:{value: '', disabled: true},
      email:{value: '', disabled: true},
      address:{value: '', disabled: true},
      country:{value: '', disabled: true},
      state:{value: '', disabled: true},
      city:{value: '', disabled: true},
      pincode:{value: '', disabled: true},
      password:{value: '', disabled: true},
      amount:{value: '', disabled: true},
      total_coins:{value: '', disabled: true},
      amount_type:{value: '', disabled: true},
      ibd_duration:{value: '', disabled: true},
      ibd_coins:{value: '', disabled: true},
      obd_duration:{value: '', disabled: true},
      obd_coins:{value: '', disabled: true},
      sms_count:{value: '', disabled: true},
      sms_coins:{value: '', disabled: true},
      activation_date:{value: '', disabled: true},
      expire_date:{value: '', disabled: true},
      virtual_cost:{value: '', disabled: true},
      did_cost:{value: '', disabled: true},
      channel_cost:{value: '', disabled: true},
      onetime_allc_ocost:{value: '', disabled: true},
      merge_cost:{value: '', disabled: true},
      crm_cost:{value: '', disabled: true},
      custmize_cost:{value: '', disabled: true},
      channel_alloc_cost:{value: '', disabled: true},
      ress_id:this.reseller_id
    })
  }


  get f(){ return this.resslrenrollform.controls;}
  onSubmit(){
  this.submitted = true;
  //this.vleadForm.get('validity').setValue($("#validitydate").val());
    var createdemo= JSON.stringify(this.resslrenrollform.value);

    if(this.resslrenrollform.invalid){
   return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"update_resller_profile",{data:createdemo}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       // this.router.navigateByUrl('/servicelist');
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }

  getrecords(){
     $(".fullpageloader").fadeIn('fast');
    this.http.get(this.baseurl+"edit_reseller_profile?resel_id="+this.reseller_id).subscribe((data:any)=>{
    $(".fullpageloader").fadeOut('fast');
    this.resslrenrollform.patchValue({
      name:data.profile[0].first_name+" "+(data.profile[0].last_name!=null?data.profile[0].last_name:''),
      companyname:data.profile[0].company,
      mobile: data.profile[0].mobile,
      email:data.profile[0].email,
      address:data.profile[0].address,
      country:data.profile[0].country,
      state:data.profile[0].state,
      city:data.profile[0].city,
      pincode:data.profile[0].pin,
      password: data.profile[0].password,
      amount:data.profile[0].received_amount,
      total_coins: data.profile[0].monthly_total_coins,
      amount_type:data.profile[0].account_type,
      ibd_duration:data.profile[0].ibd_ded_sec,
      ibd_coins:data.profile[0].ibd_ded_rate,
      obd_duration:data.profile[0].obd_ded_sec,
      obd_coins:data.profile[0].obd_ded_rate, 
      sms_count:data.profile[0].sms_ded__cnt,
      sms_coins:data.profile[0].smsded_rate,
      activation_date:(function(jDate){
        const bDate: Date = new Date(jDate);
      return bDate.toISOString().substring(0, 10);  //Ignore time
      })(data.profile[0].sub_date),
      expire_date:(function(jDate){
        const bDate: Date = new Date(jDate);
      return bDate.toISOString().substring(0, 10);  //Ignore time
      })(data.profile[0].renew_date),
      virtual_cost:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "vnum_cost");
        return settvalue.setting_value;
      })(data.otherdata),
      did_cost:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "did_cost");
        return settvalue.setting_value;
      })(data.otherdata),
      channel_cost:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "channelcost");
        return settvalue.setting_value;
      })(data.otherdata),
      onetime_allc_ocost:data.profile[0].anually_total_coins,
      merge_cost:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "mergeacccost");
        return settvalue.setting_value;
      })(data.otherdata),
      crm_cost:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "crmapicost");
        return settvalue.setting_value;
      })(data.otherdata),
      custmize_cost:(function(settings){
        let settvalue = settings.find(sett =>sett.setting_name === "othercost");
        return settvalue.setting_value;
      })(data.otherdata),
      channel_alloc_cost:data.profile[0].channel,
      ress_id:this.reseller_id
})
    })
  }
  editrecords(){
this.submitbtn = true;
this.resslrenrollform.enable();
  }

}
