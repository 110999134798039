import { Component, OnInit,ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-servicelist',
  templateUrl: './servicelist.component.html',
  styleUrls: ['./servicelist.component.css']
})
export class ServicelistComponent implements OnInit {
  
  service:any;
  statusList;
  remarkList;
  itemsPerPage:any=15;
  currentPage:number =1;
  selservice:any;
  searchForm:FormGroup;
  remarkForm:FormGroup;
  submitted:boolean=false;
  submitted1:boolean=false;
  filterform:FormGroup;
  today= new Date();
  jstoday = '';
  allcheckbox=[];
  baseurl:any;
  remarksrecords:any;
  commiteddates:any;
  reseller_id:any;
  constructor(private http:HttpClient, private fb:FormBuilder, private toastr:ToastrService, private route:Router, private getroute:ActivatedRoute, private _host: ElementRef) { 
    this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    this.reseller_id =  this.getroute.snapshot.paramMap.get('id');  
 }

  ngOnInit() {
     this.baseurl = localStorage.getItem('baseurl');
    this.getstatuslist();
 $(".searchlist a").click(function(e) {
   $(".slistdrwn").slideToggle('fast');
  });

  // $(".searchlist a").on('click', function (evt) {  // Jquery is imported in index.html
  //   $(".slistdrwn").slideToggle('fast');
  // });

  $(".slistdrwn li").on('click',function(e) {

     var sval = "Enter" + " " + $(this).text();
      //alert(sval);
      $(".searchinput").attr('placeholder', sval);
      $(".searchinput1").val($(this).text());
      $(".slistdrwn").slideUp('fast');
      
});

$(".btnBox .btn").hide('fast');
$(document).on('click','.cckediv input[type="checkbox"]',function(){
if($('.cckediv input[type="checkbox"]:checked').length>1){
  $(".btnBox .btn").fadeIn('fast');
}
else{
  $(".btnBox .btn").fadeOut('fast');
}
})
// setTimeout(() => {
//   $(document).on('click','.trow .tcol', function() {
//           $(this).next(".texpand").slideToggle('fast');
//           $(this).toggleClass('active');
//     });
// }, 200);
//   $(document).on('click','.trow .tcol', function() {
//       $(this).next(".texpand").slideToggle('fast');
//       $(this).toggleClass('active');
// });

    if(!localStorage.getItem('selservicekey')){
      localStorage.setItem('selservicekey',  'vco');
    }
    
this.createfilterform();
this.createsearchform();
this.createform();

  this.selservice = $("#servicedropdown option:selected").val();
  $('#servicedropdown').val('vco');
  if(this.reseller_id !=''){
    localStorage.setItem('selservicekey',  'vco');
  }
  this.getdata(this.currentPage, localStorage.getItem('selservicekey'),this.reseller_id,'','');
  this.remarkstatuslist();

// this.filterform.get('fservicetype').setValue(localStorage.getItem('filterservice'));
// this.filterform.get('fstatus').setValue(localStorage.getItem('filterstatus'));
// this.filterform.get('fromdate').setValue(localStorage.getItem('fromdate'));
// this.filterform.get('todate').setValue(localStorage.getItem('todate'));
  }
 createform(){
   this.remarkForm = this.fb.group({
    remark:['',Validators.required],
    remarkstatus:['',Validators.required],
    userid:[''],
    type:[''],
    status:[''],

   })
 }

 get f(){return this.remarkForm.controls;}
 onSubmit(){
  var remarkdata = JSON.stringify(this.remarkForm.value);
   this.submitted = true;
   if(this.remarkForm.invalid){
    
    return false;
   }
   else{
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+"service_enable_disable",{data:remarkdata}).subscribe((data:any)=>{
     console.log(data);
      if(data.status=='success'){
        this.toastr.success(data.message, 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        $("#remarkmodal").modal('hide');
        $(".fullpageloader").fadeOut('fast');
       this.route.navigate(['/servicelist']);
       
      }
      else if(data.status=="fail"){
      
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         $(".fullpageloader").fadeOut('fast');

       }
    }, error => {
      this.toastr.error(error, 'error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
      $(".fullpageloader").fadeOut('fast');
    }
    )
    
    }
 }
  ngAfterViewInit(){

  switch(localStorage.getItem('selservicekey')){
  case 'vlead':
    // console.log(localStorage.getItem('selservicekey'));
      $('#servicedropdown').val(localStorage.getItem('selservicekey'));
   break;
  case 'vmanage':
      $('#servicedropdown').val(localStorage.getItem('selservicekey'));
      break;
  case 'vmessage':
      $('#servicedropdown').val(localStorage.getItem('selservicekey'));
      break;
  default:
      $('#servicedropdown').val('vco');
     
  }
    }
  getdata(crpage:number,selservice:any,reseller_id?:any,serchtext?:any,serchinput?:any,)
  {
 $('.fullpageloader').show();
    this.http.get(this.baseurl+"service?page="+crpage+"&type="+selservice+"&searchtext="+serchtext+"&searchtype="+serchinput+"&resel_id="+reseller_id).subscribe(
    (result:any)=>{
    this.service=result;
    $('.fullpageloader').hide();
    });
  }

  getstatuslist(){
    this.http.get(this.baseurl+"get_status_list").subscribe(
      (result:any)=>{
      this.statusList=result;
 });
 }

 remarkstatuslist(){
  this.http.get(this.baseurl+"get_status_list?status=1").subscribe(
    (result:any)=>{
    this.remarkList=result;
});
}
  pageChange(newpage:number,){
 //  alert(JSON.stringify(JSON.stringify(this.filterform.value)));
    if( this.searchForm.get('searchtext').value !== null && this.searchForm.get('searchtext').value !== ''){
     //alert("hi");
     
      this.getdata(newpage,localStorage.getItem('selservicekey'),this.reseller_id,this.searchForm.get('searchtext').value,$(".searchinput1").val());
    }
    else{
      var stype;
     var  formdata = this.filterform.value;
      if(formdata.fservicetype!==''){
        if(formdata.fservicetype=="VAgent"||formdata.fservicetype=="Click2Call"||formdata.fservicetype=="OBD"|| formdata.fservicetype=="VConvo"){
          $('#servicedropdown').val('vco');
            localStorage.setItem('selservicekey','vco');
            $('#servicedropdown').val(localStorage.getItem("selservicekey"));
            stype ='vco';
            }
          else{
           $('#servicedropdown').val(formdata.fservicetype);
             stype =formdata.fservicetype;
            localStorage.setItem('selservicekey',formdata.fservicetype);
            $('#servicedropdown').val(localStorage.getItem("selservicekey"));
            
          }
      }
      else{
        stype=localStorage.getItem("selservicekey");
      }
     
       this.submitted=true;
      //console.log(this.filterform.value);
       let fservicetype=formdata.fservicetype;
       let fstatus=formdata.fstatus;
       let facctype=formdata.facctype;
       
    let fromdate= $('#fromdate').val();
    let todate= $('#todate').val();
    localStorage.setItem('filterservice',fservicetype);
    localStorage.setItem('filterstatus',fstatus);
    localStorage.setItem('facctype',facctype);
    localStorage.setItem('fromdate',fromdate);
    localStorage.setItem('todate',todate);
    
    $('.fullpageloader').show('fast');
        this.http.get(this.baseurl+'service_filter?service_type='+fservicetype+'&fromdate='+fromdate+'&todate='+todate+'&status='+fstatus+'&type='+stype+'&account_type='+facctype+'&page='+newpage+'&resel_id='+this.reseller_id).subscribe(
        (data)=>{
          this.service=data;
          
           $('.fullpageloader').hide('fast');
    
        })
    



     // this.getdata(newpage,localStorage.getItem('selservicekey'),this.reseller_id);
    }
   // alert(localStorage.getItem('selservicekey'));
    this.currentPage = newpage;
      }
     changeService(event:any){
        this.selservice = event.target.value;
        localStorage.setItem('selservicekey',  this.selservice)
     // console.log(localStorage.getItem('selservicekey'));
        this.getdata(1,localStorage.getItem('selservicekey'),this.reseller_id)
      }
 
 changestatus(user_id:any,status:any,type:any,event:any){
   this.getremarkslist(user_id);
      console.log(`values is ${user_id} ${status}  ${type} ${event.target.checked}`);
      $(".modalcBtn").attr("id",user_id);
   $("#remarkmodal").modal({
    backdrop: 'static',
    keyboard: false
   });
   this.remarkForm.setValue({
    remark:'',
    remarkstatus:'',
    userid:user_id,
    type:type,
    status:event.target.checked,

   });
}
closeModal(event){
  
  if($('#'+event.target.id).prop('checked')==true){

    $('#'+event.target.id).prop('checked',false)
  }
  else{
    $('#'+event.target.id).prop('checked',true)
  }

}

navigateRoute(userid:number,stype:string,subtype:string){
    if(stype=='vco'){
    if(subtype=='VAgent'){
      this.route.navigate(['/msvnumservice',userid,subtype,stype]);
     }
    if(subtype=='OBD'){
      this.route.navigate(['/msobdservice',userid,subtype,stype]);
    }
    if(subtype=='Click2Call' || subtype== 'Click2call' || subtype== 'click2call'){
      this.route.navigate(['/msclicktocallservice',userid,subtype,stype]);
    }
    if(subtype=='VConvo'){
     this.route.navigate(['/msvconvoservice',userid,subtype,stype]);
    }
     if(subtype.toLocaleLowerCase()=='hindbot'){
      this.route.navigate(['/mshindbot',userid,subtype,stype]);
      }
  }

  else if(stype=='vlead'){
   this.route.navigate(['/msvleadservice',userid,stype]);
  }
  else if(stype=='vmanage'){
    this.route.navigate(['/msvmanageservice',userid,stype]);
  }
  else if(stype=='vmessage'){
  
    this.route.navigate(['/mssmsservice',userid,stype]);
  }

}

createfilterform(){
  this.filterform = this.fb.group({
    fservicetype:'',
    fstatus:'',
    facctype:'',
    fromdate:'',
    todate: '',
  })
}
filterformsubmit(formdata)
{
  $('.cfillBox').hide('fast');
  var stype;
  if(formdata.fservicetype!==''){
    if(formdata.fservicetype=="VAgent"||formdata.fservicetype=="Click2Call"||formdata.fservicetype=="OBD"|| formdata.fservicetype=="VConvo" || formdata.fservicetype=='hindbot'){
      $('#servicedropdown').val('vco');
        localStorage.setItem('selservicekey','vco');
        $('#servicedropdown').val(localStorage.getItem("selservicekey"));
        stype ='vco';
        }
      else{
       $('#servicedropdown').val(formdata.fservicetype);
         stype =formdata.fservicetype;
        localStorage.setItem('selservicekey',formdata.fservicetype);
        $('#servicedropdown').val(localStorage.getItem("selservicekey"));
        
      }
  }
  else{
    stype=localStorage.getItem("selservicekey");
  }
 
   this.submitted=true;
  //console.log(this.filterform.value);
   let fservicetype=formdata.fservicetype;
   let fstatus=formdata.fstatus;
   let facctype=formdata.facctype;
   
let fromdate= $('#fromdate').val();
let todate= $('#todate').val();
localStorage.setItem('filterservice',fservicetype);
localStorage.setItem('filterstatus',fstatus);
localStorage.setItem('facctype',facctype);
localStorage.setItem('fromdate',fromdate);
localStorage.setItem('todate',todate);

$('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'service_filter?service_type='+fservicetype+'&fromdate='+fromdate+'&todate='+todate+'&status='+fstatus+'&type='+stype+'&account_type='+facctype).subscribe(
    (data)=>{
      this.service=data;
      
       $('.fullpageloader').hide('fast');

    })

}

createsearchform(){
this.searchForm = this.fb.group({
    searchtext:['',[Validators.required]],
    searchinput:[''],
   
 })
}
get g(){return this.searchForm.controls;}
searchFilter(searchdata:any){
 this.submitted1= true;
 if(this.searchForm.invalid){
  $("#forminvalid").fadeIn(200).fadeOut(6000);
  $(".searchinput").css('border-color','#ff0000');
setTimeout(() => {
  $(".searchinput").css('border-color','#b8b8b8');
}, 6000);
   return false;
}
  else{
    var searchtext = searchdata.searchtext;
   var searchtype = $(".searchinput1").val();
   var stype = $("#servicedropdown").val();
    $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+"service?type="+stype+"&searchtext="+searchtext+"&searchtype="+searchtype).subscribe(
    (result:any)=>{
    this.service=result;
    $('.fullpageloader').hide('fast');

    });
  }
 
}

toggle(event){
  this._host.nativeElement.classList.toggle("active");
  var panel = event.target.nextSibling;

  console.log(panel);
  if (panel.style.maxHeight) {
    panel.style.maxHeight = null;
  } else {
    panel.style.maxHeight = panel.scrollHeight + "px";
}
}

ngAfterContentInit() {
  //https://www.gajotres.net/prevent-jquery-multiple-event-triggering/
  $(document).off('click', '.trow .tcol').on('click', '.trow .tcol',function(e) {
    if($(this).hasClass('active')){
          $(this).next(".texpand").slideUp('fast');
          $(this).removeClass('active');
        }
        else{
          $(this).next(".texpand").slideDown('fast');
          $(this).addClass('active');
        }
  }); 
  // $(document).on('click','.trow .tcol', function() {
  //   if($(this).hasClass('active')){
  //     $(this).next(".texpand").slideUp('fast');
  //     $(this).removeClass('active');
  //   }
  //   else{
  //     $(this).next(".texpand").slideDown('fast');
  //     $(this).addClass('active');
  //   }
    
  //    });

}

mergeLogin(lstatus){
  $(".fullpageloader").fadeIn("fast");
  var checkboxvalues = $(".cckediv .customcheckox input:checkbox:checked").map(function(){
    return {'user_id':$(this).data('user_id'),'account_id':$(this).data('account_id'),'service_type':$(this).data('service_type')};
  }).get(); // <----

  if(lstatus=='login'){
this.http.post(this.baseurl+"merge_login",{data:checkboxvalues,status:lstatus}).subscribe((data:any)=>{
//this.route.navigate(['/servicelist']);  
console.log(data);
if(data.status=="success"){

  this.redirectTo(this.route.url);
      //console.log("hi route")
      this.toastr.success('Login Merge  succesfully!', 'Success!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
   
    }
  else if(data.status=="fail"){
   $(".fullpageloader").fadeOut('fast');
   this.toastr.error(data.message, 'Error!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
   
  }
})
  }
  else if(lstatus=='logincoin'){
    this.http.post(this.baseurl+"merge_login_with_coins",{data:checkboxvalues,status:lstatus}).subscribe((data:any)=>{
      if(data.status=="success"){
        this.redirectTo(this.route.url);
        $(".fullpageloader").fadeOut('fast');
          this.toastr.success('Login Merge  With Coin succesfully!', 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
    
        }
      else if(data.status=="fail"){
      $(".fullpageloader").fadeOut('fast');
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
       
      }
      })
  }

}

unmergeaccount(){
 $(".fullpageloader").fadeIn("fast");
  var checkboxvalues = $(".cckediv .customcheckox input:checkbox:checked").map(function(){
    return {'user_id':$(this).data('user_id'),'account_id':$(this).data('account_id'),'service_type':$(this).data('service_type')}
  }).get();

  this.http.post(this.baseurl+"unmerge",{data:checkboxvalues}).subscribe((data:any)=>{
      if(data.status=="success"){
        this.redirectTo(this.route.url);
        $(".fullpageloader").fadeOut('fast');
          this.toastr.success('Coin unmerge succesfully!', 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
        
        }
         else if(data.status=="fail"){
      $(".fullpageloader").fadeOut('fast');
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
       
      }
    })

}

redirectTo(uri) {
  this.route.navigateByUrl('/', {skipLocationChange: true}).then(() =>
  this.route.navigate([uri]));
}
getremarkslist(user_id){
  this.http.get(this.baseurl+"get_status_history?user_id="+user_id).subscribe(
    (result:any)=>{
    this.remarksrecords=result;
});
}
getCommitedDate(sid:any){
  $(".fullpageloader").fadeIn('fast');
this.http.get(this.baseurl+"get_committed_date?service_id="+sid).subscribe((data:any)=>{
if(data.status=="success"){
  this.commiteddates = data;
  $(".fullpageloader").fadeOut('fast');
  $("#commitedmodal").modal('show');
}
else{
  this.toastr.error(data.message, 'Error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
  $(".fullpageloader").fadeOut('fast');
}

})
}
}
