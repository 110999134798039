import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resellercreditback',
  templateUrl: './resellercreditback.component.html',
  styleUrls: ['./resellercreditback.component.css']
})
export class ResellercreditbackComponent implements OnInit {

  resellercreditform:FormGroup;
  submitted:boolean = false;
  retailpanel:boolean = false;
  resellerList:any;
   baseurl:any;
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {

  
  }

  ngOnInit() {
this.baseurl = localStorage.getItem('baseurl');
this.getressellerlist();
this.createform();

this.resellercreditform.get('account_type').valueChanges
  .subscribe(account_type => {
 if(account_type=='retail'){
    this.retailpanel = true;
    this.resellercreditform.get('annually_common_coin').setValidators([Validators.required]);
    this.resellercreditform.get('annually_sms_coin').setValidators([Validators.required]);
    this.resellercreditform.get('monthly_common_coin').setValidators([Validators.required]);
    this.resellercreditform.get('monthly_sms_coin').setValidators([Validators.required]);
    this.resellercreditform.get('channel').setValidators([Validators.required]);

    this.resellercreditform.get('annually_common_coin').updateValueAndValidity();
    this.resellercreditform.get('annually_sms_coin').updateValueAndValidity();
    this.resellercreditform.get('monthly_common_coin').updateValueAndValidity();
    this.resellercreditform.get('monthly_sms_coin').updateValueAndValidity();
    this.resellercreditform.get('channel').updateValueAndValidity();
  }
  else{
    this.retailpanel = false;
    this.resellercreditform.get('annually_common_coin').setValidators(null);
    this.resellercreditform.get('annually_sms_coin').setValidators(null);
    this.resellercreditform.get('monthly_common_coin').setValidators(null);
    this.resellercreditform.get('monthly_sms_coin').setValidators(null);
    this.resellercreditform.get('channel').setValidators(null);

    this.resellercreditform.get('annually_common_coin').updateValueAndValidity();
    this.resellercreditform.get('annually_sms_coin').updateValueAndValidity();
    this.resellercreditform.get('monthly_common_coin').updateValueAndValidity();
    this.resellercreditform.get('monthly_sms_coin').updateValueAndValidity();
    this.resellercreditform.get('channel').updateValueAndValidity();
  }

});


  }

  createform(){
    this.resellercreditform= this.fb.group({
      credit_type:'topup',
      resseller_id:['',[Validators.required]],
      account_type:'advanced',
      amount:['',[Validators.required]],
      annually_common_coin:[''],
      annually_sms_coin:[''],
      monthly_common_coin:[''],
      monthly_sms_coin:[''],
      channel:[''],
     })
  }


  get f(){ return this.resellercreditform.controls;}
  onSubmit(){
  this.submitted = true;
  //this.vleadForm.get('validity').setValue($("#validitydate").val());
    var resellercreditformdata= JSON.stringify(this.resellercreditform.value);

    if(this.resellercreditform.invalid){
   return false;
    }
    else{
  $(".fullpageloader").fadeIn("fast");
      this.http.post(this.baseurl+"credit_credit_back",{data:resellercreditformdata}).subscribe((data:any)=>{
       if(data.status=="success"){
         $(".fullpageloader").fadeOut('fast');
           this.toastr.success('Data Submitted  succesfully!', 'Success!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
         this.resellercreditform.patchValue({
          credit_type:'topup',
          resseller_id:'',
          account_type:'advanced',
          amount:'',
          annually_common_coin:'',
          annually_sms_coin:'',
          monthly_common_coin:'',
          monthly_sms_coin:'',
          channel:'',
         })
       // this.router.navigateByUrl('/servicelist');
        
       }
       else if(data.status=="fail"){
         $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       
      }
      
    },
     error => {
       $(".fullpageloader").fadeOut('fast');
       this.toastr.error(error, 'error!',{
         positionClass:'toast-top-center',
         timeOut: 3000
      });
  })
    }
  }


  getressellerlist(){
    this.http.get(this.baseurl+"get_reseller_list").subscribe((data:any)=>{
      this.resellerList = data.data;
    })
      }
}
