import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';




@Component({
  selector: 'app-afterdisconnectapilog',
  templateUrl: './afterdisconnectapilog.component.html',
  styleUrls: ['./afterdisconnectapilog.component.css']
})
export class AfterdisconnectapilogComponent implements OnInit {

  disconectlog;
  itemsPerPage:any=15;
  currentPage:number =1;
  filterform:FormGroup;
  submitted=false;
  today= new Date();
  jstoday = '';
  baseurl:any;
  constructor(private http:HttpClient,private fb:FormBuilder) { 
 this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
 this.filterform = this.fb.group({
      user_id: ['', Validators.required],
      fromdate: [ this.jstoday,  Validators.required],
      todate: [ this.jstoday, Validators.required]
    })
}


ngOnInit() 
{
this.baseurl = localStorage.getItem('baseurl');
  this.getdata(this.currentPage);

  $("#logtype").on('change',function(){
      //alert($(this).val());
      
      switch($(this).val()){
      case '2':
      $("#byuserid").show('fast');
       break;
      default:
          $("#byuserid").hide('fast');
    
      }
    })
}


onSubmit()
{

  this.submitted=true;
  //console.log(this.filterform.value);
  var user_id=this.filterform.value.user_id;
  var fromdate=$('#fromdate').val();
  var todate=$('#todate').val();

   $('.fullpageloader').show('fast');
    this.http.get(this.baseurl +'after_disconnect_api_log?user_id='+user_id+'&fromdate='+fromdate+'&todate='+todate).subscribe(
    (data)=>{
      this.disconectlog=data;
        $('.cfillBox').slideUp('fast');
       $('.fullpageloader').hide('fast');

    })


 
}

  getdata(crpage:number)
  {
    $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'after_disconnect_api_log?page='+crpage).subscribe(
    (data)=>{
      this.disconectlog=data;
     
       $('.fullpageloader').hide('fast');
    })
  }

   pageChange(newpage:number){
    this.getdata(newpage);
    this.currentPage = newpage;
      }

}
