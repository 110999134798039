import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.css']
})
export class CrmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(function(){
      $(".mreqnav ul li a").click(function(){

      var dataid = $(this).data('id');
      $(".mreqnav ul li a").removeClass('active');
      $(this).addClass('active');
     if(dataid=='suppcrm'){
       $("#suppcrmsec").show('fast');
       $("#apimngtsec").hide('fast');
       
     }
     else{
      $("#apimngtsec").show('fast');
      $("#suppcrmsec").hide('fast');
     }

      })
    })
  }

}
