import { Component, OnInit,ElementRef } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-msvnumservice',
  templateUrl: './msvnumservice.component.html',
  styleUrls: ['./msvnumservice.component.css']
})
export class MsvnumserviceComponent implements OnInit {
    buttunhide:boolean=false;
    vagentForm:FormGroup;
    topupdemoform:FormGroup;
    demorenewform:FormGroup;
    submitted2:boolean = false;
    submitted1:boolean = false;
    submitted:boolean = false;
    user_id:any;
    subtype:any;
    stype:any;
    settings:FormArray;
    costomizedplan:boolean=false;
    smsacount:boolean=false;
    minavopersonnel:boolean=false;
    crmsettsec:boolean=false;
    records:any;
    clidata:any;
    arr=[];
    didlist:boolean = false;
    bckdidlist:boolean = false;
    didnumberbox:boolean = true;
    bkpdidnumberbox:boolean = true;
    virtualnumberbox:boolean = true;
    virtualnumberradioBtn:boolean = false;
    virtualnumbersec:boolean=false;
    resselerList:boolean=false;
    capturecall:boolean=false;
    click2call:boolean=false;
    agentpopup:boolean=false;
    callrouting:boolean=false;
    singlepopup:boolean=false;
    calldisposition:boolean=false;
    personalsmscoin:boolean=false;
    otherlangcheck:boolean= false;
    nubmerfreezed:any;
    nubmerfreezedbkp:any;
    didstatus:boolean=false;
    backupdid:any;
    virtualnumber:any;
    channelallocation:boolean=false;
    didnetworkcli:any;
    didnetworkcli1:any;
    currentUserloclstrage:any;
    baseurl:any;
    remarksrecords:any;
    session_id:any;
    agentnubmerfreezed=[];
    agentListnetwork=[];
    agentserver_typecheck:boolean = true;
    server_typecheck:boolean=false;
    hideagentlist:boolean=false;
    allsettings=[
        {
        'settname':'Multi Level IVR',
        'labelname':'Multi Level IVR'
       },
       {
        'settname':'Multi Language IVR',
        'labelname':'Multi Language IVR'
       },
       {
        'settname':'In-Call Transfer',
        'labelname':'In-Call Transfer'
        },
       {
        'settname':'Call Conferencing',
        'labelname':'Call Conferencing'
       },
       {
        'settname':'Agent Login',
        'labelname':'Agent Login'
       },
       {
        'settname':'Call Waiting time Announcement',
        'labelname':'Call Waiting time Announcement'
       },
       {
        'settname':'Exceeding Channel Calllogs',
        'labelname':'Exceeding Channel Calllogs'
       },

       {
        'settname':'Leg B Announcement',
        'labelname':'Leg B Announcement'
       },
       {
        'settname':'virtual_sms',
        'labelname':'Virtual Sms'
       },
       {
        'settname':'mobile app',
        'labelname':'Agent Vagent App'
       },
       {
        'settname':'missedcall',
        'labelname':'Missed Call Service'
       },
       {
        'settname':'time based routing',
        'labelname':'Time based routing'
       },
       {
        'settname':'voicemail calltransfer',
        'labelname':'Voicemail calltransfer'
       },
       {
        'settname':'voicemail',
        'labelname':'Voicemail'
       },
       {
        'settname':'stickyagents',
        'labelname':'stickyagents'
       },
       {
        'settname':'click2call feature',
        'labelname':'Click-to-call feature'
       },
       {
        'settname':'crm hook api',
        'labelname':'CRM hook api'
       },
       {
        'settname':'custom hold music',
        'labelname':'Custom hold music'
       },
       {
        'settname':'custom onhold music',
        'labelname':'Custom on-hold music '
       },
       {
        'settname':'parallel ringing',
        'labelname':'Parallel ringing'
       },
       {
        'settname':'billing_status',
        'labelname':'Billing status'
       },
       {
        'settname':'missed call sound',
        'labelname':'Missed call sound'
       },
       {
        'settname':'ticket number reset',
        'labelname':'Ticket number reset'
       }
    ];
    industryList=['Education','Health Care','Banking','Beauty Care','Grocery','Government','Politics','Hotel/Guest House','NGO','Other'];
    vagentcurdata=[];
    vagentcurdata1=[];
    constructor(private elment: ElementRef, private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
    this.user_id = this.route.snapshot.paramMap.get("userid");
    this.subtype = this.route.snapshot.paramMap.get("subtype");
    this.stype = this.route.snapshot.paramMap.get("stype");
   // console.log(`${this.user_id} ${this.subtype} ${this.stype}`);
  }

  ngOnInit() {
    var gself= this;
    this.baseurl =  localStorage.getItem('baseurl');
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
   this.session_id =  this.currentUserloclstrage.user_id;
this.creattopupdemoform();
this.creatrenwaldemoform();
this.createForm();
this.getdata();
this.getremarkslist(this.user_id);
$(function() {
$("#backupdid_pool_network,#did_pool_network").select2();
    //   $( document ).on( 'focus', ':input', function(){
    //     $( this ).attr( 'autocomplete', 'off' );
    // });

    $("#deflang").change(function() {
      // $("#otherlang").select2().val(null).trigger("change");
      $("#otherlang option").prop('disabled',false);
     // $("#otherlang").select2('destroy').val("").select2();
      $("#otherlang").not(this).find("option[value="+ $(this).val() + "]").attr('disabled', true);
       });

    $("#otherlang").not("#deflang").find("option[value="+ $("#deflang").val() + "]").attr('disabled', true);


    $(document).on('change',"#vagentid",function(evt){
if($("#vagentid").val()!='' || $("#vagentid").val() !=undefined || $("#vagentid").val()!='undefined'){
  if($("#vagentid").val().length>1){

// var changevidarr = $(this).val().length>1?$("#vagentid").val().toString().split(","):[];
// //  for(var j=0; j<changevidarr.length;j++){
// //   gself.checkdidbackup(changevidarr[j]);
// //  }
gself.checkdidbackup($("#vagentid").val());
  }
  else if($("#vagentid").val().length==1){
    gself.checkdidbackup($("#vagentid").val());
  }

}
// if(evt.params!=undefined){
//   var element = evt.params.data.element;
// var $element = $(element);

// $element.detach();
// $(this).append($element);
// $(this).trigger("change");
// }
    })

//  $(".acchead").click(function() {
//           $(this).next('.accbox').slideToggle('fast');
//           $(this).toggleClass('active');
//       });

  });

  setTimeout(() => {

  $(".agentselect,.cliselect").prop('disabled',true);
    this.vagentForm.get('plandetail').get('server_type').valueChanges
   .subscribe(server_type => {

  if(server_type=='0'){
    var options = $('#did_network option');

    var optionvalues = $.map(options ,function(option) {
        var pks = {};
        pks["available_network"]=option.value;
        return pks
    });
    optionvalues.shift();
    console.log(optionvalues);
    this.agentListnetwork=optionvalues;
    $(".agentselect,.cliselect").prop('disabled',false);
  }
  else{
    this.agentListnetwork=[];
    $(".cliselect").val('');

     for(var trlength=0;$("#agentListtable>tr").length>trlength;trlength++){
     this.agentnubmerfreezed[trlength]='';
     this.agentserver_typecheck=true;
    }
    $(".agentselect,.cliselect").prop('disabled',true);
  }
   })
  }, 2000);
  this.vagentForm.get('dedplan').get('allcommon').valueChanges
   .subscribe(allcommon => {
  // this.vagentForm.get('dedplan').get('allcommon').value()==allcommon;
if(allcommon=="customized"){
//this.costomizedplan = true;

this.vagentForm.get('dedplan').get('obd_ded').setValue(this.records.ded_plan[0].obd_ded);
this.vagentForm.get('dedplan').get('ibd_ded').setValue(this.records.ded_plan[0].ibd_ded);
this.vagentForm.get('dedplan').get('email_ded').setValue(this.records.ded_plan[0].email_ded);
this.vagentForm.get('dedplan').get('sms_ded').setValue(this.records.ded_plan[0].sms_ded);
this.vagentForm.get('dedplan').get('tf_ded').setValue(this.records.ded_plan[0].tf_ded);
this.vagentForm.get('dedplan').get('fax_ded').setValue(this.records.ded_plan[0].fax_ded);
}
else if(allcommon=="allcommon"){

this.vagentForm.get('dedplan').get('obd_ded').setValue('Common');
this.vagentForm.get('dedplan').get('ibd_ded').setValue('Common');
this.vagentForm.get('dedplan').get('email_ded').setValue('Common');
this.vagentForm.get('dedplan').get('sms_ded').setValue('Common');
this.vagentForm.get('dedplan').get('tf_ded').setValue('Common');
this.vagentForm.get('dedplan').get('fax_ded').setValue('Common');
}
else{

this.vagentForm.get('dedplan').get('obd_ded').setValue('Self');
this.vagentForm.get('dedplan').get('ibd_ded').setValue('Self');
this.vagentForm.get('dedplan').get('email_ded').setValue('Self');
this.vagentForm.get('dedplan').get('sms_ded').setValue('Self');
this.vagentForm.get('dedplan').get('tf_ded').setValue('Self');
this.vagentForm.get('dedplan').get('fax_ded').setValue('Self');
 }

})

this.vagentForm.get('smssetting').get('account_type').valueChanges
.subscribe(account_type => {
if(account_type=="Minavo"){
this.smsacount = false;
this.minavopersonnel  = false;
this.personalsmscoin = false;

//this.vagentForm.get('dedplan').get('fax_ded').setValue('Self');

this.vagentForm.get('othersetting').get('A15').get("param_value").setValue(account_type);

this.vagentForm.get("smssetting").get("base_uri").setValidators(null);
this.vagentForm.get("smssetting").get("param_user_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_user_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators(null);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators(null);
this.vagentForm.get("smssetting").get("param_message_name").setValidators(null);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();

}
else if(account_type=="personal" || account_type=="Personal"){

this.smsacount = true;
this.minavopersonnel  = false;
this.personalsmscoin = false;

this.vagentForm.get('othersetting').get('A15').get("param_value").setValue(account_type);

this.vagentForm.get("smssetting").get("base_uri").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_message_name").setValidators([Validators.required]);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();

}
else if(account_type=="minavo-personal"){
this.personalsmscoin = true;
this.smsacount = true;
this.minavopersonnel  = true
this.vagentForm.get('othersetting').get('A15').get("param_value").setValue(account_type);

this.vagentForm.get("smssetting").get("base_uri").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_user_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_pass_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_sid_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_lang_value").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_mobile_name").setValidators([Validators.required]);
this.vagentForm.get("smssetting").get("param_message_name").setValidators([Validators.required]);

this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
 }
})

this.vagentForm.get('smssetting').get('minpertype').valueChanges
.subscribe(minpertype => {

   if(minpertype=='default'){

    this.vagentForm.get("smssetting").get("base_uri").setValue(this.records.deafult_sms_details[0].base_uri);
    this.vagentForm.get("smssetting").get("param_user_name").setValue(this.records.deafult_sms_details[0].param_user_name);
    this.vagentForm.get("smssetting").get("param_user_value").setValue(this.records.deafult_sms_details[0].param_user_value);
    this.vagentForm.get("smssetting").get("param_pass_name").setValue(this.records.deafult_sms_details[0].param_pass_name);
    this.vagentForm.get("smssetting").get("param_pass_value").setValue(this.records.deafult_sms_details[0].param_pass_value);
    this.vagentForm.get("smssetting").get("param_sid_name").setValue(this.records.deafult_sms_details[0].param_sid_name);
    this.vagentForm.get("smssetting").get("param_sid_value").setValue(this.records.deafult_sms_details[0].param_sid_value);
    this.vagentForm.get("smssetting").get("param_lang_name").setValue(this.records.deafult_sms_details[0].param_lang_name);
    this.vagentForm.get("smssetting").get("param_lang_value").setValue(this.records.deafult_sms_details[0].param_lang_value);
    this.vagentForm.get("smssetting").get("param_mobile_name").setValue(this.records.deafult_sms_details[0].param_mobile_name);
    this.vagentForm.get("smssetting").get("param_message_name").setValue(this.records.deafult_sms_details[0].param_message_name);

    this.vagentForm.get('smssetting').get('base_uri').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
   }
   else{

  setTimeout(()=>{
    this.vagentForm.get("smssetting").get("base_uri").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].base_uri);
    this.vagentForm.get("smssetting").get("param_user_name").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_user_name);
    this.vagentForm.get("smssetting").get("param_user_value").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_user_value);
    this.vagentForm.get("smssetting").get("param_pass_name").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_pass_name);
    this.vagentForm.get("smssetting").get("param_pass_value").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_pass_value);
    this.vagentForm.get("smssetting").get("param_sid_name").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_sid_name);
    this.vagentForm.get("smssetting").get("param_sid_value").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_sid_value);
    this.vagentForm.get("smssetting").get("param_lang_name").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_lang_name);
    this.vagentForm.get("smssetting").get("param_lang_value").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_lang_value);
    this.vagentForm.get("smssetting").get("param_mobile_name").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_mobile_name);
    this.vagentForm.get("smssetting").get("param_message_name").setValue(this.records.sms_detail[0] && this.records.sms_detail[0].param_message_name);

}, 200);

    this.vagentForm.get('smssetting').get('param_user_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_user_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_pass_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_sid_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_value').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_lang_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_mobile_name').updateValueAndValidity();
    this.vagentForm.get('smssetting').get('param_message_name').updateValueAndValidity();
   }
})

this.vagentForm.get('crmsetting').get('crm_name').valueChanges
.subscribe(crm_name => {

if(crm_name=='minavo'){
         this.crmsettsec=false;
        this.vagentForm.get('crmsetting').get('capture_call_status').setValue(0);
        this.vagentForm.get('crmsetting').get('click_tocall_status').setValue(0);
        this.vagentForm.get('crmsetting').get('agentpopup_status').setValue(0);
        this.vagentForm.get('crmsetting').get('callrouting_status').setValue(0);
        this.vagentForm.get('crmsetting').get('singlpopup_status').setValue(0);
        this.vagentForm.get('crmsetting').get('calldisposition_status').setValue(0);


        // this.vagentForm.get('crmsetting').get('capture_call_status').setValue('');
        // this.vagentForm.get('crmsetting').get('click_tocall_status').setValue('');
        // this.vagentForm.get('crmsetting').get('agentpopup_status').setValue('');
        // this.vagentForm.get('crmsetting').get('callrouting_status').setValue('');
        // this.vagentForm.get('crmsetting').get('singlpopup_status').setValue('');
        // this.vagentForm.get('crmsetting').get('calldisposition_status').setValue('');


  }
   else{


    this.crmsettsec=true;
  if(this.records.crm_details[0]===undefined){

    this.vagentForm.get('crmsetting').get('capture_call_status').setValue(0);
    this.vagentForm.get('crmsetting').get('click_tocall_status').setValue(0);
    this.vagentForm.get('crmsetting').get('agentpopup_status').setValue(0);
    this.vagentForm.get('crmsetting').get('callrouting_status').setValue(0);
    this.vagentForm.get('crmsetting').get('singlpopup_status').setValue(0);
    this.vagentForm.get('crmsetting').get('calldisposition_status').setValue(0);


    this.vagentForm.get('crmsetting').get('capture_call_input').setValue('');
    this.vagentForm.get('crmsetting').get('click_tocall_input').setValue('');
    this.vagentForm.get('crmsetting').get('agentpopup_input').setValue('');
    this.vagentForm.get('crmsetting').get('callrouting_input').setValue('');
    this.vagentForm.get('crmsetting').get('singlpopup_input').setValue('');
    this.vagentForm.get('crmsetting').get('calldisposition_input').setValue('');
  }
  else{
    this.vagentForm.get('crmsetting').get('capture_call_status').setValue(this.records.crm_details[0].capture_call_logs_status);
    this.vagentForm.get('crmsetting').get('click_tocall_status').setValue(this.records.crm_details[0].click_2_call_status);
    this.vagentForm.get('crmsetting').get('agentpopup_status').setValue(this.records.crm_details[0].agent_popup_status);
    this.vagentForm.get('crmsetting').get('callrouting_status').setValue(this.records.crm_details[0].call_routing_status);
    this.vagentForm.get('crmsetting').get('singlpopup_status').setValue(this.records.crm_details[0].single_sign_status);
    this.vagentForm.get('crmsetting').get('calldisposition_status').setValue(this.records.crm_details[0].call_disposition_status);

  }


// capture_call_status:data.crm_details[0] && data.crm_details[0].capture_call_logs_status,
// capture_call_input:data.crm_details[0] && data.crm_details[0].capture_call_logs,
// click_tocall_status:data.crm_details[0] && data.crm_details[0].click_2_call_status,
// click_tocall_input:data.crm_details[0] && data.crm_details[0].click_2_call,
// agentpopup_status:data.crm_details[0] && data.crm_details[0].agent_popup_status,
// agentpopup_input:data.crm_details[0] && data.crm_details[0].agent_popup,
// callrouting_status:data.crm_details[0] && data.crm_details[0].call_routing_status,
// callrouting_input:data.crm_details[0] && data.crm_details[0].call_routing,
// singlpopup_status:data.crm_details[0] && data.crm_details[0].single_sign_status,
// singlpopup_input:data.crm_details[0] && data.crm_details[0].single_sign,
// calldisposition_status:data.crm_details[0] && data.crm_details[0].call_disposition_status,
// calldisposition_input:data.crm_details[0] && data.crm_details[0].call_disposition,

   }
})

// this.vagentForm.get('plandetail').get('select_server').valueChanges
// .subscribe(select_server => {

//    if(select_server=='NA'){
//       this.clidata=['NA'];
//    }
// //  else if(select_server=='PRI Server'){
// //      this.clidata =  this.records.did.map(cli => cli.obd_cli);

// //    }
//    else{
//     this.clidata=  this.records.cli.map(cli => cli.obd_cli);
//    }
// })

this.vagentForm.get('setting').get('1').get("settvalue").valueChanges
.subscribe(settvalue => {
  //alert(settvalue);
 if(settvalue=='0'){
  this.otherlangcheck = false;
  this.vagentForm.get('othersetting').get('A12').get("param_value").setValidators(null);
  this.vagentForm.get('othersetting').get('A12').get("param_value").updateValueAndValidity();
}
else{
  this.otherlangcheck = true;
  this.vagentForm.get('othersetting').get('A12').get("param_value").setValidators([Validators.required]);
  this.vagentForm.get('othersetting').get('A12').get("param_value").updateValueAndValidity();
}
})
this.vagentForm.get('plandetail').get('acc_owner').valueChanges
.subscribe(acc_owner => {

   if(acc_owner=='Minavo'){
      this.resselerList= false;
   }
else{
  this.resselerList= true;
   }
})

this.vagentForm.get('crmsetting').get('capture_call_status').valueChanges
.subscribe(capture_call_status => {
if(capture_call_status==1){
  this.capturecall = true;
  this.vagentForm.get('crmsetting').get('capture_call_input').setValidators([Validators.required]);

}
else{
this.capturecall = false;

this.vagentForm.get('crmsetting').get('capture_call_input').setValidators(null);
this.vagentForm.get('crmsetting').get('capture_call_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('click_tocall_status').valueChanges
.subscribe(click_tocall_status => {

if(click_tocall_status==1){
  this.click2call = true;
  this.vagentForm.get('crmsetting').get('click_tocall_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('click_tocall_input').updateValueAndValidity();
}
else{
  this.click2call = false;
  this.vagentForm.get('crmsetting').get('click_tocall_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('click_tocall_input').updateValueAndValidity();

}
})
this.vagentForm.get('crmsetting').get('agentpopup_status').valueChanges
.subscribe(agentpopup_status => {
if(agentpopup_status==1){
  this.agentpopup = true;
  this.vagentForm.get('crmsetting').get('agentpopup_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('agentpopup_input').updateValueAndValidity();
}
else{
  this.agentpopup = false;
  this.vagentForm.get('crmsetting').get('agentpopup_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('agentpopup_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('callrouting_status').valueChanges
.subscribe(callrouting_status => {
 if(callrouting_status==1){
  this.callrouting = true;
  this.vagentForm.get('crmsetting').get('callrouting_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('callrouting_input').updateValueAndValidity();
}
else{
  this.callrouting = false;
  this.vagentForm.get('crmsetting').get('callrouting_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('callrouting_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('singlpopup_status').valueChanges
.subscribe(singlpopup_status => {
  if(singlpopup_status==1){
  this.singlepopup = true;
  this.vagentForm.get('crmsetting').get('singlpopup_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('singlpopup_input').updateValueAndValidity();
}
else{
  this.singlepopup = false;
  this.vagentForm.get('crmsetting').get('singlpopup_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('singlpopup_input').updateValueAndValidity();
}
})
this.vagentForm.get('crmsetting').get('calldisposition_status').valueChanges
.subscribe(calldisposition_status => {
 if(calldisposition_status==1){
  this.calldisposition = true;
  this.vagentForm.get('crmsetting').get('calldisposition_input').setValidators([Validators.required]);
  this.vagentForm.get('crmsetting').get('calldisposition_input').updateValueAndValidity();
}
else{
  this.calldisposition = false;
  this.vagentForm.get('crmsetting').get('calldisposition_input').setValidators(null);
  this.vagentForm.get('crmsetting').get('calldisposition_input').updateValueAndValidity();
}
})

this.vagentForm.get('othersetting').get('A5').get("param_value").valueChanges
.subscribe(param_value => {
 if(param_value=='Common'){
  this.channelallocation = true;
  this.vagentForm.get('othersetting').get('A16').get("param_value").setValue("NA");
  this.vagentForm.get('othersetting').get('A17').get("param_value").setValue("NA");
  this.vagentForm.get('othersetting').get('A16').updateValueAndValidity();
  this.vagentForm.get('othersetting').get('A17').updateValueAndValidity();
  }
else{
  this.channelallocation = false;
  this.vagentForm.get('othersetting').get('A16').get("param_value").setValue(this.records.setting[36].param_value);
  this.vagentForm.get('othersetting').get('A17').get("param_value").setValue(this.records.setting[37].param_value);
  this.vagentForm.get('othersetting').get('A16').updateValueAndValidity();
  this.vagentForm.get('othersetting').get('A17').updateValueAndValidity();
}
})

  }

createForm(){

    for(let i=0;i< this.allsettings.length;i++)
    {
     // let arr =[];
     // console.log(this.allsettings[i]);
      this.arr.push(this.BuildFormDynamic(this.allsettings[i]));

    }

    // this.arr.push({settname:'Coin Allocation Type',coinalltype:['monthly']})
this.vagentForm = this.fb.group({
    clientdetail:this.fb.group({
        company:[{value:'', disabled:true},[Validators.required]],
        first_name:[{value:'', disabled:true},[Validators.required]],
        last_name:[{value:'', disabled:true}],
        designation:[{value:'', disabled:true},[Validators.required]],
        industry:[{value:'', disabled:true},[Validators.required]],
        comp_size:[{value:'', disabled:true}],
        mobile:[{value:'', disabled:true},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
        email:[{value:'', disabled:true},[Validators.required,Validators.email]],
        password:[{value:'', disabled:true},[Validators.required]],
        address_fl:[{value:'', disabled:true}],
        address_ll:[{value:'', disabled:true}],
        pin:[{value:'', disabled:true}],
        country:[{value:'', disabled:true}],
        state:[{value:'', disabled:true}],
        city:[{value:'', disabled:true}],
        sale_exc_name:[{value:'', disabled:true}],
        telesale_exc_name:[{value:'', disabled:true}]
    }),
    adminsetting:this.fb.group({
      name:[{value:'', disabled:true},[Validators.required]],
      email:[{value:'', disabled:true},[Validators.required,Validators.email]],
      mobile:[{value:'', disabled:true},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]]
    }),
   dedplan:this.fb.group({
        allcommon:[{value:'', disabled:true},[Validators.required]],
        obd_ded:[{value:'', disabled:true},[Validators.required]],
        ibd_ded:[{value:'', disabled:true},[Validators.required]],
        email_ded:[{value:'', disabled:true},[Validators.required]],
        sms_ded:[{value:'', disabled:true},[Validators.required]],
        tf_ded:[{value:'', disabled:true},[Validators.required]],
        fax_ded:[{value:'', disabled:true},[Validators.required]],
     }),
    plandetail:this.fb.group({
         contract_period:[{value:'', disabled:true},[Validators.required]],
         service_name:[{value:'', disabled:true},[Validators.required]],
         plan_name:[{value:'', disabled:true},[Validators.required]],
         plan_price:[{value:'', disabled:true},[Validators.required]],
         plan_term:[{value:'', disabled:true},[Validators.required]],
         total_coins:[{value:'', disabled:true},[Validators.required]],
         virtual_ownership:[{value:'', disabled:true},[Validators.required]],
         type_vnum:[{value:'', disabled:true},[Validators.required]],
         free_chanel:[{value:'', disabled:true},[Validators.required]],
         adon_chnl_price:[{value:'', disabled:true},[Validators.required]],
         adon_feaure_price:[{value:'', disabled:true},[Validators.required]],
         ibd_coins:[{value:'', disabled:true},[Validators.required]],
         obd_coins:[{value:'', disabled:true},[Validators.required]],
         sms_coins:[{value:'', disabled:true},[Validators.required]],
         email_coins:[{value:'', disabled:true},[Validators.required]],
         fac_coins:[{value:'', disabled:true},[Validators.required]],
         tf_coins:[{value:'', disabled:true},[Validators.required]],
         ibd_ded_sec:[{value:'', disabled:true},[Validators.required]],
         ibd_ded_rate:[{value:'', disabled:true},[Validators.required]],
         obd_ded_sec:[{value:'', disabled:true},[Validators.required]],
         obd_ded_rate:[{value:'', disabled:true},[Validators.required]],
         sms_ded__cnt:[{value:'', disabled:true},[Validators.required]],
         smsded_rate:[{value:'', disabled:true},[Validators.required]],
         tf_ded_cnt:[{value:'', disabled:true},[Validators.required]],
         tf_ded_rate:[{value:'', disabled:true},[Validators.required]],
         email_ded_cnt:[{value:'', disabled:true},[Validators.required]],
         email_ded_rate:[{value:'', disabled:true},[Validators.required]],
         fax_ded_cnt:[{value:'', disabled:true},[Validators.required]],
         fax_ded_rate:[{value:'', disabled:true},[Validators.required]],
         c2c_rate:[{value:'', disabled:true},[Validators.required]],
         c2c_ded_sec:[{value:'', disabled:true},[Validators.required]],
         sub_type:[{value:'', disabled:true},[Validators.required]],
         virtual_number:[{value:'', disabled:true}],
         middle_mobile:[{value:'', disabled:true},[Validators.required]],
         operator:[{value:'', disabled:true},[Validators.required]],
         service_billing:[{value:'', disabled:true}],
         ivr_script:[{value:'', disabled:true}],
         ivr_type:[{value:'', disabled:true},[Validators.required]],
         accounttype:[{value:'', disabled:true},[Validators.required]],
         acc_owner:[{value:'', disabled:true},[Validators.required]],
         resselerlist:[{value:'', disabled:true},[Validators.required]],
         did_no:[{value:'', disabled:true},[Validators.required,Validators.minLength(10),Validators.maxLength(10)]],
         did_no_bk:[{value:'', disabled:true},[Validators.minLength(10),Validators.maxLength(10)]],
         did_network:[{value:'',disabled:true},[Validators.required]],
         server_type:[{value:'',disabled:true}],
         did_pool_network:[{value:'',disabled:true}],
         did_bkp_network:[{value:'',disabled:true}],
         backupdid_pool_network:[{value:'',disabled:true}],
         did_outcall_cli:[{value:'',disabled:true},[Validators.required]],
         bkpdid_outcall_cli:[{value:'',disabled:true}],
         did_network_input:[{value:'',disabled:true}],
         bkpdid_network_input:[{value:'',disabled:true}],
         vagentlist:[{ value: '',disabled:true}],
         //select_server:[{value:'', disabled:true}],
        // outcall_cli:[{value:'', disabled:true}]

 }),
 smssetting:this.fb.group({
account_type:[{value:'', disabled:true},[Validators.required]],
minpertype:[{value:'', disabled:true},[Validators.required]],
base_uri:[{value:'', disabled:true},[Validators.required]],
param_user_name:[{value:'', disabled:true},[Validators.required]],
param_user_value:[{value:'', disabled:true},[Validators.required]],
param_pass_name:[{value:'', disabled:true},[Validators.required]],
param_pass_value:[{value:'', disabled:true},[Validators.required]],
param_sid_name:[{value:'', disabled:true},[Validators.required]],
param_sid_value:[{value:'', disabled:true},[Validators.required]],
param_lang_name:[{value:'', disabled:true},[Validators.required]],
param_lang_value:[{value:'', disabled:true},[Validators.required]],
param_mobile_name:[{value:'', disabled:true},[Validators.required]],
param_message_name:[{value:'', disabled:true},[Validators.required]],
personal_sms_coins:[{value:'', disabled:true},[Validators.required]],
}),
setting:this.fb.array(this.arr),
othersetting:this.fb.group({
  A0:this.fb.group({
    setting_param:"Display Logs Type",
    param_value:[{value:'', disabled:true}]
  }),
  A1:this.fb.group({
    setting_param:"Coin Allocation Type",
    param_value:[{value:'', disabled:true}]
  }),
  A2:this.fb.group({
    setting_param:"Missed Calls Allocation",
    param_value:[{value:'not_assigned', disabled:true}]
    }),
  A3:this.fb.group({
    setting_param:"Call Time Capping",
    param_value:[{value:'NA', disabled:true}]
    }),
  A4:this.fb.group({
      setting_param:"Channel Allocation",
      param_value:[{value:'NA', disabled:true}]
    }),
    A5:this.fb.group({
      setting_param:"Channel_alloc_type",
      param_value:[{value:'NA', disabled:true}]
      }),
      A6:this.fb.group({
        setting_param:"Email Calllog Report",
        param_value:[{value:'NA', disabled:true}]
      }),
      A7:this.fb.group({
        setting_param:"User Call Forwarding",
        param_value:[{value:'NA', disabled:true}]
      }),
      A8:this.fb.group({
        setting_param:"Email Alert Setting",
        param_value:[{value:'NA', disabled:true}]
      }),
      A9:this.fb.group({
        setting_param:"Low balance Alert",
        param_value:[{value:'60', disabled:true}]
      }),
      A10:this.fb.group({
        setting_param:"round_robin_retry_cnt",
        param_value:[{value:'NA', disabled:true}]
      }),
      A11:this.fb.group({
        setting_param:"Default Language",
        param_value:[{value:'hi', disabled:true}]
      }),
      A12:this.fb.group({
        setting_param:"Other Language",
        param_value:[{value:'hi', disabled:true}]
      }),
      A13:this.fb.group({
        setting_param:"agent allowed",
        param_value:[{value:'', disabled:true}]
      }),
      A14:this.fb.group({
        setting_param:"Manager account",
        param_value:[{value:'hi', disabled:true}]
      }),
      A15:this.fb.group({
        setting_param:"smsaccount",
        param_value:[{value:'', disabled:true}]
      }),
      A16:
      this.fb.group({
        setting_param:"ibd_channel",
        param_value:[{value:'', disabled:true}]
      }),
      A17:this.fb.group({
          setting_param:"obd_channel",
          param_value:[{value:'', disabled:true}]
        }),
        A18:this.fb.group({
          setting_param:"channel_pooling",
          param_value:[{value:'', disabled:true}]
        }),
}
),
crmsetting:this.fb.group({
    crm_name:[{value:'', disabled:true},[Validators.required]],
    //crmsett:[{value:'', disabled:false},[Validators.required]],
    capture_call_status:[{value:'', disabled:true}],
    capture_call_input:[{value:'', disabled:true},[Validators.required]],
    click_tocall_status:[{value:'', disabled:true}],
    click_tocall_input:[{value:'', disabled:true},[Validators.required]],
    agentpopup_status:[{value:'', disabled:true}],
    agentpopup_input:[{value:'', disabled:true},[Validators.required]],
    callrouting_status:[{value:'', disabled:true}],
    callrouting_input:[{value:'', disabled:true},[Validators.required]],
    singlpopup_status:[{value:'', disabled:true}],
    singlpopup_input:[{value:'', disabled:true},[Validators.required]],
    calldisposition_status:[{value:'', disabled:true}],
    calldisposition_input:[{value:'', disabled:true},[Validators.required]],
}),
remarks:[{value:'', disabled:true}],
userid:this.user_id,
stype:'',
subtype:'',
account_id:''
})
}

BuildFormDynamic(sett:any):FormGroup{
    return this.fb.group({
     settname:[sett.settname],
     labelname:[sett.labelname],
     settvalue:{value:0, disabled:true},
     })
   }

get f() { return this.vagentForm.controls }
onSubmit(){
  var that = this;
  //  console.log(this.f.clientdetail.controls['company'].errors);
  if(this.vagentForm.get('plandetail').get("server_type").value=='0'){
    // $('.serveselect').each(function(){
    //   if($("#serveselect0").val() == $(this).val()){
    //    that.server_typecheck=true;
    //      }
    //       else{
    //        that.server_typecheck=false;
    //        return false;
    //       }

    //    })
       $('.agentselect').each(function(){
         if($(this).val()!=''){
          that.agentserver_typecheck=true;
         }
             else{
              //  $(this).css('border-color','#ff0000');
              //  setTimeout(() => {
              //    $(this).css('border-color','#b8b8b8');
              //  }, 8000);
              that.agentserver_typecheck=true;
            //  return false;
             }

          })

  }
  else{
 this.agentserver_typecheck=true;
  }

  var did_pool_list = $("#did_pool_network").val();
  //console.log("vagent values"+vglvals);
  var backupdid_pool_list = $("#backupdid_pool_network").val();

if(did_pool_list!=undefined || did_pool_list!='' || did_pool_list!=null){

var did_pool_listdata = did_pool_list.map(function(item){
return item
}).join(",");

}
else{
  did_pool_listdata='';
}


if(backupdid_pool_list!=undefined || backupdid_pool_list!='' || backupdid_pool_list!=null){
var backupdid_pool_listdata = backupdid_pool_list.map(function(item){
      return item
      }).join(",");
}
else{
  backupdid_pool_listdata='';
}
this.vagentForm.get('plandetail').get('did_pool_network').setValue(did_pool_listdata);
this.vagentForm.get('plandetail').get('backupdid_pool_network').setValue(backupdid_pool_listdata);
var agentlisttable=[];

  $("#agentListtable >tr").each(function() {

    var agentid = $(this).find('input[type="text"].agentid').val();
    var agentidnetwork = $(this).find('select.agentselect').val();
    var cliselect = $(this).find('input[type="text"].cliselect').val();


   var item = {}
    item ["agentid"] = agentid;
    item ["network"] = agentidnetwork;
    item ["agentcli"] = cliselect;

    agentlisttable.push(item);
});

    this.submitted = true;
    var vglvals = $("#vagentid").val();
    if(vglvals!=undefined || vglvals!='' || vglvals!=null){
     var vglvals1final = vglvals.map(function(item){
      return item
      }).join(",");
      }
      else{
        vglvals1final='';
      }
      this.vagentForm.get('plandetail').get('vagentlist').setValue(vglvals1final);
    let vagentdata =  JSON.stringify(this.vagentForm.value);
    let agentlistdata = JSON.stringify(agentlisttable);
    alert(agentlistdata);
    if(that.agentserver_typecheck==false){
      this.toastr.error("Please Choose all agent network from agent list in case of same server.", 'Error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
    return false;
    }
else if(this.vagentForm.invalid){
  if (!this.vagentForm.valid) {
    let target;

    target = this.elment.nativeElement.querySelector('.is-invalid')

    if (target) {
        $('html,body').animate({ scrollTop: $(target).offset().top }, 'slow');
        target.focus();
    }
}
return false;
 }

  else{
    $(".fullpageloader").fadeIn("fast");
        this.http.post(this.baseurl+"update_service",{data:vagentdata,agentlist:agentlistdata}).subscribe((data:any)=>{
        console.log(data);
        if(data.status=="success"){
           $(".fullpageloader").fadeOut('fast');
             this.toastr.success('Data Submitted  succesfully!', 'Success!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });
         //  $(".fullpageloader").fadeOut("fast");
           this.router.navigateByUrl('/servicelist');
           }
         else if(data.status=="fail"){
          $(".fullpageloader").fadeOut('fast');
          this.toastr.error(data.message, 'Error!',{
             positionClass:'toast-top-center',
             timeOut: 3000
           });

         }

       },
       error => {
       //  $(".fullpageloader").fadeOut('fast');
         this.toastr.error(error, 'error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
     })
       }

}

getdata(){
    $(".fullpageloader").fadeIn('fast');
    this.http.get(this.baseurl+"get_service_details?user_id="+this.user_id+"&type="+this.stype).subscribe((data:any)=>{
    this.records=data;
    let allldid = data.did.map((item)=> item.did_no);
    var allbkp_did,allmergedid;

  if(data.profile[0].bulk_did!=null || data.profile[0].bulk_did!=undefined){
  //  alert("hi"+typeof data.profile[0].bulk_did);
      allbkp_did = data.profile[0].bulk_did.length!=''?data.profile[0].bulk_did.split(','):[];
       allmergedid = [...allldid,...allbkp_did];
    }
    else{
       allmergedid = [...allldid];
    }


   this.vagentcurdata = allmergedid.filter((item, i, ar) => ar.indexOf(item) === i);
   this.vagentcurdata1 = allmergedid.filter((item, i, ar) => ar.indexOf(item) === i);
  //  console.log(this.vagentcurdata);
  this.vagentlistdata(this.vagentcurdata,allbkp_did);
 //console.log(data);
//console.log(data.setting[38].param_value);
  //  console.log(data.setting);
//console.log(data.crm_details[0] && data.crm_details[0].capture_call_logs_status);
//console.log(data.crm_details[0]);
//console.log("A1:"+data.setting[24].param_value);
//console.log(data.crm_details[0].capture_call_logs_status);
    $(".fullpageloader").fadeOut('fast');
  this.vagentForm.patchValue({
    clientdetail:{
        company:data.profile[0].company,
        first_name:data.profile[0].first_name+" "+data.profile[0].last_name,
        last_name:'',
        designation:data.profile[0].designation,
        industry:data.profile[0].industry,
        comp_size:data.profile[0].comp_size,
        mobile:data.profile[0].mobile,
        email:data.profile[0].email,
        password:data.profile[0].password,
        address_fl:data.profile[0].address_fl,
        address_ll:data.profile[0].address_ll,
        pin:data.profile[0].pin,
        country:data.profile[0].country,
        state:data.profile[0].state,
        city:data.profile[0].city,
        sale_exc_name:data.profile[0].sale_exc_name,
        telesale_exc_name:data.profile[0].telesale_exc_name
    },
    adminsetting:{
      name:data.admin_details[0] && data.admin_details[0].name,
      email:data.admin_details[0] && data.admin_details[0].email,
      mobile:data.admin_details[0] && data.admin_details[0].mobile
     },
    dedplan:{
        allcommon:this.getallcommon(data.ded_plan[0].obd_ded,data.ded_plan[0].ibd_ded,data.ded_plan[0].email_ded,data.ded_plan[0].sms_ded,data.ded_plan[0].tf_ded,data.ded_plan[0].fax_ded),
        obd_ded:data.ded_plan[0].obd_ded,
        ibd_ded:data.ded_plan[0].ibd_ded,
        email_ded:data.ded_plan[0].email_ded,
        sms_ded:data.ded_plan[0].sms_ded,
        tf_ded:data.ded_plan[0].tf_ded,
        fax_ded:data.ded_plan[0].fax_ded,
    },
    plandetail:{
        contract_period:data.profile[0].contract_period,
        service_name:data.profile[0].service_name,
        plan_name:data.plan[0].plan_name,
        plan_price:data.plan[0].plan_price,
        plan_term:data.plan[0].plan_term,
        total_coins:data.plan[0].total_coins,
        virtual_ownership:data.profile[0].virtual_ownership,
        type_vnum:data.profile[0].type_vnum,
        free_chanel:data.plan[0].free_chanel,
        adon_chnl_price:data.plan[0].adon_chnl_price,
        adon_feaure_price:data.plan[0].adon_feaure_price,
        ibd_coins:data.plan[0].ibd_coins,
        obd_coins:data.plan[0].obd_coins,
        sms_coins:data.plan[0].sms_coins,
        email_coins:data.plan[0].email_coins,
        fac_coins:data.plan[0].fac_coins,
        tf_coins:data.plan[0].tf_coins,
        ibd_ded_sec:data.plan[0].ibd_ded_sec,
        ibd_ded_rate:data.plan[0].ibd_ded_rate,
        obd_ded_sec:data.plan[0].obd_ded_sec,
        obd_ded_rate:data.plan[0].obd_ded_rate,
        sms_ded__cnt:data.plan[0].sms_ded__cnt,
        smsded_rate:data.plan[0].smsded_rate,
        tf_ded_cnt:data.plan[0].tf_ded_cnt,
        tf_ded_rate:data.plan[0].tf_ded_rate,
        email_ded_cnt:data.plan[0].email_ded_cnt,
        email_ded_rate:data.plan[0].email_ded_rate,
        fax_ded_cnt:data.plan[0].fax_ded_cnt,
        fax_ded_rate:data.plan[0].fax_ded_rate,
        c2c_rate:data.plan[0].c2c_rate,
        c2c_ded_sec:data.plan[0].c2c_ded_sec,
        sub_type:data.profile[0].sub_type,
        virtual_number:data.profile[0].virtual_number,
        middle_mobile:data.profile[0].backend_mobile,
        operator:data.profile[0].operator,
        service_billing:data.plan[0].service_billing,
        ivr_script:data.profile[0].ivr_script,
        ivr_type:function(ivertype){
if(ivertype==null){
return 'NA'
}
else{
  return ivertype;
}
        }(data.profile[0].ivr_type),
        accounttype:data.profile[0].account_type,
        acc_owner:data.profile[0].acc_owner,
        resselerlist:data.profile[0].owner_id,
        did_no:data.profile[0].did_no,
        did_no_bk:data.profile[0].did_no_bk,
        did_network:data.profile[0].outcall_network,
        server_type:function(stp){
          if(stp==0){
           return "0"
          }
          else{
           return "1"
          }
        }(data.profile[0].network_setting),
        did_pool_network:function(sid){
          var $did_pool_network = $("#did_pool_network").select2();
          if(sid==null || sid==undefined || sid==''){
            $did_pool_network.val(sid).trigger('change');
            return '';
        }
          else{
          var siddata=sid.split(",");
          $did_pool_network.val(sid);
         setTimeout(() => {
          $did_pool_network.val(siddata).trigger('change');
         }, 200);
          //$('#vagentid').select2('val',sid,true).trigger('change');
          return sid;
          }

        }(data.profile[0].pooling_network),
        did_bkp_network:data.profile[0].backup_outcall_network,
        backupdid_pool_network:function(sid){
          var $backupdid_pool_network = $("#backupdid_pool_network").select2();
          if(sid==null || sid==undefined || sid==''){
            $backupdid_pool_network.val(sid).trigger('change');
            return '';
        }
          else{

          var siddata=sid.split(",");
          $backupdid_pool_network.val(sid);
         setTimeout(() => {
          $backupdid_pool_network.val(siddata).trigger('change');
         }, 200);
          //$('#vagentid').select2('val',sid,true).trigger('change');
          return sid;
          }

        }(data.profile[0].backup_pooling_network),
        did_outcall_cli:data.profile[0].outcall_cli,
        bkpdid_outcall_cli:data.profile[0].backup_outcall_cli,
        did_network_input:data.profile[0].outcall_network,
        bkpdid_network_input:data.profile[0].backup_outcall_network,
        vagentlist:function(sid){
          var $vagentid = $("#vagentid").select2();
          if(sid==null || sid==undefined || sid==''){
            $vagentid.val(sid).trigger('change');
            return '';
        }
          else{
          var siddata=sid.split(",");
          $vagentid.val(sid);
         setTimeout(() => {
          $vagentid.val(siddata).trigger('change');
         }, 500);
          //$('#vagentid').select2('val',sid,true).trigger('change');
          return sid;
          }

        }(data.profile[0].bulk_did),
        // select_server:function(serverdetail,servername){

        //   if(serverdetail=='undefined' || serverdetail=='undefined'==null){
        //  return 'NA';
        //   }else{
        //     return "NA";
        //   }
        // }(data.server[0]),
//         select_server:function(outcli){
//           if(outcli==null || outcli=='NA'  || outcli=='undefined' ){
// return 'NA'
//           }
//           else{
//             return 'PRI Server';
//           }
//         }(data.profile[0].outcall_cli),
//         outcall_cli:data.profile[0].outcall_cli
    },
    smssetting:{
        account_type:function(accdata){
if(accdata=='Minavo' || accdata=='minavo'){
return 'Minavo';
}
else if(accdata=='personal' || accdata=='Personal'){
  return 'personal';
}
else{
return 'minavo-personal'
}
        }(data.setting[38].param_value),
        minpertype:'manual',
        base_uri:data.deafult_sms_details[0].base_uri,
        param_user_name:data.deafult_sms_details[0].param_user_name,
        param_user_value:data.deafult_sms_details[0].param_user_value,
        param_pass_name:data.deafult_sms_details[0].param_pass_name,
        param_pass_value:data.deafult_sms_details[0].param_pass_value,
        param_sid_name:data.deafult_sms_details[0].param_sid_name,
        param_sid_value:data.deafult_sms_details[0].param_sid_value,
        param_lang_name:data.deafult_sms_details[0].param_lang_name,
        param_lang_value:data.deafult_sms_details[0].param_lang_value,
        param_mobile_name:data.deafult_sms_details[0].param_mobile_name,
        param_message_name:data.deafult_sms_details[0].param_message_name,
        personal_sms_coins:data.plan[0].personal_sms_coins,

    },
    setting:[
   {settname:data.setting[0].setting_param,labelname:"Multi Level IVR", settvalue:data.setting[0].param_value},
   {settname:data.setting[1].setting_param,labelname:"Multi Language IVR",  settvalue:data.setting[1].param_value},
   {settname:data.setting[2].setting_param, labelname:"In-Call Transfer",  settvalue:data.setting[2].param_value},
   {settname:data.setting[3].setting_param, labelname:"Call Conferencing",  settvalue:data.setting[3].param_value},
   {settname:data.setting[4].setting_param, labelname:"Agent Login",  settvalue:data.setting[4].param_value},
   {settname:data.setting[5].setting_param, labelname:"Call Waiting time Announcement",  settvalue:data.setting[5].param_value},
   {settname:data.setting[6].setting_param, labelname:"Exceeding Channel Calllogs",  settvalue:data.setting[6].param_value},
   {settname:data.setting[7].setting_param, labelname:"Leg B Announcement",  settvalue:data.setting[7].param_value},
   {settname:data.setting[8].setting_param, labelname:"Virtual Sms",  settvalue:data.setting[8].param_value},
   {settname:data.setting[9].setting_param, labelname:"Mobile App",  settvalue:data.setting[9].param_value},
   {settname:data.setting[10].setting_param, labelname:"Missed Call Service",  settvalue:data.setting[10].param_value},
   {settname:data.setting[11].setting_param, labelname:"Time based routing",  settvalue:data.setting[11].param_value},
   {settname:data.setting[12].setting_param, labelname:"Voicemail calltransfer",  settvalue:data.setting[12].param_value},
   {settname:data.setting[13].setting_param, labelname:"Voicemail",  settvalue:data.setting[13].param_value},
   {settname:data.setting[14].setting_param, labelname:"stickyagents",  settvalue:data.setting[14].param_value},
   {settname:data.setting[15].setting_param, labelname:"Click-to-call feature",  settvalue:data.setting[15].param_value},
   {settname:data.setting[16].setting_param, labelname:"CRM hook api",  settvalue:data.setting[16].param_value},
   {settname:data.setting[17].setting_param, labelname:"Custom hold music",  settvalue:data.setting[17].param_value},
   {settname:data.setting[18].setting_param, labelname:"Custom on-hold music",  settvalue:data.setting[18].param_value},
   {settname:data.setting[19].setting_param, labelname:"Parallel ringing",  settvalue:data.setting[19].param_value},
   {settname:data.setting[20].setting_param, labelname:"Billing status",  settvalue:data.setting[20].param_value},
   {settname:data.setting[21].setting_param, labelname:"Missed call sound",  settvalue:data.setting[21].param_value},
   {settname:data.setting[22].setting_param, labelname:"Ticket number reset", settvalue:data.setting[22].param_value},

],
othersetting:{
  A0:{
    setting_param:"Display Logs Type",
    param_value:data.setting[23].param_value
  },
  A1:{
    setting_param:"Coin Allocation Type",
    param_value:data.setting[24].param_value.toLowerCase(),
  },
  A2:{
    setting_param:"Missed Calls Allocation",
    param_value:data.setting[25].param_value
  },
  A3:{
    setting_param:"Call Time Capping",
    param_value:function(ctimedata){
      if(ctimedata==null || ctimedata==''){
   return 'NA';
      }
      else{
   return ctimedata;
      }
    }(data.setting[26].param_value)
},
A4:{
  setting_param:"Channel Allocation",
  param_value:data.setting[27].param_value
},
A5:{
  setting_param:"Channel_alloc_type",
  param_value:data.setting[28].param_value
},
A6:{
  setting_param:"Email Calllog Report",
  param_value:data.setting[29].param_value
},
A7:{
  setting_param:"User Call Forwarding",
  param_value:data.setting[30].param_value
},
A8:{
  setting_param:"Email Alert Setting",
  param_value:data.setting[31].param_value
},
A9:{
  setting_param:"Low balance Alert",
  param_value:data.setting[32].param_value
},
A10:{
  setting_param:"round_robin_retry_cnt",
  param_value:data.setting[33].param_value
},
A11:{
  setting_param:"Default Language ",
  param_value:function(slanguage){
    var slang;
  for(let i=0; i<slanguage.length;i++){
    if(slanguage[i].default_status=='1'){
      slang = slanguage[i].lang_acro;
      return slang;
    }
  }

  }(data.select_lang_list)
},
A12:{
  setting_param:"Other Language",
  param_value:function(slanguage){
    var slang=[];
  for(let i=0; i<slanguage.length;i++){
    if(slanguage[i].default_status=='0'){
      slang.push(slanguage[i].lang_acro)
    }
  }
return slang;
  }(data.select_lang_list)
},
A13:{
  setting_param:"agent allowed",
  param_value:data.setting[34].param_value
},
A14:{
  setting_param:"Manager account",
 param_value:data.setting[35].param_value
},
A15:{
setting_param:"smsaccount",
 param_value:data.setting[38].param_value
},
A16:{
  setting_param:"ibd_channel",
   param_value:function(channeltype){
     if(channeltype=='Common'){
      return '';
     }
     else{
    return data.setting[36].param_value;
     }

   }(data.setting[28].param_value),
},
A17:{
    setting_param:"obd_channel",
     param_value:function(channeltype){
      if(channeltype=='Common'){
       return '';
      }
      else{
     return data.setting[37].param_value;
      }

    }(data.setting[28].param_value),
    },
    A18:{
      setting_param:"channel_pooling",
      param_value:data.profile[0].channel_type
      },

},
    crmsetting:{
      crm_name:data.profile[0].crm_name,
     // crmsett:data.profile[0].crm_name,
    capture_call_status:data.crm_details[0] && data.crm_details[0].capture_call_logs_status,
    capture_call_input:data.crm_details[0] && data.crm_details[0].capture_call_logs,
    click_tocall_status:data.crm_details[0] && data.crm_details[0].click_2_call_status,
    click_tocall_input:data.crm_details[0] && data.crm_details[0].click_2_call,
    agentpopup_status:data.crm_details[0] && data.crm_details[0].agent_popup_status,
    agentpopup_input:data.crm_details[0] && data.crm_details[0].agent_popup,
    callrouting_status:data.crm_details[0] && data.crm_details[0].call_routing_status,
    callrouting_input:data.crm_details[0] && data.crm_details[0].call_routing,
    singlpopup_status:data.crm_details[0] && data.crm_details[0].single_sign_status,
    singlpopup_input:data.crm_details[0] && data.crm_details[0].single_sign,
    calldisposition_status:data.crm_details[0] && data.crm_details[0].call_disposition_status,
    calldisposition_input:data.crm_details[0] && data.crm_details[0].call_disposition,
    },
 remarks:'',
     userid:data.profile[0].user_id,
     stype:this.stype,
     subtype:this.subtype,
     account_id:data.profile[0].account_id
  })

  })

  }
  getallcommon(obd,ibd,email,sms,tfree,fax){
if(obd=='Common' && ibd=='Common' && email=='Common' && sms=='Common' && tfree=='Common' && fax=='Common'){
return 'allcommon'
}
else if(obd=='Self' && ibd=='Self' && email=='Self' && sms=='Self' && tfree=='Self' && fax=='Self'){
return 'allself'
}
else{
    return 'customized'
}

  }

  releasedid(){
this.didlist = true;
this.didnumberbox= false;
  }
  freezedid(eve,didvalue){
    if(eve.target.innerHTML=="Freeze" && didvalue!==''){
      this.http.get(this.baseurl+"freeze_vnum?didnum="+didvalue+"&type=did").subscribe((data:any)=>{
        //  console.log(data)
      });

      // this.vagentForm.get('plandetail').get('did_no').disable();
          $("#freezelink").empty('');
          $("#freezelink").text('Unfreeze');

     }
        else{
          this.http.get(this.baseurl+"unfreeze_vnum?didnum="+didvalue+"&type=did").subscribe((data:any)=>{
            // console.log(data)
          });
       //   this.vagentForm.get('plandetail').get('did_no').enable();
          $("#freezelink").empty('');
          $("#freezelink").text('Freeze');

        }

    }
  releasebkpdid(){
this.bckdidlist = true;
this.bkpdidnumberbox= false;
  }

  freezebkpdid(eve,bkpdidvalue){
    this.bckdidlist = true;
    if(eve.target.innerHTML=="Freeze" && bkpdidvalue!==''){
  // this.vagentForm.get('plandetail').get('did_no_bk').disable();
      $("#bkpdidlink").empty('');
      $("#bkpdidlink").text('Unfreeze');
      this.http.get(this.baseurl+"freeze_vnum?didnum="+bkpdidvalue+"&type=did").subscribe((data:any)=>{
     //   console.log(data)
    })
    }
    else{
    //  this.vagentForm.get('plandetail').get('did_no_bk').enable();
      $("#bkpdidlink").empty('');
      $("#bkpdidlink").text('Freeze');
      this.http.get(this.baseurl+"unfreeze_vnum?didnum="+bkpdidvalue+"&type=did").subscribe((data:any)=>{
       // console.log(data)
    })
    }
  //this.didnumberbox= true;
    }
  releasevnumber(){
this.virtualnumberbox = false;
this.virtualnumberradioBtn = true
  }
  freezevnumber(evt,vnumber){
    if(evt.target.innerHTML=="Freeze" && vnumber!==''){
       //  this.vagentForm.get('plandetail').get('virtual_number').disable();
         $("#vnumlink").empty('');
         $("#vnumlink").text('Unfreeze');
         this.http.get(this.baseurl+"freeze_vnum?didnum="+vnumber+"&type=vnum").subscribe((data:any)=>{
          // console.log(data)
       })
       }
       else{
       //  this.vagentForm.get('plandetail').get('virtual_number').enable();
         $("#vnumlink").empty('');
         $("#vnumlink").text('Freeze');
         this.http.get(this.baseurl+"unfreeze_vnum?didnum="+vnumber+"&type=vnum").subscribe((data:any)=>{
          // console.log(data)
       })
       }

     }

  sameasdid(){
    this.virtualnumbersec=false;
    this.vagentForm.get('plandetail').get('virtual_number').setValue(this.vagentForm.get('plandetail').get('did_no').value);
  }
  choosevirnum(){
    this.virtualnumbersec=true;
  }
  changeCRM(crmvalue:any){
    if(crmvalue=='minavo'){
      this.crmsettsec=false;
    }
  else{
    this.crmsettsec=true;
  }

  }
  editform(){
this.vagentForm.enable();
this.buttunhide = true;
$(".agentselect,.cliselect").prop('disabled',false);
$(".cliBox").hide();
this.hideagentlist=true;
  }

  checkdid(event){
    if(event.target.value.length>9){
    this.vagentForm.get('plandetail').get('did_network').setValue('');
    this.vagentForm.get('plandetail').get('did_network').updateValueAndValidity();
   this.vagentForm.get('plandetail').get('did_outcall_cli').setValue('');
   this.vagentForm.get('plandetail').get('did_outcall_cli').updateValueAndValidity();
   this.vagentForm.get('plandetail').get('did_pool_network').setValue('');
   this.vagentForm.get('plandetail').get('did_pool_network').updateValueAndValidity();
     $('#did_pool_network').val(null).trigger('change');
   this.nubmerfreezed='';
   this.didnetworkcli='';
   this.records.did_network=[];

    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+"check_vnum",{vnum:event.target.value,user_id:this.user_id}).subscribe((data:any)=>{
      $(".fullpageloader").fadeOut('fast');
 this.nubmerfreezed= data;

if(this.nubmerfreezed.status=='success'){
  $("#freezelink").prop('disabled',false);
  }
  else{
    $("#freezelink").prop('disabled',true);
  }
  })
  }

}
checkbackupdid(event){
  if(event.target.value.length>9){
  this.vagentForm.get('plandetail').get('did_bkp_network').setValue('');
  this.vagentForm.get('plandetail').get('did_bkp_network').updateValueAndValidity();
 this.vagentForm.get('plandetail').get('bkpdid_outcall_cli').setValue('');
 this.vagentForm.get('plandetail').get('bkpdid_outcall_cli').updateValueAndValidity();
 this.vagentForm.get('plandetail').get('backupdid_pool_network').setValue('');
 this.vagentForm.get('plandetail').get('backupdid_pool_network').updateValueAndValidity();
 $('#backupdid_pool_network').val(null).trigger('change');
 this.backupdid='';
 this.didnetworkcli1='';
 this.records.bkp_did_network=[];

  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+"check_vnum",{vnum:event.target.value,user_id:this.user_id}).subscribe((data:any)=>{
    $(".fullpageloader").fadeOut('fast');

this.backupdid= data;
if(this.backupdid.status=='success'){
  $("#bkpdidlink").prop('disabled',false);
  }
  else{
    $("#bkpdidlink").prop('disabled',true);
  }
})
}
}
checkvnum(event){
  if(event.target.value.length>9){

  this.http.post(this.baseurl+"check_vnum",{vnum:event.target.value,user_id:this.user_id}).subscribe((data:any)=>{

this.virtualnumber= data;

if(this.virtualnumber.status=='success'){
$("#vnumlink").prop('disabled',false);
}
else{
  $("#vnumlink").prop('disabled',true);
}

})
}
}

changedidnetwork(cdidval,didnum){
  let dnid=cdidval.target.options[cdidval.target.selectedIndex].dataset.id;
  $(".fullpageloader").fadeIn('fast');
  this.records.did_network_cli=[];
  this.records.did_network_cli_did=[];
if(cdidval.target.value!=''){
  this.vagentForm.get('plandetail').get('did_network_input').setValue(cdidval.target.options[cdidval.target.options.selectedIndex].text);
  this.vagentForm.get('plandetail').get('did_network_input').updateValueAndValidity();

}
else{
  this.vagentForm.get('plandetail').get('did_network_input').setValue('');
  this.vagentForm.get('plandetail').get('did_network_input').updateValueAndValidity();

}

//alert(cdidval.target.value+" "+didnum);
this.http.post(this.baseurl+"get_cli",{id:dnid,did:didnum}).subscribe((data)=>{
  $(".fullpageloader").fadeOut('fast');
this.didnetworkcli = data
})
}
changedidnetwork1(cdidval,didnum){
  let dnid=cdidval.target.options[cdidval.target.selectedIndex].dataset.id;
  this.records.bkp_did_network_cli=[];
  this.records.bkp_did_network_cli_did=[];
  if(cdidval.target.value!=''){
    this.vagentForm.get('plandetail').get('bkpdid_network_input').setValue(cdidval.target.options[cdidval.target.options.selectedIndex].text);
    this.vagentForm.get('plandetail').get('bkpdid_network_input').updateValueAndValidity();

  }
  else{
    this.vagentForm.get('plandetail').get('bkpdid_network_input').setValue('');
    this.vagentForm.get('plandetail').get('bkpdid_network_input').updateValueAndValidity();

  }
  //alert(cdidval.target.value+" "+didnum);
  $(".fullpageloader").fadeIn('fast');
  this.http.post(this.baseurl+"get_cli",{id:dnid,did:didnum}).subscribe((data)=>{
    $(".fullpageloader").fadeOut('fast');
  this.didnetworkcli1 = data
  })
  }

creattopupdemoform(){
this.topupdemoform = this.fb.group({
  amount:['',[Validators.required]],
  coins:['',[Validators.required]],
  remarks:['',[Validators.required]],
  topuptype:['',[Validators.required]],
  user_id:this.user_id,
  service_type:this.subtype,
  login_id:this.currentUserloclstrage.user_id

})
}

get g(){
  return this.topupdemoform.controls
}
topupdemo(){
  this.submitted1 = true;
  let topupdata= JSON.stringify(this.topupdemoform.value);
  if(this.topupdemoform.invalid){
return false
  }
  else{
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+"demo_topup",{data:topupdata}).subscribe((data:any)=>{
     // console.log(data);
      if(data.status=="success"){
        $("#topupdemo").modal('hide');
        $(".fullpageloader").fadeOut('fast');
          this.toastr.success(data.message, 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });

        }
      else if(data.status=="fail"){
        $(".fullpageloader").fadeOut('fast');
       this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });

      }
    },
    error => {
     $(".fullpageloader").fadeOut('fast');
      this.toastr.error(error, 'error!',{
        positionClass:'toast-top-center',
        timeOut: 3000
      });
  });
  }
}

creatrenwaldemoform(){
  this.demorenewform = this.fb.group({
    renew_type:['',[Validators.required]],
    remarks:['',[Validators.required]],
    user_id:this.user_id,
    service_type:this.subtype,
    loginid:this.currentUserloclstrage.user_id
  })
  }
  get h(){
    return this.demorenewform.controls
  }
  demorenew(){
    this.submitted2 = true;
    let renewdata= JSON.stringify(this.demorenewform.value);
    if(this.demorenewform.invalid){
  return false
    }
    else{
      $(".fullpageloader").fadeIn('fast');
      this.http.post(this.baseurl+"demo_renewal",{data:renewdata}).subscribe((data:any)=>{
        console.log(data);
        if(data.status=="success"){
          $("#renewdemo").modal('hide');
          $(".fullpageloader").fadeOut('fast');
            this.toastr.success(data.message, 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });

          }
        else if(data.status=="fail"){
          $(".fullpageloader").fadeOut('fast');
         this.toastr.error(data.message, 'Error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });

        }
      },
      error => {
       $(".fullpageloader").fadeOut('fast');
        this.toastr.error(error, 'error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
    });
    }
  }
  getremarkslist(user_id){
    this.http.get(this.baseurl+"get_status_history?user_id="+user_id).subscribe(
      (result:any)=>{
        console.log(result);
      this.remarksrecords= result;
     });
  }

  persmsmodal(){
    if($("#persmscoin").val()!=''){
      $(".fullpageloader").fadeIn('fast');
      this.http.get(this.baseurl+'update_persnal_sms_coins?user_id='+this.user_id+'&service_type='+this.subtype+'&coins='+$("#persmscoin").val()).subscribe((data:any)=>{
        if(data.status=='success'){
          $(".fullpageloader").fadeOut('fast');
          $("#persmsmodal").modal('hide');
          $("#persmscoin").val('');
          this.toastr.success(data.message, 'Success!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });
        }
        else{
            $(".fullpageloader").fadeOut('fast');
            this.toastr.error(data.message, 'Error!',{
              positionClass:'toast-top-center',
              timeOut: 3000
            });

      }
    })

  }
  else{
    $("#persmscoin").css('border-color','#ff0000');
    setTimeout(() => {
      $("#persmscoin").css('border-color','#b8b8b8');
    }, 3000);
  }
}


senderid(){
  if($("#senderid").val()!='' && $("#peid").val()!=''){
    $(".fullpageloader").fadeIn('fast');
    this.http.get(this.baseurl+'approve_sender_id?user_id='+this.user_id+'&sender_id='+$("#senderid").val()+'&peid='+$("#peid").val()).subscribe((data:any)=>{
      if(data.status=='success'){
        $(".fullpageloader").fadeOut('fast');
        $("#senderidmodal").modal('hide');
        $("#senderid,#peid").val('');
        this.toastr.success(data.message, 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
      }
      else{
          $(".fullpageloader").fadeOut('fast');
          this.toastr.error(data.message, 'Error!',{
            positionClass:'toast-top-center',
            timeOut: 3000
          });

    }
  })

}
else{
  if($("#senderid").val()!=''){
    $("#peid").css('border-color','#ff0000');
    setTimeout(() => {
      $("#peid").css('border-color','#b8b8b8');
    }, 3000);
  }
  else if($("#peid").val()!=''){
    $("#senderid").css('border-color','#ff0000');
    setTimeout(() => {
      $("#senderid").css('border-color','#b8b8b8');
    }, 3000);
  }
  else{
    $("#senderid,#peid").css('border-color','#ff0000');
    setTimeout(() => {
      $("#senderid,#peid").css('border-color','#b8b8b8');
    }, 3000);
  }

}
}


extenddate(){
  if($("#extendday").val()!='' && $("#extendremark").val()!=''){
    $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+'extend_demo',{user_id:this.user_id,service_type:this.subtype,days:$("#extendday").val(),remarks:$("#extendremark").val(),session_id:this.session_id}).subscribe((data:any)=>{
      if(data.status=='success'){
        $(".fullpageloader").fadeOut('fast');
        $("#extendmodal").modal('hide');
        $("#extendday,#extendremark").val('');
        this.toastr.success(data.message, 'Success!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
      }
      else{
        $(".fullpageloader").fadeOut('fast');
        this.toastr.error(data.message, 'Error!',{
          positionClass:'toast-top-center',
          timeOut: 3000
        });
      }

  })

}
else{
  if($("#extendday").val()==''&& $("#extendremark").val()==''){
    $("#extendday,#extendremark").css('border-color','#ff0000');
    setTimeout(() => {
      $("#extendday,#extendremark").css('border-color','#b8b8b8');
    }, 3000);
  }
 else if($("#extendday").val()==''){
  $("#extendday").css('border-color','#ff0000');
  setTimeout(() => {
    $("#extendday").css('border-color','#b8b8b8');
  }, 3000);
 }
 else{
  $("#extendremark").css('border-color','#ff0000');
  setTimeout(() => {
    $("#extendremark").css('border-color','#b8b8b8');
  }, 3000);
 }

}
}
changeagentnetwork(count){
$("#cliselect"+count).val('');
}
agentcheckdid(clicval,i) {
  if(clicval.length==10){
    if(clicval!=''){
      $(".fullpageloader").fadeIn('fast');
         this.agentnubmerfreezed[i]='';
        this.http.post(this.baseurl+'check_vnum_c2c',{vnum:clicval,user_id:this.user_id}).subscribe((data:any)=>{
             $(".fullpageloader").fadeOut('fast');
        this.agentnubmerfreezed[i]= data;
       console.log(data);
       if(this.agentnubmerfreezed[i].status == 'fail'){

        //  this.nubmerfreezed[i]='';
         this.toastr.error("Out CLI already assigend. Please choose Another.", 'Error!',{
           positionClass:'toast-top-center',
           timeOut: 3000
         });
       }else{

       }

         })
        }
        else{
         this.agentnubmerfreezed[i]='';
        }

  }
   }

   vagentlistdata(sid,allbkp_did){
     //console.log(allbkp_did);
    if(allbkp_did==null || allbkp_did==undefined || allbkp_did==''){
      this.vagentcurdata =[];
  }
    else{
     // var siddata=sid.split(",");

      this.vagentcurdata=allbkp_did;
    }

  }

checkdidbackup(valuenew){
if(valuenew!=''){
  $(".fullpageloader").fadeIn('fast');
    this.http.post(this.baseurl+"check_vnum",{vnum:valuenew.toString(),user_id:this.user_id}).subscribe((data:any)=>{
      $(".fullpageloader").fadeOut('fast');
 this.nubmerfreezedbkp= data;
 $('#vagentid').select2({
  closeOnSelect: false
});
  })
}



}
}