import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import {formatDate } from '@angular/common';


@Component({
  selector: 'app-crmapilog',
  templateUrl: './crmapilog.component.html',
  styleUrls: ['./crmapilog.component.css']
})
export class CrmapilogComponent implements OnInit {

 crmapi;
 itemsPerPage:any=15;
  currentPage:number =1;
   filterform:FormGroup;
  submitted=false;
  today= new Date();
  jstoday = '';
  baseurl:any;
  constructor(private http:HttpClient,private fb:FormBuilder) { 
this.baseurl =  localStorage.getItem('baseurl');
    this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    this.filterform = this.fb.group({
      user_id: [''],
      service_type:[''],
      fromdate: [this.jstoday],
      todate: [this.jstoday],
     
    })}
 

  ngOnInit() {
    var self = this;
   this.getdata(this.currentPage)
    $("#logtype").on('change',function(){
     switch($(this).val()){
      case '2':
        $("#user_id").val('');
        self.filterform.value.user_id ='';
      $("#byuserid").show('fast');
       break;
      default:
          self.filterform.value.user_id ='';
          $("#user_id").val('');
          $("#byuserid").hide('fast');
     }
    })
  }


  onSubmit()
  {
      this.submitted=true;
  //console.log(this.filterform.value);
   let user_id=this.filterform.value.user_id;
   let service_type=this.filterform.value.service_type;
   let fromdate=$('#fromdate').val();
   let todate=$('#todate').val()
  


   $('.fullpageloader').show('fast');
    this.http.get(this.baseurl+'crm_api_logs?user_id='+user_id+'&fromdate='+fromdate+'&todate='+todate+'&service_type='+service_type).subscribe(
    (data)=>{
      this.crmapi=data;
        $('.cfillBox').slideUp('fast');
       $('.fullpageloader').hide('fast');

    })
  }
  
  getdata(crpage:number)
  {  
     $('.fullpageloader').show('fast');
     this.http.get(this.baseurl+'crm_api_logs?page='+crpage).subscribe(
     data=>{
     this.crmapi=data;
      //console.log(data);
 $('.fullpageloader').hide('fast');
     })
  }


  pageChange(newpage:number){
    this.getdata(newpage);
    this.currentPage = newpage;
      }
}
