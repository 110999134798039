import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-addressvnumdidsingle',
  templateUrl: './addressvnumdidsingle.component.html',
  styleUrls: ['./addressvnumdidsingle.component.css']
})
export class AddressvnumdidsingleComponent implements OnInit {
  addvnumsingleForm:FormGroup;
  submitted:boolean= false;
  inventory_id:any;
  baseurl:any;
  resellerList:any;

  operaters=['Airtel','Reliance','Vodafone','BSNL','IDEA','Tata Indicom','Aircel','MTNL','Spice','MTNL'];
  circles=['Andaman and Nicobar Islands','Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chandigarh','Chhattisgarh','Dadra and Nagar Haveli','Delhi','Goa','Gujarat','Haryana','Himachal Pradesh','Jammu and Kashmir','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra','Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Puducherry','Punjab','Rajasthan','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal'];
  
  constructor(private http:HttpClient, private fb: FormBuilder,private toastr: ToastrService, private router:Router , private route:ActivatedRoute) {
   this.inventory_id = this.route.snapshot.paramMap.get("id");
   
   }

  ngOnInit() {
    this.baseurl = localStorage.getItem('baseurl');
    if (this.inventory_id !== null){
      this.editform(this.inventory_id)
      }
this.getressellerlist();
this.createForm();

  }
createForm(){
  this.addvnumsingleForm = this.fb.group({
    resel_id:['',[Validators.required]],
    virtual_number:['',[Validators.required]],
    operator:['',[Validators.required]],
    circle:['',[Validators.required]],
    m_number_type:['',[Validators.required]],
    inventory_id:[''],
  })
}

editform(id:number){
  $(".fullpageloder").fadeIn('fast');
this.http.get(this.baseurl+"reseller_number_details?inventory_id="+id).subscribe((data:any)=>{
// console.log(data);
 
this.addvnumsingleForm.setValue({
  resel_id:data.data[0].resel_id,
  virtual_number:data.data[0].m_number,
  operator:data.data[0].operator,
  circle:data.data[0].circle,
  m_number_type:data.data[0].m_number_type,
  inventory_id:data.data[0].inventory_id,
});
this.addvnumsingleForm.get("resel_id").disable({ onlySelf: true });
$(".fullpageloder").fadeOut('fast');
})
}
get f(){return this.addvnumsingleForm.controls;}
onSubmit(){
this.submitted=true;
let addvnumsformdata = JSON.stringify(this.addvnumsingleForm.value);
if(this.addvnumsingleForm.invalid){
 //alert(this.addvnumsingleForm.value) 
return false;
}
else{
$(".fullpageloader").fadeIn('fast');
this.http.post(this.baseurl+"add_resller_single_inventry",{data:addvnumsformdata}).subscribe((data:any)=>{
 
  if(data.status=='success'){
    $(".fullpageloader").fadeOut('fast');
    this.toastr.success('Data Submitted  succesfully!', 'Success!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
    this.router.navigateByUrl('/resseller/resellervirtualinventry');
  }
  else if(data.status=="fail"){
    console.log("fail");
    $(".fullpageloader").fadeOut('fast');
    this.toastr.error(data.message, 'Error!',{
       positionClass:'toast-top-center',
       timeOut: 3000
     });
    
   }
}, error => {
  $(".fullpageloader").fadeOut('fast');
  this.toastr.error(error, 'error!',{
    positionClass:'toast-top-center',
    timeOut: 3000
  });
}
)

}
}
getressellerlist(){
  this.http.get(this.baseurl+"get_reseller_list").subscribe((data:any)=>{
    this.resellerList = data.data;
  })
    }
}
