import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormsModule }   from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {
agentdata:any;
itemsPerPage:any=15;
  currentPage:number =1;
  currentUserloclstrage:any;
  constructor(private http:HttpClient,private toastr: ToastrService,private router:Router , private route:ActivatedRoute) { }

  ngOnInit() {
    this.currentUserloclstrage = JSON.parse(localStorage.getItem('currentUser'));
    setTimeout(() => {
      
      if(this.currentUserloclstrage.user_type=='User'){
        this.router.navigate(['']);
      }
      else{
        this.router.navigate(['/agent']); 
      }
    }, 1);
    this.getdata(this.currentPage);
    //user change status code 
   
  }
  changestatus(user_id,status){
    $(".fullpageloader").show();
  var statusval = status.target.checked; 
   this.http.post(" https://api.cerebra.quadrafort.in/change_agent_status",{user_id:user_id,status:statusval}).subscribe(
     (data:any)=>{
   
    $(".fullpageloader").hide();
    this.toastr.success("Agent status change successfuly",'Success!',{
      positionClass:'toast-top-center',
      timeOut: 3000
    });
   
     }
   )
  };
   getdata(crpage:number){
  
     $(".fullpageloader").show();
    this.http.get(" https://api.cerebra.quadrafort.in/agent?page="+crpage).subscribe(
      (data:any)=>{
      this.agentdata = data;
    // console.log(this.agentdata);
     $(".fullpageloader").hide();
    })
  }
  serachRecords(sinpuval:any){
if(sinpuval==''){
  $(".serachpanel").addClass('error');
}
else{
 
  $(".fullpageloader").show();
  this.http.get(" https://api.cerebra.quadrafort.in/agent?search="+sinpuval).subscribe(
    (data:any)=>{
    this.agentdata = data;
  // console.log(data.length);
   $(".fullpageloader").hide();
  })
}
  }

  pageChange(newpage:number){
    this.getdata(newpage);
    this.currentPage = newpage;
      }

}
