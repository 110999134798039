import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators,FormArray, FormControl } from '@angular/forms';
import { CanActivate, ActivatedRoute ,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {formatDate } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-calloverall',
  templateUrl: './calloverall.component.html',
  styleUrls: ['./calloverall.component.css']
})
export class CalloverallComponent implements OnInit {

    servicelog;
    itemsPerPage:any=15;
   currentPage:number =1;
     filterform:FormGroup;
   submitted=false;
   today= new Date();
   jstoday = '';
   constructor(private http:HttpClient,private fb:FormBuilder, private route:Router) { 
 
  this.jstoday = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
     this.filterform = this.fb.group({
       user_id: [''],
       fromdate: [''],
       todate: [''],
       mobile:[''],
       email:['']
     })
 
   }
 
   ngOnInit() {
  this.getdata(this.currentPage,'','');
 $("#reptype").on('change',function(){
   //alert($(this).val());
   switch($(this).val()){
     case 'bymobile':
   $("#bymobile").show('fast');
   $("#byemail,#byuserid").hide('fast');
   break;
   case 'byemail':
   $("#byemail").show('fast');
   $("#bymobile,#byuserid").hide('fast');
   break;
   case 'byuserid':
   $("#byuserid").show('fast');
   $("#byemail,#bymobile").hide('fast');
   break;
   default:
  $("#byemail,#byuserid,#bymobile").hide('fast');
 
   }
 })
   }
 
 onSubmit()
 {
    this.submitted=true;
   //console.log(this.filterform.value);
    let fromdate=$('#fromdate').val();
    let todate=$('#todate').val()
    $('.fullpageloader').show('fast');
     this.http.get(' https://api.cerebra.quadrafort.in/all_call_report?from_date='+fromdate+'&to_date='+todate).subscribe(
     (data)=>{
       this.servicelog=data;
         $('.cfillBox').slideUp('fast');
        $('.fullpageloader').hide('fast');
 
     })
 
 }

   getdata(crpage:number,fromdate,todate)
   {

      if(fromdate && todate)
      {
       
        $('.fullpageloader').show('fast');
        this.http.get(' https://api.cerebra.quadrafort.in/all_call_report?page='+crpage+'&from_date='+fromdate+'&to_date='+todate).subscribe(
        (data)=>{
           this.servicelog=data;
           $('.fullpageloader').hide('fast');
        });
      }
      else
      {

        $('.fullpageloader').show('fast');
        this.http.get(' https://api.cerebra.quadrafort.in/all_call_report?page='+crpage).subscribe(
        (data)=>{
           this.servicelog=data;
           $('.fullpageloader').hide('fast');
        });

      }
   }
 
    pageChange(newpage:number){
      let fromdate=$('#fromdate').val();
    let todate=$('#todate').val();
     this.getdata(newpage,fromdate,todate);
     this.currentPage = newpage;
       }
     navigateRoute(cdate:string,id:string,){
      this.route.navigate(['/reports/calloverallview',cdate,id]);
    
    }
}
