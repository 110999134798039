﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../_services';
import { ToastrService } from 'ngx-toastr';
@Component({
     templateUrl: 'login.component.html',
     styleUrls: ['login.component.css']
 })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';
    backerror:boolean= false;
    backenderror='';

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) { 
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {

        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    //  setTimeout(() => {
    //          this.loginForm = this.formBuilder.group({
    //         username: ['', Validators.required],
    //         password: ['', Validators.required]
    //     });
    // },1);

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
   
    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return false;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe()
            .subscribe(
                data => {
                  console.log(data);
                 this.backenderror = data.message;
                  this.backerror=true;
                  $("#backenerroediv").show('fast');
                    this.router.navigate([this.returnUrl]);
                    this.loading = false;
                    setTimeout(function(){ 
                        $("#backenerroediv").hide('fast'); 
                     }, 3000);
                    
                     },
                error => {
                   
                    this.error = error;
                    
                    this.loading = false;
                });
    }
}
